<template>
  <div>
    <div v-if="this.field.fieldType == 'height'">
      <label for="cardName" class="card-input__label">{{
        this.field.fieldName
      }}</label>
      <div class="row">
        <div class="card-input col">
          <select
            class="card-input__input -select"
            v-model="field.fieldValue"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Feet</option>
            <option value="0" class="option">0</option>
            <option value="1" class="option">1</option>
            <option value="2" class="option">2</option>
            <option value="3" class="option">3</option>
            <option value="4" class="option">4</option>
            <option value="5" class="option">5</option>
            <option value="6" class="option">6</option>
            <option value="7" class="option">7</option>
            <option value="8" class="option">8</option>
          </select>
        </div>
        <div class="card-input col">
          <select
            class="card-input__input -select"
            v-model="field.fieldValue2"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Inches</option>
            <option value="'0" class="option">0</option>
            <option value="'1" class="option">1</option>
            <option value="'2" class="option">2</option>
            <option value="'3" class="option">3</option>
            <option value="'4" class="option">4</option>
            <option value="'5" class="option">5</option>
            <option value="'6" class="option">6</option>
            <option value="'7" class="option">7</option>
            <option value="'8" class="option">8</option>
            <option value="'9" class="option">9</option>
            <option value="'10" class="option">10</option>
            <option value="'11" class="option">11</option>
          </select>
        </div>
      </div>
    </div>
    <!--email input type-->
    <div v-if="this.field.fieldType == 'email'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            this.field.fieldName
          }}</label>
          <input
            type="email"
            placeholder="Email"
            id="cardName"
            class="card-input__input"
            v-model="field.fieldValue"
            @change="handleChange"
            required
          />
        </div>
      </div>
    </div>
    <!--Phone Number Type-->
    <div v-if="this.field.fieldType == 'phoneNumber'">
      <div class="card-input">
        <label for="phoneNumber" class="card-input__label">{{
          this.field.fieldName
        }}</label>
        <input
          type="text"
          id="phoneNumber"
          class="card-input__input"
          placeholder="Phone Number"
          v-model="field.fieldValue"
          @change="handleChange"
          required
        />
      </div>
    </div>
    <!--Text-short Input-->
    <div v-if="this.field.fieldType == 'textShort'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            this.field.fieldName
          }}</label>
          <input
            type="text"
            id="cardName"
            class="card-input__input"
            v-model="field.fieldValue"
            @change="handleChange"
            required
          />
        </div>
      </div>
    </div>
    <!--Text-Long Input-->
    <div v-if="this.field.fieldType == 'textLong'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            this.field.fieldName
          }}</label>
          <textarea
            type="text"
            id="cardName"
            class="card-input__input"
            rows="4"
            cols="50"
            style="height: 120px"
            v-model="field.fieldValue"
            @change="handleChange"
            required
          ></textarea>
        </div>
      </div>
    </div>
    <!--Phone Number Type-->
    <div v-if="this.field.fieldType == 'number'">
      <div class="card-input">
        <label for="phoneNumber" class="card-input__label">{{
          this.field.fieldName
        }}</label>
        <input
          type="number"
          id="Number"
          class="card-input__input"
          placeholder="Number"
          v-model="field.fieldValue"
          @change="handleChange"
          required
        />
      </div>
    </div>
    <!--yesNo Type-->
    <div v-if="this.field.fieldType == 'yesNo'">
      <div class="col">
        <div class="card-input">
          <label for="phoneNumber" class="card-input__label">{{
            this.field.fieldName
          }}</label>
          <select
            class="card-input__input -select"
            style="background-position: 95% center"
            v-model="field.fieldValue"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Yes/No</option>
            <option value="Yes" class="option">Yes</option>
            <option value="No" class="option">No</option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="this.field.fieldType == 'dropdown'">
      <div class="col">
        <div class="card-input">
          <label for="phoneNumber" class="card-input__label">{{
            this.field.fieldName
          }}</label>
          <select
            class="card-input__input -select"
            style="background-position: 95% center"
            v-model="field.fieldValue"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Select</option>
            <template v-for="option in this.field.fieldOptions" :key="option">
              <option :value="option" class="option">
                {{ option }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <div v-if="this.field.fieldType == 'fileUpload'">
      <div class="row">
        <div class="card-input col">
          <p
            class="hoverLink"
            :id="this.index + 'downloadFile'"
            @click="getCampFile('fileDownload' + this.field.fieldName)"
            style="text-decoration: underline; color: #363166"
          >
            <span
              >{{ this.field.fieldName }}
              <i class="fa-solid fa-file-arrow-down fa-lg"></i>
              <i
                v-if="this.field.uploadedFileName !== ''"
                class="fa-sharp fa-solid fa-circle-check fa-lg"
                style="color: #19d412; margin-left: 5px"
              ></i
            ></span>
          </p>
        </div>
        <div class="card-input col">
          <p v-if="this.field.uploadedFileName !== ''" style="color: #19d412">
            {{ this.field.uploadedFileName }}
          </p>
          <input
            type="file"
            accept="*"
            @change="uploadCamperFile($event)"
            class="hidden"
            :id="this.index"
          /><label
            class="link-primary hoverLink text-center center"
            :for="this.index"
            ><span class="text-end"
              >Upload file
              <i class="fa-sharp fa-solid fa-file-arrow-up fa-lg"></i
            ></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert'
export default {
  components: {},
  props: {
    presetField: {
      type: Object,
      default: () => ({
        fieldValue: '',
        fieldValue2: '',
        uploadedFileName: '',
        uploadedFileLocation: '',
        S3key: '',
        fieldType: '',
        fieldName: '',
      }),
    },
    index: Number,
  },
  data() {
    return {
      field: {
        fieldValue: this.presetField.fieldValue,
        fieldValue2: this.presetField.fieldValue2,
        uploadedFileName: this.presetField.uploadedFileName,
        uploadedFileLocation: this.presetField.uploadedFileLocation,
        S3key: this.presetField.S3key,
        fieldType: this.presetField.fieldType,
        fieldName: this.presetField.fieldName,
      },
    }
  },
  methods: {
    uploadCamperFile(event) {
      const URL =
        import.meta.env.VITE_APP_DB_URL + 'util/uploadCamperFileNoSave'
      let data = new FormData()
      data.append('file', event.target.files[0])
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(res => {
          console.log(res.data)
          this.field.uploadedFileLocation = res.data.location
          this.field.uploadedFileName = res.data.name
          this.field.S3key = res.data.S3Key
        })
        .catch(function (err) {
          this.uploadedFileLocation = ''
          this.uploadedFileName = ''
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
        .finally(() => {
          this.$emit('changed', {
            field: this.field,
          })
        })
    },
    handleChange() {
      this.$emit('changed', { field: this.field })
    },
    getCampFile(domID) {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'util/getCampFile?S3Key=' +
        this.fileLocation.slice(42) +
        '&fileName=' +
        this.fileName
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
          responseType: 'blob',
        })
        .then(async response => {
          // console.log(response.data);
          // const downloadBlobURL = window.URL.createObjectURL(downloadBlob);
          const downloadBlobURL = window.URL.createObjectURL(
            new Blob([response.data])
          )

          console.log(downloadBlobURL)
          document.getElementById(domID).href = downloadBlobURL
          // this.selectedFile = response.data;

          const link = document.createElement('a')
          link.href = downloadBlobURL
          link.setAttribute('download', this.fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (err) {
          console.log(err)

          swal('Error', err, 'error')
        })
    },
  },

  created() {},
  mounted() {},
}
</script>
<style>
.hidden {
  opacity: 0.01;
}
.hoverLink {
  cursor: pointer;
}
</style>
