<template>
  <div
    class="min-h-screen bg-gradient-to-br from-[#030021]/5 to-[#363166]/10 py-12 px-4 sm:px-6"
  >
    <ClientLayout :user="user"></ClientLayout>
    <div
      class="container mt-5"
      v-bind:style="{ marginLeft: isMobile() ? '21px' : '' }"
    >
      <div
        class="max-w-6xl mx-auto"
        v-show="!this.messageBool && !this.textBool"
      >
        <!-- Header Card with Logo and Camp Name -->
        <div
          class="relative overflow-hidden rounded-3xl shadow-2xl mb-8 bg-white"
        >
          <div
            class="absolute inset-0 bg-gradient-to-r from-[#363166] to-[#030021] opacity-90"
          ></div>
          <div class="relative z-10 p-8 flex flex-col items-center">
            <div
              class="bg-white/20 backdrop-blur-md p-4 rounded-2xl shadow-xl mb-6 border border-white/30"
            >
              <img
                :src="getCampLogo"
                class="mx-auto rounded-lg object-contain h-40 md:h-56 w-auto"
                alt="Camp logo"
              />
            </div>
            <h1
              class="text-4xl md:text-5xl font-extrabold text-white text-center tracking-tight"
            >
              {{ camp.campName }}
            </h1>
            <div
              class="mt-4 inline-flex items-center px-4 py-2 rounded-full bg-white/20 backdrop-blur-sm border border-white/30"
            >
              <span class="text-white font-medium">
                {{ camp.sessionSupported ? 'Training Session' : 'Camp/Clinic' }}
              </span>
            </div>
          </div>
          <div
            class="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-black/20 to-transparent"
          ></div>
        </div>

        <!-- Main Content Grid -->
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <!-- Left Column: Details -->
          <div class="lg:col-span-5">
            <div
              class="bg-white rounded-3xl shadow-xl overflow-hidden"
              style="height: 755px"
            >
              <div class="bg-[#363166] py-4 px-6">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h2 class="text-2xl font-bold text-white ml-2">
                    Camp Details
                  </h2>
                </div>
              </div>

              <div
                class="p-6 space-y-6 overflow-y-auto"
                style="height: calc(755px - 72px)"
              >
                <!-- Location -->
                <div class="flex items-start">
                  <div
                    class="flex-shrink-0 h-10 w-10 rounded-full bg-[#363166]/10 flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-[#363166]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4">
                    <h3 class="text-lg font-medium text-[#030021]">Location</h3>
                    <p class="mt-1 text-[#363166]">
                      {{ camp.campLocation }}
                    </p>
                  </div>
                </div>

                <!-- Registrants -->
                <div class="flex items-start">
                  <div
                    class="flex-shrink-0 h-10 w-10 rounded-full bg-[#363166]/10 flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-[#363166]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4">
                    <h3 class="text-lg font-medium text-[#030021]">
                      Registrants
                    </h3>
                    <div class="mt-1">
                      <p class="text-[#363166]">
                        Current:
                        <span class="font-semibold text-[#363166]">{{
                          getRegistrants
                        }}</span>
                      </p>
                      <p v-if="!getParticipants" class="text-[#363166]">
                        Maximum:
                        <span class="font-semibold text-[#363166]">{{
                          camp.campMaxParticipants
                        }}</span>
                      </p>
                      <p v-else class="text-[#363166]">
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                        >
                          Unlimited Participants
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Waiver -->
                <div class="flex items-start">
                  <div
                    class="flex-shrink-0 h-10 w-10 rounded-full bg-[#363166]/10 flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-[#363166]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4">
                    <h3 class="text-lg font-medium text-[#030021]">Waiver</h3>
                    <p class="mt-1">
                      <span
                        v-if="camp.requireWaiver"
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                      >
                        Required
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                      >
                        Not Required
                      </span>
                    </p>
                  </div>
                </div>

                <!-- Registration Deadline -->
                <div class="flex items-start">
                  <div
                    class="flex-shrink-0 h-10 w-10 rounded-full bg-[#363166]/10 flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-[#363166]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4">
                    <h3 class="text-lg font-medium text-[#030021]">
                      Registration Deadline
                    </h3>
                    <p class="mt-1 text-[#363166]">
                      {{ getFormattedRegDate }}
                    </p>
                    <p v-if="camp.acceptLateRegistration" class="mt-1">
                      <span
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                      >
                        Accepting Late Registrations
                      </span>
                    </p>
                    <p v-else-if="camp.campRegistrationDeadline" class="mt-1">
                      <span
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800"
                      >
                        Not Accepting Late Registrations
                      </span>
                    </p>
                  </div>
                </div>

                <!-- Description -->
                <div class="border-t border-gray-200 pt-6">
                  <h3 class="text-lg font-medium text-[#030021]">
                    Description
                  </h3>
                  <p class="mt-2 text-[#363166] leading-relaxed">
                    {{ camp.campDescription }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Column: Payment and T-Shirts -->
          <div class="lg:col-span-7 h-full flex flex-col">
            <!-- Payment Details Card -->
            <div
              class="bg-white rounded-3xl shadow-xl overflow-hidden"
              style="flex: 0 0 auto"
            >
              <div class="bg-[#030021] py-4 px-6">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h2 class="text-2xl font-bold text-white ml-2">
                    Payment Details
                  </h2>
                </div>
              </div>

              <div class="p-6">
                <!-- Price Section -->
                <div class="flex justify-between items-center mb-6">
                  <div class="flex items-center">
                    <div
                      class="h-12 w-12 rounded-full bg-[#363166]/10 flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-[#363166]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div class="ml-4">
                      <h3 class="text-lg font-medium text-[#030021]">
                        Standard Price
                      </h3>
                      <p class="text-[#363166]">Base registration fee</p>
                    </div>
                  </div>
                  <div class="text-2xl font-bold text-[#19D412]">
                    ${{ getFormattedCampPrice }}
                  </div>
                </div>

                <!-- Discount Section -->
                <div
                  v-if="camp.discountCode"
                  class="bg-[#19D412]/5 rounded-xl p-4 mb-6 border border-[#19D412]/20"
                >
                  <div class="flex justify-between items-center">
                    <div class="flex items-center">
                      <div
                        class="h-10 w-10 rounded-full bg-[#19D412]/10 flex items-center justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-[#19D412]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                          />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <p class="text-sm font-medium text-[#030021]">
                          Discount Code
                        </p>
                        <p class="text-lg font-bold text-[#19D412]">
                          {{ camp.discountCode }}
                        </p>
                      </div>
                    </div>
                    <div class="text-xl font-bold text-[#19D412]">
                      ${{ camp.discountPrice.toFixed(2) }}
                    </div>
                  </div>
                </div>

                <!-- Bundle Prices -->
                <div
                  v-if="
                    camp.multipleCamperPrices &&
                    camp.multipleCamperPrices.length > 0
                  "
                  class="mb-6"
                >
                  <h3 class="text-lg font-medium text-[#030021] mb-3">
                    Bundle Pricing
                  </h3>
                  <div class="space-y-3">
                    <div
                      v-for="(price, index) in camp.multipleCamperPrices"
                      :key="index"
                      class="flex justify-between items-center p-3 rounded-lg bg-[#363166]/5 border border-[#363166]/20"
                    >
                      <span class="text-[#363166]">
                        Additional Registrant{{
                          index === camp.multipleCamperPrices.length - 1
                            ? 's'
                            : ''
                        }}
                        {{ index + 1
                        }}{{
                          index === camp.multipleCamperPrices.length - 1
                            ? '+'
                            : ''
                        }}
                      </span>
                      <span class="font-bold text-[#19D412]"
                        >${{ price.camperPrice.toFixed(2) }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- Revenue Progress -->
                <div class="mb-6">
                  <div class="flex justify-between items-center mb-2">
                    <h3 class="text-lg font-medium text-[#030021]">
                      Revenue Progress
                    </h3>
                    <div class="text-sm font-medium text-[#363166]">
                      ${{ getFormattedMoney }} of ${{ getFormattedRevenueGoal }}
                    </div>
                  </div>

                  <div class="relative">
                    <div
                      class="overflow-hidden h-6 rounded-full bg-[#363166]/20"
                    >
                      <div
                        class="h-full rounded-full bg-[#19D412] transition-all duration-1000 ease-out"
                        :style="{ width: `${getRevenueGoalProgress}%` }"
                      ></div>
                    </div>
                    <div
                      class="absolute inset-0 flex items-center justify-center"
                    >
                      <span class="text-xs font-bold text-white drop-shadow-md"
                        >{{ Math.round(getRevenueGoalProgress) }}%</span
                      >
                    </div>
                  </div>

                  <p
                    class="mt-2 text-center text-sm font-medium text-[#19D412]"
                  >
                    Keep Going!
                  </p>
                </div>

                <!-- Add-On Purchases -->
                <div
                  v-if="getFormattedAddOns && getFormattedAddOns.length !== 0"
                  class="mb-6"
                >
                  <h3 class="text-lg font-medium text-[#030021] mb-3">
                    Add-On Purchases
                  </h3>
                  <div class="space-y-3">
                    <div
                      v-for="(addOn, index) in getFormattedAddOns"
                      :key="index"
                      class="flex justify-between items-center p-3 rounded-lg bg-[#363166]/5 border border-[#363166]/20"
                    >
                      <span class="text-[#363166]">{{ addOn.addOnName }}</span>
                      <span
                        class="px-3 py-1 bg-white rounded-full font-medium text-[#19D412] shadow-sm border border-[#19D412]/20"
                      >
                        {{ addOn.numCampers }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Schedule Card -->
            <div
              v-if="this.camp.campDates && this.camp.campDates[0]"
              class="mt-8 bg-white rounded-3xl shadow-xl overflow-hidden"
              style="height: 350px"
            >
              <div class="bg-[#363166] py-4 px-6">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h2 class="text-2xl font-bold text-white ml-2">Schedule</h2>
                </div>
              </div>
              <div
                class="p-6 overflow-y-auto"
                style="height: calc(350px - 72px)"
              >
                <div
                  v-if="!camp.campDates || !camp.campDates[0]"
                  class="text-center py-8 text-gray-500"
                >
                  No scheduled days available
                </div>
                <div v-else class="space-y-4">
                  <div
                    v-for="(day, index) in camp.campDates"
                    :key="index"
                    class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4 transition-all duration-300 hover:shadow-md"
                  >
                    <div class="flex items-center justify-between">
                      <div class="flex items-center">
                        <div
                          class="h-10 w-10 rounded-full bg-[#363166] flex items-center justify-center text-white font-bold"
                        >
                          {{ index + 1 }}
                        </div>
                        <h3 class="ml-3 text-lg font-medium text-[#030021]">
                          Day {{ index + 1 }}
                        </h3>
                      </div>
                    </div>
                    <div class="mt-3 grid grid-cols-2 gap-4">
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-[#363166]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <span class="ml-2 text-[#363166]"
                          >Start:
                          <span class="font-medium">{{
                            dayToString(day.start)
                          }}</span></span
                        >
                      </div>
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-[#363166]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        <span class="ml-2 text-[#363166]"
                          >End:
                          <span class="font-medium">{{
                            dayToString(day.end)
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- T-Shirt Sales Card -->
        <div
          v-if="camp.tShirtsProvided"
          class="mt-8 bg-white rounded-3xl shadow-xl overflow-hidden"
        >
          <div class="bg-[#363166] py-4 px-6">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 3h12l2 4-4 2v10a2 2 0 01-2 2H10a2 2 0 01-2-2V9L4 7l2-4z"
                />
              </svg>
              <h2 class="text-2xl font-bold text-white ml-2">T-Shirt Sales</h2>
            </div>
          </div>

          <div class="p-6">
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <!-- Youth Sizes -->
              <div class="col-span-2 bg-[#363166]/5 rounded-xl p-4">
                <h3 class="text-lg font-medium text-[#030021] mb-3">
                  Youth Sizes
                </h3>
                <div class="space-y-3">
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">Small</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getYS }}</span
                    >
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">Medium</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getYM }}</span
                    >
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">Large</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getYL }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- Adult Sizes -->
              <div class="col-span-2 bg-[#363166]/5 rounded-xl p-4">
                <h3 class="text-lg font-medium text-[#030021] mb-3">
                  Adult Sizes
                </h3>
                <div class="space-y-3">
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">Small</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getAS }}</span
                    >
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">Medium</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getAM }}</span
                    >
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">Large</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getAL }}</span
                    >
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">XL</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getAXL }}</span
                    >
                  </div>
                  <div class="flex justify-between items-center">
                    <span class="text-[#363166]">XXL</span>
                    <span
                      class="px-3 py-1 bg-white rounded-full font-medium text-[#363166] shadow-sm border border-[#363166]/20"
                      >{{ getAXXL }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Active Links -->
        <div class="mt-8 bg-white rounded-3xl shadow-xl overflow-hidden">
          <div class="bg-gradient-to-r from-[#363166] to-[#030021] py-4 px-6">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                />
              </svg>
              <h2 class="text-2xl font-bold text-white ml-2">Active Links</h2>
            </div>
          </div>

          <div class="p-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <!-- Payment Link -->
              <a
                :href="getCampURL"
                target="_blank"
                class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-6 transition-all duration-300 hover:shadow-md group relative overflow-hidden"
              >
                <div class="flex items-center">
                  <div
                    class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                      />
                    </svg>
                  </div>
                  <h3 class="ml-4 text-xl font-medium text-[#030021]">
                    Payment Link
                  </h3>
                </div>
                <div
                  class="absolute -bottom-2 -right-2 w-12 h-12 bg-[#19D412] rounded-full flex items-center justify-center transform translate-x-1/4 translate-y-1/4 group-hover:translate-x-0 group-hover:translate-y-0 transition-all duration-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
              </a>

              <!-- Payment QR Code -->
              <a
                :href="getQrLink"
                target="_blank"
                class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-6 transition-all duration-300 hover:shadow-md group relative overflow-hidden"
              >
                <div class="flex items-center">
                  <div
                    class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                      />
                    </svg>
                  </div>
                  <h3 class="ml-4 text-xl font-medium text-[#030021]">
                    Payment QR Code
                  </h3>
                </div>
                <div
                  class="absolute -bottom-2 -right-2 w-12 h-12 bg-[#19D412] rounded-full flex items-center justify-center transform translate-x-1/4 translate-y-1/4 group-hover:translate-x-0 group-hover:translate-y-0 transition-all duration-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>

        <!-- Settings Controls -->
        <div class="mt-8 bg-white rounded-3xl shadow-xl overflow-hidden">
          <div class="bg-gradient-to-r from-[#363166] to-[#030021] py-4 px-6">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <h2 class="text-2xl font-bold text-white ml-2">Camp Controls</h2>
            </div>
          </div>

          <div class="p-6">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
              <!-- Post On Landing Page -->
              <div
                class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-6"
              >
                <h3 class="text-lg font-medium text-[#030021] mb-4 text-center">
                  Post On Landing Page
                </h3>
                <div class="flex items-center justify-center">
                  <label
                    class="relative inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      @click="toggleCampToWebsite"
                      :checked="camp.isPostedToWebsite"
                    />
                    <div
                      class="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-[#19D412]"
                    ></div>
                  </label>
                  <span class="ml-3 text-[#363166] min-w-[140px] text-center">{{
                    camp.isPostedToWebsite
                      ? 'Visible on website'
                      : 'Hidden from website'
                  }}</span>
                </div>
              </div>

              <!-- Turn Link On -->
              <div
                class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-6"
              >
                <h3 class="text-lg font-medium text-[#030021] mb-4 text-center">
                  Turn Link On
                </h3>
                <div class="flex items-center justify-center">
                  <label
                    class="relative inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      @click="toggleTurnCampOn"
                      :checked="camp.turnCampOn"
                    />
                    <div
                      class="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-[#19D412]"
                    ></div>
                  </label>
                  <span class="ml-3 text-[#363166] min-w-[140px] text-center">{{
                    camp.turnCampOn ? 'Link is active' : 'Link is inactive'
                  }}</span>
                </div>
              </div>

              <!-- Receive Purchase Notifications -->
              <div
                class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-6"
              >
                <h3 class="text-lg font-medium text-[#030021] mb-4 text-center">
                  Receive Purchase Notifications
                </h3>
                <div class="flex items-center justify-center">
                  <label
                    class="relative inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      @click="toggleEmailNotification"
                      :checked="camp.sendEmailNotification"
                    />
                    <div
                      class="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-[#19D412]"
                    ></div>
                  </label>
                  <span class="ml-3 text-[#363166] min-w-[140px] text-center">{{
                    camp.sendEmailNotification
                      ? 'Notifications on'
                      : 'Notifications off'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Action Hub -->
        <div class="mt-8 bg-white rounded-3xl shadow-xl overflow-hidden">
          <div
            class="bg-gradient-to-r from-[#19D412] to-[#19D412]/80 py-4 px-6"
          >
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
              <h2 class="text-2xl font-bold text-white ml-2">Action Hub</h2>
            </div>
          </div>

          <div class="p-6">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <!-- Customer List -->
              <div
                @click="customerListClick()"
                class="bg-white rounded-[24px] p-8 shadow-sm hover:shadow-2xl transition-all duration-300 cursor-pointer relative min-h-[220px] group hover:scale-[1.02] hover:bg-gradient-to-br hover:from-white hover:to-[#363166]/5"
              >
                <div class="mb-6">
                  <span
                    class="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                  >
                    Feature
                  </span>
                </div>

                <div class="flex flex-col items-center text-center space-y-4">
                  <div
                    class="h-14 w-14 rounded-2xl bg-[#363166] flex items-center justify-center text-white flex-shrink-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-7 w-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h3 class="text-2xl font-semibold text-[#030021] mb-2">
                      Customer List
                    </h3>
                    <p class="text-[#363166] text-base">
                      View and manage every customer
                    </p>
                  </div>
                </div>

                <div class="absolute bottom-8 left-8 right-8">
                  <div
                    class="h-[2px] w-full bg-[#363166]/10 rounded-full group-hover:bg-[#363166]/20 transition-colors duration-300"
                  ></div>
                </div>
              </div>

              <!-- Message All -->
              <div
                @click="messageActionHubClick()"
                class="bg-white rounded-[24px] p-8 shadow-sm hover:shadow-2xl transition-all duration-300 cursor-pointer relative min-h-[220px] group hover:scale-[1.02] hover:bg-gradient-to-br hover:from-white hover:to-[#363166]/5"
              >
                <div class="mb-6">
                  <span
                    class="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                  >
                    Feature
                  </span>
                </div>

                <div class="flex flex-col items-center text-center space-y-4">
                  <div
                    class="h-14 w-14 rounded-2xl bg-[#363166] flex items-center justify-center text-white flex-shrink-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-7 w-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h3 class="text-2xl font-semibold text-[#030021] mb-2">
                      Message All
                    </h3>
                    <p class="text-[#363166] text-base">
                      Message every customer
                    </p>
                  </div>
                </div>

                <div class="absolute bottom-8 left-8 right-8">
                  <div
                    class="h-[2px] w-full bg-[#363166]/10 rounded-full group-hover:bg-[#363166]/20 transition-colors duration-300"
                  ></div>
                </div>
              </div>

              <!-- Customize Receipt -->
              <div
                @click="customizeReceipt()"
                class="bg-white rounded-[24px] p-8 shadow-sm hover:shadow-2xl transition-all duration-300 cursor-pointer relative min-h-[220px] group hover:scale-[1.02] hover:bg-gradient-to-br hover:from-white hover:to-[#363166]/5"
              >
                <div class="mb-6">
                  <span
                    class="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                  >
                    Feature
                  </span>
                </div>

                <div class="flex flex-col items-center text-center space-y-4">
                  <div
                    class="h-14 w-14 rounded-2xl bg-[#363166] flex items-center justify-center text-white flex-shrink-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-7 w-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h3 class="text-2xl font-semibold text-[#030021] mb-2">
                      Customize Receipt
                    </h3>
                    <p class="text-[#363166] text-base">
                      Personalize your email receipts
                    </p>
                  </div>
                </div>

                <div class="absolute bottom-8 left-8 right-8">
                  <div
                    class="h-[2px] w-full bg-[#363166]/10 rounded-full group-hover:bg-[#363166]/20 transition-colors duration-300"
                  ></div>
                </div>
              </div>

              <!-- Duplicate Link -->
              <div
                @click="copyCamp()"
                class="bg-white rounded-[24px] p-8 shadow-sm hover:shadow-2xl transition-all duration-300 cursor-pointer relative min-h-[220px] group hover:scale-[1.02] hover:bg-gradient-to-br hover:from-white hover:to-[#363166]/5"
              >
                <div class="mb-6">
                  <span
                    class="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#19D412]/10 text-[#19D412]"
                  >
                    Feature
                  </span>
                </div>

                <div class="flex flex-col items-center text-center space-y-4">
                  <div
                    class="h-14 w-14 rounded-2xl bg-[#363166] flex items-center justify-center text-white flex-shrink-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-7 w-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <h3 class="text-2xl font-semibold text-[#030021] mb-2">
                      Duplicate Link
                    </h3>
                    <p class="text-[#363166] text-base">
                      Don't waste time re-typing
                    </p>
                  </div>
                </div>

                <div class="absolute bottom-8 left-8 right-8">
                  <div
                    class="h-[2px] w-full bg-[#363166]/10 rounded-full group-hover:bg-[#363166]/20 transition-colors duration-300"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <button
              class="card-form__button rounded-pill modern-button edit-button"
              @click="editCamp"
            >
              <i class="fas fa-edit me-2"></i>
              Edit
              {{ this.camp.sessionSupported ? 'Session' : 'Camp/Clinic' }}
            </button>
          </div>

          <div class="col-md-6 col-sm-6">
            <button
              class="card-form__button rounded-pill modern-button delete-button"
              @click="deleteCamp"
            >
              <i class="fas fa-trash-alt me-2"></i>
              Delete
              {{ this.camp.sessionSupported ? 'Session' : 'Camp/Clinic' }}
            </button>
          </div>
        </div>
      </div>

      <!-- Hidden elements -->
      <CustomizeEmailReceipt
        :userID="user._id"
        :campStripeId="camp.campStripeId"
        :customEmailReceipt="camp.customEmailReceipt"
        :isMembership="false"
      />
      <button
        data-bs-toggle="modal"
        data-bs-target="#customizeEmailReceiptModal"
        id="customizeEmailReceiptTrigger"
        style="display: none"
      ></button>
    </div>

    <!--Start of Message Functionality-->
    <div class="min-h-screen" v-show="messageBool || textBool">
      <div
        class="max-w-6xl mx-auto bg-white rounded-xl shadow-xl overflow-hidden"
      >
        <div class="flex flex-col md:flex-row">
          <!-- Tabs Section -->
          <div class="md:w-72 bg-gray-50/70 p-6">
            <h3 class="text-lg font-medium text-gray-800 mb-6 tracking-tight">
              Message Center
            </h3>

            <!-- Email Tab -->
            <div
              class="tab-card mb-4 cursor-pointer"
              :class="{ 'active-tab': messageBool }"
              @click="setTabStyle('email')"
            >
              <div
                class="h-1 bg-brand-green rounded-t-lg"
                v-if="messageBool"
              ></div>
              <div class="p-3 bg-white rounded-lg shadow-sm">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <div
                      class="p-2 bg-gradient-to-br from-green-100 to-green-50 rounded-full mr-3 shadow-sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-brand-green"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                        ></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                    </div>
                    <span class="font-medium text-gray-800">Email</span>
                  </div>
                  <span
                    class="px-2.5 py-1 text-xs font-semibold bg-green-100 text-brand-green rounded-full"
                    >Pro</span
                  >
                </div>
              </div>
            </div>

            <!-- Text Tab -->
            <div
              class="tab-card cursor-pointer"
              :class="{ 'active-tab': textBool }"
              @click="setTabStyle('text')"
            >
              <div
                class="h-1 bg-brand-purple rounded-t-lg"
                v-if="textBool"
              ></div>
              <div class="p-3 bg-white rounded-lg shadow-sm">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <div
                      class="p-2 bg-gradient-to-br from-purple-100 to-purple-50 rounded-full mr-3 shadow-sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-brand-purple"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <rect
                          x="5"
                          y="2"
                          width="14"
                          height="20"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="12" y1="18" x2="12" y2="18"></line>
                      </svg>
                    </div>
                    <span class="font-medium text-gray-800">Text</span>
                  </div>
                  <span
                    class="px-2.5 py-1 text-xs font-semibold bg-purple-100 text-brand-purple rounded-full"
                    >All Star</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Content Section -->
          <div class="flex-1 border-l border-gray-200">
            <!-- Email Form -->
            <div v-show="messageBool" class="p-8">
              <div class="mb-8">
                <img
                  src="/SummerAthletes.png"
                  class="logo-image"
                  :style="{
                    width: '250px',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                    objectFit: 'contain',
                  }"
                  alt="Summer Athletes"
                />
              </div>

              <div class="space-y-6">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1.5"
                    >Subject</label
                  >
                  <input
                    type="text"
                    class="w-full px-4 py-3 rounded-lg bg-white/80 border-0"
                    placeholder="Subject"
                    v-model="message.subject"
                  />
                </div>

                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1.5"
                    >Intro</label
                  >
                  <input
                    type="text"
                    class="w-full px-4 py-3 rounded-lg bg-white/80 border-0"
                    placeholder="Hi Everyone,"
                    v-model="message.intro"
                  />
                </div>

                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1.5"
                    >Paragraph 1</label
                  >
                  <textarea
                    class="w-full px-4 py-3 rounded-lg bg-white/80 border-0"
                    placeholder="Paragraph 1"
                    rows="4"
                    v-model="message.body1"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1.5"
                    >Paragraph 2</label
                  >
                  <textarea
                    class="w-full px-4 py-3 rounded-lg bg-white/80 border-0"
                    placeholder="Paragraph 2 (optional)"
                    rows="4"
                    v-model="message.body2"
                  ></textarea>
                </div>
              </div>

              <div class="mt-10">
                <div class="mb-8">
                  <button
                    class="w-full py-3 px-6 bg-[#19d412] text-white font-medium custom-rounded-button hover:bg-opacity-90 transition-all flex items-center justify-center shadow-md transform hover:scale-101 hover:shadow-lg duration-200 mb-6"
                    @click="sendEmail"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mr-2"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M22 2L11 13"></path>
                      <path d="M22 2l-7 20-4-9-9-4 20-7z"></path>
                    </svg>
                    Send email to all
                  </button>
                </div>

                <div class="mb-8">
                  <button
                    class="w-full py-3 px-6 bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 font-medium custom-rounded-button transition-all duration-200 shadow-sm hover:shadow-md transform hover:scale-101 mb-6"
                    @click="setTabStyle('back')"
                  >
                    Back to event
                  </button>
                </div>
              </div>
            </div>

            <!-- Text Message Form -->
            <div v-show="textBool" class="p-8">
              <div class="mb-8">
                <img
                  src="/SummerAthletes.png"
                  class="logo-image"
                  :style="{
                    width: '250px',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                    objectFit: 'contain',
                  }"
                  alt="Summer Athletes"
                />
              </div>

              <div class="space-y-6">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1.5"
                    >Text Message</label
                  >
                  <textarea
                    class="w-full px-4 py-3 rounded-lg bg-white/80 border-0"
                    placeholder="Friendly reminder..."
                    rows="6"
                    v-model="text.content"
                  ></textarea>
                </div>
              </div>

              <div class="mt-10">
                <div class="mb-8">
                  <button
                    class="w-full py-3 px-6 bg-[#363166] text-white font-medium custom-rounded-button hover:bg-opacity-90 transition-all flex items-center justify-center shadow-md transform hover:scale-101 hover:shadow-lg duration-200 mb-6"
                    @click="sendText"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mr-2"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M22 2L11 13"></path>
                      <path d="M22 2l-7 20-4-9-9-4 20-7z"></path>
                    </svg>
                    Send text to all
                  </button>
                </div>

                <div class="mb-8">
                  <button
                    class="w-full py-3 px-6 bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 font-medium custom-rounded-button transition-all duration-200 shadow-sm hover:shadow-md transform hover:scale-101 mb-6"
                    @click="setTabStyle('back')"
                  >
                    Back to event
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Message Functionality-->
  </div>
</template>
<script>
import axios from 'axios'
import ViewCampCustomAddOns from '../components/ViewCampCustomAddOns.vue'
import CustomizeEmailReceipt from '../components/CustomizeEmailReceipt.vue'
import { VeProgress } from 'vue-ellipse-progress'
import swal from 'sweetalert'
import ClientLayout from '../components/ClientLayout.vue'
import numeral from 'numeral'
export default {
  components: {
    ClientLayout,
    ViewCampCustomAddOns,
    CustomizeEmailReceipt,
    VeProgress,
  },
  name: 'view-individual-camp',
  data() {
    return {
      json_fields: {
        'Camper First Name': 'firstName',
        'Camper Last Name': 'lastName',
        'Camper T-shirt size': 'tShirtSize',
        'Camper Emergency Contact First Name': {
          callback: value => {
            return value.emergencyContactInfo[0].firstName
          },
        },
        'Camper Emergency Contact Last Name': {
          callback: value => {
            return value.emergencyContactInfo[0].lastName
          },
        },
        'Camper Emergency Contact Email': {
          callback: value => {
            return value.emergencyContactInfo[0].email
          },
        },
        'Camper Emergency Contact Phone Number': {
          callback: value => {
            return value.emergencyContactInfo[0].phoneNumber
          },
        },
        'Camper Emergency Contact Address': {
          callback: value => {
            return value.emergencyContactInfo[0].address
          },
        },
        'Camper Emergency Contact City': {
          callback: value => {
            return value.emergencyContactInfo[0].city
          },
        },
        'Camper Emergency Contact State': {
          callback: value => {
            return value.emergencyContactInfo[0].state
          },
        },
        'Camper Emergency Contact Zip Code': {
          callback: value => {
            return value.emergencyContactInfo[0].zipCode
          },
        },
      },
      steps: [
        {
          target: '.camp-step-0', // We're using document.querySelector() under the hood
          content: `The <strong>Details</strong> section shows all details of the camp you created.`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-1', // We're using document.querySelector() under the hood
          content: `The <strong>T-Shirt Sales</strong> column specifies all T-Shirt sales for this specific camp. <br/> <br /> Each size has a value that shows how many people have purchased it.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-2', // We're using document.querySelector() under the hood
          content: `The <strong>Payment Details</strong> section gives an overview of all revenue information. <br/> <br /> It shows what the base price is, how much the camp has earned, and how close you are to your goals.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-3', // We're using document.querySelector() under the hood
          content: `The <strong>Post on Landing Page</strong> toggle publishes your website to your free landing page. <br/> <br /> This makes the event public, and shows up when someone navigates to your landing page via your AI Website`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-4', // We're using document.querySelector() under the hood
          content: `The <strong>Turn Camp/Clinic On</strong> toggle is what activates your camp/clinic. <br/> <br /> Shutting this off will deactivate your camp/clinic until you toggle it back on.`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-5', // We're using document.querySelector() under the hood
          content: `The <strong>Receive Purchase Notifications</strong> toggle tracks if you receive email alerts when someone pays via this link.`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-6', // We're using document.querySelector() under the hood
          content: `The <strong>Link to Registration</strong> is how you get people signed up and <strong><em>make money</em></strong> on the platform! <br/> <br /> Be sure to post this out on social media as soon as possible, to help advertise for the event! We recommend at least <br/> <strong><em>one month in advance of advertising!</strong></em>`,
          params: {
            enableScrolling: false,
            placement: 'left',
            highlight: true,
          },
        },
        {
          target: '.camp-step-7', // We're using document.querySelector() under the hood
          content: `The <strong>QR Code</strong> takes your campers to the exact same page as the sign-up link! <br/> <br /> Please be sure to post this out wherever you can, it is most useful for both online and physical fliers!`,
          params: {
            enableScrolling: false,
            placement: 'right',
            highlight: true,
          },
        },
        {
          target: '.camp-step-8', // We're using document.querySelector() under the hood
          content: `The <strong>Action Hub</strong> is your camp command station. <br/> <br /> Manage your contacts, message your registrants, and work with your data, all in one spot!`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
      ],
      messageBool: false,
      textBool: false,
      message: {
        subject: '',
        intro: '',
        body1: '',
        body2: '',
      },
      text: {
        content: '',
      },
      campId: 0,
      user: {},
      temp: {},
      money: 0,
      camp: {
        campName: '',
        campDescription: '',
        campLocation: '',
        campLogo: '',
        campPrice: 0,
        discountPrice: 0,
        campOrganization: '',
        campDates: [],
        campRegistrationDeadline: '',
        campMaxParticipants: '',
        acceptLateRegistration: '',
        requireWaiver: '',
        tShirtsProvided: '',
        campStripeId: '',
        campers: [],
        colors: {
          primary: '',
          secondary: '',
        },
        multipleCamperPrices: [],
        customEmailReceipt: '',
      },
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    toggleCampToWebsite() {
      console.log('website toggle???')
      this.camp.isPostedToWebsite = !this.camp.isPostedToWebsite
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/toggleCampToWebsite'
      axios.post(
        url,
        {
          campStripeId: this.camp.campStripeId,
          isPostedToWebsite: this.camp.isPostedToWebsite,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },
    toggleTurnCampOn() {
      this.camp.turnCampOn = !this.camp.turnCampOn
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/toggleTurnCampOn'
      axios.post(
        url,
        {
          campStripeId: this.camp.campStripeId,
          turnCampOn: this.camp.turnCampOn,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },

    toggleEmailNotification() {
      this.camp.sendEmailNotification = !this.camp.sendEmailNotification
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/toggleEmailNotification'
      axios.post(
        url,
        {
          campStripeId: this.camp.campStripeId,
          sendEmailNotification: this.camp.sendEmailNotification,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },

    messageActionHubClick() {
      this.messageBool = true
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },

    async sendEmail() {
      if (this.user.subscription.subscriptionTier < 2) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least a Pro user to send mass messages. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'user/emailAll'
      axios
        .post(
          url,
          {
            subject: this.message.subject,
            intro: this.message.intro,
            body1: this.message.body1,
            body2: this.message.body2,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            campId: this.camp.campStripeId,
            type: 'camp',
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          this.newMembership = this.savedData
        })
      swal('Success', 'Message Sent!', 'success')
    },
    async sendText() {
      if (this.user.subscription.subscriptionTier < 3) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least an All Star user to send mass texts. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'user/textAll'
      axios
        .post(
          url,
          {
            content: this.text.content,
            phoneNumber: this.user.phoneNumber,
            campId: this.camp.campStripeId,
            coachID: this.user._id,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.camp.textLimit += 1
            swal('Success', 'Message Sent!', 'success')
          } else {
            swal('Sorry!', 'You have already reached your text limit.', 'error')
          }
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          this.newMembership = this.savedData
        })
    },
    dayToString(date) {
      let temp = new Date(date)
      return temp.toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    customerListClick() {
      window.scrollTo(0, 0)
      this.$router.push('/Dashboard/CheckIn/' + this.campId)
    },

    customizeReceipt() {
      if (this.user.subscription.subscriptionTier >= 3) {
        //run customize receipt modal
        document.getElementById('customizeEmailReceiptTrigger').click()
      } else {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: "You need to be at least an All Star user to customize your event's email receipts. Please upgrade your plan to continue.",
        })
        document.getElementById('openAdvertisementPopUp').click()
      }
    },

    copyCamp() {
      this.$router.push('/Dashboard/CreateCamp/Individual/' + this.campId)
    },
    showDownloadAd() {
      swal({
        icon: 'info',
        title: 'Upgrade Your Plan',
        text: 'You need to be at least a Pro user to download customer data locally. Please upgrade your plan to continue.',
      })
      document.getElementById('openAdvertisementPopUp').click()
    },

    async getCamps() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/camps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]._id == this.campId) {
              this.camp = response.data[i]
              this.camp.customEmailReceipt = response.data[i].customEmailReceipt
              if (response.data[i].campers.length < 3) {
                if (!this.camp.tShirtsProvided) {
                  this.steps.splice(1, 1)
                }
              }
            }
          }

          if (this.camp.isPostedToWebsite == null) {
            this.camp.isPostedToWebsite = false
          }
          if (this.camp.sendEmailNotification == null) {
            this.camp.sendEmailNotification = false
          }
          this.temp = response.data
        })
        .catch(function () {
          //report error as a swal message
          swal('Error', 'Could not find data', 'error')
        })
    },

    editCamp() {
      this.$router.push(
        '/Dashboard/CreateCamp/Individual/' + this.campId + '/edit'
      )
    },

    //delete camps
    async deleteCamp() {
      swal({
        title: 'Confirm Deletion',
        text: 'Once the camp/clinic is deleted, all customer information is lost, and you are responsible for reaching out to the customers to refund and inform them of the cancellation.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          let url =
            import.meta.env.VITE_APP_DB_URL + 'individualCamp/deleteCamp'
          axios
            .post(
              url,
              {
                campId: this.campId,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              swal('Success', 'Deleted', 'success')
              this.camps = response.data.camps
              this.$router.push('/Dashboard')
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
            })
        }
      })
    },
    showSubscriptionChoices() {
      this.$router.push(
        '/SubscriptionChoices/' + this.user.subscription.subscriptionType
      )
    },
    setTabStyle(type) {
      this.messageBool = type === 'email'
      this.textBool = type === 'text'
    },
  },

  created() {
    this.getUserDetails()
    this.campId = this.$route.params.id
    this.getCamps()
  },
  computed: {
    determineSteps() {
      if (this.isMobile()) {
        let steps = this.steps
        for (let step of steps) {
          step.params.placement = 'bottom'
        }
        return steps
      }
      return this.steps
    },
    getFormattedCampPrice() {
      return numeral(this.camp.campPrice).format('0,0.00')
    },
    getFormattedMoney() {
      return numeral(this.getMoney).format('0,0.00')
    },
    getFormattedRevenueGoal() {
      return numeral(this.getRevenueGoal).format('0,0.00')
    },
    getCampLogo() {
      if (this.camp.campLogo != '') {
        return this.camp.campLogo
      }
      return 'https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png'
    },
    getFormattedAddOns() {
      let temp = []
      let count
      if (this.camp.customAddOns) {
        for (count = 0; count < this.camp.customAddOns.length; count++) {
          let numCampersRegistered = 0
          let x
          for (x = 0; x < this.camp.campers.length; x++) {
            if (this.camp.campers[x]?.customAddOnValues[count]?.isPurchased) {
              numCampersRegistered = numCampersRegistered + 1
            }
          }
          temp.push({
            addOnName: this.camp.customAddOns[count].addOnName,
            numCampers: numCampersRegistered,
          })
        }
      }

      return temp
    },
    getFormattedData() {
      let exportData = []
      for (let x = 0; x++; x < this.camp.campers.length) {
        exportData[x] = this.camp.campers[x]
      }
      return exportData
    },
    getCampURL() {
      if (this.camp.colors) {
        return (
          import.meta.env.VITE_APP_CLIENT_URL +
          'coach/' +
          this.user._id +
          '/' +
          this.camp.colors.primary +
          '/' +
          this.camp.colors.secondary +
          '/viewPayment/' +
          this.campId
        )
      }
      return (
        import.meta.env.VITE_APP_CLIENT_URL +
        'coach/' +
        this.user._id +
        '/19d412/030021/viewPayment/' +
        this.campId
      )
    },
    getQrLink() {
      if (this.camp.colors) {
        return (
          'https://api.qrserver.com/v1/create-qr-code/?data=' +
          import.meta.env.VITE_APP_CAMP_URL +
          'campcheckout/' +
          this.camp.campStripeId +
          '/' +
          this.camp.colors.primary +
          '/' +
          this.camp.colors.secondary +
          '&amp;size=100x100'
        )
      }
      return (
        'https://api.qrserver.com/v1/create-qr-code/?data=' +
        import.meta.env.VITE_APP_CAMP_URL +
        'campcheckout/' +
        this.camp.campStripeId +
        '&amp;size=100x100'
      )
    },
    getCampDays() {
      return this.camp.campDates
    },
    getParticipants() {
      return this.camp.campMaxParticipants == null
    },
    getFormattedRegDate() {
      if (this.camp.campRegistrationDeadline) {
        let temp = new Date(this.camp.campRegistrationDeadline)
        let time = temp.toLocaleDateString()
        return time
      } else {
        return 'None'
      }
    },
    getFormattedCampDate() {
      let time = null
      try {
        let temp = new Date(this.camp.campDates[0].start)
        time = temp.toLocaleString()
      } catch (err) {
        return time
      }

      return time
    },

    getMoney() {
      let total = 0
      for (const camper of this.camp.campers) {
        total += camper.pricePaid
      }
      return total.toFixed(2)
    },
    getRegistrants() {
      return this.camp.campers.length
    },
    getYS() {
      let size = 'YS'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getYM() {
      let size = 'YM'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getYL() {
      let size = 'YL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAS() {
      let size = 'AS'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAM() {
      let size = 'AM'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAL() {
      let size = 'AL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAXL() {
      let size = 'AXL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAXXL() {
      let size = 'AXXL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getRevenueGoal() {
      return this.camp.campRevenueGoal ? this.camp.campRevenueGoal : 10000
    },
    getRevenueGoalProgress() {
      const currentRevenue = this.getMoney
      const goal = this.camp.campRevenueGoal ? this.camp.campRevenueGoal : 10000
      const percentage = (currentRevenue / goal) * 100
      return percentage > 100 ? 100 : percentage
    },
  },
  mounted: function () {},
}
</script>
<style scoped>
:root {
  --brand-green: #19d412;
  --brand-purple: #363166;
}

/* Button base styles */
button {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Shimmer effect for buttons */
button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.5s;
}

button:hover::before {
  left: 100%;
}

/* Button hover transform */
.w-full.py-3.px-6.custom-rounded-button:hover {
  transform: scale(1.01);
}

/* Specific button color adjustments on hover */
.w-full.py-3.px-6.bg-\[\#19d412\].text-white.custom-rounded-button:hover {
  background-color: #15b910;
}

.w-full.py-3.px-6.bg-\[\#363166\].text-white.custom-rounded-button:hover {
  background-color: #2d2955;
}

.w-full.py-3.px-6.bg-white.border.border-gray-200.text-gray-700.custom-rounded-button:hover {
  background-color: #f3f4f6;
}

.bg-brand-green {
  background-color: var(--brand-green);
}

.bg-brand-purple {
  background-color: var(--brand-purple);
}

.text-brand-green {
  color: var(--brand-green);
}

.text-brand-purple {
  color: var(--brand-purple);
}

/* Tab card styling with identical hover effects for both tabs */
.tab-card {
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
}

/* Identical hover effect for both tabs */
.tab-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-4px);
}

.active-tab {
  position: relative;
}

/* Overlay effect for tab hover */
.tab-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.4s ease;
  pointer-events: none;
}

.tab-card:hover::after {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Form styling */
.form-group textarea:focus,
.form-group input:focus {
  outline: none;
}

.form-group input,
.form-group textarea {
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Input and textarea base styles */
input,
textarea {
  border: 1px solid #e5e7eb !important;
  transition: all 0.3s ease !important;
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: #d1d5db !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

/* Button styling with subtle hover effects */
button {
  transition: all 0.5s ease;
}

button:active {
  transform: translateY(1px);
}

/* Send button styling with subtle hover effects */
.send-button {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.send-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.send-button:hover::before {
  transform: translateX(0);
}

.send-button-green:hover {
  background-color: #17c010;
}

.send-button-purple:hover {
  background-color: #2f2a58;
}

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.message_tab {
  background: whitesmoke;
  border-color: whitesmoke;
  border-width: 3px;
  transition: all 0.2s ease-in-out;
}
.message_tab .card-header {
  background: lightgrey;
  transition: 0.3s;
}
/*.message_tab:hover .card-header{*/
/*  background: #19d412;*/
/*}*/
.message_tab:hover {
  background: white;
  cursor: pointer;
  transform: scale(1.1);
}

.off_tab {
  background: whitesmoke;
}
.on_tab {
  background: #19d412;
}

img.rounded-corners {
  border-radius: 30px;
}

.actionFeature {
  background-color: #a39ed0;
}

.action-button {
  background-color: #363166;
}

.actionHub:hover {
  box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.5);
  cursor: pointer;
}

.logo-image {
  border-radius: 0;
  box-shadow: none;
}

.custom-rounded-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.modern-button {
  width: 100%;
  padding: 12px 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  border: none;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.modern-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.5s;
}

.modern-button:hover::before {
  left: 100%;
}

.edit-button {
  background: linear-gradient(135deg, #363166 0%, #4a3f8c 100%);
  color: white;
  box-shadow: 0 4px 15px rgba(54, 49, 102, 0.2);
}

.edit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(54, 49, 102, 0.3);
  background: linear-gradient(135deg, #4a3f8c 0%, #363166 100%);
}

.delete-button {
  background: linear-gradient(135deg, #dc3545 0%, #c82333 100%);
  color: white;
  box-shadow: 0 4px 15px rgba(220, 53, 69, 0.2);
}

.delete-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(220, 53, 69, 0.3);
  background: linear-gradient(135deg, #c82333 0%, #dc3545 100%);
}

.modern-button i {
  font-size: 1.1em;
  transition: transform 0.3s ease;
}

.modern-button:hover i {
  transform: scale(1.1);
}
</style>
