<template>
  <div
    class="min-h-screen bg-gradient-to-br from-[#030021]/5 to-[#363166]/10 py-8 px-4 sm:px-6 lg:px-8 mt-12"
    :style="{
      marginLeft: isMobile ? '21px' : '',
    }"
  >
    <ClientLayout :user="user" />

    <!-- Loading Overlay -->
    <div
      v-if="loading"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
        <div
          class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#363166] mb-4"
        ></div>
        <p class="text-[#030021] font-medium">Loading campers...</p>
      </div>
    </div>

    <!-- Header Section -->
    <div class="max-w-6xl mx-auto">
      <div class="bg-white rounded-2xl shadow-xl overflow-hidden mb-8">
        <div class="bg-gradient-to-r from-[#363166] to-[#030021] py-6 px-8">
          <div class="flex flex-col md:flex-row items-start justify-between">
            <div class="flex items-center mb-4 md:mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white mr-3 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <h1 class="text-3xl font-bold text-white">
                {{ campName }} Check-In
              </h1>
            </div>

            <div class="flex flex-col sm:flex-row gap-3">
              <!-- Download Data Button -->
              <button
                v-if="
                  user.subscription && user.subscription.subscriptionTier >= 2
                "
                class="h-10 inline-flex items-center justify-center px-4 whitespace-nowrap bg-white text-[#363166] font-medium rounded-lg shadow-md hover:bg-[#19D412]/10 transition-all duration-300"
              >
                <JsonExcel
                  :data="resultQuery"
                  :name="campName + '.xls'"
                  :fields="json_fields"
                  class="flex items-center w-full h-full"
                >
                  <div class="flex items-center whitespace-nowrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    Download Data
                  </div>
                </JsonExcel>
              </button>

              <button
                v-else
                @click="showDownloadAd()"
                class="h-10 inline-flex items-center justify-center px-4 whitespace-nowrap bg-white text-[#363166] font-medium rounded-lg shadow-md hover:bg-[#19D412]/10 transition-all duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                Download Data
              </button>

              <!-- Back Button -->
              <a
                :href="getLinktoCamp"
                class="h-10 inline-flex items-center justify-center px-4 whitespace-nowrap bg-[#19D412] text-white font-medium rounded-lg shadow-md hover:bg-[#19D412]/90 transition-all duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
                Back
              </a>
            </div>
          </div>
        </div>

        <!-- Search and Upload Section -->
        <div class="p-6">
          <div
            class="flex flex-col md:flex-row items-center justify-between gap-4"
          >
            <!-- Search Bar -->
            <div class="relative w-full md:w-2/3">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="search"
                class="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-[#363166] focus:border-[#363166] transition-all duration-300"
                placeholder="Search by name..."
                v-model="searchQuery"
              />
            </div>

            <!-- Upload Button -->
            <button
              @click="goToUpload()"
              class="inline-flex items-center justify-center px-6 py-3 bg-gradient-to-r from-[#363166] to-[#030021] text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 w-full md:w-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
              Upload Customer
            </button>
          </div>
        </div>
      </div>

      <!-- Stats Summary -->
      <div
        v-if="resultQuery.length > 0"
        class="bg-white rounded-2xl shadow-xl overflow-hidden mb-8"
      >
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4"
            >
              <div class="flex items-center">
                <div
                  class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <div class="ml-4">
                  <h3 class="text-sm font-medium text-[#363166]">
                    Total Campers
                  </h3>
                  <p class="text-2xl font-bold text-[#030021]">
                    {{ campers.length }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4"
            >
              <div class="flex items-center">
                <div
                  class="h-12 w-12 rounded-full bg-[#19D412] flex items-center justify-center text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="ml-4">
                  <h3 class="text-sm font-medium text-[#363166]">Checked In</h3>
                  <p class="text-2xl font-bold text-[#030021]">
                    {{ checkedInCount }}
                    <span class="text-sm text-[#363166]"
                      >({{ checkedInPercentage }}%)</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4"
            >
              <div class="flex items-center">
                <div
                  class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="ml-4">
                  <h3 class="text-sm font-medium text-[#363166]">
                    Total Revenue
                  </h3>
                  <p class="text-2xl font-bold text-[#030021]">
                    ${{ totalRevenue.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Camper List -->
      <div v-if="resultQuery.length > 0" class="space-y-6">
        <div v-if="searchQuery" class="bg-white rounded-lg p-4 mb-2 shadow-sm">
          <p class="text-[#363166]">
            Found
            <span class="font-bold">{{ resultQuery.length }}</span> campers
            matching "{{ searchQuery }}"
            <button
              @click="searchQuery = ''"
              class="ml-2 text-[#19D412] hover:underline"
            >
              Clear search
            </button>
          </p>
        </div>

        <div
          v-for="(camper, index) in resultQuery"
          :key="camper._id"
          class="transform transition-all duration-300"
        >
          <CamperCard
            :firstName="camper.firstName"
            :lastName="camper.lastName"
            :tShirtSize="camper.tShirtSize"
            :isCheckedIn="camper.isCheckedIn"
            :tShirtReceived="camper.tShirtReceived"
            :campName="campName"
            :camperId="camper._id"
            :discountCode="camper.discountCodeApplied"
            :camperAddOns="camper.customAddOnValues"
            :camperProfileImage="camper.camperProfileImage"
            :bundlePricePaid="camper.bundlePricePaid"
            :scheduledSession="camper.scheduledSession"
            :pricePaid="camper.pricePaid"
            :isTeamCamp="false"
            :index="index"
            @camperUpdated="handleCamperUpdate"
          />
        </div>
      </div>

      <!-- No Results State -->
      <div
        v-else
        class="bg-white rounded-2xl shadow-xl overflow-hidden p-8 text-center"
      >
        <div class="flex flex-col items-center justify-center py-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-16 w-16 text-gray-300 mb-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 class="text-2xl font-bold text-[#030021] mb-2">
            {{
              campers.length > 0 ? 'No matching results' : 'No campers found'
            }}
          </h2>
          <p class="text-[#363166] mb-6">
            {{
              campers.length > 0
                ? 'Try adjusting your search criteria.'
                : 'Start by adding campers to this camp.'
            }}
          </p>

          <div class="flex flex-col sm:flex-row gap-4">
            <button
              v-if="campers.length > 0 && searchQuery"
              @click="searchQuery = ''"
              class="inline-flex items-center justify-center px-6 py-3 bg-[#363166] text-white font-medium rounded-lg shadow-md hover:bg-[#363166]/90 transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              Clear Search
            </button>

            <button
              @click="goToUpload()"
              class="inline-flex items-center justify-center px-6 py-3 bg-[#19D412] text-white font-medium rounded-lg shadow-md hover:bg-[#19D412]/90 transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              Add Campers
            </button>

            <a
              :href="getAdLink"
              class="inline-flex items-center justify-center px-6 py-3 bg-gradient-to-r from-[#363166] to-[#030021] text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
              Advertise My Event
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Hidden elements for external triggers -->
    <button id="openAdvertisementPopUp" style="display: none"></button>
  </div>
</template>

<script>
import axios from 'axios'
import CamperCard from '../../components/Campers/CamperCard.vue'
import swal from 'sweetalert'
import ClientLayout from '../../components/ClientLayout.vue'
import JsonExcel from 'vue-json-excel3'

export default {
  components: { ClientLayout, CamperCard, JsonExcel },
  data() {
    return {
      user: {},
      camp: {},
      campID: '',
      campName: '',
      campers: [],
      searchQuery: '',
      subscriptions: [],
      loading: true,
      json_fields: {
        'Camper First Name': 'firstName',
        'Camper Last Name': 'lastName',
        'Camper T-shirt size': 'tShirtSize',
        'Camper Emergency Contact First Name': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].firstName
              : 'N/A'
          },
        },
        'Camper Emergency Contact Last Name': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].lastName
              : 'N/A'
          },
        },
        'Camper Emergency Contact Email': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].email
              : 'N/A'
          },
        },
        'Camper Emergency Contact Phone Number': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].phoneNumber
              : 'N/A'
          },
        },
        'Camper Emergency Contact Address': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].address
              : 'N/A'
          },
        },
        'Camper Emergency Contact City': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].city
              : 'N/A'
          },
        },
        'Camper Emergency Contact State': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].state
              : 'N/A'
          },
        },
        'Camper Emergency Contact Zip Code': {
          callback: value => {
            return value.emergencyContactInfo && value.emergencyContactInfo[0]
              ? value.emergencyContactInfo[0].zipCode
              : 'N/A'
          },
        },
        'Amount Paid (USD)': {
          callback: value => {
            return value.pricePaid
          },
        },
      },
    }
  },
  methods: {
    getUserDetails() {
      try {
        const userData = localStorage.getItem('user')
        if (userData) {
          this.user = JSON.parse(userData)
        } else {
          console.error('User data not found in localStorage')
          this.redirectToLogin()
        }
      } catch (error) {
        console.error('Error parsing user data:', error)
        this.redirectToLogin()
      }
    },
    handleCamperUpdate(updatedCamper) {
      console.log('Camper updated:', updatedCamper)
      // Update the camper in the campers array
      const index = this.campers.findIndex(
        camper => camper._id === updatedCamper.camperId,
      )
      if (index !== -1) {
        this.campers[index].isCheckedIn = updatedCamper.isCheckedIn
        this.campers[index].tShirtReceived = updatedCamper.tShirtReceived
      }
    },

    redirectToLogin() {
      // Redirect to login page if user data is not available
      this.$router.push('/login')
    },

    goToUpload() {
      this.$router.push(`/Dashboard/UploadCamper/${this.campID}`)
    },

    showDownloadAd() {
      swal({
        icon: 'info',
        title: 'Upgrade Your Plan',
        text: 'You need to be at least a Pro user to download customer data locally. Please upgrade your plan to continue.',
        buttons: {
          cancel: 'Not Now',
          upgrade: {
            text: 'Upgrade Plan',
            value: 'upgrade',
          },
        },
      }).then(value => {
        if (value === 'upgrade') {
          document.getElementById('openAdvertisementPopUp').click()
        }
      })
    },

    addCustomFieldsExport() {
      if (!this.camp.customFields) return

      for (let x = 0; x < this.camp.customFields.length; x++) {
        if (this.camp.customFields[x]?.fieldName) {
          this.json_fields[this.camp.customFields[x].fieldName] = {
            callback: value => {
              return value.customFieldValues && value.customFieldValues[x]
                ? value.customFieldValues[x].fieldValue
                : 'N/A'
            },
          }
        }
      }
    },

    addCustomAddOnsExport() {
      if (!this.camp.customAddOns) return

      for (let x = 0; x < this.camp.customAddOns.length; x++) {
        if (this.camp.customAddOns[x]?.addOnName) {
          this.json_fields[
            'Camper Purchased ' + this.camp.customAddOns[x].addOnName
          ] = {
            callback: value => {
              return value?.customAddOnValues && value.customAddOnValues[x]
                ? value.customAddOnValues[x].isPurchased
                  ? 'Yes'
                  : 'No'
                : 'No'
            },
          }
        }
      }
    },

    discountsExport() {
      if (!this.camp.discounts) return

      this.json_fields['Discount Code Used'] = {
        callback: value => {
          return value.usedDiscountCode ? value.discountCodeApplied : 'None'
        },
      }
    },

    async getCamp() {
      this.loading = true

      try {
        const url = `${import.meta.env.VITE_APP_DB_URL}individualCamp/getCampByCampID?campID=${this.campID}`
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })

        this.camp = response.data.camp

        // Sort campers by session date and time if applicable
        if (response.data.camp.sessionSupported && response.data.camp.campers) {
          this.campers = response.data.camp.campers.sort((a, b) => {
            // Check if scheduledSession exists for both campers
            if (!a.scheduledSession && !b.scheduledSession) return 0
            if (!a.scheduledSession) return 1
            if (!b.scheduledSession) return -1

            // Compare dates
            const dateA = new Date(a.scheduledSession.date || null)
            const dateB = new Date(b.scheduledSession.date || null)

            if (dateA.getTime() !== dateB.getTime()) {
              return dateA - dateB
            }

            // If dates are the same, compare times
            return (a.scheduledSession.time || '').localeCompare(
              b.scheduledSession.time || '',
            )
          })
        } else if (response.data.camp.campers) {
          this.campers = response.data.camp.campers
        } else {
          this.campers = []
        }

        this.campName = response.data.camp.campName || 'Camp'

        // Add export fields
        this.addCustomFieldsExport()
        this.addCustomAddOnsExport()
        this.discountsExport()
      } catch (err) {
        console.error('Error fetching camp data:', err)
        swal(
          'Error',
          err.response?.data?.errorMessage || 'Failed to load camp data',
          'error',
        )
      } finally {
        this.loading = false
      }
    },

    async fetchSubscriptions() {
      try {
        const url = `${import.meta.env.VITE_APP_DB_URL}subscription/getAllSubscriptions`
        const response = await axios.get(url)
        this.subscriptions = response.data.subscriptions || []
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
      }
    },
  },

  created() {
    this.campID = this.$route.params.campId
    this.getUserDetails()
    this.getCamp()
    this.fetchSubscriptions()
  },

  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    getLinktoCamp() {
      return '/Dashboard/ViewCamp/Individual/' + this.campID
    },

    resultQuery() {
      if (this.searchQuery) {
        const searchTerms = this.searchQuery.toLowerCase().split(' ')
        return this.campers.filter(item => {
          return searchTerms.every(
            term =>
              item.firstName?.toLowerCase().includes(term) ||
              item.lastName?.toLowerCase().includes(term),
          )
        })
      } else {
        return this.campers
      }
    },

    getAdLink() {
      if (
        this.user.subscription &&
        this.user.subscription.subscriptionTier >= 4
      ) {
        return '/Dashboard/AdStore'
      } else {
        const adSub =
          this.subscriptions.length > 0
            ? this.subscriptions[this.subscriptions.length - 1]
            : null
        return adSub
          ? `/SubscriptionChoices/Checkout/${adSub._id}/false`
          : '/SubscriptionChoices'
      }
    },

    checkedInCount() {
      return this.campers.filter(camper => camper.isCheckedIn).length
    },

    checkedInPercentage() {
      if (this.campers.length === 0) return 0
      return Math.round((this.checkedInCount / this.campers.length) * 100)
    },

    totalRevenue() {
      return this.campers.reduce(
        (sum, camper) => sum + (camper.pricePaid || 0),
        0,
      )
    },
  },
}
</script>

<style scoped>
/* Loading animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Smooth transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Card hover effect */
.transform {
  transition: all 0.3s ease-in-out;
}

.transform:hover {
  transform: translateY(-4px);
  box-shadow:
    0 10px 25px -5px rgba(54, 49, 102, 0.1),
    0 10px 10px -5px rgba(54, 49, 102, 0.04);
}

/* Custom button styles */
button,
a {
  border-radius: 0.5rem !important;
  overflow: hidden;
  position: relative;
}

button::after,
a::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(to right, #363166, #030021);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

button:hover::after,
a:hover::after {
  opacity: 1;
}

/* Ensure consistent padding for all buttons */
.inline-flex {
  padding: 0.75rem 1.5rem !important;
}
</style>
