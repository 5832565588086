<template>
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section
      class="container justify-content-center"
      v-bind:style="{ marginLeft: isMobile() ? '21px' : '' }"
    >
      <LinkExternalAccount
        :newAccount="newAccount"
        :userID="this.user._id"
        @accountLinked="setPayoutInfo"
      ></LinkExternalAccount>

      <InitiatePayout
        :accountBalance="accountBalance"
        :payoutInfo="payoutInfo"
        :transfer="transfer"
        :userID="user._id"
        :subscriptionTier="user.subscription.subscriptionTier"
        @transferInitiated="setPayoutInfo"
      ></InitiatePayout>

      <SendToFriends
        :sendToFriendConfirmation="sendToFriendConfirmation"
        :sendToFriendEmail="sendToFriendEmail"
        :sendMoneyToFriend="sendMoneyToFriend"
        :inviteFriend="inviteFriend"
        :invitedFriendSuccessfully="invitedFriendSuccessfully"
        :addingContact="addingContact"
        :receivingFriend="receivingFriend"
        :user="user"
        :accountBalance="accountBalance"
        :sendMoneyAmount="sendMoneyAmount"
        :viewingContact="viewingContact"
        :payoutInfo="payoutInfo"
        @updateSendToFriendConfirmation="updateSendToFriendConfirmation"
        @updateSendToFriendEmail="updateSendToFriendEmail"
        @updateSendMoneyToFriend="updateSendMoneyToFriend"
        @updateInviteFriend="updateInviteFriend"
        @updateInvitedFriendSuccessfully="updateInvitedFriendSuccessfully"
        @updateAddingContact="updateAddingContact"
        @updateReceivingFriend="updateReceivingFriend"
        @updateAccountBalance="updateAccountBalance"
        @updateSendMoneyAmount="updateSendMoneyAmount"
        @updatePayoutInfo="setPayoutInfo"
      ></SendToFriends>

      <ViewExternalAccountDetails
        :viewingAccount="this.viewingAccount"
        :transfer="this.transfer"
        :userID="this.user._id"
        @updatedPayoutInfo="setPayoutInfo"
      ></ViewExternalAccountDetails>

      <div class="container mt-5 justify-content-center">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card" style="max-height: 1200px">
              <div class="card-body">
                <h4 class="card-title text-center">
                  <b>Current Account Balance</b>
                </h4>
                <br />
                <br />
                <h1 class="card-text text-center">
                  ${{ formatAccountBalance }}
                </h1>
                <br />

                <div class="center justify-content-center mt-3">
                  <a
                    class="card2 camp-step-4"
                    data-bs-toggle="modal"
                    data-bs-target="#initiatePayout"
                    target="_blank"
                    style="cursor: pointer; color: #363166"
                  >
                    <h5 class="text-center">
                      <i
                        class="fa-solid fa-money-bill-transfer"
                        style="color: #363166"
                      ></i>
                      Initiate Bank Transfer
                    </h5>
                    <div class="go-corner" href="#">
                      <div class="go-arrow">→</div>
                    </div>
                  </a>

                  <a
                    class="card2 camp-step-4"
                    data-bs-toggle="modal"
                    data-bs-target="#sendToFriends"
                    target="_blank"
                    style="cursor: pointer; color: #363166"
                    @click="sendToFriendsClick()"
                  >
                    <h5 class="text-center">
                      <i class="fa fa-user-group" style="color: #363166"></i>
                      Send to Friends
                      <span class="m-1 badge newGradient" style="color: white"
                        >New</span
                      >
                    </h5>
                    <div class="go-corner" href="#">
                      <div class="go-arrow">→</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="card" style="max-height: 1200px">
              <div class="card-body">
                <h4 class="card-title text-center">
                  <b>Linked Bank Accounts</b>
                </h4>
                <br />
                <div
                  v-for="externalAccount in this.payoutInfo.externalAccounts.filter(
                    x => x.isDeleted === false
                  )"
                  :key="externalAccount.accountID"
                >
                  <div
                    class="card card-body mb-3 information-card"
                    style="cursor: pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#viewExternalAccountDetails"
                    @click="
                      viewExternalAccountDetails(externalAccount.accountID)
                    "
                  >
                    <h5 class="text-center">
                      {{ externalAccount.accountName.split('-')[1] }} |
                      {{
                        externalAccount.accountType === 'CHECKING'
                          ? 'Checking'
                          : 'Savings'
                      }}
                    </h5>
                  </div>
                </div>
                <div class="center justify-content-center">
                  <a
                    class="card2 camp-step-3"
                    data-bs-toggle="modal"
                    data-bs-target="#addExternalAccount"
                    target="_blank"
                    style="cursor: pointer; color: #363166"
                  >
                    <h5 class="text-center">
                      <i
                        class="fa-solid fa-building-columns"
                        style="color: #363166"
                      ></i>
                      Link New Bank Account
                    </h5>
                    <div class="go-corner" href="#">
                      <div class="go-arrow">→</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="card" style="max-height: 1200px">
              <div class="card-body">
                <h4 class="card-title text-center">
                  <b>Friends List</b>
                </h4>
                <br />
                <div
                  v-for="contact in this.payoutInfo.contactList"
                  :key="contact.userID"
                >
                  <div
                    class="card card-body mb-3 information-card"
                    style="cursor: pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#viewContactDetails"
                    v-if="!contact.isDeleted"
                    @click="viewContactDetails(contact)"
                  >
                    <h5 class="text-center">
                      {{ contact.firstName + ' ' + contact.lastName }}
                    </h5>
                    <div
                      class="center text-center text-muted"
                      style="cursor: pointer"
                    >
                      {{ contact.email }}
                    </div>
                  </div>
                </div>
                <div class="center justify-content-center">
                  <a
                    class="card2 camp-step-3"
                    data-bs-toggle="modal"
                    data-bs-target="#sendToFriends"
                    target="_blank"
                    style="cursor: pointer; color: #363166"
                    @click="addContact()"
                  >
                    <h5 class="text-center">
                      <i
                        class="fa-solid fa-user-plus"
                        style="color: #363166"
                      ></i>
                      Add New Friend
                    </h5>
                    <div class="go-corner" href="#">
                      <div class="go-arrow">→</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import ClientLayout from '../../components/ClientLayout.vue'
import LinkExternalAccount from '../../components/Banking/BankAccountsModals/LinkExternalAccount.vue'
import InitiatePayout from '../../components/Banking/BankAccountsModals/InitiatePayout.vue'
import SendToFriends from '../../components/Banking/BankAccountsModals/SendToFriends.vue'
import ViewExternalAccountDetails from '../../components/Banking/BankAccountsModals/ViewExternalAccountDetails.vue'
import swal from 'sweetalert'
import swal2 from 'sweetalert2'
import numeral from 'numeral'
export default {
  components: {
    ClientLayout,
    LinkExternalAccount,
    InitiatePayout,
    SendToFriends,
    ViewExternalAccountDetails,
  },
  data() {
    return {
      user: {
        _id: '',
        subscription: {
          subscriptionTier: null,
        },
      },
      camps: [],
      teamCamps: [],
      memberships: [],
      newAccount: {
        routingNumber: null,
        accountNumber: null,
        accountNumberConfirmation: null,
        accountNickname: null,
        type: null,
      },
      transfer: {
        amount: 0.0,
        account: null,
      },
      payoutInfo: {
        payoutTotal: 0,
        externalAccounts: [],
      },
      viewingAccount: {},
      viewingContact: {
        userID: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },
      sendToFriendEmail: '',
      receivingFriend: {},
      sendToFriendConfirmation: false,
      sendMoneyToFriend: false,
      inviteFriend: false,
      addingContact: false,
      invitedFriendSuccessfully: false,
      sendMoneyAmount: 0.0,

      accountBalance: 0.0,
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    updateSendToFriendEmail(email) {
      this.sendToFriendEmail = email
    },
    updateSendToFriendConfirmation(boolean) {
      this.sendToFriendConfirmation = boolean
    },
    updateSendMoneyToFriend(boolean) {
      this.sendMoneyToFriend = boolean
    },
    updateInviteFriend(boolean) {
      this.inviteFriend = boolean
    },
    updateInvitedFriendSuccessfully(boolean) {
      this.invitedFriendSuccessfully = boolean
    },
    updateAddingContact(boolean) {
      this.addingContact = boolean
    },
    updateReceivingFriend(boolean) {
      this.receivingFriend = boolean
    },
    updateAccountBalance(value) {
      this.accountBalance = value
    },
    updateSendMoneyAmount(value) {
      this.sendMoneyAmount = value
    },

    setPayoutInfo(payInfo) {
      console.log(payInfo)
      this.payoutInfo = payInfo.payoutInfo
      console.log(payInfo.payoutInfo.contactList)
      this.accountBalance = payInfo.accountBalance
        ? payInfo.accountBalance
        : this.accountBalance
    },
    viewExternalAccountDetails(accountID) {
      for (const account of this.payoutInfo.externalAccounts) {
        if (account.accountID === accountID) {
          this.viewingAccount = account
        }
      }
    },

    addContact() {
      console.log('HIT')
      this.addingContact = true
      this.sendToFriendConfirmation = false
      this.sendMoneyToFriend = false
      this.inviteFriend = false
      this.invitedFriendSuccessfully = false
    },

    sendToFriendsClick() {
      this.addingContact = false
      this.sendToFriendConfirmation = false
      this.sendMoneyToFriend = false
      this.inviteFriend = false
      this.invitedFriendSuccessfully = false
    },

    viewContactDetails(contact) {
      axios
        .get(
          import.meta.env.VITE_APP_DB_URL +
            'banking/checkSendToFriendsEmail?email=' +
            contact.email?.toLowerCase(),
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.viewingContact.firstName = response.data.user.firstName
            this.viewingContact.lastName = response.data.user.lastName
            this.viewingContact.phoneNumber = response.data.user.phoneNumber
            this.viewingContact.email = response.data.user.email
            this.viewingContact.userID = response.data.user._id
            this.sendToFriendEmail = this.viewingContact.email
            this.receivingFriend = this.viewingContact
            this.sendMoneyToFriend = true
            if (
              contact.firstName != response.data.user.firstName ||
              contact.lastName != response.data.user.lastName ||
              contact.phoneNumber != response.data.user.phoneNumber
            ) {
              swal2.fire({
                title: 'Friend Details Have Changed',
                icon: 'warning',
                html: `Certain details of the friend associated with this email have been changed since you added them. Please double check all information and verify ownership regarding this account before transferring funds`,
                confirmButtonText: 'Okay, got it!',
              })
            }
          } else {
            swal2
              .fire({
                title: 'Account No Longer Exists',
                icon: 'error',
                html: `We're sorry! An account under this email address no longer exists. Please reach out to the account owner, and get their new email address.`,
                confirmButtonText: 'Okay, got it!',
              })
              .then(willConfirm => {
                if (willConfirm.isConfirmed) {
                  document.getElementById('viewContactDetailsClose').click()
                }
              })
          }
        })
    },

    getUserDetails() {
      let decoded = JSON.parse(localStorage.getItem('user'))
      this.user = decoded
      console.log(this.user)
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'user/getPayoutInfo?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.payoutInfo = response.data.payoutInfo
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getCamps() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/camps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.camps = response.data.reverse()
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
      url =
        import.meta.env.VITE_APP_DB_URL +
        'teamCamp/teamCamps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.teamCamps = response.data
          this.teamCamps.sort((a, b) =>
            a.campDates[a.campDates.length - 1].end <
            b.campDates[b.campDates.length - 1].end
              ? 1
              : -1
          )
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    async getMemberships() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getCoachMemberships?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.memberships = response.data
          this.memberships.sort((a, b) => (a.date > b.date ? 1 : -1))
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getAccountBalance() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'util/getAccountBalance?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.accountBalance = response.data.accountBalance
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
  },

  computed: {
    formatAccountBalance() {
      return numeral(this.accountBalance).format('0,0.00')
    },
  },

  created() {
    this.getUserDetails()
    this.getCamps()
    this.getMemberships()
    this.getAccountBalance()
  },
}
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.currencyinput {
  border: 1px inset #ccc;
}
.currencyinput input {
  border: 0;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.newGradient {
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #32bd28 0%,
    #45487a 50%,
    #45487a 100%
  );
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
