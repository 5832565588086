<template>
  <div class="wrapper" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div
            class="profile-container"
            v-bind:style="{
              marginTop: '3%',
              marginBottom: '3%',
            }"
          >
            <div class="profile-card">
              <!-- Profile Header -->
              <div class="profile-header">
                <div class="header-content">
                  <div class="profile-image-section">
                    <div class="profile-image-container">
                      <img :src="getImage" class="profile-image" />
                      <label for="image-input" class="image-upload-label">
                        <i class="fa-solid fa-plus"></i>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        @change="uploadImage($event)"
                        id="image-input"
                        hidden
                      />
                    </div>
                  </div>
                  <div class="profile-info">
                    <img
                      src="../../../../public/SummerAthletes.png"
                      class="logo-image"
                      v-bind:style="{
                        width: isMobile() ? '200px' : '250px',
                        maxWidth: '100%',
                      }"
                    />
                    <h1 class="customer-name">
                      {{ data.firstName }} {{ data.lastName }}
                    </h1>
                    <h3 v-if="this.camperRefunded" class="refunded-badge">
                      (Camper Refunded)
                    </h3>
                  </div>
                </div>
              </div>

              <!-- Emergency Contact Section -->
              <div class="section-title">Emergency Contact Information</div>
              <div class="row g-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      v-model="data.emergencyContactInfo[0].firstName"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      v-model="data.emergencyContactInfo[0].lastName"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone Number"
                      v-model="data.emergencyContactInfo[0].phoneNumber"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="data.emergencyContactInfo[0].email"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Address"
                      v-model="data.emergencyContactInfo[0].address"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      v-model="data.emergencyContactInfo[0].city"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>State</label>
                    <select
                      class="form-control"
                      v-model="data.emergencyContactInfo[0].state"
                    >
                      <option value="" disabled selected>State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Zip Code</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Zip Code"
                      v-model="data.emergencyContactInfo[0].zipCode"
                    />
                  </div>
                </div>
              </div>

              <!-- Custom Fields Section -->
              <div class="section-title mt-4">Additional Information</div>
              <div class="row">
                <!--Start of col 1-->
                <div class="col">
                  <li
                    style="list-style-type: none"
                    v-for="index in getTriCol.length"
                    :key="index"
                  >
                    <CustomFieldDisplay
                      v-if="
                        getTriCol[index - 1].fieldType != 'textLong' &&
                        (index + getTextLongNum) % 3 == 0
                      "
                      :field="getTriCol[index - 1]"
                      @change="formatData($event, value2)"
                    ></CustomFieldDisplay>
                  </li>
                </div>
                <!--Start of col 2-->
                <div class="col">
                  <li
                    style="list-style-type: none"
                    v-for="index in getTriCol.length"
                    :key="index"
                  >
                    <CustomFieldDisplay
                      v-if="
                        getTriCol[index - 1].fieldType != 'textLong' &&
                        (index + getTextLongNum) % 3 == 1
                      "
                      :field="getTriCol[index - 1]"
                      @change="formatData($event, value2)"
                    ></CustomFieldDisplay>
                  </li>
                </div>

                <!--Start of col 3-->
                <div class="col">
                  <li
                    style="list-style-type: none"
                    v-for="index in getTriCol.length"
                    :key="index"
                  >
                    <CustomFieldDisplay
                      v-if="
                        getTriCol[index - 1].fieldType != 'textLong' &&
                        (index + getTextLongNum) % 3 == 2
                      "
                      :field="getTriCol[index - 1]"
                      @change="formatData($event, index)"
                    ></CustomFieldDisplay>
                  </li>
                </div>
              </div>

              <!-- Notes Section -->
              <div class="section-title mt-4">Notes</div>
              <div class="notes-container">
                <CamperNotes
                  v-for="(note, index) in data.camperNotes"
                  :key="index"
                  :note="note"
                  @changed="noteChange($event, index + 1)"
                ></CamperNotes>
              </div>

              <!-- Documents Section -->
              <div v-if="!this.camperRefunded" class="documents-section mt-4">
                <div class="section-header" @click="toggleUploadedDocuments">
                  <h3>Uploaded Documents</h3>
                  <i
                    :class="
                      showUploadedDocuments
                        ? 'fa-solid fa-caret-down'
                        : 'fa-solid fa-caret-right'
                    "
                  ></i>
                </div>
                <div v-if="showUploadedDocuments" class="documents-list">
                  <div
                    v-for="(file, index) in data.files"
                    :key="index"
                    class="document-item"
                  >
                    <span class="document-number">{{ index + 1 }}.</span>
                    <a
                      href="#"
                      @click="getCamperFile(file.S3Key, file.fileName)"
                      :id="file.S3Key"
                      class="document-link"
                    >
                      {{ file.fileName }}
                    </a>
                  </div>
                </div>
              </div>

              <!-- Action Buttons -->
              <div v-if="!this.camperRefunded" class="action-buttons mt-4">
                <div class="row g-3">
                  <div class="col-md-4">
                    <label for="file-input" class="action-button upload-button">
                      <i class="fas fa-upload me-2"></i>Upload Document
                    </label>
                    <input
                      type="file"
                      accept="*"
                      @change="uploadCamperFile($event)"
                      id="file-input"
                      hidden
                    />
                  </div>
                  <div class="col-md-4">
                    <button class="action-button" @click="addNotes">
                      <i class="fas fa-plus me-2"></i>Add Notes
                    </button>
                  </div>
                  <div class="col-md-4">
                    <button
                      class="action-button save-button"
                      @click="saveCamperInfo"
                    >
                      <i class="fas fa-save me-2"></i>Save Changes
                    </button>
                  </div>
                  <div class="col-md-4">
                    <button
                      class="action-button back-button"
                      @click="backToCamperList"
                    >
                      <i class="fas fa-arrow-left me-2"></i>Back to List
                    </button>
                  </div>
                  <div class="col-md-4">
                    <button
                      class="action-button refund-button"
                      data-bs-toggle="modal"
                      data-bs-target="#partialRefundModal"
                    >
                      <i class="fas fa-undo me-2"></i>Refund Customer
                    </button>
                  </div>
                  <div class="col-md-4">
                    <button
                      class="action-button delete-button"
                      @click="refundAndDelete"
                    >
                      <i class="fas fa-trash me-2"></i>Refund & Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PartialRefundModal
      :pricePaid="data.pricePaid"
      :campID="this.$route.params.id"
      :camperID="this.$route.params.profile"
      :userID="this.user._id"
      @refunded="refund"
    ></PartialRefundModal>
  </div>
</template>

<script>
import CustomFieldDisplay from '../../components/CustomFieldDisplay.vue'
import swal from 'sweetalert'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import CamperNotes from '../../components/Campers/CamperNotes.vue'
import ClientLayout from '../../components/ClientLayout.vue'
import PartialRefundModal from '../../components/Campers/PartialRefundModal.vue'

export default {
  components: {
    ClientLayout,
    CustomFieldDisplay,
    CamperNotes,
    PartialRefundModal,
  },
  data() {
    return {
      user: {},
      data: {
        emergencyContactInfo: [
          {
            firstName: '',
            lastName: '',
          },
        ],
        customFieldValues: [],
      },
      camp: {},
      numFields: 0,
      test: '',
      uploadedFiles: [],
      selectedFile: null,
      showUploadedDocuments: false,
      camperRefunded: false,
    }
  },

  methods: {
    uploadImage(event) {
      let id = this.$route.params.id
      let profile = this.$route.params.profile
      //this.gif = true;
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/setProfileImage'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('type', 'IndividualCamp')
      data.append('eventId', id)
      data.append('profileId', profile)
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          console.log(response)
          this.data.camperProfileImage = response.data.location
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error',
          )
        })
    },
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    refund(pricePaid) {
      this.data.pricePaid = pricePaid
    },

    formatData() {
      console.log('formatting')
      for (let i = 0; i < this.data.customFieldValues.length; i++) {
        console.log('test')
        if (this.data.customFieldValues[i].value2) {
          this.data.customFieldValues[i].fieldValue =
            this.data.customFieldValues[i].fieldValue +
            "'" +
            this.data.customFieldValues[i].value2
          delete this.data.customFieldValues[i].value2
        }
      }
    },
    noteChange(x, y) {
      console.log(x)
      console.log(y)
      this.data.camperNotes[y - 1] = x
    },
    saveCamperInfo() {
      console.log('saving')
      let campId = this.$route.params.id
      swal({
        title: 'Confirm',
        text: 'Confirm save of camper information',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willSave => {
        if (willSave) {
          console.log('here')
          this.formatData()
          let url =
            import.meta.env.VITE_APP_DB_URL + 'individualCamp/updateCamperInfo'
          axios
            .post(
              url,
              {
                id: this.user._id,
                camp: campId,

                data: this.data,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              },
            )
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
              this.newCamp = this.savedData
            })

          swal('Success', 'Camper Info Updated', 'success')
        }
      })
    },
    addNotes() {
      console.log('here')
      let temp = { title: '', notes: '' }
      this.data.camperNotes.push(temp)
      this.numFields++
    },
    async refundAndDelete() {
      let campId = this.$route.params.id
      swal({
        title: 'Confirm Details',
        text: 'Confirm deletion and refund of camper',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          let url =
            import.meta.env.VITE_APP_DB_URL +
            'individualCamp/deleteAndRefundCamper'
          axios
            .post(
              url,
              {
                id: this.user._id,
                camp: campId,
                camper: this.data._id,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              },
            )
            .then(response => {
              if (response.data.errorMessage) {
                swal('Error', response.data.errorMessage, 'error')
                return
              }
              console.log('here')
              console.log(response)
              swal(
                'Success',
                'Camper refunded and deleted successfully',
                'success',
              )
              this.$router.push('/Dashboard/CheckIn/' + campId)
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
              this.newCamp = this.savedData
              this.$router.push('/Dashboard/CheckIn/' + campId)
            })
        }
      })
    },
    backToCamperList() {
      let id = this.$route.params.id
      this.$router.push('/Dashboard/CheckIn/' + id)
    },
    getUserDetails() {
      let token = localStorage.getItem('jwt')
      let decoded = VueJwtDecode.decode(token)
      this.user = decoded
    },
    async getCamperData() {
      let id = this.$route.params.id
      let profile = this.$route.params.profile

      // First get the camp data to get custom field structure
      let campUrl =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getCampByCampID?campID=' +
        id
      await axios
        .get(campUrl, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.camp = response.data.camp
          console.log('Camp data:', this.camp)
        })
        .catch(err => {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })

      // Then get the camper data
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getCamperData?coachId=' +
        this.user._id +
        '&campId=' +
        id +
        '&profile=' +
        profile
      await axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.data = response.data
          this.camperRefunded = response.data.camperRefunded

          // Initialize customFieldValues if needed
          if (!this.data.customFieldValues) {
            this.data.customFieldValues = []
          }

          // Initialize camperNotes if needed
          if (!this.data.camperNotes) {
            this.data.camperNotes = []
            this.numFields = 0
          } else {
            this.numFields = this.data.camperNotes.length
          }

          console.log('Camper data:', this.data)
        })
        .catch(err => {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    uploadCamperFile(event) {
      this.gif = true
      const URL =
        import.meta.env.VITE_APP_DB_URL +
        'util/uploadCamperFile?userId=' +
        this.user._id +
        '&campId=' +
        this.$route.params.id +
        '&camperId=' +
        this.$route.params.profile
      let data = new FormData()
      data.append('name', 'camper-' + this.$route.params.profile)
      data.append('file', event.target.files[0])
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(() => {
          console.log(event.target.files[0])
          this.gif = false
          swal(
            'Success',
            `${event.target.files[0].name} uploaded successfully. You may need to refresh the page.`,
            'success',
          )
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error',
          )
        })
    },
    getCamperFile(S3Key, fileName) {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'util/getCamperFile?S3Key=' +
        S3Key +
        '&fileName=' +
        fileName
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
          responseType: 'blob',
        })
        .then(async response => {
          // console.log(response.data);
          // const downloadBlobURL = window.URL.createObjectURL(downloadBlob);
          const downloadBlobURL = window.URL.createObjectURL(
            new Blob([response.data]),
          )

          console.log(downloadBlobURL)
          document.getElementById(S3Key).href = downloadBlobURL
          // this.selectedFile = response.data;

          const link = document.createElement('a')
          link.href = downloadBlobURL
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (err) {
          console.log(err)

          swal('Error', err, 'error')
        })
    },
    toggleUploadedDocuments() {
      this.showUploadedDocuments = !this.showUploadedDocuments
    },
  },
  created() {
    this.getUserDetails()
    this.getCamperData()
  },
  computed: {
    getImage() {
      if (!this.data.camperProfileImage) {
        return '/headshot-icon.png'
      } else {
        return this.data.camperProfileImage
      }
    },
    getTriCol() {
      let temp = []
      for (let x = 0; x < this.data.customFieldValues.length; x++) {
        temp.push(this.data.customFieldValues[x])
      }

      return temp
    },
    getTextLongNum() {
      let temp = 0
      for (let x = 0; x < this.data.customFieldValues.length; x++) {
        if (this.data.customFieldValues[x].fieldType == 'textLong')
          temp = temp + 1
      }

      return temp
    },
  },
}
</script>

<style scoped>
.profile-container {
  padding: 20px;
  margin: 0 auto;
}

.profile-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 100%;
}

.profile-header {
  padding: 30px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.header-content {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  max-width: 900px;
  margin: 0 auto;
  padding-left: 80px;
}

.profile-image-section {
  flex-shrink: 0;
  margin-right: 40px;
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 10px 0;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #363166;
}

.image-upload-label {
  position: absolute;
  bottom: 5px;
  right: 15px;
  background: #19d412;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.image-upload-label:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.profile-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  margin-left: -40px;
}

.logo-image {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(-20px);
}

.customer-name {
  color: #363166;
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 10px 0;
  transform: translateX(-20px);
}

.refunded-badge {
  color: #dc3545;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}

.section-title {
  color: #363166;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  color: #666;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.form-control {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #363166;
  box-shadow: 0 0 0 0.2rem rgba(54, 49, 102, 0.25);
}

.documents-section {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #363166;
}

.section-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.documents-list {
  margin-top: 15px;
}

.document-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 10px;
}

.document-number {
  color: #666;
  font-weight: 500;
}

.document-link {
  color: #363166;
  text-decoration: none;
  transition: color 0.3s ease;
}

.document-link:hover {
  color: #2a2461;
}

.action-buttons {
  margin-top: 30px;
}

.action-button {
  width: 100%;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.upload-button {
  background: #363166;
  color: white;
}

.save-button {
  background: #28a745;
  color: white;
}

.back-button {
  background: #6c757d;
  color: white;
}

.refund-button {
  background: #ffc107;
  color: #000;
}

.delete-button {
  background: #dc3545;
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .header-content {
    padding-left: 0;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .profile-info {
    margin-left: 0;
  }

  .logo-image,
  .customer-name {
    transform: none;
  }

  .profile-image-container {
    width: 120px;
    height: 120px;
  }

  .customer-name {
    font-size: 1.75rem;
  }
}
</style>
