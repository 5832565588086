import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'
import 'bootstrap/dist/css/bootstrap.css'

import * as Sentry from '@sentry/vue'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://db26f9a5219be6c935b7a79bf7a6ef37@o4508217888538624.ingest.us.sentry.io/4508217910099968',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/app\.summerathletes\.com\//,
    /^https:\/\/testapp\.summerathletes\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(router)

app.mount('#app')
