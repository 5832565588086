<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <!-- Loading Skeleton -->
        <template v-if="!camp">
          <div class="w-full">
            <!-- Image Skeleton -->
            <div
              class="w-full h-[200px] bg-gray-200 animate-pulse rounded-lg"
            ></div>

            <!-- Title Skeleton -->
            <div class="mt-6 space-y-4">
              <div
                class="h-8 bg-gray-200 rounded animate-pulse w-1/2 mx-auto"
              ></div>

              <!-- Form Fields Skeleton -->
              <div class="space-y-4 mt-4">
                <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
                <div
                  class="h-10 bg-gray-200 rounded animate-pulse w-full"
                ></div>
                <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
                <div
                  class="h-10 bg-gray-200 rounded animate-pulse w-full"
                ></div>
              </div>

              <!-- T-shirt Size Skeleton -->
              <div class="mt-6">
                <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
                <div
                  class="h-10 bg-gray-200 rounded animate-pulse w-full mt-2"
                ></div>
              </div>

              <!-- Add Ons Skeleton -->
              <div class="mt-6">
                <div class="h-4 bg-gray-200 rounded animate-pulse w-1/3"></div>
                <div class="space-y-2 mt-2">
                  <div
                    class="h-8 bg-gray-200 rounded animate-pulse w-full"
                  ></div>
                  <div
                    class="h-8 bg-gray-200 rounded animate-pulse w-full"
                  ></div>
                </div>
              </div>

              <!-- Emergency Contact Skeleton -->
              <div class="mt-6">
                <div class="h-8 bg-gray-200 rounded animate-pulse w-3/4"></div>
                <div class="space-y-4 mt-4">
                  <div
                    class="h-4 bg-gray-200 rounded animate-pulse w-1/4"
                  ></div>
                  <div
                    class="h-10 bg-gray-200 rounded animate-pulse w-full"
                  ></div>
                  <div
                    class="h-4 bg-gray-200 rounded animate-pulse w-1/4"
                  ></div>
                  <div
                    class="h-10 bg-gray-200 rounded animate-pulse w-full"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Actual Content -->
        <template v-else>
          <img :src="getImage()" class="card-img-top rounded-corners" />
          <h3 class="text-center" style="margin-top: 20px">Registrant Info</h3>
          <div class="row">
            <div class="card-input col">
              <label for="cardName" class="card-input__label">First Name</label>
              <input
                type="text"
                id="cardName"
                class="card-input__input"
                v-model="campers[0].firstName"
                data-ref="cardName"
                autocomplete="given-name"
                required
              />
            </div>

            <div class="card-input col">
              <label for="cardName" class="card-input__label">Last Name</label>
              <input
                type="text"
                id="cardName"
                class="card-input__input"
                v-model="campers[0].lastName"
                data-ref="cardName"
                autocomplete="family-name"
              />
            </div>
          </div>
          <li
            style="list-style-type: none"
            v-for="(count, index) in campers[0].customFields"
            :key="index"
          >
            <CustomField
              :presetField="campers[0].customFields[index]"
              :number="0"
              :index="index"
              @changed="updateFieldValue(0, index, $event.field)"
            ></CustomField>
          </li>
          <!--End of Custom Forms-->
          <div v-if="this.camp.tShirtsProvided">
            <label>T-shirt size</label>
            <select
              class="card-input__input -select"
              style="margin-bottom: 20px"
              v-model="campers[0].tShirtSize"
              required
            >
              <option disabled selected value="">Size</option>
              <option value="YS" class="option">Youth Small</option>
              <option value="YM" class="option">Youth Medium</option>
              <option value="YL" class="option">Youth Large</option>
              <option value="AS" class="option">Adult Small</option>
              <option value="AM" class="option">Adult Medium</option>
              <option value="AL" class="option">Adult Large</option>
              <option value="AXL" class="option">Adult XLarge</option>
              <option value="AXXL" class="option">Adult XXLarge</option>
            </select>
          </div>

          <div
            v-if="campers[0].customAddOns.length > 0"
            class="list-group-item"
          >
            <hr />

            <b>Optional Add Ons</b>
            <ul>
              <li
                v-for="(field, index) in campers[0].customAddOns.length"
                :key="field"
                class="list-group-item"
              >
                <div class="row d-flex">
                  <div class="col-md-3">
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      value=""
                      v-model="campers[0].customAddOns[index].isPurchased"
                    />{{ campers[0].customAddOns[index].addOnName }}
                  </div>
                  <div class="col-md-3">
                    ${{ campers[0].customAddOns[index].addOnPrice }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!--start of extra registrants-->
          <div v-if="this.campers.length > 1">
            <li
              class="list-group-item m-3 v-step-5"
              v-for="(camper, camperIndex) in campers.slice(1, 4)"
              :key="'campDate' + camperIndex"
            >
              <div
                class="row"
                style="
                  border: 3px solid #45487a;
                  border-radius: 15px;
                  max-width: 558px;
                "
              >
                <h3 class="text-center" style="margin-top: 20px">
                  Registrant {{ camperIndex + 2 }} Info
                </h3>
                <div class="card-input col">
                  <label for="cardName" class="card-input__label"
                    >First Name</label
                  >
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    v-model="campers[camperIndex + 1].firstName"
                    data-ref="cardName"
                    autocomplete="given-name"
                    required
                  />
                </div>
                <div class="card-input col">
                  <label for="cardName" class="card-input__label"
                    >Last Name</label
                  >
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    v-model="campers[camperIndex + 1].lastName"
                    data-ref="cardName"
                    autocomplete="family-name"
                  />
                </div>
                <!--Start of Custom Forms-->
                <li
                  style="list-style-type: none"
                  v-for="(count, index) in campers[camperIndex + 1]
                    .customFields"
                  :key="index"
                >
                  <CustomField
                    :presetField="campers[camperIndex + 1].customFields[index]"
                    :number="camperIndex + 1"
                    :index="
                      index +
                      (camperIndex + 1) *
                        campers[camperIndex + 1].customFields.length
                    "
                    @changed="
                      updateFieldValue(camperIndex + 1, index, $event.field)
                    "
                  ></CustomField>
                </li>
                <!--End of Custom Forms-->
                <!--Start of Custom Add Ons-->
                <div
                  v-if="campers[camperIndex + 1].customAddOns.length > 0"
                  class="list-group-item"
                >
                  <hr />

                  <b>Optional Add Ons</b>
                  <ul>
                    <li
                      v-for="(field, index) in campers[camperIndex + 1]
                        .customAddOns.length"
                      :key="field"
                      class="list-group-item"
                    >
                      <div class="row d-flex">
                        <div class="col-md-3">
                          <input
                            class="form-check-input me-2"
                            type="checkbox"
                            value=""
                            v-model="
                              campers[camperIndex + 1].customAddOns[index]
                                .isPurchased
                            "
                          />{{
                            campers[camperIndex + 1].customAddOns[index]
                              .addOnName
                          }}
                        </div>
                        <div class="col-md-3">
                          ${{
                            campers[camperIndex + 1].customAddOns[index]
                              .addOnPrice
                          }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!--End of Custom Add Ons-->

                <i
                  class="fa-solid fa-trash removeDay"
                  @click="removeAdditionalCamper(camperIndex + 1)"
                ></i>
                <div
                  v-if="getAddOnLength != 0"
                  class="list-group-item"
                  style="margin-left: 12px"
                ></div>
              </div>
            </li>
          </div>
          <!--end of extra registrants-->
          <div v-if="checkAddRegistrantButton" class="flex justify-center">
            <button
              class="flex items-center justify-center gap-2 text-white py-1 px-3 font-small transition-colors duration-200 custom-button"
              @click="addAdditionalCamper"
              :style="{
                marginTop: '10px',
                color: 'white',
              }"
            >
              Add Registrant - ${{ getAdditionalCamperPrice }}
            </button>
          </div>
          <br v-show="!this.emergencyBool" />
          <h3 v-show="!this.emergencyBool">Emergency Contact Information</h3>
          <div v-show="!this.emergencyBool" style="margin-top: 15px">
            <div class="row">
              <div class="card-input col">
                <label class="card-input__label">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  class="card-input__input"
                  v-model="emergencyContactInfo.firstName"
                />
              </div>
              <div class="card-input col">
                <label class="card-input__label">Last Name</label>
                <input
                  type="text"
                  id="name"
                  class="card-input__input"
                  v-model="emergencyContactInfo.lastName"
                />
              </div>
            </div>
            <div class="card-input">
              <label class="card-input__label">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                class="card-input__input"
                v-model="emergencyContactInfo.phoneNumber"
              />
            </div>
            <div class="card-input">
              <label class="card-input__label">Email</label>
              <input
                type="email"
                id="email"
                class="card-input__input"
                v-model="emergencyContactInfo.email"
              />
            </div>
            <div class="card-input">
              <label class="card-input__label">Address</label>
              <input
                type="text"
                id="address"
                class="card-input__input"
                v-model="emergencyContactInfo.address"
              />
            </div>
            <div class="card-input">
              <label class="card-input__label">City</label>
              <input
                type="text"
                id="address"
                class="card-input__input"
                v-model="emergencyContactInfo.city"
              />
            </div>

            <div class="card-form__row">
              <div class="card-form__col">
                <div class="card-form__group">
                  <label for="cardMonth" class="card-input__label">State</label>
                  <select
                    class="card-input__input -select"
                    id="cardMonth"
                    v-model="emergencyContactInfo.state"
                  >
                    <option value="" disabled selected>State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div class="card-form__col">
                <div class="card-input">
                  <label for="cardCvv">Zip Code</label>
                  <input
                    type="text"
                    class="card-input__input"
                    v-model="emergencyContactInfo.zipCode"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr />

            <h5 class="text-center">Pay With</h5>
            <div class="row mt-3">
              <div class="col-md-6 col-sm-6">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="paypal"
                    v-model="this.paymentType"
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    ><a title="PayPal"
                      ><img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png"
                        alt="PayPal"
                        style="max-height: 30px; max-width: 100px"
                      /> </a
                  ></label>
                </div>

                <br />
                <br />
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="venmo"
                    v-model="this.paymentType"
                  />
                  <label class="form-check-label" for="inlineRadio3"
                    ><a title="Venmo"
                      ><img
                        src="https://images.ctfassets.net/gkyt4bl1j2fs/273APNSE63eFeQGVaNokaJ/da5948b50660aa0a99b02ea2e6990e2f/Blue.png?w=424&h=84&q=50&fm=webp&bg=transparent"
                        alt="venmo"
                        style="max-height: 30px; max-width: 100px"
                      /> </a
                  ></label>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 mt-5">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="creditcard"
                    v-model="this.paymentType"
                  />
                  <label class="form-check-label" for="inlineRadio4"
                    ><h5>
                      <i
                        class="fa-solid fa-credit-card fa-lg"
                        style="color: #0d0d0d"
                      ></i>
                      Credit/Debit
                    </h5></label
                  >
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>

          <input
            class="form-check-input me-2"
            type="checkbox"
            value=""
            id="form6Example8"
            v-model="this.emergencyBool"
          />
          <label class="form-check-label" for="form6Example8">
            Emergency Contact is Billing Info
          </label>
          <br />
          <br />
          <div class="row">
            <button
              class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
              @click="submitCamperInfo"
            >
              Next
            </button>
          </div>
          <div class="row mt-3">
            <button
              class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
// import axios from "axios";
import CustomField from '../SubComponents/CustomField.vue'

import numeral from 'numeral'

export default {
  components: {
    CustomField,
  },
  props: {
    camp: Object,
    isMobile: Boolean,
    presetCampers: Array,
    presetEmergencyBool: Boolean,
    presetPaymentType: String,
    sessionInfo: Object,
  },
  data() {
    return {
      campers: [],
      emergencyBool: this.presetEmergencyBool,
      emergencyContactInfo: {},
      requiredFilesShown: false,
      addingAdditionalCampers: false,
      paymentType: this.presetPaymentType,
    }
  },
  methods: {
    back() {
      const currentRoute = this.$route.path
      const newRoute = currentRoute.replace('campcheckout', 'viewpayment')
      this.$router.push(newRoute)
    },
    getImage() {
      if (this.camp.campLogo == null || this.camp.campLogo == '')
        return '/landscape.svg'
      else return this.camp.campLogo
    },
    async proceed() {},
    removeAdditionalCamper(removalIndex) {
      this.campers.splice(removalIndex, 1)
    },
    async submitCamperInfo() {
      if (!this.emergencyBool) {
        this.campers.forEach(camper => {
          camper.emergencyContactInfo = this.emergencyContactInfo
        })
      }
      swal({
        title: 'Agree and Accept Liability',
        text: this.getCampWaiver,
        icon: 'warning',
        buttons: ['Cancel registration', 'I understand and accept liability'],
        dangerMode: true,
      }).then(isConfirm => {
        if (isConfirm) {
          this.$emit(
            'proceed',
            this.campers,
            this.emergencyBool,
            this.paymentType
          )
        } else {
          swal('Cancelled', 'Registration cancelled.', 'error')
        }
      })
    },
    createCamperArray() {
      if (this.presetCampers != null) {
        this.campers = this.presetCampers
        return
      }
      console.log('here')
      let campCustomFields = this.camp.customFields.map(field => ({
        ...field,
        fieldValue: null, // Default value for fieldValue
      }))
      let campAddOns = this.camp.customAddOns.map(addOn => ({
        ...addOn,
        isPurchased: false, // Default value for isPurchased
      }))
      let camper = {
        firstName: null,
        lastName: null,
        address: null,
        tShirtSize: null,
        email: null,
        phoneNumber: null,
        customFields: campCustomFields,
        customAddOns: campAddOns,
      }
      this.campers = []
      this.campers.push(camper)
    },
    addAdditionalCamper() {
      let campCustomFields = this.camp.customFields.map(field => ({
        ...field,
        fieldValue: null, // Default value for fieldValue
      }))
      let campAddOns = this.camp.customAddOns.map(addOn => ({
        ...addOn,
        isPurchased: false, // Default value for isPurchased
      }))
      let camper = {
        firstName: null,
        lastName: null,
        address: null,
        tShirtSize: null,
        email: null,
        phoneNumber: null,
        customFields: campCustomFields,
        customAddOns: campAddOns,
      }
      console.log(camper)
      this.campers.push(camper)
    },
    updateFieldValue(camperIndex, fieldIndex, field) {
      this.campers[camperIndex].customFields[fieldIndex] = field
    },
  },

  created() {
    this.createCamperArray()
  },

  computed: {
    checkAddRegistrantButton() {
      return this.camp.sessionSupported ? false : true
    },
    getCampWaiver() {
      let waiver =
        'Summer Athletes accepts no liability for any physical injury, and all refunds are handled by the organizer. Additionally, by accepting you are consenting to receive important message updates about the event.'
      if (this.camp.requireWaiver) {
        waiver =
          'Summer Athletes and the organizer accept no liability for any physical injury, and all refunds are handled by the organizer. Additionally, by accepting you are consenting to receive important message updates about the event.'
        if (this.camp.customWaiver != null) {
          if (this.camp.customWaiver != '')
            waiver =
              this.camp.customWaiver +
              '\n\nIn addition to the waiver above, Summer Athletes accepts no liability for any physical injury. All refunds are handled by the organizer. Additionally, by accepting you are consenting to receive important message updates about the event.'
        }
      }
      return waiver
    },
    getAdditionalCamperPrice() {
      // Ensure camp and relevant data are available
      if (
        !this.camp ||
        !this.camp.multipleCamperPrices ||
        this.campers.length < 1
      ) {
        return 0 // Default to 0 if data is missing
      }

      // Determine the number of current campers
      const currentCampers = this.campers.length

      // Determine the price for the next camper
      if (currentCampers < this.camp.multipleCamperPrices.length) {
        // Use the next tier price for additional campers
        return this.camp.multipleCamperPrices[currentCampers - 1].camperPrice
      }

      // If no tier is available, return the last defined tier price
      return (
        this.camp.multipleCamperPrices[
          this.camp.multipleCamperPrices.length - 1
        ]?.camperPrice || this.camp.campPrice
      )
    },
    primaryColor() {
      return this.$route.params.primaryColor
        ? '#' + this.$route.params.primaryColor
        : '#363166'
    },
  },
  mounted() {},
}
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  background-color: v-bind('primaryColor') !important;
}
.custom-button:hover {
  filter: brightness(0.95) !important;
}
</style>
