<script setup></script>

<template>
  <div
    class="modal fade"
    id="advertisementPopUp"
    tabindex="-1"
    aria-labelledby="advertisementPopUpLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div
        class="modal-content bg-white rounded-3xl shadow-2xl border-0 max-w-5xl mx-auto"
      >
        <!-- Modal Header -->
        <div class="modal-header border-b border-gray-100 p-4">
          <h3
            class="modal-title text-xl font-bold text-indigo-900"
            id="advertisementPopUpLabel"
          >
            Upgrade Your Experience
          </h3>
          <button
            type="button"
            class="text-gray-400 hover:text-gray-500 focus:outline-none"
            id="advertisementPopUpClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-solid fa-times text-xl"></i>
          </button>
          <button
            type="button"
            id="closeModal"
            class="hidden"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body p-6">
          <!-- Billing Toggle -->
          <div class="flex justify-center items-center space-x-4 mb-4">
            <span
              :class="[
                'text-sm font-medium transition-colors duration-200',
                !yearly ? 'text-indigo-900' : 'text-gray-500',
              ]"
            >
              Monthly Billing
            </span>

            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                @click="setPeriod()"
                :checked="yearly"
                class="sr-only peer"
              />
              <div
                class="w-14 h-7 bg-gray-300 peer-checked:bg-green-500 rounded-full peer peer-checked:after:translate-x-7 after:content-[''] after:absolute after:top-1 after:left-1 after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all"
              ></div>
            </label>

            <span
              :class="[
                'text-sm font-medium transition-colors duration-200',
                yearly ? 'text-indigo-900' : 'text-gray-500',
              ]"
            >
              Annual Billing
            </span>
          </div>

          <!-- Savings Message -->
          <div class="text-center mb-4">
            <p v-if="yearly" class="text-xs text-green-600 font-medium">
              <i class="fa-solid fa-gift mr-1"></i>
              Get up to 3 Months Free with Annual Billing!
            </p>
            <p v-else class="text-xs text-gray-600">
              <i class="fa-solid fa-clock mr-1"></i>
              Flexible monthly billing - No long-term commitment
            </p>
          </div>

          <!-- Pricing Cards Grid -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-4xl mx-auto">
            <!-- All Star Card with Highlight -->
            <div
              v-if="allStarSubscription && currentSubscriptionTier < 3"
              class="relative transform hover:scale-[1.02] transition-all duration-300"
            >
              <div
                class="absolute inset-0 bg-gradient-to-b from-indigo-600 to-indigo-800 rounded-3xl transform -rotate-1"
              ></div>
              <AllStarDetailsCard
                :yearly="yearly"
                :isMobile="isMobile"
                :subscription="allStarSubscription"
                :class="{
                  'ring-2 ring-indigo-600': currentSubscriptionTier === 3,
                }"
                @buySubscription="buySubscription"
                class="transform rotate-1"
              />
            </div>

            <!-- Hall of Fame Card -->
            <div
              :class="{
                'md:col-span-2 md:max-w-xl md:mx-auto':
                  currentSubscriptionTier === 3,
              }"
            >
              <HallOfFameDetailsCard
                v-if="hofSubscription"
                :yearly="yearly"
                :isMobile="isMobile"
                :subscription="hofSubscription"
                :class="{
                  'ring-2 ring-indigo-600': currentSubscriptionTier === 4,
                }"
                @buySubscription="buySubscription"
              />
            </div>
          </div>

          <!-- Current Plan Badge -->
          <div
            class="mt-4 text-center"
            v-if="currentSubscriptionName !== 'New'"
          >
            <span
              class="inline-flex items-center px-2.5 py-1 rounded-full bg-indigo-100 text-indigo-800 text-xs font-medium"
            >
              <i class="fa-solid fa-star mr-1.5"></i>
              Current Plan: {{ currentSubscriptionName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AllStarDetailsCard from './AllStarDetailsCard.vue'
import HallOfFameDetailsCard from './HallOfFameDetailsCard.vue'
import axios from 'axios'

export default {
  components: {
    AllStarDetailsCard,
    HallOfFameDetailsCard,
  },
  props: {
    currentSubscriptionName: String,
    currentSubscriptionTier: Number,
  },
  data() {
    return {
      yearly: false,
      allStarSubscription: null,
      hofSubscription: null,
    }
  },
  methods: {
    setPeriod() {
      this.yearly = !this.yearly
    },
    buySubscription(subscription) {
      document.getElementById('advertisementPopUpClose').click()
      this.$router.push(
        '/SubscriptionChoices/Checkout/' + subscription._id + '/' + this.yearly,
      )
    },
    async getSubscriptions() {
      try {
        // Get All Star subscription
        const allStarResponse = await axios.get(
          `${import.meta.env.VITE_APP_DB_URL}subscription/getSubscriptionByName?name=All Star`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          },
        )
        this.allStarSubscription = allStarResponse.data.subscription

        // Get Hall of Fame subscription
        const hofResponse = await axios.get(
          `${import.meta.env.VITE_APP_DB_URL}subscription/getSubscriptionByName?name=Hall of Fame`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          },
        )
        this.hofSubscription = hofResponse.data.subscription
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
      }
    },
  },
  computed: {
    isMobile() {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
  },
  mounted() {
    this.getSubscriptions()
  },
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.actionFeature {
  background-color: #a39ed0;
}
</style>
