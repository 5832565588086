<template>
  <div
    class="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100 hover:shadow-xl transition-shadow duration-300 w-full max-w-2xl"
  >
    <div
      class="bg-gradient-to-r from-[#363166] via-[#423d7d] to-[#363166] px-6 py-3 text-white"
    >
      <div class="flex items-center justify-between">
        <h3 class="text-lg font-medium opacity-90">Account Overview</h3>
        <span
          @click="showSubscriptionChoices"
          class="px-3 py-1 bg-white bg-opacity-20 rounded-full text-sm font-medium cursor-pointer hover:bg-opacity-30 transition-colors text-[#363166] font-semibold"
        >
          {{ subscriptionName }}
        </span>
      </div>
    </div>

    <div class="p-6">
      <!-- Camps/Clinics Section -->
      <div class="mb-4">
        <div class="flex items-center justify-between">
          <h4 class="text-lg font-medium text-gray-800">
            {{ oneTimePaymentLinks }} Camps/Sessions
          </h4>
          <div
            class="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-blue-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          </div>
        </div>
        <div class="mt-2 pl-4 border-l-2 border-blue-200">
          <p class="text-gray-600">{{ oneTimeCustomers }} Customers</p>
        </div>
      </div>

      <div class="w-full h-px bg-gray-200 my-3"></div>

      <!-- Memberships Section -->
      <div class="mb-4">
        <div class="flex items-center justify-between">
          <h4 class="text-lg font-medium text-gray-800">
            {{ recurringPaymentLinks }} Memberships
          </h4>
          <div
            class="h-8 w-8 rounded-full bg-green-100 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-green-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"
              ></path>
              <path d="M21 3v5h-5"></path>
              <path
                d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"
              ></path>
              <path d="M8 16H3v5"></path>
            </svg>
          </div>
        </div>
        <div class="mt-2 pl-4 border-l-2 border-green-200">
          <p class="text-gray-600">
            {{ activeRecurringCustomers }} Active Memberships
          </p>
        </div>
      </div>

      <div class="w-full h-px bg-gray-200 my-3"></div>

      <!-- Buttons on same line -->
      <div class="button-container mt-4">
        <button
          v-if="oneTimePaymentLinks + recurringPaymentLinks > 0"
          @click="toPaymentLinks"
          class="action-button bg-[#363166] hover:bg-[#423d7d] text-white py-3 px-6 font-medium transition-colors duration-200 custom-button"
        >
          My Payment Links
        </button>
        <button
          @click="createPaymentLink"
          class="action-button bg-[#363166] hover:bg-[#423d7d] text-white py-3 px-6 font-medium transition-colors duration-200 custom-button"
        >
          Create New Link
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    subscriptionName: String,
    oneTimePaymentLinks: Number,
    oneTimeCustomers: Number,
    recurringPaymentLinks: Number,
    activeRecurringCustomers: Number,
  },
  data() {
    return {}
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    createPaymentLink() {
      this.$router.push('/Dashboard/createEvent')
    },
    toPaymentLinks() {
      this.$router.push('/PaymentLinks')
    },
    showSubscriptionChoices() {
      document.getElementById('openAdvertisementPopUp')?.click()
    },
  },
  computed: {},
  created() {},
}
</script>
<style scoped>
.order {
  animation: bounce 0.7s infinite alternate;
}

.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.action-button {
  flex: 1;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
