<template>
  <div class="min-h-screen bg-white">
    <ClientLayout :user="user"></ClientLayout>
    <section class="pt-20 pb-6">
      <div class="container mx-auto px-8" :class="{ 'ml-5': isMobile() }">
        <!-- Desktop View -->
        <div v-if="!isMobile()" class="space-y-6">
          <!-- Export Button Section -->
          <div
            class="flex justify-end"
            v-if="this.user.subscription.subscriptionTier >= 3"
          >
            <JsonExcel
              :data="getCustomerList"
              :name="'transactions.xls'"
              :fields="json_fields"
            >
              <button
                class="px-8 py-2 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
              >
                Export Transactions
              </button>
            </JsonExcel>
          </div>
          <div class="flex justify-end" v-else>
            <button
              class="px-8 py-2 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
              @click="showDownloadTransctionAd()"
            >
              Export Transactions
            </button>
          </div>

          <!-- Transactions Table -->
          <div class="bg-white rounded-xl shadow-lg border border-gray-200">
            <div class="overflow-x-auto">
              <table class="w-full table-fixed divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      class="w-[10%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Type
                    </th>
                    <th
                      class="w-[15%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Event
                    </th>
                    <th
                      class="w-[15%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      From
                    </th>
                    <th
                      class="w-[15%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      To
                    </th>
                    <th
                      v-if="user.highSchoolCoach"
                      class="w-[10%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      School
                    </th>
                    <th
                      class="w-[10%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Status
                    </th>
                    <th
                      class="w-[12%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Amount
                    </th>
                    <th
                      class="w-[10%] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Date
                      <button
                        @click="dateExplanation"
                        class="ml-1 text-[#6B66B0] hover:text-[#5B5696]"
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    </th>
                    <th
                      class="w-[13%] px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Profile
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="bg-white divide-y divide-gray-200 overflow-y-auto"
                  style="max-height: calc(100vh - 250px)"
                >
                  <tr
                    v-for="(customer, index) in getCustomerList"
                    v-bind:key="customer.id"
                    class="table-row"
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
                  >
                    <td class="px-4 py-4 text-sm text-gray-900 truncate">
                      {{ customer.type }}
                    </td>
                    <td class="px-4 py-4 text-sm text-gray-600 truncate">
                      {{ customer.eventName }}
                    </td>
                    <td class="px-4 py-4 text-sm text-gray-600 truncate">
                      {{
                        customer.type === 'Payout' ||
                        customer.status === 'REFUNDED' ||
                        customer.type === 'Purchase'
                          ? 'Account Balance'
                          : customer.name
                      }}
                    </td>
                    <td class="px-4 py-4 text-sm text-gray-600">
                      <div class="truncate">
                        {{
                          customer.type === 'Payout' ||
                          customer.status === 'REFUNDED' ||
                          customer.type === 'Purchase'
                            ? customer.name
                            : 'Account Balance'
                        }}
                      </div>
                      <div
                        v-if="
                          customer.type === 'Payout' &&
                          customer.priorityTransfer
                        "
                        class="mt-1"
                      >
                        <span
                          class="inline-flex items-center text-xs text-[#6B66B0]"
                        >
                          <i class="fa-solid fa-bolt-lightning mr-1"></i>
                          Priority Transfer
                        </span>
                      </div>
                    </td>
                    <td
                      v-if="user.highSchoolCoach"
                      class="px-4 py-4 text-sm text-gray-600"
                    >
                      {{ customer.schoolName }}
                    </td>
                    <td class="px-4 py-4">
                      <span
                        v-if="customer.status === 'REFUNDED'"
                        class="px-2 py-1 text-xs font-medium rounded-full bg-red-100 text-red-800"
                      >
                        {{ customer.status }}
                      </span>
                      <span
                        v-else-if="customer.status === 'PENDING'"
                        class="px-2 py-1 text-xs font-medium rounded-full bg-yellow-100 text-yellow-800"
                      >
                        {{ customer.status }}
                      </span>
                      <span
                        v-else-if="customer.status === 'CHARGE FAILED'"
                        class="px-2 py-1 text-xs font-medium rounded-full bg-red-100 text-red-800"
                      >
                        {{ customer.status }}
                      </span>
                      <span
                        v-else-if="customer.status === 'UNSUBSCRIBED'"
                        class="px-2 py-1 text-xs font-medium rounded-full bg-red-100 text-red-800"
                      >
                        {{ customer.status }}
                      </span>
                      <span
                        v-else
                        class="px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800"
                      >
                        COMPLETED
                      </span>
                    </td>
                    <td class="px-4 py-4">
                      <div class="text-sm">
                        <strong
                          v-if="
                            customer.status === 'REFUNDED' ||
                            customer.type === 'Payout' ||
                            customer.type === 'Purchase'
                          "
                          class="text-red-600"
                        >
                          -${{ formatCurrency(customer.paid) }}
                        </strong>
                        <strong v-else class="text-green-600">
                          +${{ formatCurrency(customer.paid) }}
                        </strong>
                      </div>
                    </td>
                    <td class="px-4 py-4 text-sm text-gray-600">
                      {{ customer.date }}
                    </td>
                    <td class="px-2 py-4 text-center">
                      <button
                        v-if="customer.type === 'One-time'"
                        @click="
                          goToCamperProfile(customer.camperID, customer.campID)
                        "
                        class="min-w-[80px] px-3 py-1 bg-[#6B66B0] text-white text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2 custom-rounded-button"
                      >
                        Go to Profile
                      </button>
                      <button
                        v-else-if="customer.type === 'Recurring'"
                        @click="
                          goToMemberProfile(
                            customer.membershipID,
                            customer.memberID,
                            customer.subscriptionID,
                          )
                        "
                        class="min-w-[80px] px-3 py-1 bg-[#6B66B0] text-white text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2 custom-rounded-button"
                      >
                        Go to Profile
                      </button>
                      <button
                        v-else
                        @click="goToExternalAccounts()"
                        class="min-w-[80px] px-3 py-1 bg-[#6B66B0] text-white text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2 custom-rounded-button"
                      >
                        Manage Funds
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Mobile View -->
        <div v-else class="space-y-6">
          <!-- Export Button Section -->
          <div
            class="flex justify-end px-4"
            v-if="this.user.subscription.subscriptionTier >= 3"
          >
            <JsonExcel
              :data="getCustomerList"
              :name="'transactions.xls'"
              :fields="json_fields"
            >
              <button
                class="px-6 py-2 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
              >
                Export Transactions
              </button>
            </JsonExcel>
          </div>
          <div class="flex justify-end px-4" v-else>
            <button
              class="px-6 py-2 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
              @click="showDownloadTransctionAd()"
            >
              Export Transactions
            </button>
          </div>

          <!-- Mobile Transactions List -->
          <div class="mx-auto max-w-3xl px-4">
            <div
              class="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200"
            >
              <div class="overflow-y-auto" style="max-height: 700px">
                <div class="divide-y divide-gray-200">
                  <!-- Header -->
                  <div class="px-4 py-3 bg-gray-50">
                    <div class="grid grid-cols-2">
                      <div
                        class="text-xs font-semibold text-gray-500 uppercase tracking-wider"
                      >
                        Transaction
                      </div>
                      <div
                        class="text-xs font-semibold text-gray-500 uppercase tracking-wider text-right"
                      >
                        Amount
                      </div>
                    </div>
                  </div>
                  <!-- Transaction Items -->
                  <div
                    v-for="customer in getCustomerList"
                    v-bind:key="customer.id"
                    @click="
                      goToProfileMobile(
                        customer.camperID,
                        customer.campID,
                        customer.membershipID,
                        customer.memberID,
                        customer.subscriptionID,
                        customer.type,
                      )
                    "
                    class="hover:bg-gray-50 active:bg-gray-100 transition-colors duration-150"
                  >
                    <div class="p-4">
                      <div class="grid grid-cols-2 gap-4">
                        <div>
                          <div class="font-medium text-gray-900 truncate mb-1">
                            {{ customer.name }}
                          </div>
                          <div class="text-sm text-gray-600 mb-2">
                            {{ customer.eventName }}
                          </div>
                          <div class="flex flex-wrap gap-2">
                            <span
                              v-if="customer.status === 'REFUNDED'"
                              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                            >
                              {{ customer.status }}
                            </span>
                            <span
                              v-else-if="customer.status === 'PENDING'"
                              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
                            >
                              {{ customer.status }}
                            </span>
                            <span
                              v-else-if="customer.status === 'CHARGE FAILED'"
                              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                            >
                              {{ customer.status }}
                            </span>
                            <span
                              v-else-if="customer.status === 'UNSUBSCRIBED'"
                              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                            >
                              {{ customer.status }}
                            </span>
                            <span
                              v-else
                              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                            >
                              COMPLETED
                            </span>
                          </div>
                          <div class="text-xs text-gray-500 mt-2">
                            {{ customer.date }}
                          </div>
                          <div
                            v-if="
                              customer.type === 'Payout' &&
                              customer.priorityTransfer
                            "
                            class="mt-2"
                          >
                            <span
                              class="inline-flex items-center text-xs text-[#6B66B0]"
                            >
                              <i class="fa-solid fa-bolt-lightning mr-1"></i>
                              Priority Transfer
                            </span>
                          </div>
                        </div>
                        <div class="text-right flex flex-col justify-between">
                          <div>
                            <strong
                              v-if="
                                customer.status === 'REFUNDED' ||
                                customer.type === 'Payout' ||
                                customer.type === 'Purchase'
                              "
                              class="text-lg font-semibold text-red-600"
                            >
                              -${{ formatCurrency(customer.paid) }}
                            </strong>
                            <strong
                              v-else
                              class="text-lg font-semibold text-green-600"
                            >
                              +${{ formatCurrency(customer.paid) }}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import ClientLayout from '../../components/ClientLayout.vue'
// import CoachDashboardMobile from "../Mobile/CoachDashboardMobile.vue";
import swal from 'sweetalert'
import moment from 'moment/moment'
import JsonExcel from 'vue-json-excel3'
import numeral from 'numeral'

export default {
  components: {
    ClientLayout,
    JsonExcel,
  },
  data() {
    return {
      user: {
        subscription: {
          subscriptionName: '',
          subscriptionTier: 0,
        },
      },
      payoutInfo: {
        payoutList: [],
        paymentsReceivedList: [],
      },
      totalCash: null,
      totalCampers: null,
      coinPackages: [],
      camps: [],
      teamCamps: [],
      memberships: [],
      deletedCamps: [],
      deletedMemberships: [],
      events: {},
      maxCampValue: 0,
      campPayoutTotal: 0,
      json_fields: {
        'Event Type': {
          callback: value => {
            return value.type
          },
        },
        'Event Name': {
          callback: value => {
            return value.eventName
          },
        },
        From: {
          callback: value => {
            return value.type === 'Payout' ||
              value.status === 'REFUNDED' ||
              value.type === 'Purchase'
              ? 'Account Balance'
              : value.name
          },
        },
        To: {
          callback: value => {
            return value.type === 'Payout' ||
              value.status === 'REFUNDED' ||
              value.type === 'Purchase'
              ? value.name
              : 'Account Balance'
          },
        },
        'Amount (USD)': {
          callback: value => {
            return parseFloat(value.paid).toFixed(2)
          },
        },
        'Transaction Type': {
          callback: value => {
            return value.type === 'Payout' ||
              value.status === 'REFUNDED' ||
              value.type === 'Purchase'
              ? 'Debit'
              : 'Credit'
          },
        },
        Date: {
          callback: value => {
            return value.date
          },
        },
        Status: {
          callback: value => {
            return value.status ? value.status : 'COMPLETED'
          },
        },
      },
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    formatCurrency(value) {
      return numeral(value).format('0,0.00')
    },
    dateExplanation() {
      swal({
        title: 'Transaction Dates',
        text: 'This is the date of each transaction. Some transactions that took place before the 8/31/2023 will not have a date associated with them.',
        icon: 'info',
      })
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'user/getPayoutInfo?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.payoutInfo = response.data.payoutInfo
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    goToCamperProfile(camperID, campID) {
      this.$router.push('/Dashboard/CheckIn/' + campID + '/' + camperID)
    },
    goToMemberProfile(membershipID, memberID, subscriptionID) {
      this.$router.push(
        '/Dashboard/MembershipCheckIn/' +
          membershipID +
          '/' +
          memberID +
          '/' +
          subscriptionID,
      )
    },
    goToExternalAccounts() {
      this.$router.push('/Dashboard/BankAccounts')
    },
    goToProfileMobile(
      camperID,
      campID,
      membershipID,
      memberID,
      subscriptionID,
      type,
    ) {
      if (type === 'Camp') {
        this.$router.push('/Dashboard/CheckIn/' + campID + '/' + camperID)
      } else {
        this.$router.push(
          '/Dashboard/MembershipCheckIn/' +
            membershipID +
            '/' +
            memberID +
            '/' +
            subscriptionID,
        )
      }
    },
    showDownloadTransctionAd() {
      swal({
        title: 'Upgrade Your Plan',
        text: 'You must be at least an All Star user to download transaction data locally. Upgrade your plan to continue.',
        icon: 'info',
      })
      document.getElementById('openAdvertisementPopUp').click()
    },
    async getCamps() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/camps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.camps = response.data.reverse()
          // this.camps.sort((a, b) =>
          //   a.campDates[a.campDates.length - 1].end <
          //   b.campDates[b.campDates.length - 1].end
          //     ? 1
          //     : -1
          // );
          this.events.camps = this.camps
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
      url =
        import.meta.env.VITE_APP_DB_URL +
        'teamCamp/teamCamps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.teamCamps = response.data
          this.teamCamps.sort((a, b) =>
            a.campDates[a.campDates.length - 1].end <
            b.campDates[b.campDates.length - 1].end
              ? 1
              : -1,
          )
          this.events.teamCamps = this.teamCamps
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    async getDeletedCamps() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getDeletedCamps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.deletedCamps = response.data.reverse()
          this.events.deletedCamps = this.deletedCamps
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    async getMemberships() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getCoachMemberships?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.memberships = response.data
          this.memberships.sort((a, b) => (a.date > b.date ? 1 : -1))
          this.events.memberships = this.memberships
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getCreditPackagePurchases() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'advertisment/getCreditPackagePurchases?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.coinPackages = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    //async ajax to get deleted memberships
    async getDeletedMemberships() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getDeletedMemberships?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.deletedMemberships = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    getHighSchoolCoach() {
      if (this.user._id === '63bc4c971d273112650180d3') {
        this.user.highSchoolCoach = true
        this.json_fields.School = {
          callback: value => {
            if (value.customFieldValues) {
              for (const field of value.customFieldValues) {
                if (
                  field.fieldName === 'School' ||
                  field.fieldName === 'School ' ||
                  field.fieldName === 'School Name' ||
                  field.fieldName === 'School Name '
                ) {
                  return field.fieldValue
                }
              }
            }
            return 'None'
          },
        }
      }
    },
  },

  created() {
    this.getUserDetails()
    this.getCamps()
    this.getMemberships()
    this.getDeletedCamps()
    this.getDeletedMemberships()
    this.getCreditPackagePurchases()
    this.getHighSchoolCoach()
  },

  computed: {
    getCustomerList() {
      let customerList = []
      //Add coin purchases
      for (const cPackage of this.coinPackages) {
        if (cPackage.method == 'accountBalance') {
          let coinCustomer = {}
          coinCustomer.type = 'Purchase'
          coinCustomer.eventName = 'Credit Purchase'
          coinCustomer.name = 'Credit Balance' // or any other appropriate identifier
          customer.customFieldValues = camper.customFieldValues
          coinCustomer.paid = parseFloat(cPackage.price).toFixed(2)
          coinCustomer.date = cPackage.date
            ? moment(cPackage.date).format('YYYY-MM-DD')
            : '--'
          coinCustomer.status = 'COMPLETED' // or any other appropriate status
          customerList.push(coinCustomer)
        }
      }
      //Add regular campers
      let allCamps = this.camps.concat(this.deletedCamps)
      for (const camp of allCamps) {
        for (const camper of camp.campers) {
          let customer = {}
          customer.type = 'One-time'
          customer.camperID = camper._id
          customer.campID = camp._id
          customer.eventName = camp.campName
          customer.name = camper.firstName + ' ' + camper.lastName
          customer.customFieldValues = camper.customFieldValues
          customer.paid = camper.pricePaid.toFixed(2)
          customer.date = camper.registrationDate
            ? moment(camper.registrationDate).format('YYYY-MM-DD')
            : '--'
          customerList.push(customer)
        }
        //Add refunded campers
        for (const camper of camp.refundedCampers) {
          let originalCustomer = {}
          originalCustomer.type = 'One-time'
          originalCustomer.camperID = camper._id
          originalCustomer.campID = camp._id
          originalCustomer.eventName = camp.campName
          originalCustomer.name = camper.firstName + ' ' + camper.lastName
          originalCustomer.customFieldValues = camper.customFieldValues
          originalCustomer.paid = camper.pricePaid.toFixed(2)
          originalCustomer.date = camper.registrationDate
            ? moment(camper.registrationDate).format('YYYY-MM-DD')
            : '--'
          originalCustomer.status = 'COMPLETED'
          customerList.push(originalCustomer)

          let customer = {}
          customer.type = 'One-time'
          customer.camperID = camper._id
          customer.campID = camp._id
          customer.eventName = camp.campName
          customer.name = camper.firstName + ' ' + camper.lastName
          customer.customFieldValues = camper.customFieldValues
          customer.paid = camper.pricePaid.toFixed(2)
          customer.date = camper.refundDate
            ? moment(camper.refundDate).format('YYYY-MM-DD')
            : '--'
          customer.status = 'REFUNDED'
          customerList.push(customer)
        }
      }

      //Add members
      let allMemberships = this.memberships.concat(this.deletedMemberships)
      for (const membership of allMemberships) {
        for (const member of membership.members) {
          if (member.paymentPeriod === 'weeklyPrice') {
            const weeklyPrice = membership.membershipPayPeriod.weeklyPrice
            const memberStartDate = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const startDateRange = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const endDateRange = member.isActive
              ? moment(new Date(), 'YYYY-MM-DD')
              : moment(new Date(member.endDate), 'YYYY-MM-DD').add(1, 'days')
            let currentChargeDate = startDateRange
            for (let index = 0; index < 1000; index++) {
              let addAmount = 7 * index
              currentChargeDate = memberStartDate.clone().add(addAmount, 'days')
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                let customer = {}
                customer.type = 'Recurring'
                customer.memberID = member.memberID
                customer.subscriptionID = member.subscriptionID
                customer.membershipID = membership._id
                customer.eventName = membership.membershipName
                customer.name =
                  member.memberFirstName + ' ' + member.memberLastName
                customer.paid = parseFloat(weeklyPrice).toFixed(2)
                customer.date = currentChargeDate
                  .add(1, 'days')
                  .format('YYYY-MM-DD')
                if (
                  moment(new Date(), 'YYYY-MM-DD').diff(
                    currentChargeDate.format('YYYY-MM-DD'),
                    'days',
                  ) <= 1
                ) {
                  customer.status = 'PENDING'
                }
                customerList.push(customer)
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break
              }
            }
          }

          if (member.paymentPeriod === 'monthlyPrice') {
            const monthlyPrice = membership.membershipPayPeriod.monthlyPrice
            const memberStartDate = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const startDateRange = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const endDateRange = member.isActive
              ? moment(new Date(), 'YYYY-MM-DD')
              : moment(new Date(member.endDate), 'YYYY-MM-DD').add(1, 'days')
            let currentChargeDate = startDateRange
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, 'months')
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                let customer = {}
                customer.type = 'Recurring'
                customer.memberID = member.memberID
                customer.subscriptionID = member.subscriptionID
                customer.membershipID = membership._id
                customer.eventName = membership.membershipName
                customer.name =
                  member.memberFirstName + ' ' + member.memberLastName
                customer.paid = parseFloat(monthlyPrice).toFixed(2)
                customer.date = currentChargeDate.format('YYYY-MM-DD')
                if (
                  moment(new Date(), 'YYYY-MM-DD').diff(
                    currentChargeDate.format('YYYY-MM-DD'),
                    'days',
                  ) <= 1
                ) {
                  customer.status = 'PENDING'
                }
                customerList.push(customer)
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break
              }
            }
          }

          if (member.paymentPeriod === 'semiAnnualPrice') {
            const semiAnnualPrice =
              membership.membershipPayPeriod.semiAnnualPrice
            const memberStartDate = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const startDateRange = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const endDateRange = member.isActive
              ? moment(new Date(), 'YYYY-MM-DD')
              : moment(new Date(member.endDate), 'YYYY-MM-DD').add(1, 'days')
            let currentChargeDate = startDateRange
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, 'months')
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                if (index % 6 === 0) {
                  let customer = {}
                  customer.type = 'Recurring'
                  customer.memberID = member.memberID
                  customer.subscriptionID = member.subscriptionID
                  customer.membershipID = membership._id
                  customer.eventName = membership.membershipName
                  customer.name =
                    member.memberFirstName + ' ' + member.memberLastName
                  customer.paid = parseFloat(semiAnnualPrice).toFixed(2)
                  customer.date = currentChargeDate
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
                  if (
                    moment(new Date(), 'YYYY-MM-DD').diff(
                      currentChargeDate.format('YYYY-MM-DD'),
                      'days',
                    ) <= 1
                  ) {
                    customer.status = 'PENDING'
                  }
                  customerList.push(customer)
                }
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break
              }
            }
          }

          if (member.paymentPeriod === 'annualPrice') {
            const annualPrice = membership.membershipPayPeriod.annualPrice
            const memberStartDate = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const startDateRange = moment(
              new Date(member.startDate),
              'YYYY-MM-DD',
            ).add(1, 'days')
            const endDateRange = member.isActive
              ? moment(new Date(), 'YYYY-MM-DD')
              : moment(new Date(member.endDate), 'YYYY-MM-DD').add(1, 'days')
            let currentChargeDate = startDateRange
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, 'years')
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                let customer = {}
                customer.type = 'Recurring'
                customer.memberID = member.memberID
                customer.subscriptionID = member.subscriptionID
                customer.membershipID = membership._id
                customer.eventName = membership.membershipName
                customer.name =
                  member.memberFirstName + ' ' + member.memberLastName
                customer.paid = parseFloat(annualPrice).toFixed(2)
                customer.date = currentChargeDate
                  .add(1, 'days')
                  .format('YYYY-MM-DD')
                if (
                  moment(new Date(), 'YYYY-MM-DD').diff(
                    currentChargeDate.format('YYYY-MM-DD'),
                    'days',
                  ) <= 1
                ) {
                  customer.status = 'PENDING'
                }
                customerList.push(customer)
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break
              }
            }
          }

          if (!member.isActive && member.chargeFailure) {
            customerList[customerList.length - 1].status = 'CHARGE FAILED'
            customerList[customerList.length - 1].paid = 0.0
          }

          if (!member.isActive) {
            let inactiveCustomer = {}
            inactiveCustomer.type = 'Recurring'
            inactiveCustomer.memberID = member.memberID
            inactiveCustomer.subscriptionID = member.subscriptionID
            inactiveCustomer.membershipID = membership._id
            inactiveCustomer.eventName = membership.membershipName
            inactiveCustomer.name =
              member.memberFirstName + ' ' + member.memberLastName
            inactiveCustomer.paid = 0.0
            inactiveCustomer.date = member.endDate
              ? moment(member.endDate).add(1, 'days').format('YYYY-MM-DD')
              : '--'
            inactiveCustomer.status = 'UNSUBSCRIBED'
            customerList.push(inactiveCustomer)
          }
        }
      }

      for (const payment of this.payoutInfo.paymentsReceivedList) {
        let customer = {}
        customer.type = 'Payment'
        customer.priorityTransfer = payment.priorityTransfer
        customer.paymentID = payment._id
        customer.eventName = 'RECEIVED'
        customer.name = payment.sentFrom.senderEmail
        customer.paid = parseFloat(payment.amount).toFixed(2)
        customer.date = payment.date
          ? moment(payment.date).format('YYYY-MM-DD')
          : '--'
        customer.status = payment.status ? payment.status : 'PENDING'
        customerList.push(customer)
      }

      //For all payouts
      for (const payout of this.payoutInfo.payoutList) {
        let customer = {}
        customer.type = 'Payout'
        customer.priorityTransfer = payout.priorityTransfer
        customer.payoutID = payout._id
        customer.eventName = 'WITHDRAWAL'
        customer.name = payout.account.accountName.split(' - ')[1]
        customer.paid = parseFloat(payout.amount).toFixed(2)
        customer.date = payout.date
          ? moment(payout.date).format('YYYY-MM-DD')
          : '--'
        customer.status = payout.status ? payout.status : 'PENDING'
        customerList.push(customer)
      }

      customerList = customerList.sort((a, b) => (a.date < b.date ? 1 : -1))

      // Pre-process school information for special user
      if (this.user.highSchoolCoach) {
        customerList.forEach(customer => {
          customer.schoolName = 'None'
          if (customer.customFieldValues) {
            for (const field of customer.customFieldValues) {
              if (
                field.fieldName === 'School' ||
                field.fieldName === 'School Name'
              ) {
                customer.schoolName = field.fieldValue
                break
              }
            }
          }
        })
      }

      let runningTotal = 0
      for (let customerIndex in customerList.reverse()) {
        if (customerList[customerIndex].status === 'REFUNDED') {
          runningTotal -= parseFloat(customerList[customerIndex].paid)
          customerList[customerIndex].runningTotal = runningTotal.toFixed(2)
        } else if (customerList[customerIndex].type === 'Payout') {
          runningTotal -= parseFloat(customerList[customerIndex].paid)
          customerList[customerIndex].runningTotal = runningTotal.toFixed(2)
        } else if (customerList[customerIndex].type === 'Purchase') {
          runningTotal -= parseFloat(customerList[customerIndex].paid)
          customerList[customerIndex].runningTotal = runningTotal.toFixed(2)
        } else {
          runningTotal += parseFloat(customerList[customerIndex].paid)
          customerList[customerIndex].runningTotal = runningTotal.toFixed(2)
        }
      }
      return customerList.reverse()
    },
    getWebsiteLink() {
      return '/coach/' + this.user._id
    },
  },
}
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.custom-rounded-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.table-row {
  transition: background-color 0.1s ease-out;
  will-change: transform;
  transform: translateZ(0);
}

.table-row:hover {
  background-color: rgb(209 213 219) !important;
  transform: scale(1.01) translateZ(0);
}

@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
