<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="getImage()" class="card-img-top rounded-corners" />
        <ul class="list-group" style="margin-top: 20px">
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <b>{{ this.camp.campName }}</b>
            </div>
          </li>
          <!--Start Of Line Items-->
          <li
            class="list-group-item"
            v-for="(item, index) in getLineItems"
            :key="index"
          >
            <div class="d-flex justify-content-between">
              <!-- Main Line Item -->
              <div>{{ item.title }}</div>
              <div class="text-end">
                <div>${{ item.price.toFixed(2) }}</div>
              </div>
            </div>

            <!-- Subitems -->
            <ul class="list-group mt-2">
              <li
                class="border-0"
                v-for="(subitem, subIndex) in item.subitems"
                :key="`${index}-${subIndex}`"
              >
                <div class="d-flex justify-content-between">
                  <div class="ms-4">{{ subitem.title }}</div>
                  <div class="text-end">
                    <div>${{ subitem.price.toFixed(2) }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </li>

          <!-- Total Section -->
          <!--End of Line Items-->
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div><b>Total</b></div>
              <div>
                <div>
                  <b>{{ getFormattedTotal }}</b>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div
          v-if="this.camp.discounts"
          class="d-flex justify-content-between row"
        >
          <div class="col mt-4">
            <input
              type="text"
              id="form6Example4"
              class="form-control"
              placeholder="Discount Code"
              v-model="camperDiscountCode"
            />
          </div>
          <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <button
              class="card-form__button"
              @click="applyDiscountCode"
              style="max-height: 60%"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
            >
              Apply
            </button>
          </div>
        </div>

        <div>
          <div v-if="this.paymentType != 'creditcard'">
            <PayPal
              class="mt-3"
              :camp="this.camp"
              :campers="this.campers"
              :fullPrice="this.getTotal"
              :discountCode="this.camperDiscountCode"
              :paymentType="this.paymentType"
              :sessionInfo="this.sessionInfo"
              @paypalSuccess="this.paypalSuccess"
            ></PayPal>
            <br />
            <br />
            <br />
            <button
              :class="{
                'card-form__button': true,
                'w-25': !isMobile,
                'w-50': isMobile,
                'rounded-pill': true,
              }"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>
          <div v-else>
            <br />
            <button
              class="w-full flex items-center justify-center gap-2 bg-[#363166] hover:bg-[#423d7d] text-white py-3 px-4 font-medium transition-colors duration-200 custom-button !text-xl"
              :style="{
                backgroundColor:
                  '#' + $route.params.primaryColor || '19d412' + ' !important',
              }"
              @click="submitPayment"
            >
              {{ getTotal === 0 ? 'Register Now' : `Pay ${getFormattedTotal}` }}
            </button>
            <br />

            <button
              class="w-full flex items-center justify-center gap-2 bg-[#363166] hover:bg-[#423d7d] text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
              :style="{
                backgroundColor:
                  '#' + $route.params.primaryColor || '19d412' + ' !important',
              }"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'
import PayPal from '../SubComponents/PayPal.vue'
import prettyMoney from 'pretty-money-js'

export default {
  components: {
    PayPal,
  },
  props: {
    camp: Object,
    campers: Array,
    sessionInfo: Object,
    lineItems: Array,
    paymentType: String,
    isMobile: Boolean,
  },
  data() {
    return {
      totalPrice: 0,
      campDisplayName: '',
      approvedFreeCamp: false,
      showAllFees: false,
      camperDiscountCode: '',
      discountPrice: 0,
      discountApplied: false,
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
    getImage() {
      if (this.camp.campLogo == null || this.camp.campLogo == '')
        return '/landscape.svg'
      else return this.camp.campLogo
    },
    paypalSuccess() {
      console.log('OrderSummary')
      this.$emit('paypalSuccess')
    },
    submitPayment() {
      this.$emit('proceed')
    },

    async applyDiscountCode() {
      try {
        const url = new URL(
          'individualCamp/checkDiscountCode',
          import.meta.env.VITE_APP_DB_URL
        )
        url.searchParams.append('discountCode', this.camperDiscountCode)
        url.searchParams.append('campStripeId', this.camp.campStripeId)

        const response = await axios.get(url.toString())
        if (response.status === 200 || response.status === 201) {
          this.discountApplied = true
          this.discountPrice = response.data.discountPrice

          this.$emit('discountCodeApplied', this.camperDiscountCode)
          this.getTotalPrice()
        }
        await swal({
          title: 'Success!',
          text: 'Discount code applied successfully!',
          icon: 'success',
          button: 'OK',
        })
      } catch (error) {
        console.error('Error applying discount code:', error)
        await swal({
          title: "We're Sorry!",
          text: 'Discount Code Invalid',
          icon: 'error',
          button: 'OK',
        })
      }
    },

    getTotalPrice() {
      const url = `${import.meta.env.VITE_APP_DB_URL}util/getTotalPrice`
      axios
        .post(url, {
          campStripeId: this.camp.campStripeId,
          campers: this.campers,
          paymentType: this.paymentType,
          discountCode: this.camperDiscountCode,
        })
        .then(response => {
          this.totalPrice = response.data.totalPrice
        })
    },
  },
  created() {},
  mounted() {
    this.getTotalPrice()
  },
  computed: {
    getTotal() {
      return parseFloat(this.totalPrice)
    },

    getFormattedTotal() {
      return prettyMoney.format(parseFloat(this.totalPrice))
    },

    getLineItems() {
      if (this.discountApplied && this.lineItems) {
        return this.lineItems.map(item => ({
          ...item,
          price: this.discountPrice,
        }))
      }
      return this.lineItems
    },
  },
}
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}
.custom-button:hover {
  filter: brightness(0.95) !important;
}
</style>
