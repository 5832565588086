<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="getImage()" class="card-img-top rounded-corners" />
        <ul class="list-group" style="margin-top: 20px">
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <b>{{ this.membership.membershipName }}</b>
            </div>
          </li>
          <!--Start Of Line Items-->
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <!-- Main Line Item -->
              <div>{{ this.member.firstName }} {{ this.member.lastName }}</div>
              <div class="text-end">
                <div>${{ getBasePriceSelected }}</div>
              </div>
            </div>
          </li>

          <!-- Total Section -->
          <!--End of Line Items-->
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div><b>Total</b></div>
              <div>
                <div>
                  <b>${{ getBasePriceSelected }}</b>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <br />
          <button
            class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
            @click="submitPayment"
          >
            {{ `Pay $${getBasePriceSelected}/` }}
          </button>

          <br />
          <button
            class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import swal from 'sweetalert'
import axios from 'axios'
import PayPal from '../SubComponents/PayPal.vue'
import prettyMoney from 'pretty-money-js'

export default {
  components: {
    PayPal,
  },
  props: {
    membership: Object,
    member: Object,
    isMobile: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    back() {
      this.$emit('back')
    },
    getImage() {
      if (
        this.membership.membershipLogo == null ||
        this.membership.membershipLogo == ''
      )
        return '/landscape.svg'
      else return this.membership.membershipLogo
    },
    submitPayment() {
      this.$emit('proceed')
    },
  },
  created() {},
  mounted() {},
  computed: {
    getBasePriceSelected() {
      if (this.$route.params.paymentPeriod === 'weeklyPrice') {
        return this.membership.membershipPayPeriod.weeklyPrice
      } else if (this.$route.params.paymentPeriod === 'monthlyPrice') {
        return this.membership.membershipPayPeriod.monthlyPrice
      } else if (this.$route.params.paymentPeriod === 'semiAnnualPrice') {
        return this.membership.membershipPayPeriod.semiAnnualPrice
      } else if (this.$route.params.paymentPeriod === 'annualPrice') {
        return this.membership.membershipPayPeriod.annualPrice
      } else {
        return 0
      }
    },
    primaryColor() {
      return this.$route.params.primaryColor
        ? '#' + this.$route.params.primaryColor
        : '#363166'
    },
  },
}
</script>
  <style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  background-color: v-bind('primaryColor') !important;
}
.custom-button:hover {
  filter: brightness(0.95) !important;
}
</style>
  