<template>
  <div class="wrapper digital-ai" :style="{ width: isMobile() ? '100%' : '' }">
    <div class="container justify-content-center">
      <div :style="{ width: isMobile() ? '100%' : '' }">
        <!-- <v-tour name="myTour" :steps="this.steps"></v-tour> -->
        <section style="margin-bottom: 4%">
          <div
            class="mt-5 justify-content-center"
            :style="{ width: isMobile() ? '100%' : '' }"
          >
            <div
              :class="{
                'card-form': true,
                'justify-content-center': true,
              }"
              v-bind:style="{
                width: isMobile() ? '100%' : '',
              }"
            >
              <div
                :class="{ 'card-form__inner': true }"
                v-bind:style="{
                  minWidth: isMobile() ? '100%' : '',
                  minHeight: '640px',
                  width: isMobile() ? '100%' : '',
                }"
                style="margin-top: 0px"
              >
                <h1
                  class="mt-3 text-center"
                  style="font-family: 'Mokoto'; color: #363166"
                >
                  AI website creator
                </h1>
                <center>
                  <img
                    v-if="
                      (this.website.logo == '' || this.website.logo == null) &&
                      !this.gif
                    "
                    src="../../../public/DashLogo.png"
                    class="card-img-top"
                    style="max-width: 100px; max-height: 75px"
                  />

                  <img
                    v-else
                    :src="this.website.logo"
                    class="card-img-top"
                    style="max-width: 500px"
                  />
                  <img
                    id="mygif"
                    src="../../../public/1495.gif"
                    class="animated-gif center"
                    v-show="this.gif"
                  />
                </center>
                <div
                  style="min-height: 244px"
                  v-if="
                    !this.fullyLoaded && !this.runLoading && !this.runComplete
                  "
                >
                  <br v-if="this.website.logo" />
                  <h5>{{ this.formTitle }}</h5>
                  <div
                    class="progress"
                    style="transition: width 0.5s"
                    role="progressbar"
                    aria-label="Animated striped example"
                    :aria-valuenow="this.progressBarWidth"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      :style="{ width: this.progressBarWidth + '%' }"
                    ></div>
                  </div>
                  <hr />
                  <div v-if="this.formToggle === 0">
                    <div class="card-input">
                      <label class="card-input__label mt-1"
                        >Organization Name</label
                      >
                      <input
                        type="text"
                        id="organizationName"
                        class="card-input__input"
                        placeholder="Name"
                        v-model="website.organization"
                        required
                      />
                    </div>
                    <div class="card-input mt-3">
                      <label for="address" class="card-input__label"
                        >Organization Address
                        <span
                          v-if="!addressVerified"
                          class="text-muted"
                          style="font-size: 12px"
                          >Unverified</span
                        >
                        <span v-else class="text-muted" style="font-size: 12px"
                          ><i class="fa-solid fa-circle-check"></i>
                          Verified</span
                        ></label
                      >
                      <vue-google-autocomplete
                        ref="address"
                        id="map"
                        classname="form-control"
                        placeholder="Address"
                        class="card-input__input"
                        v-on:placechanged="getAddressData"
                        v-on:change="setAddressData"
                        :country="['us']"
                        v-model="customer.address"
                      >
                      </vue-google-autocomplete>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <label class="form-label">Primary Color</label>
                        <i
                          class="fa-solid fa-circle-info fa-lg"
                          style="
                            color: #45487a;
                            cursor: pointer;
                            margin-left: 3px;
                          "
                          @click="pColorExplanation"
                        ></i>
                        <br />
                        <input
                          id="colorPicker"
                          type="color"
                          v-model="website.primaryColor"
                        />
                        <div
                          class="color-display rounded"
                          :style="{ backgroundColor: website.primaryColor }"
                        >
                          {{ website.primaryColor }}
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6">
                        <label class="form-label">Accent Color</label>
                        <i
                          class="fa-solid fa-circle-info fa-lg"
                          style="
                            color: #45487a;
                            cursor: pointer;
                            margin-left: 3px;
                          "
                          @click="sColorExplanation"
                        ></i
                        ><br />
                        <input
                          id="colorPicker"
                          type="color"
                          v-model="website.secondaryColor"
                        />
                        <div
                          class="color-display rounded"
                          :style="{ backgroundColor: website.secondaryColor }"
                        >
                          {{ website.secondaryColor }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="this.formToggle === 1">
                    <div class="row">
                      <div class="col">
                        <div class="card-input">
                          <label for="firstName" class="card-input__label"
                            >First Name</label
                          >
                          <input
                            type="text"
                            id="firstName"
                            class="card-input__input"
                            placeholder="First Name"
                            v-model="customer.firstName"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-input">
                          <label for="lastName" class="card-input__label"
                            >Last Name</label
                          >
                          <input
                            type="text"
                            id="lastName"
                            class="card-input__input"
                            placeholder="Last Name"
                            v-model="customer.lastName"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="formFile" class="form-label"
                        >Upload Logo</label
                      >
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        accept=".jpeg, .jpg, .png"
                        @change="uploadImage($event)"
                      />
                    </div>
                    <a
                      class="card2 camp-step-3"
                      target="_blank"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadImages"
                      style="cursor: pointer; color: #363166"
                    >
                      <h5>Upload Additional Images</h5>
                      <div class="go-corner" href="#">
                        <div class="go-arrow">+</div>
                      </div>
                    </a>
                    <a
                      class="card2 camp-step-3 mt-3"
                      data-bs-toggle="modal"
                      data-bs-target="#linkSocialMedia"
                      target="_blank"
                      style="cursor: pointer; color: #363166"
                    >
                      <h5>
                        Link your social media <i class="fa-solid fa-users"></i>
                      </h5>
                      <div class="go-corner" href="#">
                        <div class="go-arrow">+</div>
                      </div>
                    </a>
                    <div
                      class="modal fade"
                      id="linkSocialMedia"
                      tabindex="-1"
                      aria-labelledby="linkSocialMediaLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5
                              class="modal-title text-center"
                              id="linkSocialMediaLabel"
                            >
                              Link Your Social Media
                            </h5>
                          </div>
                          <div class="modal-body">
                            <label class="form-label mt-3" for="form6Example1"
                              >Twitter Profile Link
                              <i class="fa-brands fa-x-twitter"></i
                            ></label>
                            <input
                              type="text"
                              id="form6Example1"
                              class="form-control"
                              placeholder="e.g. https://twitter.com/SummerAthletes"
                              v-model="website.twitterProfileLink"
                              required
                            />
                            <label class="form-label mt-3" for="Instagram"
                              >Instagram Profile Link
                              <i class="fa-brands fa-instagram"></i>
                            </label>
                            <input
                              type="text"
                              id="Instagram"
                              class="form-control"
                              placeholder="e.g. https://www.instagram.com/summerathletes/"
                              v-model="website.instaProfileLink"
                              required
                            />
                            <label class="form-label mt-3" for="form6Example1"
                              >Facebook Profile Link
                              <i class="fa-brands fa-facebook"></i
                            ></label>
                            <input
                              type="text"
                              id="form6Example1"
                              class="form-control"
                              placeholder="e.g. https://www.facebook.com/summerathletes"
                              v-model="website.facebookProfileLink"
                              required
                            />
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              id="websiteSettingsClose"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              id="websiteSettingsClose"
                              class="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      this.formToggle === 2 &&
                      !runLoading &&
                      !fullyLoaded &&
                      !runComplete
                    "
                  >
                    <div class="row">
                      <div class="col">
                        <div class="card-input">
                          <label for="email" class="card-input__label"
                            >Email</label
                          >
                          <input
                            type="email"
                            id="email"
                            class="card-input__input"
                            placeholder="Email"
                            v-model="customer.email"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-input">
                          <label for="phoneNumber" class="card-input__label"
                            >Phone Number</label
                          >
                          <input
                            type="number"
                            id="phoneNumber"
                            class="card-input__input"
                            placeholder="Phone Number"
                            v-model="customer.phoneNumber"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <label class="form-label mt-3"
                      >Description of Services
                      <span>
                        <i
                          class="fa-solid fa-circle-info fa-lg"
                          style="
                            color: #45487a;
                            cursor: pointer;
                            margin-left: 3px;
                          "
                          @click="descriptionExplanation"
                        ></i></span
                    ></label>

                    <textarea
                      class="form-control"
                      rows="4"
                      placeholder="e.g. Business Statement here"
                      v-model="website.description"
                      minlength="250"
                      required
                      style="min-height: 200px"
                    ></textarea>
                  </div>
                  <!--              </Transition>-->
                </div>
                <br />
                <div
                  class="row justify-content-center"
                  v-if="
                    formToggle < 2 &&
                    !runLoading &&
                    !fullyLoaded &&
                    !runComplete
                  "
                >
                  <div class="col-md-3" v-if="formToggle > 0">
                    <button
                      class="card-form__button mt-3"
                      @click="handleBack()"
                      v-if="formToggle > 0"
                      style="margin-top: 10px"
                    >
                      <i class="fa-solid fa-arrow-left"></i> Back
                    </button>
                  </div>
                  <div class="col-md-9" v-if="formToggle < 2">
                    <button
                      class="card-form__button mt-3"
                      @click="handleNext()"
                      style="margin-top: 10px"
                    >
                      Next <i class="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div
                  v-else-if="!runLoading && !fullyLoaded && !runComplete"
                  class="row"
                >
                  <div class="col-md-3">
                    <button
                      class="card-form__button mt-3"
                      @click="handleBack()"
                      v-if="formToggle > 0"
                      style="margin-top: 10px"
                    >
                      <i class="fa-solid fa-arrow-left"></i> Back
                    </button>
                  </div>
                  <div class="col-md-9">
                    <button
                      class="card-form__button mt-3"
                      @click="generateWebsite"
                      style="margin-top: 10px"
                    >
                      <div v-if="this.generating">
                        <div class="spinner-grow spinner-grow-sm m-1"></div>
                        <div class="spinner-grow spinner-grow-sm m-1"></div>
                        <div class="spinner-grow spinner-grow-sm m-1"></div>
                      </div>
                      <div v-else>Generate Website</div>
                    </button>
                  </div>
                </div>
                <div
                  class="card grow m-3 card-form__inner"
                  v-if="runLoading"
                  style="max-width: 700px; min-height: 203px"
                >
                  <div class="card-body row">
                    <div class="col-md-6 col-sm-6" style="margin-top: 10px">
                      <h2
                        v-bind:style="{ textAlign: isMobile ? 'center' : '' }"
                      >
                        <b v-if="loadPartOne">Mapping AI Routes...</b>
                        <b v-else-if="loadPartTwo"
                          >Implementing AI Security...</b
                        >
                        <b v-else-if="loadPartThree"
                          >Running AI Diagnostics...</b
                        >
                        <b v-else-if="loadPartFour"
                          >Finishing AI Algorithms...</b
                        >
                      </h2>
                      <div class="progress" v-if="loadPartOne">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
                          role="progressbar"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 25%"
                        ></div>
                      </div>
                      <div class="progress" v-if="loadPartTwo">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 50%"
                        ></div>
                      </div>
                      <div class="progress" v-if="loadPartThree">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
                          role="progressbar"
                          aria-valuenow="90"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 75%"
                        ></div>
                      </div>
                      <div class="progress" v-if="loadPartFour">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
                          role="progressbar"
                          aria-valuenow="90"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 90%"
                        ></div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div
                        v-bind:style="{
                          textAlign: isMobile ? 'right' : '',
                          marginTop: '10px',
                          marginRight: '10px',
                        }"
                      >
                        <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="card grow m-3 card-form__inner"
                  v-if="this.runComplete"
                  style="max-width: 700px; min-height: 203px"
                >
                  <div class="card-body row">
                    <div class="col-md-10 col-sm-6" style="margin-top: 10px">
                      <h2 class="text-start">
                        <b
                          ><a :href="getWebsiteURL" target="_blank"
                            >{{ this.newWebsite.title }}
                          </a>
                        </b>
                      </h2>
                      <div v-if="this.fullyLoaded" class="progress">
                        <div
                          class="progress-bar progress-bar-background"
                          role="progressbar"
                          aria-valuenow="90"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 100%"
                        ></div>
                      </div>
                    </div>
                    <span
                      ><i
                        class="fa-solid fa-circle-check"
                        style="color: #19d412"
                      ></i>
                      Website Active</span
                    >
                    <span
                      ><i
                        class="fa-solid fa-circle-check"
                        style="color: #19d412"
                      ></i>
                      Monitoring Active</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="modal fade"
        id="uploadImages"
        tabindex="-1"
        aria-labelledby="uploadImagesLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="uploadImagesLabel">
                Upload Additional Images - Preferably 5
              </h5>
            </div>
            <div class="modal-body">
              <input
                type="file"
                ref="fileInput"
                class="form-control"
                accept=".jpeg, .jpg, .png"
                @change="uploadAdditionalImage($event)"
              />
              <br />
              <input
                type="file"
                ref="fileInput"
                class="form-control"
                accept=".jpeg, .jpg, .png"
                @change="uploadAdditionalImage($event)"
              />
              <br />
              <input
                type="file"
                ref="fileInput"
                class="form-control"
                accept=".jpeg, .jpg, .png"
                @change="uploadAdditionalImage($event)"
              />
              <br />
              <input
                type="file"
                ref="fileInput"
                class="form-control"
                accept=".jpeg, .jpg, .png"
                @change="uploadAdditionalImage($event)"
              />
              <br />
              <input
                type="file"
                ref="fileInput"
                class="form-control"
                accept=".jpeg, .jpg, .png"
                @change="uploadAdditionalImage($event)"
              />
              <br />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="websiteSettingsClose"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                id="websiteSettingsClose"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: { VueGoogleAutocomplete },
  data() {
    return {
      formToggle: 0,
      formTitle: 'Setup Your Website',
      progressBarWidth: 21,
      generating: false,
      addressVerified: false,
      user: {
        firstName: '',
        accountActive: true,
        subscription: {
          subscriptionName: 'Demo',
          subscriptionTier: 0,
        },
      },
      gif: false,
      website: {
        logo: null,
        images: [],
        primaryColor: null,
        secondaryColor: null,
        organization: null,
        domainName: null,
        twitterProfileLink: null,
        instaProfileLink: null,
        facebookProfileLink: null,
        location: null,
        themeDescriptor: null,
        description: null,
        coachEmail: '',
        camps: [],
        memberships: [],
      },
      customer: {
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        phoneNumber: '',
      },
      clickedConfirm: false,
      images: [],
      newWebsite: {},
      contactID: '',
      runLoading: false,
      fullyLoaded: false,
      loadPartOne: true,
      loadPartTwo: false,
      loadPartThree: false,
      loadPartFour: false,
      runComplete: false,
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    handleNext() {
      this.formToggle++
      this.progressBarWidth = this.progressBarWidth + 33
      if (this.formToggle === 1) {
        this.formTitle = '2) Showcase Your Brand'
      } else if (this.formToggle === 2) {
        this.formTitle = '3) Tell Us More About Your Organization'
      }
    },
    handleBack() {
      this.formToggle--
      this.progressBarWidth = this.progressBarWidth - 33
      if (this.formToggle === 0) {
        this.formTitle = '1) Setup Your Website'
      } else if (this.formToggle === 1) {
        this.formTitle = '2) Showcase Your Brand'
      } else if (this.formToggle === 2) {
        this.formTitle = '3) Tell Us More About Your Organization'
      }
    },
    setAddressData(e) {
      if (!this.customer.address) {
        this.customer.address = e.toString()
      }
    },
    getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData)
      console.log(id)
      console.log(addressData)
      this.customer.address =
        addressData.street_number + ' ' + addressData.route
      this.customer.city = addressData.locality
      this.customer.state = addressData.administrative_area_level_1
      this.customer.zipCode = addressData.postal_code
      this.addressVerified = true
    },
    logoExplanation() {
      swal({
        title: 'Logo',
        text: 'This will be the main logo of who the website it for.',
        icon: 'info',
      })
    },
    additionalImagesExplanation() {
      swal({
        title: 'Additional Images',
        text: 'These are any additional images that you want on your website. We recommend at least 3 on top of your logo, but 5 is preferred.',
        icon: 'info',
      })
    },
    pColorExplanation() {
      swal({
        title: 'Primary Color',
        text: 'Here you will pick the primary color of your website. Our AI will make this be the primary color in the theme of the website.',
        icon: 'info',
      })
    },
    sColorExplanation() {
      swal({
        title: 'Secondary Color',
        text: 'Here you will pick the secondary color of your website. Our AI will make this be an accent color on your website.',
        icon: 'info',
      })
    },
    organizationExplanation() {
      swal({
        title: 'Organization/Coach',
        text: 'This is the organization or coach that the website is for.',
        icon: 'info',
      })
    },
    domainNameExplanation() {
      swal({
        title: 'Domain Name',
        text: "This is the main URL name of your website. For instance, Google's URL is www.google.com. Their domain name would be 'google'",
        icon: 'info',
      })
    },
    locationExplanation() {
      swal({
        title: 'Location',
        text: 'This is the location of where you are located. Our AI will optimize your website to appear in searches in this area.',
        icon: 'info',
      })
    },
    themeDescriptionExplanation() {
      swal({
        title: 'Theme Description',
        text: 'Describe some adjectives that describe the overall design and feel of the website you want. Some description examples are "Light", "Modern","Minimalistic", "Vibrant", "Professional", etc...',
        icon: 'info',
      })
    },
    pagesExplanation() {
      swal({
        title: 'Website Page',
        text: 'List some of the pages that you want on your website. E.G. Home page, contact us page, history, about us, etc...',
        icon: 'info',
      })
    },
    descriptionExplanation() {
      swal({
        title: 'Description',
        text: 'This is the description of what services you are offering, what your organization does, or your past expierience as a coach. The more information that you can give to Digital AI, the better your website will be. Try to write 1-2 sentences.',
        icon: 'info',
      })
    },
    additionalInstructionsExplanation() {
      swal({
        title: 'Additional Instructions',
        text: 'Add any additional instructions about the website you want. Some common examples are to make the website have lots of sports specific photos, or to use a certain tone of voice when creating the website.',
        icon: 'info',
      })
    },
    validateForm() {
      if (!this.website.logo) {
        swal('Error', 'You must upload a logo for your website.', 'error')
        return true
      }
      if (this.website.images.length < 3) {
        swal(
          'Error',
          'You must have at least 3 additional images on your website.',
          'error',
        )
        return true
      }
      if (
        this.website.primaryColor == null ||
        this.website.primaryColor == ''
      ) {
        swal('Error', 'The primary color must be filled out.', 'error')
        return true
      }
      if (
        this.website.secondaryColor == null ||
        this.website.secondaryColor == ''
      ) {
        swal('Error', 'The secondary color must be filled out.', 'error')
        return true
      }
      if (
        this.website.organization == null ||
        this.website.orgainzation == ''
      ) {
        swal('Error', 'The Organization must be filled out.', 'error')
        return true
      }

      if (this.website.description == null || this.website.description == '') {
        swal('Error', 'The description must be filled out.', 'error')
        return true
      }
      if (!this.website.organization) {
        swal('Error', 'Organization Name must be filled out.', 'error')
        return true
      }
      if (!this.customer.address) {
        swal('Error', 'Organization Address must be filled out.', 'error')
        return true
      }
      if (!this.customer.firstName) {
        swal('Error', 'First Name must be filled out.', 'error')
        return true
      }
      if (!this.customer.lastName) {
        swal('Error', 'Last Name must be filled out.', 'error')
        return true
      }
      if (!this.customer.email) {
        swal('Error', 'Email must be filled out.', 'error')
        return true
      }
      if (!this.customer.phoneNumber) {
        swal('Error', 'Phone Number must be filled out.', 'error')
        return true
      }

      return false
    },
    isValidSubdomain(inputString) {
      return /^[a-zA-Z0-9]*$/.test(inputString)
    },
    uploadAdditionalImage(event) {
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadAIDemoImage'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          this.website.images.push(response.data)
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error',
          )
        })
    },
    uploadImage(event) {
      this.gif = true
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadAIDemoImage'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          this.website.logo = response.data
          this.gif = false
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error',
          )
        })
    },
    async generateWebsite() {
      this.clickedConfirm = true
      console.log(this.validateForm())
      if (this.validateForm()) {
        console.log('not returning')
        return
      }
      this.generating = true
      swal({
        title: 'Generating Website',
        text: 'The A.I. is generating your website now. Please stay on this page and do not refresh.',
        icon: 'info',
      })
      let url = import.meta.env.VITE_APP_DB_URL + 'ai/createAIDemoWebsite'
      this.website.coachEmail = this.user.email
      this.website.domainName = uuidv4()
      axios
        .post(
          url,
          {
            id: this.user._id,
            website: this.website,
            customer: this.customer,
            digitalAI: true,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          },
        )
        .then(response => {
          console.log(response.data.aiWebsites)
          this.newWebsite = response.data.aiWebsite
          this.contactID = response.data.contactID
          this.runLoading = true
          setTimeout(() => {
            this.runLoading = false
            this.loadPartOne = true
            this.loadPartFour = false
            this.runComplete = true
            this.fullyLoaded = true
          }, 30000)
          setTimeout(() => {
            this.loadPartOne = false
            this.loadPartTwo = true
          }, 7500)
          setTimeout(() => {
            this.loadPartTwo = false
            this.loadPartThree = true
          }, 15000)
          setTimeout(() => {
            this.loadPartThree = false
            this.loadPartFour = true
          }, 22500)
          setTimeout(() => {
            this.fullyLoaded = false
            window.location = this.getWebsiteURL
          }, 30500)
          swal(
            'Success',
            'Website Created! Please wait approximately ONE MINUTE for AI to finish building and publish your website!',
            'success',
          )
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Our server is receiving more requests than expected. Please wait a few minutes and try again',
            'error',
          )
        })
    },
  },
  created() {
    this.getUserDetails()
  },

  mounted() {},
  computed: {
    getWebsiteURL() {
      return `https://${this.newWebsite.subdomain}.summerathletes.com?contact=${this.contactID}`
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.clickedConfirm) {
      swal({
        title: 'Leave without saving?',
        text: 'Do you want to leave without saving? If you create the this, you will be able to edit it later and not loose your progress.',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: 'Leave without saving',
        },
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
}
</script>
<style>
.digital-ai {
  background-image: url('../../../public/darkness.webp');
}
.editor-datetime {
  position: relative;
}
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.removeDay {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeDay:hover {
  color: darkred;
  cursor: pointer;
}

.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

.nav {
  margin-top: 40px;
}

.pull-right {
  float: right;
}

a,
a:active {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #777;
  cursor: pointer;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 1;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: '✔';
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #45487a;
}

.arrow-steps .step.incomplete {
  color: #fff;
  background-color: #ffcc00;
}

.arrow-steps .step.finished {
  color: #fff;
  background-color: #19d412;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #45487a;
}

.arrow-steps .step.incomplete:after {
  border-left: 17px solid #ffcc00;
}

.arrow-steps .step.finished:after {
  border-left: 17px solid #19d412;
}
</style>
