<template>
  <div
    class="bg-white rounded-2xl shadow-lg overflow-hidden border border-[#363166]/10 hover:shadow-xl transition-all duration-300"
  >
    <div class="p-6">
      <div class="flex flex-col md:flex-row gap-6">
        <!-- Left Column: Profile Image and Name -->
        <div class="flex flex-col items-center md:items-start md:w-1/3">
          <div class="relative w-32 h-32 mb-4">
            <img
              :src="getImage"
              class="w-full h-full object-cover rounded-full border-4 border-[#363166]/20 shadow-md"
              alt="Member profile"
            />
          </div>

          <h2
            class="text-2xl font-bold text-[#030021] mb-2 text-center md:text-left"
          >
            {{ firstName }} {{ lastName }}
          </h2>

          <div class="mb-4 flex items-center justify-center md:justify-start">
            <span :class="getStatusBadgeClass">
              {{ membershipStatus }}
            </span>
          </div>

          <button
            @click="toMemberProfile()"
            class="inline-flex items-center justify-center px-6 py-2 bg-gradient-to-r from-[#363166] to-[#030021] text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 w-full md:w-auto mb-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            View Profile
          </button>
        </div>

        <!-- Right Column: Member Details -->
        <div class="md:w-2/3 space-y-4">
          <!-- Additional Information Cards -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <!-- Discount Code -->
            <!-- Membership Dates -->
            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-5 border border-[#363166]/10 hover:shadow-md transition-all duration-300"
            >
              <div class="flex items-start">
                <div
                  class="h-10 w-10 rounded-full bg-[#363166] flex items-center justify-center text-white mr-3 mt-1 flex-shrink-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div class="flex-1">
                  <h3 class="font-semibold text-[#030021] text-lg">
                    Membership
                  </h3>
                  <div class="mt-1">
                    <div class="flex items-center text-[#363166] mt-1">
                      <span class="font-medium">Pay Period:</span>
                      <span class="ml-2">{{ getFormattedPayPeriod }}</span>
                    </div>
                    <div class="flex items-center text-[#363166] mt-1">
                      <span class="font-medium">Joined:</span>
                      <span class="ml-2">{{ getFormattedStartDate }}</span>
                    </div>
                    <div
                      class="flex items-center text-[#363166] mt-1"
                      v-if="this.endDate"
                    >
                      <span class="font-medium">Ended:</span>
                      <span class="ml-2">{{ getFormattedEndDate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="discountCode" class="bg-[#19D412]/10 rounded-xl p-4">
              <h3 class="font-semibold text-[#030021] mb-2 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2 text-[#19D412]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                  />
                </svg>
                Discount Code
              </h3>
              <p class="text-[#363166] font-medium">{{ discountCode }}</p>
            </div>

            <!-- Amount Paid -->
            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-5 border border-[#363166]/10 hover:shadow-md transition-all duration-300"
            >
              <div class="flex items-start">
                <div
                  class="h-10 w-10 rounded-full bg-[#363166] flex items-center justify-center text-white mr-3 mt-1 flex-shrink-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="flex-1">
                  <h3 class="font-semibold text-[#030021] text-lg">
                    Pricing Tier
                  </h3>
                  <p class="text-[#19D412] font-bold text-2xl mt-1">
                    ${{ pricePaid.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    membershipId: {
      type: String,
      required: true,
    },
    membershipType: {
      type: String,
      default: 'Standard',
    },
    membershipStatus: {
      type: String,
      default: 'Active',
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    membershipPayPeriod: {
      type: Array,
      default: [],
    },
    memberPayPeriod: {
      type: String,
      default: 'monthlyPrice',
    },
    profileImage: {
      type: String,
      default: '',
    },
    discountCode: {
      type: String,
      default: '',
    },
    membershipName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pricePaid: 0,
    }
  },

  computed: {
    pricePaid() {
      if (this.memberPayPeriod === 'weeklyPrice') {
        return this.membershipPayPeriod.weeklyPrice
      } else if (this.memberPayPeriod === 'monthlyPrice') {
        return this.membershipPayPeriod.monthlyPrice
      } else if (this.memberPayPeriod === 'semiAnnualPrice') {
        return this.membershipPayPeriod.semiAnnualPrice
      } else if (this.memberPayPeriod === 'annualPrice') {
        return this.membershipPayPeriod.annualPrice
      }
    },
    getImage() {
      if (!this.profileImage) {
        return '/headshot-icon.png'
      } else {
        return this.profileImage
      }
    },
    getFormattedPayPeriod() {
      if (this.memberPayPeriod === 'weeklyPrice') {
        return 'Weekly'
      } else if (this.memberPayPeriod === 'monthlyPrice') {
        return 'Monthly'
      } else if (this.memberPayPeriod === 'semiAnnualPrice') {
        return 'Semi-Annual'
      } else if (this.memberPayPeriod === 'annualPrice') {
        return 'Annual'
      }
    },

    getFormattedStartDate() {
      if (!this.startDate) return 'N/A'
      return moment(this.startDate).format('MMM D, YYYY')
    },

    getFormattedEndDate() {
      if (!this.endDate) return 'N/A'
      return moment(this.endDate).format('MMM D, YYYY')
    },

    getStatusBadgeClass() {
      let baseClasses = 'px-3 py-1 rounded-full text-sm font-medium'

      if (this.membershipStatus === 'Active') {
        return `${baseClasses} bg-[#19D412]/20 text-[#19D412]`
      } else if (this.membershipStatus === 'Inactive') {
        return `${baseClasses} bg-red-100 text-red-800`
      } else {
        return `${baseClasses} bg-gray-100 text-gray-800`
      }
    },
  },

  methods: {
    toMemberProfile() {
      this.$router.push(
        `/Dashboard/ViewMember/${this.membershipName}/${this.membershipId}`,
      )
    },
  },
}
</script>

<style scoped>
/* Card hover effect */
.card-hover:hover {
  transform: translateY(-4px);
  box-shadow:
    0 10px 25px -5px rgba(54, 49, 102, 0.1),
    0 10px 10px -5px rgba(54, 49, 102, 0.04);
}

button,
a {
  border-radius: 0.5rem !important;
  overflow: hidden;
  position: relative;
}

button::after,
a::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(to right, #363166, #030021);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

button:hover::after,
a:hover::after {
  opacity: 1;
}
</style>
