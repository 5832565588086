<template>
  <div class="space-y-6">
    <!-- Camps/Clinics Section -->
    <div
      v-if="camps.length != 0"
      class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
    >
      <div class="px-6 py-4 bg-gray-50 border-b border-gray-200">
        <h3 class="text-sm font-semibold text-gray-900">Camps/Clinics</h3>
      </div>
      <div class="divide-y divide-gray-200">
        <div v-for="(camp, index) in camps" :key="index" class="px-6 py-4">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-4">
              <span class="text-sm text-gray-900">{{ camp.campName }}</span>
              <div class="flex items-center">
                <label class="text-sm text-gray-600 mr-2">Check In</label>
                <input
                  type="checkbox"
                  v-model="camp.isCheckedIn"
                  @change="updateCamperStatus(index, camp.isCheckedIn)"
                  class="h-4 w-4 text-[#19d412] border-gray-300 rounded focus:ring-[#19d412]"
                />
              </div>
            </div>
            <button
              @click="navigateIndividualCamp(index)"
              class="px-4 py-1.5 bg-[#6B66B0] text-white text-sm font-medium custom-rounded-button hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Leagues/Tournaments Section -->
    <div
      v-if="teamCamps.length != 0"
      class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
    >
      <div class="px-6 py-4 bg-gray-50 border-b border-gray-200">
        <h3 class="text-sm font-semibold text-gray-900">Leagues/Tournaments</h3>
      </div>
      <div class="divide-y divide-gray-200">
        <div
          v-for="(teamCamp, index) in teamCamps"
          :key="index"
          class="px-6 py-4"
        >
          <div class="flex items-center justify-between">
            <span class="text-sm text-gray-900">{{ teamCamp.campName }}</span>
            <button
              @click="navigateTeamCamp(index)"
              class="px-4 py-1.5 bg-[#6B66B0] text-white text-sm font-medium custom-rounded-button hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Recurring Payments Section -->
    <div
      v-if="memberships.length != 0"
      class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
    >
      <div class="px-6 py-4 bg-gray-50 border-b border-gray-200">
        <h3 class="text-sm font-semibold text-gray-900">Recurring Payments</h3>
      </div>
      <div class="divide-y divide-gray-200">
        <div
          v-for="(membership, index) in memberships"
          :key="index"
          class="px-6 py-4"
        >
          <div class="flex items-center justify-between">
            <span class="text-sm text-gray-900">{{
              membership.membershipName
            }}</span>
            <button
              @click="navigateMembership(index)"
              class="px-4 py-1.5 bg-[#6B66B0] text-white text-sm font-medium custom-rounded-button hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'

export default {
  props: {
    firstName: String,
    lastName: String,
    camps: Array,
    memberships: Array,
    teamCamps: Array,
  },
  methods: {
    updateCamperStatus(index, checkedIn) {
      let obj = {
        campId: this.camps[index].campId,
        camperId: this.camps[index].camperId,
        isCheckedIn: checkedIn,
      }
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/setCamperStatus'
      axios
        .post(url, obj, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    navigateIndividualCamp(index) {
      document.getElementById('PeopleDetailsCloseButton').click()
      this.$router.push(
        '/Dashboard/CheckIn/' +
          this.camps[index].campId +
          '/' +
          this.camps[index].camperId,
      )
    },
    navigateTeamCamp(index) {
      document.getElementById('PeopleDetailsCloseButton').click()
      this.$router.push(
        '/Dashboard/TeamList/' +
          this.teamCamps[index].campId +
          '/' +
          this.teamCamps[index].teamId +
          '/' +
          this.teamCamps[index].camperId,
      )
    },
    navigateMembership(index) {
      document.getElementById('PeopleDetailsCloseButton').click()
      this.$router.push(
        '/Dashboard/MembershipCheckIn/' +
          this.memberships[index].membershipId +
          '/' +
          this.memberships[index].memberId +
          '/' +
          this.memberships[index].subscriptionId,
      )
    },
  },
}
</script>

<style scoped>
.custom-rounded-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}
</style>
