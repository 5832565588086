<template>
  <div
    class="relative bg-white rounded-3xl shadow-2xl overflow-hidden transform transition-all duration-300 hover:scale-[1.02]"
  >
    <!-- Popular badge -->
    <div class="absolute top-0 right-0">
      <div
        class="bg-gradient-to-r from-yellow-400 to-yellow-500 text-white px-8 py-2 transform rotate-45 translate-x-8 translate-y-[-10px] shadow-lg"
      >
        <span class="text-sm font-bold">MOST POPULAR</span>
      </div>
    </div>

    <!-- Card header -->
    <div
      class="bg-gradient-to-br from-indigo-900 to-indigo-700 px-6 py-8 text-center"
    >
      <div class="mb-4">
        <div
          class="w-20 h-20 mx-auto rounded-full bg-white/20 flex items-center justify-center"
        >
          <i class="fa-solid fa-ranking-star text-3xl text-yellow-400"></i>
        </div>
      </div>
      <h3 class="text-2xl font-bold text-white mb-2">
        {{ subscription.subscriptionName }}
      </h3>
      <p class="text-white/80">
        {{ yearly ? 'Billed Annually' : 'Billed Monthly' }}
      </p>
    </div>

    <!-- Pricing section -->
    <div class="px-6 py-8">
      <div class="text-center mb-8">
        <div v-if="yearly" class="text-gray-400 line-through mb-2">
          ${{ getFakeYearly }}
        </div>
        <div class="flex items-center justify-center">
          <span class="text-4xl font-bold text-indigo-900">$</span>
          <span class="text-6xl font-bold text-indigo-900">
            {{ yearly ? getYearly.split('.')[0] : getMonthly.split('.')[0] }}
          </span>
          <span class="text-2xl font-bold text-indigo-900">
            .{{ yearly ? getYearly.split('.')[1] : getMonthly.split('.')[1] }}
          </span>
          <span class="text-gray-500 ml-2 text-xl">
            / {{ yearly ? 'year' : 'month' }}
          </span>
        </div>
        <div v-if="yearly" class="mt-4">
          <span
            class="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 text-sm font-medium"
          >
            <i class="fas fa-tag mr-1"></i>
            Save up to 25%
          </span>
        </div>
      </div>

      <!-- CTA Button -->
      <button
        class="w-full py-4 px-6 rounded-xl font-bold text-white bg-gradient-to-r from-indigo-600 to-indigo-800 hover:from-indigo-700 hover:to-indigo-900 transition-all duration-300 shadow-lg hover:shadow-xl"
        @click.stop="buySubscription(subscription)"
        :disabled="isDisabled"
        :class="{ 'opacity-50 cursor-not-allowed': isDisabled }"
      >
        {{ getButtonText }}
        <i class="fa-solid fa-arrow-right ml-2"></i>
      </button>

      <!-- Features -->
      <div class="mt-8">
        <p class="text-center text-gray-600 mb-6">Everything in Pro, Plus...</p>
        <ul class="space-y-4">
          <li class="flex items-start">
            <i
              class="fa-solid fa-check-circle text-green-500 mt-1 mr-3 text-xl"
            ></i>
            <span class="text-gray-800">Recurring Memberships</span>
          </li>
          <li class="flex items-start">
            <i
              class="fa-solid fa-check-circle text-green-500 mt-1 mr-3 text-xl"
            ></i>
            <span class="text-gray-800">Custom Website Domain</span>
          </li>
          <li class="flex items-start">
            <i
              class="fa-solid fa-check-circle text-green-500 mt-1 mr-3 text-xl"
            ></i>
            <span class="text-gray-800">Unlimited Mass Texting</span>
          </li>
          <li class="flex items-start">
            <i
              class="fa-solid fa-check-circle text-green-500 mt-1 mr-3 text-xl"
            ></i>
            <span class="text-gray-800">
              Free Priority Transfers
              <i class="fa-solid fa-bolt text-indigo-600 ml-2"></i>
            </span>
          </li>
          <li class="flex items-start">
            <i
              class="fa-solid fa-check-circle text-green-500 mt-1 mr-3 text-xl"
            ></i>
            <span class="text-gray-800">
              Customer Checkout Fee
              <span class="font-bold text-indigo-600">
                {{ subscription.customerUpchargePercentage.toFixed(1) }}%
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import numeral from 'numeral'

export default {
  props: {
    promo: {
      type: Boolean,
      default: false,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: '',
      },
    }
  },
  methods: {
    buySubscription(plan) {
      this.$emit('buySubscription', plan)
    },
    getSubscription() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'subscription/getSubscriptionByName?name=All Star'
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + localStorage.getItem('token'),
          },
        })
        .then(response => {
          this.subscription = response.data.subscription
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.getSubscription()
  },

  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format('0,0.00')
    },

    getYearly() {
      return numeral(this.subscription.yearlyPrice).format('0,0.00')
    },

    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format('0,0.00')
    },
    getButtonText() {
      return 'Get Started'
    },
  },
}
</script>

<style scoped>
/* Most Popular Tag */
.most-popular-tag {
  background-color: #ffc107;
  padding: 0.5rem;
  color: #363166;
  font-weight: bold;
  border-radius: 5px;
}

/* Action Feature Badge */
.actionFeature {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}

.original-price {
  text-decoration: line-through;
  color: #999;
}

.promotional-price {
  font-weight: bold;
  color: #4caf50;
}

.list-unstyled li {
  display: flex;
  align-items: center;
}

.list-unstyled li i {
  margin-right: 8px;
}

.card-body {
  font-size: 20px;
  font-weight: bold;
  color: #ffc107;
}

.price {
  font-size: 24px;
  color: #ffc107;
}

.plan-details__item-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
