<template>
  <div class="min-h-screen bg-white">
    <ClientLayout :user="user"></ClientLayout>
    <section class="pt-20 pb-6">
      <div class="container mx-auto px-8" :class="{ 'ml-5': isMobile() }">
        <!-- Header Section -->
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold text-gray-900">My Directory</h1>
          <div v-if="user.subscription.subscriptionTier >= 2">
            <JsonExcel
              :data="people"
              :name="'myDirectory.xls'"
              :fields="json_fields"
            >
              <button
                class="px-8 py-2 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
              >
                Export Data
              </button>
            </JsonExcel>
          </div>
          <div v-else>
            <button
              class="px-8 py-2 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
              @click="showDownloadCusotmerAd()"
            >
              Export Data
            </button>
          </div>
        </div>

        <!-- Search Bar -->
        <div class="max-w-2xl mx-auto mb-6">
          <div class="relative">
            <input
              class="w-full px-4 py-2.5 rounded-full border border-gray-200 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-200 text-sm"
              type="search"
              placeholder="Search customers..."
              v-model="searchQuery"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-4">
              <svg
                class="h-4 w-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>

        <!-- Table Section -->
        <div class="bg-white rounded-lg shadow-sm border border-gray-200">
          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    First Name
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Name
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(person, index) in resultQuery"
                  :key="person.email + person.firstName + person.lastName"
                  class="table-row"
                  :class="{
                    'bg-white': index % 2 === 0,
                    'bg-gray-100': index % 2 !== 0,
                  }"
                >
                  <td
                    class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ person.firstName }}
                  </td>
                  <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                    {{ person.lastName }}
                  </td>
                  <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                    {{ person.email }}
                  </td>
                  <td class="px-6 py-3 whitespace-nowrap text-right">
                    <button
                      class="min-w-[100px] px-8 py-1.5 bg-[#6B66B0] text-white custom-rounded-button text-sm font-medium hover:bg-[#5B5696] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#6B66B0] focus:ring-offset-2"
                      data-bs-toggle="modal"
                      data-bs-target="#PeopleDetailsModal"
                      @click="selectedPerson = person"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <PeopleDetailsModal
      :firstName="selectedPerson.firstName"
      :lastName="selectedPerson.lastName"
      :camps="selectedPerson.camps"
      :teamCamps="selectedPerson.teamCamps"
      :memberships="selectedPerson.memberships"
    ></PeopleDetailsModal>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import ClientLayout from '../../components/ClientLayout.vue'
import PeopleDetailsModal from '../../components/CRM/DetailsModal.vue'
import JsonExcel from 'vue-json-excel3'

export default {
  components: { ClientLayout, PeopleDetailsModal, JsonExcel },
  data() {
    return {
      selectedPerson: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        camps: [],
        teamCamps: [],
        memberships: [],
      },
      fields: [
        { key: 'firstName', sortable: true },
        { key: 'lastName', sortable: true },
        { key: 'email', sortable: true },
        { key: 'show_details', sortable: false },
      ],
      json_fields: {
        'First Name': 'firstName',
        'Last Name': 'lastName',
        Email: 'email',
        'Phone Number': 'phone',
        Camps: {
          callback: value => {
            let camps = ''
            value.camps.forEach(camp => {
              camps += camp.campName + ', '
            })
            return camps
          },
        },
        Memberships: {
          callback: value => {
            let memberships = ''
            value.memberships.forEach(membership => {
              memberships += membership.membershipName + ', '
            })
            return memberships
          },
        },
        Tournaments: {
          callback: value => {
            let camps = ''
            value.teamCamps.forEach(camp => {
              camps += camp.campName + ', '
            })
            return camps
          },
        },
      },
      user: {},
      campId: '',
      campName: '',
      people: [],
      searchQuery: '',
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    async getAllPeople() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'crm/getAllPeople?id=' + this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response)
          this.people = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    showDownloadCusotmerAd() {
      swal({
        title: 'Upgrade Your Plan',
        text: 'You must be at least an Pro user to download customer data locally. Upgrade your plan to continue.',
        icon: 'info',
      })
      document.getElementById('openAdvertisementPopUp').click()
    },
    toggleDetails(index) {
      this.people[index].detailsShowing = !this.people[index].detailsShowing
    },
  },

  created() {
    this.campId = this.$route.params.campId
    this.getUserDetails()
    this.getAllPeople()
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.people.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              v =>
                item.firstName.toLowerCase().includes(v) ||
                item.lastName.toLowerCase().includes(v),
            )
        })
      } else {
        return this.people
      }
    },
  },
}
</script>

<style scoped>
#table-transition-example .flip-list-move {
  transition: transform 1s;
}

.custom-rounded-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}

.table-row {
  transition: background-color 0.1s ease-out;
  will-change: transform;
  transform: translateZ(0);
}

.table-row:hover {
  background-color: rgb(209 213 219) !important;
  transform: scale(1.01) translateZ(0);
}
</style>
