<template>
  <footer class="secondary-background text-white py-8">
    <div class="max-w-7xl mx-auto px-4 py-8">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-32">
        <!-- More Information -->
        <div>
          <h3 class="text-lg font-bold mb-4">More Information</h3>
          <ul class="space-y-2 pl-0">
            <li>
              <a href="/contactus" class="text-white hover:underline"
                >Contact</a
              >
            </li>
            <li>
              <a href="/privacypolicy" class="text-white hover:underline"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a href="/termsofservice" class="text-white hover:underline"
                >Terms of Service</a
              >
            </li>
          </ul>
        </div>

        <!-- Logo Section -->
        <div class="md:flex md:justify-center">
          <img
            src="/PoweredBySA.png"
            alt="Summer Athletes Logo"
            class="mb-4 h-20 -ml-10 md:ml-0 md:mx-center"
          />
        </div>

        <!-- Connect With Us -->
        <div>
          <h3 class="text-lg font-bold mb-4">Connect With Us!</h3>
          <p class="text-sm">
            Follow Summer Athletes for the latest updates, limited releases, and
            deals on upcoming camps!
          </p>
        </div>
      </div>

      <!-- Bottom Section -->
      <div class="mt-8 border-t border-gray-700 pt-4 text-center">
        <p class="text-sm text-gray-500">
          &copy; 2025, Summer Athletes - All Rights Reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped>
/* Adjust padding for smaller screens */
@media (max-width: 640px) {
  footer {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.primary-background {
  background-color: #19d412 !important;
}
.secondary-background {
  background-color: #030021 !important;
}
</style>