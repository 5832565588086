<template>
  <div class="container">
    <section style="margin-top: 4%">
      <div class="container mt-5">
        <div
          class="card-form"
          v-bind:style="{
            marginLeft: isMobile() ? '21px' : '',
          }"
        >
          <div class="card-form__inner" style="text-align: center">
            <img
              src="../../../public/SummerAthletes.png"
              class="card-img-top v-step-0"
            />

            <div
              class="flex flex-col items-center justify-center p-4 space-y-3 max-w-md mx-auto"
            >
              <div class="row w-full">
                <button
                  class="create-button card-form__button v-step-05 v-step-2 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                  @click="individualCamp"
                >
                  <span
                    class="relative z-10 flex items-center justify-center gap-2 text-lg"
                  >
                    <i class="fa-solid fa-users"></i>
                    Create Camp/Clinic
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                  ></span>
                  <span
                    class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                  ></span>
                  <span
                    class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
                  ></span>
                </button>
              </div>

              <div class="row mt-3 w-full">
                <button
                  class="create-button card-form__button v-step-1 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                  @click="scheduledSession"
                >
                  <span
                    class="relative z-10 flex items-center justify-center gap-2 text-lg"
                  >
                    <i class="fa-solid fa-user"></i>
                    Create Training Session
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                  ></span>
                  <span
                    class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                  ></span>
                  <span
                    class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
                  ></span>
                </button>
              </div>

              <div class="row mt-3 w-full">
                <button
                  class="create-button card-form__button v-step-1 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                  @click="membership"
                >
                  <span
                    class="relative z-10 flex items-center justify-center gap-2 text-lg"
                  >
                    <i class="fa-solid fa-repeat"></i>
                    Create Membership
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                  ></span>
                  <span
                    class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                  ></span>
                  <span
                    class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      campBool: false,
    }
  },
  components: {},
  props: {},
  methods: {
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    individualCamp() {
      this.$router.push('/Dashboard/CreateCamp/Individual')
    },
    teamCamp() {
      this.$router.push('/Dashboard/CreateCamp/Team/')
    },
    membership() {
      if (this.user.subscription.subscriptionTier < 3) {
        swal(
          'Upgrade to Earn More!',
          'To start maximizing your monthly revenue, please upgrade to an All Star or higher subscription today!',
          'info',
        )
        document.getElementById('openAdvertisementPopUp')?.click()
      } else {
        this.$router.push('/Dashboard/CreateMembership/')
      }
    },
    scheduledSession() {
      this.$router.push(
        '/Dashboard/CreateCamp/Individual?scheduledSession=true',
      )
    },
  },

  created() {
    this.getUserDetails()
  },
  mounted() {
    if (localStorage.getItem('startTour')) {
      console.log('starting')
      this.$tours['myTour1'].start()
    }
  },
}
</script>
<style scoped>
.rounded-pill {
  border-radius: 50px !important;
}

/* Add a subtle texture to the buttons */
.create-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
}

/* Add ripple animation */
@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.create-button:active .animate-ripple-on-click {
  animation: ripple 0.8s ease-out;
}

/* Add shine effect */
.create-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
  transition: left 0.7s;
}

.create-button:hover::after {
  left: 100%;
}
</style>
