<template>
  <div class="wrapper container justify-content-center">
    <!-- <v-tour name="myTour" :steps="this.steps"></v-tour> -->
    <ClientLayout :user="user"></ClientLayout>
    <div
      :class="{
        'card-form': true,
        container: true,
        'd-flex': true,
        'justify-content-center': true,
        'mx-4': true,
      }"
      style="margin-top: 4%; min-width: 100%"
    >
      <div
        :class="{ 'card-form__inner': true }"
        v-bind:style="{
          minWidth: isMobile() ? '100%' : '',
          // minWidth: isMobile() ? '23rem' : '75%',
        }"
      >
        <div v-if="this.showCampDetails">
          <div class="d-flex justify-content-center">
            <div class="arrow-steps clearfix" v-if="!isMobile()">
              <div class="step current" @click="toggleCampDetails">
                <span>Details</span>
              </div>
              <div class="step" @click="toggleCustomizationForm">
                <span>Customize Form</span>
              </div>
              <div class="step" @click="togglePaymentDetails">
                <span>Pricing</span>
              </div>
            </div>
          </div>
          <center class="m-3">
            <img
              v-if="
                (this.newCamp.campLogo == '' ||
                  this.newCamp.campLogo == null) &&
                !this.gif
              "
              src="../../../public/SummerAthletes.png"
              class="card-img-top"
              style="max-width: 400px; max-height: 300px"
            />

            <img
              v-else
              :src="this.newCamp.campLogo"
              class="card-img-top rounded-3"
              style="max-width: 500px"
            />
            <img
              id="mygif"
              src="../../../public/1495.gif"
              class="animated-gif center"
              v-show="this.gif"
            />
          </center>
          <div class="mb-3">
            <label for="formFile" class="form-label">Upload Logo</label>
            <input
              class="form-control v-step-0"
              type="file"
              id="formFile"
              @change="uploadImage($event)"
            />
          </div>
          <label
            class="form-label mt-3"
            for="form6Example1"
            style="margin-top: 5px"
            >Name<span class="text-danger">*</span></label
          >
          <input
            type="text"
            id="form6Example1"
            class="form-control v-step-2"
            placeholder="e.g. Junior High Boys Basketball Camp"
            v-model="newCamp.campName"
            required
          />

          <label
            class="form-label mt-3"
            for="form6Example4"
            style="margin-top: 5px"
            >Location<span class="text-danger">* </span>
            <span
              v-if="!addressVerified"
              class="text-muted"
              style="font-size: 12px"
              >Unverified</span
            >
            <span v-else class="text-muted" style="font-size: 12px"
              ><i class="fa-solid fa-circle-check"></i> Verified</span
            ></label
          >
          <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Address"
            class="card-input__input"
            :country="['us']"
            v-on:placechanged="getAddressData"
            v-on:change="setAddressData"
            v-model="newCamp.campLocation"
          >
          </vue-google-autocomplete>

          <label class="form-label mt-3"
            >Age Range<span class="text-danger">*</span></label
          >
          <multiselect
            v-model="newCamp.ageRange"
            placeholder="Select Age Range"
            label="name"
            track-by="code"
            :options="getAgeRangeOptions"
            :multiple="true"
            :close-on-select="false"
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
              maxWidth: '558px',
            }"
          ></multiselect>

          <label class="form-label mt-3"
            >Gender<span class="text-danger">*</span></label
          >
          <multiselect
            v-model="newCamp.gender"
            placeholder="Select Gender"
            label="name"
            track-by="code"
            :options="getGenderOptions"
            :multiple="true"
            :close-on-select="false"
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
            }"
          ></multiselect>

          <div class="row mt-5">
            <div class="col-md-7 col-sm-3">
              <label class="form-label"
                >Description<span class="text-danger">*</span></label
              >
            </div>
            <div class="col-md-5 col-sm-3 mb-3">
              <SAIButton @click="generateDescription" />
            </div>
          </div>
          <textarea
            class="form-control v-step-4"
            rows="4"
            placeholder="e.g. The Junior High Basketball Camp will teach your child...."
            v-model="newCamp.campDescription"
            required
          ></textarea>
          <br />

          <label
            class="form-label"
            v-if="!this.$route.query.scheduledSession"
            for="form6Example3"
            style="margin-top: 5px"
            >Max Participants</label
          >
          <input
            v-if="!this.$route.query.scheduledSession"
            v-model="newCamp.campMaxParticipants"
            required
            id="form6Example1"
            class="form-control mb-3 v-step-6"
            placeholder="Leave blank for unlimited"
            type="number"
          />

          <div
            :class="{
              'form-check': true,
              'd-flex': true,
            }"
            style="margin-top: 20px"
            v-if="!this.$route.query.scheduledSession"
          >
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="setDatesAndTimes"
              :disabled="this.$route.query.date"
              @click="showDateVersusSessionInfoOnDateClick()"
            />
            <label
              class="form-check-label v-step-8"
              @click="showDateVersusSessionInfoOnDateClick()"
            >
              Set Dates and Times</label
            >
          </div>
          <!--Start of camp day input-->
          <div v-if="setDatesAndTimes">
            <li
              class="list-group-item m-3 v-step-5"
              v-for="(item, index) in this.newCamp.campDates"
              :key="'campDate' + index"
            >
              <div
                class="row"
                style="
                  border: 3px solid #45487a;
                  border-radius: 15px;
                  max-width: 558px;
                "
              >
                <label class="form-label mt-3" for="form6Example3"
                  >Day {{ index + 1 }}</label
                >
                <input
                  v-model="item.date"
                  required
                  id="form6Example1"
                  class="form-control m-2"
                  type="date"
                  :style="{ maxWidth: isMobile() ? '250px' : '530px' }"
                />
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Start Time</label
                  >
                  <input
                    v-model="item.start"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="time"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3">End Time</label>
                  <input
                    v-model="item.end"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="time"
                  />
                </div>
                <i
                  class="fa-solid fa-trash removeDay"
                  @click="removeDay(index)"
                ></i>
              </div>
            </li>
            <div>
              <center>
                <button
                  class="btn btn-primary btn-block mb-4 rounded-pill"
                  @click="addDay"
                  style="margin-top: 10px"
                >
                  Add New Day
                </button>
              </center>
            </div>
            <label
              class="form-label"
              for="form6Example3"
              style="margin-top: 5px"
              >Registration Deadline</label
            >
            <input
              v-model="newCamp.campRegistrationDeadline"
              required
              id="form6Example1"
              class="form-control v-step-7"
              type="date"
            />
            <div class="form-check d-flex mb-4" style="margin-top: 20px">
              <input
                class="form-check-input me-2"
                type="checkbox"
                value=""
                v-model="newCamp.acceptLateRegistration"
              />
              <label class="form-check-label v-step-8">
                Accept Late Registrations</label
              >
            </div>
          </div>

          <div
            class="form-check d-flex mb-4"
            style="margin-top: 20px"
            v-if="
              this.$route.query.date &&
              this.$route.query.hasScheduledSessions === 'true'
            "
          >
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="setSessionScheduler"
              :disabled="this.$route.query.date"
              @click="showDateVersusSessionInfoOnSessionClick()"
            />
            <label class="form-check-label v-step-8">
              Set Booked Sessions Scheduler</label
            >
          </div>

          <div
            v-if="!setDatesAndTimes && setSessionScheduler"
            style="
              border: 3px solid #45487a;
              border-radius: 15px;
              max-width: 558px;
            "
          >
            <div class="m-3">
              <label
                class="form-label"
                for="form6Example3"
                style="margin-top: 5px"
                ><span class="text-danger">* </span>Session Duration (In
                Minutes)
                <i
                  class="fa-solid fa-circle-info"
                  style="color: #45487a; cursor: pointer"
                  @click="showSessionDurationExplanation"
                ></i
              ></label>
              <input
                v-model="newCamp.sessionInfo.sessionDuration"
                required
                id="form6Example1"
                class="form-control mb-3 v-step-6"
                placeholder="e.g. 90 minutes"
                type="number"
              />
              <label
                class="form-label"
                for="form6Example3"
                style="margin-top: 5px"
                ><span class="text-danger">* </span>Max Advance Notice (In Days)
                <i
                  class="fa-solid fa-circle-info"
                  style="color: #45487a; cursor: pointer"
                  @click="showSessionMaxAdvanceNoticeExplanation"
                ></i
              ></label>
              <input
                v-model="newCamp.sessionInfo.maxAdvanceNotice"
                required
                id="form6Example1"
                class="form-control mb-3 v-step-6"
                placeholder="e.g. no more than 30 days out"
                type="number"
              />
              <label
                class="form-label"
                for="form6Example3"
                style="margin-top: 5px"
                ><span class="text-danger">* </span>Minimum Advance Notice (In
                Days)
                <i
                  class="fa-solid fa-circle-info"
                  style="color: #45487a; cursor: pointer"
                  @click="showSessionMinAdvanceNoticeExplanation"
                ></i
              ></label>
              <input
                v-model="newCamp.sessionInfo.minAdvanceNotice"
                required
                id="form6Example1"
                class="form-control mb-3 v-step-6"
                placeholder="e.g. at least 3 days advance notice required"
                type="number"
              />
              <label
                class="form-label"
                for="form6Example3"
                style="margin-top: 5px"
                ><span class="text-danger">* </span>Max Session Participants
                <i
                  class="fa-solid fa-circle-info"
                  style="color: #45487a; cursor: pointer"
                  @click="showSessionMaxParticipantsExplanation"
                ></i
              ></label>
              <input
                v-model="newCamp.sessionInfo.maxSessionParticipants"
                required
                id="form6Example1"
                class="form-control mb-3 v-step-6"
                placeholder="e.g. max of 2 participants per session"
                type="number"
              />
            </div>
          </div>
          <br />
          <div
            v-if="
              !setDatesAndTimes &&
              setSessionScheduler &&
              this.$route.query.date &&
              this.$route.query.hasScheduledSessions === 'true'
            "
            style="
              border: 3px solid #45487a;
              border-radius: 15px;
              max-width: 558px;
            "
          >
            <div class="m-3">
              <h3 class="text-center">Availability Window</h3>
              <p class="text-center text-muted">
                *This is your availability for potential sessions. Customers can
                only book a timeslot within this window.
              </p>
              <label class="form-label"
                >Starting Day:
                <strong>{{ this.$route.query.date }}</strong></label
              >

              <div class="row">
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Start Time</label
                  >
                  <input
                    v-model="availabilitySetting.startTime"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="time"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3">End Time</label>
                  <input
                    v-model="availabilitySetting.endTime"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="time"
                  />
                </div>
              </div>

              <div class="form-check d-flex mb-4" style="margin-top: 20px">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  value=""
                  v-model="availabilitySetting.repeating"
                />
                <label class="form-check-label v-step-8">
                  This Session is Recurring?</label
                >
              </div>
              <div v-if="availabilitySetting.repeating">
                <ul class="list-group">
                  <li class="list-group-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="DAY"
                      v-model="availabilitySetting.repeatingSetting"
                    />
                    Every Day
                  </li>
                  <li class="list-group-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="WEEK"
                      checked
                      v-model="availabilitySetting.repeatingSetting"
                    />
                    Every Week
                  </li>
                  <li class="list-group-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="MONTH"
                      checked
                      v-model="availabilitySetting.repeatingSetting"
                    />
                    Every Month
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <button
            class="create-button card-form__button v-step-9 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
            @click="toggleCustomizationForm"
            style="margin-top: 10px"
          >
            <span
              class="relative z-10 flex items-center justify-center gap-2 text-lg"
            >
              Customize Form
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <span
              class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            ></span>
            <span
              class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
            ></span>
            <span
              class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
            ></span>
          </button>
        </div>

        <!--          Customization Form-->
        <div v-else-if="this.showFormCustomization">
          <!-- <h1 class="text-center mb-3">Create Camp/Clinic</h1> -->
          <div class="d-flex justify-content-center" v-if="!isMobile()">
            <div class="arrow-steps clearfix">
              <div
                :class="{
                  step: true,
                  finished: campDetailsComplete(),
                  incomplete: !campDetailsComplete(),
                }"
                @click="toggleCampDetails"
              >
                <span>Details</span>
              </div>
              <div class="step current" @click="toggleCustomizationForm">
                <span>Customize Form</span>
              </div>
              <div class="step" @click="togglePaymentDetails">
                <span>Pricing</span>
              </div>
            </div>
          </div>
          <center class="m-3">
            <img
              v-if="
                (this.newCamp.campLogo == '' ||
                  this.newCamp.campLogo == null) &&
                !this.gif
              "
              src="../../../public/SummerAthletes.png"
              class="card-img-top rounded-3"
              style="max-width: 400px; max-height: 300px"
            />

            <img
              v-else
              :src="this.newCamp.campLogo"
              class="card-img-top rounded-3"
              style="max-width: 500px; max-height: 400px"
            />
            <img
              id="mygif"
              src="../../../public/1495.gif"
              class="animated-gif center"
              v-show="this.gif"
            />
          </center>
          <div
            style="
              border: 3px solid #45487a;
              border-radius: 15px;
              max-width: 558px;
            "
          >
            <div class="m-3">
              <h2 class="text-center" style="margin-top: 50px">
                Default Form Preview
                <i
                  class="fa-solid fa-circle-info"
                  style="color: #45487a; cursor: pointer"
                  @click="showDefaultFormExplanation"
                ></i>
              </h2>
              <!-- <h5 class="text-center center mb-3">(What Camper Will See)</h5> -->
              <h5></h5>
              <div class="row">
                <div class="card-input col">
                  <label for="cardName" class="card-input__label"
                    >First Name</label
                  >
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    data-ref="cardName"
                    autocomplete="given-name"
                    required
                    disabled
                  />
                </div>

                <div class="card-input col">
                  <label for="cardName" class="card-input__label"
                    >Last Name</label
                  >
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    data-ref="cardName"
                    autocomplete="family-name"
                    disabled
                  />
                </div>
              </div>
              <div v-if="newCamp.tShirtsProvided">
                <label>T-shirt size</label>
                <select
                  class="card-input__input -select"
                  style="margin-bottom: 20px"
                  required
                >
                  <option disabled selected value="">Size</option>
                  <option value="YS" class="option">Youth Small</option>
                  <option value="YM" class="option">Youth Medium</option>
                  <option value="YL" class="option">Youth Large</option>
                  <option value="AS" class="option">Adult Small</option>
                  <option value="AM" class="option">Adult Medium</option>
                  <option value="AL" class="option">Adult Large</option>
                  <option value="AXL" class="option">Adult XLarge</option>
                  <option value="AXXL" class="option">Adult XXLarge</option>
                </select>
              </div>

              <h3 v-show="!emergencyContactInfo.emergencyBool">
                Emergency Contact Information
              </h3>
              <div
                v-show="!emergencyContactInfo.emergencyBool"
                style="margin-top: 15px"
              >
                <div class="row">
                  <div class="card-input col">
                    <label class="card-input__label">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      class="card-input__input"
                      disabled
                    />
                  </div>
                  <div class="card-input col">
                    <label class="card-input__label">Last Name</label>
                    <input
                      type="text"
                      id="name"
                      class="card-input__input"
                      disabled
                    />
                  </div>
                </div>
                <div class="card-input">
                  <label class="card-input__label">Phone Number</label>
                  <input
                    type="text"
                    id="phoneNumber"
                    class="card-input__input"
                    disabled
                  />
                </div>
                <div class="card-input">
                  <label class="card-input__label">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="card-input__input"
                    disabled
                  />
                </div>
                <div class="card-input">
                  <label class="card-input__label">Address</label>
                  <input
                    type="text"
                    id="address"
                    class="card-input__input"
                    disabled
                  />
                </div>
                <div class="card-input">
                  <label class="card-input__label">City</label>
                  <input
                    type="text"
                    id="address"
                    class="card-input__input"
                    disabled
                  />
                </div>

                <div class="card-form__row">
                  <div class="card-form__col">
                    <div class="card-form__group">
                      <label for="cardMonth" class="card-input__label"
                        >State</label
                      >
                      <select class="card-input__input -select" id="cardMonth">
                        <option value="" disabled selected>State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <div class="card-form__col">
                    <div class="card-input">
                      <label for="cardCvv">Zip Code</label>
                      <input type="text" class="card-input__input" disabled />
                    </div>
                  </div>
                </div>
              </div>

              <input
                v-if="!this.requireEmergency"
                class="form-check-input me-2"
                type="checkbox"
                value=""
                id="form6Example8"
                v-model="emergencyContactInfo.emergencyBool"
              />
              <label
                v-if="!this.requireEmergency"
                class="form-check-label"
                for="form6Example8"
              >
                Emergency Contact will be same as credit card info
              </label>
            </div>
          </div>
          <br />
          <CustomFields
            v-if="this.showCustomFields"
            @back="onBack"
            :fields="this.newCamp.campCustomFields"
            ref="custom"
            class="mb-5"
            style="
              border: 3px solid #45487a;
              border-radius: 15px;
              max-width: 558px;
            "
            :editable="getEditable"
            :startNewCustomFieldsIndex="startNewCustomFieldsIndex"
            :isMobile="isMobile()"
          ></CustomFields>

          <div class="form-check d-flex mb-3">
            <input
              class="form-check-input me-2 create-camp-step-7"
              type="checkbox"
              value=""
              v-model="newCamp.requireWaiver"
            />
            <label class="form-check-label"> Require Waiver on Checkout </label>
          </div>

          <div v-if="newCamp.requireWaiver == true" class="mb-4">
            <div class="row mt-5">
              <div class="col-md-7 col-sm-3">
                <label class="form-label"
                  >Custom Waiver<span class="text-danger">*</span></label
                >
              </div>
              <div class="col-md-5 col-sm-3 mb-3">
                <SAIButton @click="generateWaiver" />
              </div>
            </div>
            <textarea
              class="form-control"
              rows="4"
              placeholder="Leave blank if using Summer Athletes default waiver"
              v-model="newCamp.customWaiver"
              required
            ></textarea>
          </div>

          <div
            class="form-check d-flex mb-4"
            v-if="!this.$route.query.scheduledSession"
          >
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="newCamp.tShirtsProvided"
            />
            <label class="form-check-label create-camp-step-8">
              Collect T-Shirt Size
            </label>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-6">
              <button
                class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] hover:from-[#16b910] hover:to-[#16b910] transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                @click="toggleCampDetails"
                style="margin-top: 10px"
              >
                <span
                  class="relative z-10 flex items-center justify-center gap-2 text-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </span>
                <span
                  class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                ></span>
              </button>
            </div>
            <div class="col-md-9 col-sm-6">
              <button
                class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                @click="togglePaymentDetails"
                style="margin-top: 10px"
              >
                <span
                  class="relative z-10 flex items-center justify-center gap-2 text-lg"
                >
                  Set Pricing
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                ></span>
                <span
                  class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                ></span>
                <span
                  class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
                ></span>
              </button>
            </div>
          </div>
        </div>

        <div v-else-if="this.showPaymentDetails">
          <div class="d-flex justify-content-center" v-if="!isMobile()">
            <div class="arrow-steps clearfix">
              <div
                :class="{
                  step: true,
                  finished: campDetailsComplete(),
                  incomplete: !campDetailsComplete(),
                }"
                @click="toggleCampDetails"
              >
                <span>Details</span>
              </div>
              <div class="step finished" @click="toggleCustomizationForm">
                <span>Customize Form</span>
              </div>
              <div class="step current" @click="togglePaymentDetails">
                <span>Pricing</span>
              </div>
            </div>
          </div>
          <center class="m-3">
            <img
              v-if="
                (this.newCamp.campLogo == '' ||
                  this.newCamp.campLogo == null) &&
                !this.gif
              "
              src="../../../public/SummerAthletes.png"
              class="card-img-top"
              style="max-width: 400px; max-height: 300px"
            />

            <img
              v-else
              :src="this.newCamp.campLogo"
              class="card-img-top rounded-3"
              style="max-width: 500px; max-height: 400px"
            />
            <img
              id="mygif"
              src="../../../public/1495.gif"
              class="animated-gif center"
              v-show="this.gif"
            />
          </center>

          <label
            class="form-label create-camp-step-2"
            for="form6Example6"
            style="margin-top: 5px"
            >Price (In USD)</label
          >
          <input
            type="number"
            id="form6Example6"
            class="form-control mb-3"
            placeholder="75"
            min="0"
            v-model="newCamp.campPrice"
            required
          />

          <div class="form-check d-flex mb-3">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="creatingDiscountCode"
            />
            <label class="form-check-label"> Setup Discount Codes </label>
          </div>
          <div v-if="creatingDiscountCode == true">
            <li
              class="list-group-item m-3 v-step-5"
              v-for="(item, index) in this.newCamp.discounts"
              :key="'campDiscount' + index"
            >
              <div
                class="row"
                style="
                  border: 3px solid #45487a;
                  border-radius: 15px;
                  max-width: 558px;
                "
              >
                <label class="form-label mt-3" for="form6Example3"
                  ><strong>Discount {{ index + 1 }})</strong></label
                >
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Discount Code</label
                  >
                  <input
                    v-model="item.discountCode"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="text"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Discount Price</label
                  >
                  <input
                    v-model="item.discountPrice"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="number"
                  />
                </div>
                <i
                  class="fa-solid fa-trash removeDay"
                  @click="removeDiscount(index)"
                ></i>
              </div>
            </li>
            <div>
              <center>
                <button
                  class="btn btn-primary btn-block mb-4 rounded-pill"
                  @click="addDiscount"
                  style="margin-top: 10px"
                >
                  Add Discount
                </button>
              </center>
            </div>

            <!--            <label class="form-label">Discount Code</label>-->
            <!--            <input-->
            <!--              type="text"-->
            <!--              id="form6Example4"-->
            <!--              class="form-control"-->
            <!--              placeholder="e.g. FREECAMPS2023"-->
            <!--              v-model="newCamp.discountCode"-->
            <!--            />-->

            <!--            <label class="form-label">Discount Price (In USD)</label>-->
            <!--            <input-->
            <!--              type="number"-->
            <!--              id="form6Example6"-->
            <!--              class="form-control mb-3"-->
            <!--              placeholder="50"-->
            <!--              min="0"-->
            <!--              v-model="newCamp.discountPrice"-->
            <!--            />-->
          </div>

          <div
            class="form-check d-flex mb-3"
            v-if="!this.$route.query.scheduledSession"
          >
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="newCamp.addingMultipleCamperPrices"
            />
            <label class="form-check-label">
              Setup Bundling Discounts - for Multiple Registrants
            </label>
          </div>
          <!--Start of bundle prices-->
          <div v-if="newCamp.addingMultipleCamperPrices">
            <li
              class="list-group-item m-3 v-step-5"
              v-for="(priceObject, index) in this.newCamp.multipleCamperPrices"
              :key="'campDate' + index"
            >
              <div
                class="row"
                style="
                  border: 3px solid #45487a;
                  border-radius: 15px;
                  max-width: 558px;
                "
              >
                <!--              <label class="form-label mt-3" for="form6Example3"-->
                <!--                >{{ index + 1 }} Extra Registrant</label-->
                <!--              >-->
                <div class="col" style="margin-top: 5px">
                  <label
                    class="form-label"
                    for="form6Example4"
                    v-if="index === newCamp.multipleCamperPrices.length - 1"
                  >
                    Additional Registrants {{ index + 1 }}+:
                  </label>
                  <label class="form-label" for="form6Example3" v-else>
                    Additional Registrant {{ index + 1 }}:
                  </label>
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Additional Price
                  </label>
                  <label
                    class="form-label"
                    for="form6Example4"
                    style="margin-right: 30px"
                    v-if="index === newCamp.multipleCamperPrices.length - 1"
                  >
                    (Per Registrant)
                  </label>
                  <input
                    v-model="priceObject.camperPrice"
                    required
                    id="form6Example1"
                    class="form-control mb-3"
                    type="number"
                  />
                </div>
                <i
                  class="fa-solid fa-trash removeDay"
                  @click="removeCamperPrice(index)"
                ></i>
              </div>
            </li>
            <div>
              <center>
                <button
                  class="btn btn-primary btn-block mb-4 rounded-pill"
                  @click="addCamperPrice"
                  style="margin-top: 10px"
                >
                  Add Registrant Discount
                </button>
              </center>
            </div>
          </div>

          <div class="form-check d-flex mb-4">
            <input
              class="form-check-input me-2 create-camp-step-9"
              type="checkbox"
              value=""
              v-model="hasPricingAddOns"
            />
            <label class="form-check-label">
              Add Pricing Add-Ons
              <i
                class="fa-solid fa-circle-info"
                style="color: #45487a; cursor: pointer"
                @click="showPricingAddOnsExplanation"
              ></i>
            </label>
          </div>

          <DynamicPricing
            v-if="hasPricingAddOns"
            @back="onBackDynamic"
            :addOns="this.newCamp.campCustomAddOns"
            :editable="getEditable"
            :startNewDynamicPricingIndex="startNewDynamicPricingIndex"
            ref="custom"
            class="mb-3"
            style="
              border: 3px solid #45487a;
              border-radius: 15px;
              max-width: 558px;
            "
          ></DynamicPricing>

          <div class="form-check d-flex mb-4">
            <input
              class="form-check-input me-2 create-camp-step-9"
              type="checkbox"
              value=""
              v-model="newCamp.showPlatformFees"
            />
            <label class="form-check-label">
              Show Platform Fees at Checkout
              <i
                class="fa-solid fa-circle-info"
                style="color: #45487a; cursor: pointer"
                @click="showPlatformFeesExplanation"
              ></i>
            </label>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-6">
              <button
                class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] hover:from-[#16b910] hover:to-[#16b910] transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                @click="toggleCustomizationForm"
                style="margin-top: 10px"
              >
                <span
                  class="relative z-10 flex items-center justify-center gap-2 text-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </span>
                <span
                  class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                ></span>
              </button>
            </div>
            <div class="col-md-9 col-sm-6">
              <button
                v-if="!editMode"
                class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                @click="createCamp()"
                style="margin-top: 10px"
              >
                <span
                  class="relative z-10 flex items-center justify-center gap-2 text-lg"
                >
                  {{
                    this.$route.query.scheduledSession
                      ? 'Create Training Session'
                      : 'Create Camp/Clinic'
                  }}
                </span>
                <span
                  class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                ></span>
                <span
                  class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                ></span>
                <span
                  class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
                ></span>
              </button>
              <button
                v-else
                class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
                @click="createCamp()"
                style="margin-top: 10px"
              >
                <span
                  class="relative z-10 flex items-center justify-center gap-2 text-lg"
                >
                  Save changes
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                ></span>
                <span
                  class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
                ></span>
                <span
                  class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import ClientLayout from '../components/ClientLayout.vue'
import CustomFields from '../components/CustomFields.vue'
import DynamicPricing from '../components/DynamicPricing.vue'
import SAIButton from '../components/Buttons/SA-IButton.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import moment from 'moment'

export default {
  components: {
    DynamicPricing,
    CustomFields,
    ClientLayout,
    VueGoogleAutocomplete,
    SAIButton,
    Multiselect,
  },
  name: 'create-camp-tour',
  data() {
    return {
      campEasyForm: false,
      clickedConfirm: false,
      hasPricingAddOns: false,
      editMode: false,
      gif: false,
      showCampDetails: true,
      showFormCustomization: false,
      showPaymentDetails: false,
      requireEmergency: false,
      showCustomFields: false,
      dynamicToggle: false,
      setDatesAndTimes: true,
      setSessionScheduler: false,
      setCustomEmailReceipt: false,
      creatingDiscountCode: false,
      savedData: {},
      user: {},
      chosenLogoFile: null,
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false,
      },
      availabilitySetting: {
        startTime: '',
        endTime: '',
        repeating: false,
        repeatingSetting: '',
        date: '',
        calendarID: '',
      },
      emergencyContactInfo: { emergencyBool: true },
      addressVerified: false,
      startNewCustomFieldsIndex: 0,
      startNewDynamicPricingIndex: 0,
      newCamp: {
        campCustomFields: [],
        campCustomAddOns: [],
        campName: '',
        campDescription: '',
        campLocation: '',
        campLogo: '',
        campPrice: null,
        multipleCamperPrices: [{ camperPrice: null }],
        addingMultipleCamperPrices: false,
        lateRegistrationPrice: null,
        campDates: [{ date: '', start: '', end: '' }],
        campRegistrationDeadline: '',
        campMaxParticipants: '',
        ageRange: [
          { name: 'Elementary School', code: 'elementary' },
          { name: 'Middle School', code: 'middle' },
          { name: 'High School', code: 'high' },
        ],
        gender: [
          { name: 'Male', code: 'male' },
          { name: 'Female', code: 'female' },
        ],
        acceptLateRegistration: false,
        requireWaiver: false,
        tShirtsProvided: false,
        showPlatformFees: false,
        customWaiver: '',
        customEmailReceipt: '',

        discounts: [
          {
            discountCode: '',
            discountPrice: null,
          },
        ],
        revenueGoal: null,
        sessionInfo: {
          sessionDuration: null,
          maxAdvanceNotice: null,
          minAdvanceNotice: null,
          maxSessionParticipants: null,
        },
        advancedCreation: false,
      },

      steps: [
        {
          target: '.v-step-0', // We're using document.querySelector() under the hood
          content: `To start, you can upload your own logo that will show up on the camp/clinic. If you do not have a logo you want to attach, you can skip this.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-1', // We're using document.querySelector() under the hood
          content: `This field shows the organization that this camp/clinic is affiliated with.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-2', // We're using document.querySelector() under the hood
          content: `This is the name of the camp/clinic.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-3', // We're using document.querySelector() under the hood
          content: `This is the location of where you will be meeting. This doesn't need to be a full address, but it can be.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-4', // We're using document.querySelector() under the hood
          content: `This is the description of what the camp/clinic is. Here you can give a description of what your customers are buying and what it will entail.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-5', // We're using document.querySelector() under the hood
          content: `This is the dates and times.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-6', // We're using document.querySelector() under the hood
          content: `This is the maximum number of purchases before the link will shut off. If you do not want a set number, just leave this blank.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-7', // We're using document.querySelector() under the hood
          content: `This is the registration deadline. This date has to be before the first day.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-8', // We're using document.querySelector() under the hood
          content: `If you do not want the link to shut off after the registration deadline occurs, select this box.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.v-step-9', // We're using document.querySelector() under the hood
          content: `After filling out all of the details about the camp/clinic, start customizing the information that you want to collect when a customer pays.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
      ],
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    toggleEasyForm() {
      this.campEasyForm = false
    },
    createEasy() {
      console.log('CREATED EASY')
      this.clickedConfirm = true
    },
    goToEasyCreate() {
      swal({
        title: 'Are you sure?',
        text: 'This will take you to the easy camp creation page and some of your current progress may be lost.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          window.scrollTo(0, 0)
          this.campEasyForm = true
        }
      })
      return
    },
    getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData)
      console.log(id)
      console.log(addressData)
      this.newCamp.campLocation = placeResultData.formatted_address
      this.addressVerified = true
    },
    setAddressData(e) {
      if (!this.newCamp.campLocation || this.editMode) {
        console.log(e)
        this.newCamp.campLocation = e.toString()
      }
    },
    uploadImage(event) {
      if (this.user.subscription.subscriptionTier < 2) {
        swal({
          icon: 'info',
          title: 'Upgrade for Higher Success!',
          text: 'To start branding your events, please upgrade to a Pro or higher subscription today!',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }

      this.gif = true
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadFile'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          this.newCamp.campLogo = response.data
          this.gif = false
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },
    customFields() {
      this.showCustomFields = true
    },
    dynamicPricing() {
      this.dynamicToggle = true
    },
    async presetFields() {
      let preset = this.$route.params.id
      if (preset) {
        await this.getCampInfo(preset)
      } else {
        console.log('normal')
      }
      if (this.$route.params.mode === 'edit') {
        this.editMode = true
      }
    },
    async getCampInfo(preset) {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/camps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]._id == preset) {
              console.log(response.data[i])
              if (response.data[i].multipleCamperPrices.length > 0) {
                response.data[i].addingMultipleCamperPrices = true
              }

              this.newCamp = response.data[i]
              console.log(response.data[i].multipleCamperPrices)

              if (response.data[i].multipleCamperPrices.length > 0) {
                this.newCamp.addingMultipleCamperPrices = true
              }

              if (response.data[i].campDates.length > 0) {
                this.setDatesAndTimes = true
              } else {
                if (response.data[i].sessionSupported) {
                  this.setSessionScheduler = true
                }
              }

              if (this.newCamp.customFields.length > 0) {
                this.newCamp.campCustomFields = this.newCamp.customFields
                this.startNewCustomFieldsIndex =
                  response.data[i].customFields.length
              }

              if (
                this.newCamp.customEmailReceipt &&
                this.newCamp.customEmailReceipt.length > 0
              ) {
                this.setCustomEmailReceipt = true
              }

              if (response.data[i].customAddOns.length > 0) {
                console.log(response.data[i].customAddOns)
                this.newCamp.campCustomAddOns = response.data[
                  i
                ].customAddOns.map(addOn => ({ ...addOn }))
                this.startNewDynamicPricingIndex =
                  response.data[i].customAddOns.length
              }

              if (response.data[i].discounts?.length > 0) {
                this.creatingDiscountCode = true
              }

              if (response.data[i].customAddOns?.length > 0) {
                this.hasPricingAddOns = true
              }

              if (response.data[i].customWaiver) {
                this.newCamp.requireWaiver = true
              }

              let campDates = []
              for (let date of response.data[i].campDates) {
                //FORMATTING DATES FROM DB UTC TO UI LOCAL TIME
                let startDate = new Date(date.start)
                let endDate = new Date(date.end)
                let startDateHours = startDate.getHours().toString()
                let startDateMinutes = startDate.getMinutes().toString()
                let endDateHours = endDate.getHours().toString()
                let endDateMinutes = endDate.getMinutes().toString()
                let startDateOnly = startDate
                  .toLocaleDateString()
                  .replaceAll('/', '-')
                  .split('-')
                if (startDateOnly[0].length === 1) {
                  startDateOnly[0] = '0' + startDateOnly[0]
                }
                if (startDateOnly[1].length === 1) {
                  startDateOnly[1] = '0' + startDateOnly[1]
                }
                if (startDateHours.length === 1) {
                  startDateHours = '0' + startDateHours
                }
                if (startDateMinutes.length === 1) {
                  startDateMinutes = '0' + startDate.getMinutes().toString()
                }
                if (endDateHours.length === 1) {
                  endDateHours = '0' + endDateHours
                }
                if (endDateMinutes.length === 1) {
                  endDateMinutes = '0' + endDate.getMinutes().toString()
                }
                startDateOnly = startDateOnly[2]
                  .concat('-')
                  .concat(startDateOnly[0])
                  .concat('-')
                  .concat(startDateOnly[1])

                campDates.push({
                  date: startDateOnly,
                  start: startDateHours + ':' + startDateMinutes,
                  end: endDateHours + ':' + endDateMinutes,
                })
                //END OF DATE FORMATTING
              }
              this.newCamp.campDates = campDates
              this.newCamp.campRegistrationDeadline =
                response.data[i].campRegistrationDeadline.split('T')[0]

              this.newCamp.campCustomFields = []
              this.newCamp.campCustomFields = this.newCamp.customFields
            }
          }
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
        })
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    onBack(x) {
      // this.showCustomFields = false;
      this.newCamp.campCustomFields = [...x]
    },
    onBackDynamic(x) {
      this.dynamicToggle = false
      this.newCamp.campCustomAddOns = [...x]
    },
    addDay() {
      const lastDate = this.newCamp.campDates[this.newCamp.campDates.length - 1]
      const nextDate = new Date(lastDate.date)
      nextDate.setDate(nextDate.getDate() + 1)
      const nextDateStr = nextDate.toISOString().split('T')[0]
      this.newCamp.campDates.push({
        date: nextDateStr,
        start: lastDate.start,
        end: lastDate.end,
      })
    },
    addDiscount() {
      this.newCamp.discounts.push({ discountCode: '', discountPrice: null })
    },
    addCamperPrice() {
      this.newCamp.multipleCamperPrices.push({ camperPrice: null })
    },
    logUserOut() {
      localStorage.removeItem('jwt')
      this.$router.push('/')
    },
    validateInfo() {
      console.log('HIT')
      if (this.newCamp.campName == '') {
        swal('Error', 'Name must be filled out.', 'error')
        return 1
      }
      if (this.newCamp.campLocation == '') {
        swal('Error', 'Location must be filled out.', 'error')
        return 1
      }

      if (this.newCamp.campPrice == null) {
        swal('Error', 'Price must be filled out.', 'error')
        return 1
      }
      if (this.newCamp.campDescription == '') {
        swal('Error', 'Description must be filled out.', 'error')
        return 1
      }
      if (
        this.newCamp.campPrice == 0 &&
        this.user.subscription.subscriptionTier < 3
      ) {
        swal(
          'Upgrade for Success!',
          'To start creating free events, please upgrade to an All Star or higher subscription today!',
          'info'
        )
        document.getElementById('openAdvertisementPopUp').click()
        return 1
      }

      for (let priceIndex in this.newCamp.multipleCamperPrices) {
        if (
          (!this.newCamp.multipleCamperPrices[priceIndex].camperPrice ||
            this.newCamp.multipleCamperPrices[priceIndex].camperPrice ===
              '0') &&
          this.newCamp.addingMultipleCamperPrices
        ) {
          swal('Error', 'Bundling Prices cannot be 0', 'error')
          return 1
        }
        if (priceIndex == 0) {
          if (
            parseFloat(
              this.newCamp.multipleCamperPrices[priceIndex].camperPrice
            ) >= parseFloat(this.newCamp.campPrice) &&
            this.newCamp.addingMultipleCamperPrices
          ) {
            swal(
              'Error',
              'Bundling Prices cannot be greater/equal to the price of the camp',
              'error'
            )
            return 1
          }
        } else {
          console.log(
            this.newCamp.multipleCamperPrices[parseInt(priceIndex) - 1]
              .camperPrice
          )
          if (
            parseFloat(
              this.newCamp.multipleCamperPrices[priceIndex].camperPrice
            ) >=
            parseFloat(
              this.newCamp.multipleCamperPrices[parseInt(priceIndex) - 1]
                .camperPrice
            )
          ) {
            console.log(
              this.newCamp.multipleCamperPrices[priceIndex].camperPrice +
                ' > ' +
                this.newCamp.multipleCamperPrices[parseInt(priceIndex) - 1]
                  .camperPrice
            )
            swal(
              'Error',
              'Bundling Prices cannot be greater/equal to the price before it',
              'error'
            )
            return 1
          }
        }
      }

      return 0
    },
    validateDays() {
      if (this.newCamp.campDates[0]) {
        console.log('DAYS HIT')

        let today = new Date()
        let tempCalc = new Date()
        let tempReg = new Date(
          this.newCamp.campRegistrationDeadline + 'T23:59:59'
        )
        if (today.getHours() != 0) {
          today.setHours(tempCalc.getHours() - 1)
        } else if (today.getMinutes() >= 6) {
          today.setMinutes(tempCalc.getMinutes - 5)
        }
        if (today > tempReg) {
          swal(
            'Error',
            'Registration Deadline must be in future ',
            'error'
          ).then(fillOutCampDetails => {
            if (fillOutCampDetails) {
              this.toggleCampDetails()
            }
          })
          return 1
        }
        for (const day of this.newCamp.campDates) {
          if (day.start > day.end) {
            let temp1 = day.date + 1
            swal(
              'Error',
              'Start time must be before the end time for day ' + temp1,
              'error'
            ).then(fillOutCampDetails => {
              if (fillOutCampDetails) {
                this.toggleCampDetails()
              }
            })
            return 1
          }
          let tempStart = new Date(day.date + 'T' + day.start)
          let tempEnd = new Date(day.date + 'T' + day.end)
          if (today > tempStart) {
            swal('Error', 'All dates must be in future ', 'error')
            return 1
          }
          if (today > tempEnd) {
            swal('Error', 'All dates must be in future ', 'error').then(
              fillOutCampDetails => {
                if (fillOutCampDetails) {
                  this.toggleCampDetails()
                }
              }
            )
            return 1
          }
          console.log(tempStart)
          console.log(this.newCamp.campRegistrationDeadline)
          if (tempReg > tempStart) {
            console.log('HIT')
            swal(
              'Error',
              'Registration deadline must be not after dates of camp ',
              'error'
            ).then(fillOutCampDetails => {
              if (fillOutCampDetails) {
                this.toggleCampDetails()
              }
            })
            return 1
          }
          if (today > tempReg) {
            swal(
              'Error',
              'Registration deadline must be in future',
              'error'
            ).then(fillOutCampDetails => {
              if (fillOutCampDetails) {
                this.toggleCampDetails()
              }
            })
            return 1
          }
        }
        return 0
      }
      return 0
    },
    generateDescription() {
      swal('Generating...', 'Running AI analysis on your event...', 'info')
      let url =
        import.meta.env.VITE_APP_DB_URL +
        `ai/generateCampDescription?campName=${this.newCamp.campName}&campLocation=${this.newCamp.campLocation}&campPrice=${this.newCamp.campPrice}&campDescription=${this.newCamp.campDescription}&userID=${this.user._id}`
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response.data)
          this.newCamp.campDescription = response.data.generatedDescription
          swal(
            'Generated!',
            'SA-I Description Generation Successful!',
            'success'
          )
        })
    },
    generateWaiver() {
      swal('Generating...', 'Running AI analysis on your event...', 'info')
      let url =
        import.meta.env.VITE_APP_DB_URL +
        `ai/generateCampWaiver?campName=${this.newCamp.campName}&campLocation=${this.newCamp.campLocation}&campDescription=${this.newCamp.campDescription}&userID=${this.user._id}`
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response.data)
          this.newCamp.customWaiver = response.data.generatedWaiver
          swal(
            'Generated!',
            'SA-I Description Generation Successful!',
            'success'
          )
        })
    },
    toggleCampDetails() {
      this.showCampDetails = true
      this.showFormCustomization = false
      this.showCustomFields = false
      this.showPaymentDetails = false
      window.scrollTo(0, 0)
    },
    toggleCustomizationForm() {
      this.showCampDetails = false
      this.showPaymentDetails = false
      this.showFormCustomization = true
      this.showCustomFields = true
      window.scrollTo(0, 0)
    },
    togglePaymentDetails() {
      this.showPaymentDetails = true
      this.showCampDetails = false
      this.showFormCustomization = false
      this.showCustomFields = false
      window.scrollTo(0, 0)
    },
    showDefaultFormExplanation() {
      swal({
        title: 'Default Form Explanation',
        text: 'This is a preview of what information the default signup form collects. This portion is not to be filled out, only to see what fields we already ask for, and you can specify your additional fields below accordingly, if any.',
        icon: 'info',
      })
    },

    showSessionDurationExplanation() {
      swal({
        title: 'Session Duration Explanation',
        text: 'This is the length of time, in minutes, that one of your scheduled sessions will last.',
        icon: 'info',
      })
    },

    showSessionMaxAdvanceNoticeExplanation() {
      swal({
        title: 'Session Max Advance Notice Explanation',
        text: 'This is the maximum number, in days, that a customer can schedule a session with you in advance.',
        icon: 'info',
      })
    },

    showSessionMinAdvanceNoticeExplanation() {
      swal({
        title: 'Session Min Advance Notice Explanation',
        text: 'This is the minimum number, in days, that a customer needs to provide to be able to schedule a session with you.',
        icon: 'info',
      })
    },

    showSessionMaxParticipantsExplanation() {
      swal({
        title: 'Session Max Participants Explanation',
        text: 'This is the maximum number of customers that can book the same timeslot on this session. Single sessions would have 1 customer, group sessions could have 20 customers, etc.',
        icon: 'info',
      })
    },

    showDateVersusSessionInfoOnSessionClick() {
      if (this.setDatesAndTimes) {
        swal({
          title: 'You cannot have dates and sessions',
          text: 'Event Dates and Session Information cannot both be set. Session information will be associated with your calendar availability in the menu bar. Event dates are for one time events, and are set here.\n\n Whatever checkbox is selected will be the information that is saved.',
          icon: 'info',
        })
      }
      this.setDatesAndTimes = false
    },

    showDateVersusSessionInfoOnDateClick() {
      this.setDatesAndTimes = true
    },

    async createCamp() {
      this.savedData = this.newCamp

      if (!this.campDetailsComplete()) {
        swal({
          title: 'Details Incomplete',
          text: 'The details page is incomplete. Please fill out all fields and then return to create your camp/clinic.',
          icon: 'error',
          buttons: true,
          dangerMode: true,
        }).then(fillOutCampDetails => {
          if (fillOutCampDetails) {
            this.toggleCampDetails()
          }
        })
        return
      }

      if (!this.checkDateAndTime()) {
        this.toggleCampDetails()
        return
      }
      let infoValidated = this.validateInfo()
      console.log(infoValidated)
      let daysValidated = this.validateDays()
      console.log(daysValidated)
      if (this.validateInfo() == 1) {
        return
      }
      if (this.validateDays() == 1) {
        return
      }

      for (let fieldIndex in this.newCamp.campCustomFields) {
        if (
          this.newCamp.campCustomFields[fieldIndex].fieldType === 'dropdown'
        ) {
          if (
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[0] === '' &&
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[1] === '' &&
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[2] === '' &&
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[3] === '' &&
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[4] === '' &&
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[5] === '' &&
            this.newCamp.campCustomFields[fieldIndex].fieldOptions[6] === ''
          ) {
            this.newCamp.campCustomFields.splice(fieldIndex, 1)
          }
        }
      }

      swal({
        title: 'Confirm Details',
        text: 'Once the camp/clinic has had a purchase, no changes are allowed.  Until then, everything that was filled out can be updated.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          this.clickedConfirm = true
          let url = this.editMode
            ? import.meta.env.VITE_APP_DB_URL + 'individualCamp/editCamp'
            : import.meta.env.VITE_APP_DB_URL + 'individualCamp/createCamp'
          if (!this.newCamp.addingMultipleCamperPrices) {
            this.newCamp.multipleCamperPrices = []
          }
          axios
            .post(
              url,
              {
                id: this.user._id,
                campCustomFields: this.newCamp.campCustomFields,
                campCustomAddOns: this.newCamp.campCustomAddOns,
                campName: this.newCamp.campName,
                campDescription: this.newCamp.campDescription,
                campLocation: this.newCamp.campLocation,
                campLogo: this.newCamp.campLogo,
                campPrice: this.newCamp.campPrice,
                campDates: this.newCamp.campDates.map(day => {
                  // Remove the day property and format start and end as full dateTimes
                  return {
                    start: new Date(day.date + 'T' + day.start),
                    end: new Date(day.date + 'T' + day.end),
                  }
                }),
                campRegistrationDeadline: this.newCamp.campRegistrationDeadline,
                campMaxParticipants: this.newCamp.campMaxParticipants,
                ageRange: this.newCamp.ageRange,
                gender: this.newCamp.gender,
                acceptLateRegistration: this.newCamp.acceptLateRegistration,
                requireWaiver: this.newCamp.requireWaiver,
                tShirtsProvided: this.newCamp.tShirtsProvided,
                hidePlatformFees: !this.newCamp.showPlatformFees,
                customWaiver: this.newCamp.customWaiver,
                customEmailReceipt: this.newCamp.customEmailReceipt,
                discounts: this.newCamp.discounts,
                revenueGoal: this.newCamp.revenueGoal,
                campStripeId: this.newCamp.campStripeId,
                multipleCamperPrices: this.newCamp.multipleCamperPrices,
                sessionSupported: this.$route.query.scheduledSession
                  ? true
                  : this.newCamp.sessionSupported,
                advancedCreation: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              this.camps = response.data
              if (this.editMode) {
                swal('Success', 'Event Edited!', 'success')
                this.$router.push(
                  '/Dashboard/ViewCamp/Individual/' + this.newCamp._id
                )
              } else {
                swal('Success', 'Event Created!', 'success')
                this.$router.push('/Dashboard')
              }
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
              this.newCamp = this.savedData
            })
        }
      })
    },
    removeDay(index) {
      if (this.newCamp.campDates.length > 0) {
        this.newCamp.campDates.splice(index, 1)
      }
    },
    removeDiscount(index) {
      if (this.newCamp.discounts.length > 0) {
        this.newCamp.discounts.splice(index, 1)
      }
    },
    removeCamperPrice(index) {
      if (this.newCamp.multipleCamperPrices.length > 1) {
        this.newCamp.multipleCamperPrices.splice(index, 1)
      }
    },
    campDetailsComplete() {
      if (this.newCamp.campName == '') {
        console.log('NAME IS EMPTY')

        return false
      }
      if (this.newCamp.campLocation == '') {
        console.log('LOCATION IS EMPTY')

        return false
      }
      if (this.newCamp.campDescription == '') {
        console.log('DESCRIPTION IS EMPTY')

        return false
      }

      return true
    },

    checkDateAndTime() {
      if (
        this.$route.query.date &&
        this.$route.query.hasScheduledSessions === 'false'
      ) {
        const { campDates } = this.newCamp
        if (
          campDates.length === 0 ||
          !campDates[0].start ||
          !campDates[0].end ||
          !campDates[0].date
        ) {
          swal({
            title: 'Date and Time Incomplete',
            text: 'Please set both the date and time of your scheduled event',
            icon: 'error',
          })

          return false
        }
      }
      return true
    },

    showPricingAddOnsExplanation() {
      swal({
        title: 'Pricing Add-ons',
        text: 'Sell additional items at checkout, that are optional to purchase. These can be shoes, hoodies, served lunches, etc.',
        icon: 'info',
      })
    },
    showPlatformFeesExplanation() {
      swal({
        title: 'Show Platform Fees',
        text: 'Select this option if you would like to display the breakdown of all fees at checkout (Platform fee, credit card fee, etc). This option is not recommended.',
        icon: 'info',
      })
    },
    showCustomizeEmailReceiptExplanation() {
      swal({
        title: 'Customize Email Receipt',
        text: 'This text will be added to the default email receipt for this event. Use this option to provide custom information to your registrants upon signup.',
        icon: 'info',
      })
    },
  },
  created() {
    this.getUserDetails()
    this.presetFields()
    if (this.$route.query.date) {
      this.campEasyForm = false
      this.setDatesAndTimes = true
      this.newCamp.campDates[0].date = moment(this.$route.query.date).format(
        'YYYY-MM-DD'
      )
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.clickedConfirm) {
      swal({
        title: 'Leave without saving?',
        text: 'Do you want to leave without saving? If you create the camp/clinic, you will be able to edit it later and not loose your progress.',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: 'Leave without saving',
        },
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
  mounted() {
    if (localStorage.getItem('startTour')) {
      console.log('starting')
      this.$tours['myTour'].start()
    }
  },
  computed: {
    getAgeRangeOptions() {
      return [
        { name: 'Elementary School', code: 'elementary' },
        { name: 'Middle School', code: 'middle' },
        { name: 'High School', code: 'high' },
      ]
    },
    getGenderOptions() {
      return [
        { name: 'Male', code: 'male' },
        { name: 'Female', code: 'female' },
      ]
    },
    getEditable() {
      return (
        (this.editMode && this.newCamp.campers?.length === 0) || !this.editMode
      )
    },
  },
}
</script>
<style>
.editor-datetime {
  position: relative;
}

/* Multiselect styling */

.multiselect__tag {
  background: #19d412 !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #19d412 !important;
}

.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}

.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.removeDay {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeDay:hover {
  color: darkred;
  cursor: pointer;
}

.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

.nav {
  margin-top: 40px;
}

.pull-right {
  float: right;
}

a,
a:active {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #777;
  cursor: pointer;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 1;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: '✔';
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #45487a;
}

.arrow-steps .step.incomplete {
  color: #fff;
  background-color: #ffcc00;
}

.arrow-steps .step.finished {
  color: #fff;
  background-color: #19d412;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #45487a;
}

.arrow-steps .step.incomplete:after {
  border-left: 17px solid #ffcc00;
}

.arrow-steps .step.finished:after {
  border-left: 17px solid #19d412;
}

/* Add a subtle texture to the buttons */
.create-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
}

/* Add ripple animation */
@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.create-button:active .animate-ripple-on-click {
  animation: ripple 0.8s ease-out;
}

/* Add shine effect */
.create-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
  transition: left 0.7s;
}

.create-button:hover::after {
  left: 100%;
}
</style>
