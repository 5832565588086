<template>
  <div
    class="relative bg-white rounded-3xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-[1.02]"
  >
    <!-- Card header -->
    <div
      class="bg-gradient-to-br from-gray-800 to-gray-600 px-6 py-8 text-center"
    >
      <div class="mb-4">
        <div
          class="w-16 h-16 mx-auto rounded-full bg-white/20 flex items-center justify-center"
        >
          <i class="fa-solid fa-trophy text-2xl text-yellow-400"></i>
        </div>
      </div>
      <h3 class="text-2xl font-bold text-white mb-2">
        {{ subscription.subscriptionName }}
      </h3>
      <p class="text-white/80">
        {{ yearly ? 'Billed Annually' : 'Billed Monthly' }}
      </p>
    </div>

    <!-- Pricing section -->
    <div class="px-6 py-8">
      <div class="text-center mb-8">
        <div v-if="yearly" class="text-gray-400 line-through mb-2">
          ${{ getFakeYearly }}
        </div>
        <div class="flex items-center justify-center">
          <span class="text-3xl font-bold text-gray-800">$</span>
          <span class="text-5xl font-bold text-gray-800">
            {{ yearly ? getYearly.split('.')[0] : getMonthly.split('.')[0] }}
          </span>
          <span class="text-2xl font-bold text-gray-800">
            .{{ yearly ? getYearly.split('.')[1] : getMonthly.split('.')[1] }}
          </span>
          <span class="text-gray-500 ml-2 text-lg">
            / {{ yearly ? 'year' : 'month' }}
          </span>
        </div>
        <div v-if="yearly" class="mt-4">
          <span
            class="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 text-sm font-medium"
          >
            <i class="fas fa-tag mr-1"></i>
            Save up to 30%
          </span>
        </div>
      </div>

      <!-- CTA Button -->
      <button
        class="w-full py-4 px-6 rounded-xl font-bold text-white bg-gradient-to-r from-gray-700 to-gray-900 hover:from-gray-800 hover:to-black transition-all duration-300 shadow-md hover:shadow-lg"
        @click.stop="buySubscription(subscription)"
      >
        Get Started
        <i class="fa-solid fa-arrow-right ml-2"></i>
      </button>

      <!-- Features -->
      <div class="mt-8">
        <p class="text-center text-gray-600 mb-6">
          Everything in All Star, Plus...
        </p>
        <ul class="space-y-4">
          <li
            v-for="(feature, index) in features"
            :key="index"
            class="group relative flex items-start"
          >
            <i class="fa-solid fa-check-circle text-green-500 mt-1 mr-3"></i>
            <div>
              <span class="text-gray-800">{{ feature.title }}</span>
              <div
                v-if="feature.description"
                class="hidden group-hover:block absolute left-0 mt-2 w-64 p-4 bg-gray-900 text-white text-sm rounded-lg shadow-xl z-10"
              >
                {{ feature.description }}
                <div
                  class="absolute -top-2 left-4 w-4 h-4 bg-gray-900 transform rotate-45"
                ></div>
              </div>
            </div>
          </li>
          <li class="flex items-start">
            <i
              class="fa-solid fa-check-circle text-green-500 mt-1 mr-3 text-xl"
            ></i>
            <span class="text-gray-800">
              Customer Checkout Fee
              <span class="font-bold text-indigo-600">
                {{ subscription.customerUpchargePercentage.toFixed(1) }}%
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import numeral from 'numeral'
export default {
  props: {
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    promo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: '',
      },
      features: [
        { title: 'Marketing and Strategic Planning' },
        { title: 'Liability Insurance Handling' },
        { title: 'Quarterly Business Reviews' },
        { title: 'Tailored Onboarding and Training Sessions' },
      ],
    }
  },
  methods: {
    buySubscription(plan) {
      this.$emit('buySubscription', plan)
    },
    getSubscription() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'subscription/getSubscriptionByName?name=Hall of Fame'
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then(response => {
          this.subscription = response.data.subscription
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format('0,0.00')
    },
    getYearly() {
      return numeral(this.subscription.yearlyPrice).format('0,0.00')
    },
    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format('0,0.00')
    },
  },
  mounted() {
    this.getSubscription()
  },
}
</script>

<style scoped>
.actionFeature {
  background-color: #363166;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.information-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
}

.btn-primary {
  background-color: #363166;
  border-color: #363166;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.list-unstyled li {
  display: flex;
  align-items: center;
}

.list-unstyled li i {
  margin-right: 8px;
}

.card-body {
  font-size: 20px;
  font-weight: bold;
  color: #363166;
}

.price {
  font-size: 24px;
  color: #363166;
}
.plan-details__item-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.plan-details__item-description {
  display: none;
  position: relative;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
  font-size: 14px;
  margin-top: 5px;
}

.has-description:hover .plan-details__item-description {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 1000;
  width: 250px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #333 transparent transparent transparent;
  left: 15px;
  top: -5px;
}

.plan-details__item li {
  margin-bottom: 15px;
}

.plan-details__item-title i {
  margin-right: 10px;
  color: #19d412;
}
</style>
