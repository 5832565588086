<template>
  <div class="card-form">
    <div class="card-form__inner">
      <!-- Image Skeleton -->
      <div class="w-full h-[200px] bg-gray-200 animate-pulse rounded-lg"></div>

      <!-- Title Skeleton -->
      <div class="mt-6 space-y-4">
        <div class="h-8 bg-gray-200 rounded animate-pulse w-1/2 mx-auto"></div>

        <!-- Form Fields Skeleton -->
        <div class="space-y-4 mt-4">
          <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
          <div class="h-10 bg-gray-200 rounded animate-pulse w-full"></div>
          <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
          <div class="h-10 bg-gray-200 rounded animate-pulse w-full"></div>
        </div>

        <!-- T-shirt Size Skeleton -->
        <div class="mt-6">
          <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
          <div class="h-10 bg-gray-200 rounded animate-pulse w-full mt-2"></div>
        </div>

        <!-- Add Ons Skeleton -->
        <div class="mt-6">
          <div class="h-4 bg-gray-200 rounded animate-pulse w-1/3"></div>
          <div class="space-y-2 mt-2">
            <div class="h-8 bg-gray-200 rounded animate-pulse w-full"></div>
            <div class="h-8 bg-gray-200 rounded animate-pulse w-full"></div>
          </div>
        </div>

        <!-- Emergency Contact Skeleton -->
        <div class="mt-6">
          <div class="h-8 bg-gray-200 rounded animate-pulse w-3/4"></div>
          <div class="space-y-4 mt-4">
            <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
            <div class="h-10 bg-gray-200 rounded animate-pulse w-full"></div>
            <div class="h-4 bg-gray-200 rounded animate-pulse w-1/4"></div>
            <div class="h-10 bg-gray-200 rounded animate-pulse w-full"></div>
          </div>
        </div>

        <!-- Payment Options Skeleton -->
        <div class="mt-6">
          <div
            class="h-8 bg-gray-200 rounded animate-pulse w-1/2 mx-auto"
          ></div>
          <div class="flex justify-center space-x-4 mt-4">
            <div class="h-12 w-24 bg-gray-200 rounded animate-pulse"></div>
            <div class="h-12 w-24 bg-gray-200 rounded animate-pulse"></div>
            <div class="h-12 w-24 bg-gray-200 rounded animate-pulse"></div>
          </div>
        </div>

        <!-- Navigation Buttons Skeleton -->
        <div class="mt-8 space-y-4">
          <div class="h-12 bg-gray-200 rounded animate-pulse w-full"></div>
          <div class="h-12 bg-gray-200 rounded animate-pulse w-full"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSkeleton',
}
</script>

<style scoped>
.card-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.card-form__inner {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style> 