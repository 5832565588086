<template>
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section class="container justify-content-center">
      <div class="container mt-4 justify-content-center">
        <div class="row justify-content-center">
          <div class="col-md-5 col-sm-5">
            <div class="card-form" style="margin-bottom: 30px">
              <dashboard-account-balance
                :accountBalance="this.getAccountBalance"
              ></dashboard-account-balance>
            </div>
            <div class="card-form" style="margin-bottom: 50px">
              <dashboard-my-website
                :totalViews="this.getTotalViews"
                :websiteName="this.getWebsiteName"
                :monthlyViews="this.getMonthlyViews"
                :websiteUrl="this.getWebsiteUrl"
              ></dashboard-my-website>
            </div>
          </div>
          <div class="col-md-7 col-sm-7 justify-content-center">
            <div class="card-form" style="margin-bottom: 30px">
              <dashboard-account-overview
                :subscriptionName="this.getSubscriptionName"
                :oneTimePaymentLinks="this.getOneTimePaymentLinks"
                :oneTimeCustomers="this.getOneTimeCustomers"
                :recurringPaymentLinks="this.getRecurringPaymentLinks"
                :activeRecurringCustomers="this.getActiveRecurringCustomers"
              ></dashboard-account-overview>
            </div>
            <div class="card-form v-step-0" style="margin-bottom: 15px">
              <div>
                <DashboardRecentTransactions
                  :transactions="this.getTransactions"
                ></DashboardRecentTransactions>
                <br />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-3"></div>
          <div class="col-md-4 col-sm-4 justify-content-center"></div>
          <!-- <div class="col-md-5 col-sm-5 justify-content-center">
            <div class="card-form calendar-card" style="margin-bottom: 15px">
              <div
                class="card-form__inner"
                v-bind:style="{
                  width: isMobile() ? '99%' : '',
                  marginLeft: isMobile() ? '21px' : '',
                  textAlign: isMobile() ? 'center' : '',
                }"
              >
                <div>
                  <DashboardCalendar></DashboardCalendar>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert'
import ClientLayout from '../components/ClientLayout.vue'
import DashboardAccountBalance from '../components/DashboardCards/DashboardAccountBalance.vue'
import DashboardAccountOverview from '../components/DashboardCards/DashboardAccountOverview.vue'
import DashboardMyWebsite from '../components/DashboardCards/DashboardMyWebsite.vue'
import DashboardRecentTransactions from '../components/DashboardCards/DashboardRecentTransactions.vue'
//import DashboardCalendar from "../components/DashboardCards/DashboardCalendar.vue";

export default {
  components: {
    ClientLayout,
    DashboardAccountBalance,
    DashboardAccountOverview,
    DashboardMyWebsite,
    DashboardRecentTransactions,
    //DashboardCalendar,
  },
  data() {
    return {
      user: {},
      data: {
        transactions: [],
      },
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    showSubscriptionChoices() {
      console.log(this.user.subscription)
      this.$router.push(
        '/SubscriptionChoices/' + this.user.subscription.subscriptionMongoID,
      )
    },
    addPaymentLogin() {
      window.addEventListener('message', event => {
        // Check the origin for security purposes
        if (event.origin !== import.meta.env.VITE_APP_CLIENT_URL) {
          //console.warn("Message from unknown origin", event.origin);
          return
        }
        console.log(event.data)

        // Assuming the message is of the type we expect
        if (event.data.type && event.data.type === 'authentication') {
          console.log('Received token:', event.data.token)
          // Process the token here (e.g., storing it, using it for API calls)
        }
      })
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    async getCoachDashboardData() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'user/getCoachDashboardData?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.data = response.data
          console.log(this.data)
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    viewTransactions() {
      this.$router.push('/Dashboard/Transactions')
    },
    viewExternalAccounts() {
      this.$router.push('/Dashboard/BankAccounts')
    },
    createFirstEvent() {
      this.$router.push('/Dashboard/VideoTour')
    },
  },

  created() {
    this.addPaymentLogin()
    this.getUserDetails()
    this.isMobile()
    this.getCoachDashboardData()
  },

  mounted() {
    if (
      this.user.subscription.subscriptionTier < 4 &&
      localStorage.getItem('advertisementShown') !== 'true'
    ) {
      document.getElementById('openAdvertisementPopUp')?.click()
      localStorage.setItem('advertisementShown', 'true')
    }
  },

  computed: {
    getSubscriptionTier() {
      let subscriptionTier = 0
      if (this.user.subscription) {
        subscriptionTier = this.user.subscription.subscriptionTier
      }
      return subscriptionTier
    },
    getSubscriptionName() {
      let subscriptionName = 'Rookie'
      if (this.user.subscription) {
        subscriptionName = this.user.subscription.subscriptionName
      }
      return subscriptionName
    },
    getAccountBalance() {
      return this.data.accountBalance
    },
    getOneTimePaymentLinks() {
      return this.data.oneTimePaymentLinks
    },
    getOneTimeCustomers() {
      return this.data.oneTimeCustomers
    },
    getRecurringPaymentLinks() {
      return this.data.recurringPaymentLinks
    },
    getActiveRecurringCustomers() {
      return this.data.activeRecurringCustomers
    },
    getWebsiteUrl() {
      return 'https://' + this.data.websiteSubdomain + '.summerathletes.com'
    },
    getTotalViews() {
      return this.data.websiteTotalViews
      //return this.data.websiteViews;
    },
    getMonthlyViews() {
      return this.data.websiteMonthlyViews
    },
    getWebsiteName() {
      return this.data.websiteTitle
    },
    getTransactions() {
      // return [
      //   {
      //     amount: 150.0,
      //     name: "Grocery Store",
      //     type: "expense",
      //   },
      //   {
      //     amount: -50.0,
      //     name: "ATM Withdrawal",
      //     type: "withdrawal",
      //   },
      //   {
      //     amount: 500.0,
      //     name: "Salary",
      //     type: "income",
      //   },
      //   {
      //     amount: -75.0,
      //     name: "Electric Bill",
      //     type: "expense",
      //   },
      //   {
      //     amount: 200.0,
      //     name: "Freelance Work",
      //     type: "income",
      //   },
      //   {
      //     amount: -20.0,
      //     name: "Coffee Shop",
      //     type: "expense",
      //   },
      //   {
      //     amount: 300.0,
      //     name: "Stock Dividend",
      //     type: "income",
      //   },
      //   {
      //     amount: -100.0,
      //     name: "Online Shopping",
      //     type: "expense",
      //   },
      // ];
      return this.data.transactions
    },
  },
}
</script>
<style scoped>
.link {
  color: #0d6efd;
  text-decoration: underline;
}

.link:hover {
  opacity: 0.8;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}

/* .calendar-card {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.calendar-card .card-form__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-card .card-form__inner > div {
  width: 100%;
  height: 100%;
  overflow: auto;
} */
</style>
