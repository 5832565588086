<template>
  <div>
    <NavBar></NavBar>
    <div class="mt-4 mb-4">
      <div class="w-5/6 mx-auto bg-white shadow-md rounded-lg px-4">
        <div class="flex flex-wrap">
          <!-- Loading Skeleton -->
          <template v-if="!paymentLink.name">
            <!-- Image Skeleton -->
            <div class="w-full md:w-1/2 flex flex items-start mt-4">
              <div
                class="w-full h-[400px] bg-gray-200 animate-pulse rounded-lg"
              ></div>
            </div>
            <!-- Content Skeleton -->
            <div class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-6">
              <div class="space-y-4">
                <div class="h-8 bg-gray-200 rounded animate-pulse w-3/4"></div>
                <div class="h-4 bg-gray-200 rounded animate-pulse w-full"></div>
                <div class="h-4 bg-gray-200 rounded animate-pulse w-5/6"></div>
                <div class="h-4 bg-gray-200 rounded animate-pulse w-4/6"></div>
                <div class="h-4 bg-gray-200 rounded animate-pulse w-3/4"></div>
                <div
                  class="h-10 bg-gray-200 rounded-full animate-pulse w-1/3"
                ></div>
              </div>
            </div>
          </template>
          <!-- Actual Content -->
          <template v-else>
            <!-- Image Section -->
            <div class="w-full md:w-1/2 flex flex items-start mt-4">
              <img
                :src="getImage() || '/landscape.svg'"
                alt="Product Image"
                class="w-full rounded-lg object-cover space-y-4"
              />
            </div>

            <!-- Details Section -->
            <div class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-6">
              <div class="space-y-2">
                <h2 class="text-2xl font-bold text-gray-800">
                  {{ paymentLink.name }}
                </h2>

                <p class="text-gray-600">
                  {{ paymentLink.description }}
                </p>
                <!-- Age Ranges -->
                <div
                  v-if="paymentLink.ageRange && paymentLink.ageRange.length > 0"
                  class="space-y-1"
                >
                  <p class="text-lg font-semibold text-gray-800">Age Ranges:</p>
                  <div class="flex flex-wrap gap-2">
                    <span
                      v-for="range in paymentLink.ageRange"
                      :key="range"
                      class="text-base font-medium text-gray-900 bg-gray-200 px-4 py-1 rounded-full"
                    >
                      {{ range.name }}
                    </span>
                  </div>
                </div>
                <!-- Genders -->
                <div
                  v-if="paymentLink.gender && paymentLink.gender.length > 0"
                  class="space-y-1"
                >
                  <p class="text-lg font-semibold text-gray-800">Genders:</p>
                  <div class="flex flex-wrap gap-2">
                    <span
                      v-for="gender in paymentLink.gender"
                      :key="gender"
                      class="text-base font-medium text-gray-900 bg-gray-200 px-4 py-1 rounded-full"
                    >
                      {{ gender.name }}
                    </span>
                  </div>
                </div>
                <p
                  v-if="this.paymentLink.location"
                  class="text-lg font-semibold text-gray-800"
                >
                  Location: {{ paymentLink.location }}
                </p>
                <div
                  v-if="
                    paymentLink.type === 'camp' &&
                    paymentLink.dates &&
                    paymentLink.dates.length > 0
                  "
                  class="space-y-2"
                >
                  <div
                    v-for="(date, index) in paymentLink.dates"
                    :key="index"
                    class="text-lg font-semibold text-gray-800"
                  >
                    {{
                      paymentLink.dates.length > 1 ? `Day ${index + 1}: ` : ''
                    }}
                    {{
                      new Date(date.start).toLocaleDateString(undefined, {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    }}
                    from
                    {{
                      new Date(date.start).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    }}
                    to
                    {{
                      new Date(date.end).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    }}
                  </div>
                </div>
                <p
                  v-if="this.paymentLink.type != 'membership'"
                  class="text-lg font-semibold text-gray-800"
                >
                  ${{ paymentLink.price }}
                </p>
                <div
                  v-if="paymentLink.type === 'membership'"
                  class="space-y-4 mt-6"
                >
                  <p class="text-2xl font-bold text-gray-800 mb-4">
                    Choose your pricing plan:
                  </p>
                  <div
                    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
                  >
                    <div
                      v-for="(price, key) in paymentLink.price"
                      :key="key"
                      class="relative"
                    >
                      <input
                        type="radio"
                        :id="key"
                        :value="key"
                        v-model="paymentPeriodSelected"
                        class="peer sr-only"
                      />
                      <label
                        :for="key"
                        class="flex flex-col p-4 border-2 rounded-lg cursor-pointer transition-all duration-200 hover:border-blue-500 peer-checked:border-blue-600 peer-checked:shadow-lg peer-checked:scale-105 h-[120px]"
                        :style="{
                          borderColor:
                            '#' + ($route.params.primaryColor || '19d412'),
                          '--tw-ring-color':
                            '#' + ($route.params.primaryColor || '19d412'),
                          backgroundColor:
                            paymentPeriodSelected === key
                              ? '#' +
                                ($route.params.primaryColor || '19d412') +
                                '20'
                              : 'white',
                          borderWidth:
                            paymentPeriodSelected === key ? '3px' : '2px',
                        }"
                      >
                        <div
                          class="flex flex-col items-center text-center h-full justify-center"
                        >
                          <span
                            class="text-lg font-semibold text-gray-800 mb-2"
                          >
                            {{ getLabel(key) }}
                          </span>
                          <span
                            class="text-2xl font-bold mb-2"
                            :style="{
                              color:
                                '#' + ($route.params.primaryColor || '19d412'),
                            }"
                          >
                            ${{ getFormattedPrice(price) }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <!--New css-->
                <div
                  class="flex flex-col md:flex-row space-y-4 md:space-y-5 md:space-x-4"
                >
                  <button
                    class="w-full flex items-center justify-center gap-2 bg-[#363166] hover:bg-[#423d7d] text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
                    :style="{
                      backgroundColor:
                        '#' + $route.params.primaryColor ||
                        '19d412' + ' !important',
                    }"
                    @click="toCheckout"
                  >
                    {{ isScheduledSession ? 'Book a Timeslot' : 'Checkout' }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="mt-8">
          <hr class="border-2 border-gray-400 mb-6" />
          <h1 class="text-2xl font-bold text-gray-800 mb-4">Suggested</h1>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
          >
            <template v-if="!suggested.length">
              <div v-for="n in 4" :key="n" class="card-form mb-5">
                <div class="card-form__inner">
                  <div
                    class="image-wrapper"
                    style="height: 33vh; overflow: hidden"
                  >
                    <div
                      class="w-full h-full bg-gray-200 animate-pulse rounded-corners"
                    ></div>
                  </div>
                  <div class="card-body">
                    <div
                      class="h-8 bg-gray-200 rounded animate-pulse mb-4"
                    ></div>
                    <div
                      class="h-6 bg-gray-200 rounded animate-pulse mb-4"
                    ></div>
                    <div
                      class="h-6 bg-gray-200 rounded animate-pulse mb-4"
                    ></div>
                    <div
                      class="h-10 bg-gray-200 rounded-full animate-pulse"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="event in suggested" :key="event._id">
                <Card
                  :title="event.name"
                  :type="event.type"
                  :price="event.price"
                  :priceObject="event.priceObject"
                  :dates="event.dates"
                  :image="event.image"
                  :buttonText="
                    event.type === 'membership'
                      ? 'View Membership'
                      : event.sessionSupported
                      ? 'View Session'
                      : 'View Camp'
                  "
                  :buttonAction="event.buttonAction"
                  :buttonColor="$route.params.primaryColor || '#19d412'"
                  placeholder="landscape"
                  :id="event.id"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import NavBar from '../components/NavBar.vue'
import NavFooter from '../components/NavFooter.vue'
import Card from '../components/Card.vue'
export default {
  components: {
    NavBar,
    Card,
    NavFooter,
  },
  data() {
    return {
      paymentId: null,
      athleteId: null,
      paymentLink: {},
      suggested: [],
      schoolBranding: {},
      paymentPeriodSelected: null,
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getImage() {
      return this.paymentLink.logo
    },
    viewAthleteButton() {
      this.$router.push('/shop/viewathlete/' + this.athleteId)
    },
    toCheckout() {
      if (this.paymentLink.type == 'camp') {
        this.$router.push(
          `/coach/${this.$route.params.coachId}/${this.$route.params.primaryColor}/${this.$route.params.secondaryColor}/campcheckout/${this.paymentId}`
        )
      } else {
        if (!this.paymentPeriodSelected) {
          swal('Error', 'Please select a payment plan', 'error')
          return
        }
        this.$router.push(
          `/coach/${this.$route.params.coachId}/${this.$route.params.primaryColor}/${this.$route.params.secondaryColor}/membershipcheckout/${this.paymentId}/${this.paymentPeriodSelected}`
        )
      }
    },
    // Navigate to athlete profile
    viewAthlete(id) {
      this.$router.push('/shop/viewathlete/' + id)
    },
    // Navigate to camp view
    viewCamp(id) {
      this.$router.push('/shop/viewpayment/' + id).then(() => {
        setTimeout(() => {
          window.scrollTo(0, 0) // Scroll to the top of the page
        }, 150) // only necessary on this page because it is the same route so it doesn't have re-render
      })
    },
    async getPayment() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'shop/getPayment?id=' + this.paymentId
      axios
        .get(url)
        .then(response => {
          console.log(response)
          this.paymentLink = response.data.payment
          this.athleteId = response.data.athleteId
          this.getSuggested()
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
          swal('Error', 'Could not be found.', 'error')
        })
    },
    getSubTitle(item) {
      if (!item.sessionSupported) {
        return item.campDates[0].start.toLocaleString()
      }
      return ''
    },
    getButtonText(item) {
      if (!item.sessionSupported) {
        return 'View Camp'
      }
      return 'View Session'
    },
    async getSuggested() {
      let url
      console.log(this.paymentLink.type)
      if (this.paymentLink.type !== 'membership') {
        url =
          import.meta.env.VITE_APP_DB_URL +
          'shop/getSuggested?campId=' +
          this.paymentId
      } else {
        url =
          import.meta.env.VITE_APP_DB_URL +
          'shop/getSuggested?membershipId=' +
          this.paymentId
      }
      axios
        .get(url)
        .then(response => {
          this.suggested = response.data.results
          console.log(response.data)
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
          swal('Error', 'Could not be found.', 'error')
        })
    },
    getFormattedPrice(price) {
      return price.toFixed(2)
    },
    getLabel(key) {
      const labels = {
        weeklyPrice: 'Weekly',
        monthlyPrice: 'Monthly',
        semiAnnualPrice: 'Semi-Annual',
        annualPrice: 'Annual',
      }
      return labels[key] || key
    },
  },
  created() {
    this.paymentId = this.$route.params.id
    this.getPayment()
  },
  watch: {
    // Watch for changes in the route parameter and call getPayment() again
    '$route.params.id': {
      handler(newId) {
        this.paymentId = newId
        this.getPayment()
      },
      immediate: true,
    },
    // Watch for any route changes to scroll to top
    $route: {
      handler() {
        window.scrollTo(0, 0)
      },
      immediate: true,
    },
  },
  mounted() {},
  computed: {
    isScheduledSession() {
      return false
    },
  },
}
</script>
<style scoped>
.shop {
  background-color: white !important;
}
.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}
.custom-button:hover {
  filter: brightness(0.95) !important;
}
</style>