<template>
  <div
    class="bg-white rounded-2xl shadow-lg overflow-hidden border border-[#363166]/10 hover:shadow-xl transition-all duration-300"
  >
    <div class="p-6">
      <!-- Move the check-in toggle to the top -->
      <div class="flex justify-end mb-4">
        <label :for="this.index" class="flex items-center cursor-pointer">
          <div class="mr-3 text-lg font-medium text-[#030021]">Checked In</div>
          <div class="relative">
            <input
              type="checkbox"
              :id="this.index"
              :checked="camperIsCheckedIn"
              @change="toggleCheckedIn"
              class="sr-only"
            />
            <div
              :class="[
                'block w-21 h-8 rounded-full transition-colors duration-300',
                camperIsCheckedIn ? 'bg-[#19D412]' : 'bg-gray-200',
              ]"
            ></div>
            <div
              class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
              :class="{
                'transform translate-x-[3.25rem]': camperIsCheckedIn,
              }"
            ></div>
          </div>
        </label>
      </div>

      <div class="flex flex-col md:flex-row gap-6">
        <!-- Left Column: Profile Image and Name -->
        <div class="flex flex-col items-center md:items-start md:w-1/3">
          <div class="relative w-32 h-32 mb-4">
            <img
              :src="getImage"
              class="w-full h-full object-cover rounded-full border-4 border-[#363166]/20 shadow-md"
              alt="Camper profile"
            />
            <div
              class="absolute -bottom-2 -right-2 bg-white rounded-full p-1 shadow-md"
              v-if="camperIsCheckedIn"
            >
              <div class="bg-[#19D412] rounded-full p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <h2
            class="text-2xl font-bold text-[#030021] mb-4 text-center md:text-left"
          >
            {{ camperFirstName }} {{ camperLastName }}
          </h2>
          <button
            @click="toCamperProfile()"
            class="inline-flex items-center justify-center px-6 py-2 bg-gradient-to-r from-[#363166] to-[#030021] text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 w-full md:w-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            View Profile
          </button>
        </div>

        <!-- Right Column: Camper Details -->
        <div class="md:w-2/3 space-y-4">
          <!-- T-Shirt Status -->
          <div
            v-if="getTShirtString != ''"
            class="flex items-center justify-center md:justify-start"
          >
            <label
              :for="'tShirt' + index"
              class="flex items-center cursor-pointer"
            >
              <div class="mr-3 text-lg font-medium text-[#030021]">
                Received T-Shirt
              </div>
              <div class="relative">
                <input
                  type="checkbox"
                  :id="'tShirt' + index"
                  :checked="camperTShirtReceived"
                  @change="toggleTShirtReceived"
                  class="sr-only"
                />
                <div
                  :class="[
                    'block w-17 h-7 rounded-full transition-colors duration-300',
                    camperTShirtReceived ? 'bg-[#19D412]' : 'bg-gray-200',
                  ]"
                ></div>
                <div
                  class="dot absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition"
                  :class="{
                    'transform translate-x-[2.5rem]': camperTShirtReceived,
                  }"
                ></div>
              </div>
            </label>

            <!-- <div class="ml-4 flex items-center text-[#363166]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                />
              </svg>
              {{ getTShirtString }}
            </div> -->
          </div>

          <!-- Additional Information Cards -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            <!-- Add-ons -->
            <div
              v-if="getCustomAddOns.length != 0"
              class="bg-[#363166]/5 rounded-xl p-4 md:col-span-2 border border-[#363166]/10"
            >
              <h3 class="font-semibold text-[#030021] mb-2 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2 text-[#363166]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  />
                </svg>
                Purchased Add-ons
              </h3>
              <ul class="list-disc list-inside text-[#363166] space-y-1">
                <li v-for="(addon, index) in getCustomAddOns" :key="index">
                  {{ addon }}
                </li>
              </ul>
            </div>

            <!-- Amount Paid -->
            <div
              class="bg-[#363166]/5 rounded-xl p-4 border border-[#363166]/10"
            >
              <h3
                class="font-semibold text-[#030021] mb-2 flex items-center justify-center whitespace-nowrap"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10 text-[#363166] mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Amount Paid
              </h3>
              <p class="text-[#030021] font-bold text-xl text-center">
                ${{ pricePaid.toFixed(2) }}
              </p>
              <div v-if="discountCode" class="mt-2 text-sm text-[#363166]">
                <span class="font-medium">Discount Applied:</span>
                {{ discountCode }}
              </div>
            </div>

            <!-- Registration Status -->
            <div
              class="bg-[#363166]/5 rounded-xl p-4 md:col-span-2 border border-[#363166]/10"
            >
              <h3 class="font-semibold text-[#030021] mb-2 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2 text-[#363166]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
                Registration Status
              </h3>
              <div class="grid grid-cols-2 gap-4">
                <div class="flex items-center">
                  <div
                    class="h-8 w-8 rounded-full bg-[#363166]/10 flex items-center justify-center mr-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-[#363166]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div>
                    <div class="text-sm text-[#363166]">Payment Processed</div>
                    <div class="font-medium text-[#030021]">
                      Waiver Accepted
                    </div>
                  </div>
                </div>
                <div v-if="getTShirtString" class="flex items-center">
                  <div
                    class="h-8 w-8 rounded-full bg-[#363166]/10 flex items-center justify-center mr-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-[#363166]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                      />
                    </svg>
                  </div>
                  <div>
                    <div class="text-sm text-[#363166]">T-Shirt</div>
                    <div class="font-medium text-[#030021]">
                      {{ getTShirtString }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Scheduled Session -->
          <div
            v-if="checkScheduledSession"
            class="bg-gradient-to-r from-[#363166]/10 to-[#363166]/20 rounded-xl p-4 border border-[#363166]/20 mt-4"
          >
            <h3 class="font-semibold text-[#030021] mb-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2 text-[#363166]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              Scheduled Session
            </h3>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div class="flex items-center">
                <div
                  class="h-10 w-10 rounded-full bg-[#363166]/10 flex items-center justify-center mr-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-[#363166]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div>
                  <div class="text-sm text-[#363166]">Date</div>
                  <div class="font-medium text-[#030021]">
                    {{ getFormattedDate }}
                  </div>
                </div>
              </div>

              <div class="flex items-center">
                <div
                  class="h-10 w-10 rounded-full bg-[#363166]/10 flex items-center justify-center mr-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-[#363166]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>
                  <div class="text-sm text-[#363166]">Time</div>
                  <div class="font-medium text-[#030021]">
                    {{ getFormattedTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'
import moment from 'moment'

export default {
  data() {
    return {
      teamId: '',
      campId: '',
      camperFirstName: '',
      camperLastName: '',
      camperIsCheckedIn: false,
      camperTShirtReceived: false,
      camperTShirtSize: '',
      camperCampName: '',
      customAddOns: [],
      showInfo: false,
      localCamperId: '',
    }
  },
  props: {
    firstName: String,
    lastName: String,
    isCheckedIn: Boolean,
    tShirtReceived: Boolean,
    tShirtSize: String,
    campName: String,
    camperId: String,
    camperAddOns: Array,
    discountCode: String,
    isTeamCamp: Boolean,
    camperProfileImage: String,
    bundlePricePaid: Number,
    scheduledSession: Object,
    pricePaid: Number,
    index: Number,
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    toCamperProfile() {
      console.log('To Camper Profile: ' + this.camperId)
      if (this.isTeamCamp) {
        this.$router.push(
          '/Dashboard/TeamList/' +
            this.$route.params.campId +
            '/' +
            this.$route.params.teamId +
            '/' +
            this.camperId,
        )
      } else {
        this.$router.push(
          '/Dashboard/CheckIn/' + this.campId + '/' + this.camperId,
        )
      }
    },
    toggleCheckedIn() {
      this.camperIsCheckedIn = !this.camperIsCheckedIn
      console.log('Toggle: ', this.camperId)
      this.updateStatus()
    },
    toggleTShirtReceived() {
      this.camperTShirtReceived = !this.camperTShirtReceived
      this.updateStatus()
    },
    updateStatus() {
      console.log('Update: ', this.localCamperId)
      let obj = {
        campId: this.campId,
        camperId: this.localCamperId,
        isCheckedIn: this.camperIsCheckedIn,
        tShirtReceived: this.camperTShirtReceived,
      }
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/setCamperStatus'
      if (this.isTeamCamp) {
        this.teamId = this.$route.params.teamId
        url = import.meta.env.VITE_APP_DB_URL + 'teamCamp/setTeamCamperStatus'
        obj.teamId = this.teamId
      }
      axios
        .post(url, obj, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(() => {
          // Emit the status update to parent
          this.$emit('camperUpdated', {
            camperId: this.localCamperId,
            isCheckedIn: this.camperIsCheckedIn,
            tShirtReceived: this.camperTShirtReceived,
          })
        })
        .catch(err => {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          // Revert the state on error
          this.camperIsCheckedIn = !this.camperIsCheckedIn
        })
    },
  },
  created() {
    this.campId = this.$route.params.campId
    this.camperFirstName = this.firstName
    this.camperLastName = this.lastName
    this.camperIsCheckedIn = this.isCheckedIn
    this.camperTShirtReceived = this.tShirtReceived
    this.camperTShirtSize = this.tShirtSize
    this.camperCampName = this.campName
    this.customAddOns = this.camperAddOns || []
    this.localCamperId = this.camperId
  },
  computed: {
    getImage() {
      if (!this.camperProfileImage) {
        return '/headshot-icon.png'
      } else {
        return this.camperProfileImage
      }
    },
    getCustomAddOns() {
      let temp = []
      let count
      for (count = 0; count < this.customAddOns.length; count++) {
        if (this.customAddOns[count].isPurchased) {
          temp.push(this.customAddOns[count].addOnName)
        }
      }
      return temp
    },
    getTShirtString() {
      if (this.camperTShirtSize == 'YS') {
        return 'Youth Small'
      }
      if (this.camperTShirtSize == 'YM') {
        return 'Youth Medium'
      }
      if (this.camperTShirtSize == 'YL') {
        return 'Youth Large'
      }
      if (this.camperTShirtSize == 'AS') {
        return 'Adult Small'
      }
      if (this.camperTShirtSize == 'AM') {
        return 'Adult Medium'
      }
      if (this.camperTShirtSize == 'AL') {
        return 'Adult Large'
      }
      if (this.camperTShirtSize == 'AXL') {
        return 'Adult XL'
      }
      if (this.camperTShirtSize == 'AXXL') {
        return 'Adult XXL'
      }

      return ''
    },
    getFormattedDate() {
      if (this.scheduledSession) {
        return moment(this.scheduledSession?.date)
          .add(1, 'days')
          .format('MM/DD/YYYY')
      }
      return ''
    },
    getFormattedTime() {
      let time = this.scheduledSession?.time
      if (time) {
        let startTime = time.split(' - ')[0]
        let endTime = time.split(' - ')[1]

        let startHour = startTime.split(':')[0]
        let endHour = endTime.split(':')[0]

        let startTag = ''
        let endTag = ''

        if (parseInt(startHour) < 12) {
          startTag = 'am'
        } else {
          if (startHour > 12) {
            startHour = parseInt(startHour) - 12
          }
          startTag = 'pm'
        }

        if (parseInt(endHour) < 12) {
          endTag = 'am'
        } else {
          if (endHour > 12) {
            endHour = parseInt(endHour) - 12
          }
          endTag = 'pm'
        }

        return `${startHour}:${
          startTime.split(':')[1]
        }${startTag} - ${endHour}:${endTime.split(':')[1]}${endTag}`
      }
      return ''
    },
    checkScheduledSession() {
      if (this.scheduledSession) {
        if (this.scheduledSession.date && this.scheduledSession.time) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>
/* Custom toggle switch animation */
.dot {
  transition: all 0.3s ease-in-out;
}

/* Card hover effect */
.card-hover:hover {
  transform: translateY(-4px);
  box-shadow:
    0 10px 25px -5px rgba(54, 49, 102, 0.1),
    0 10px 10px -5px rgba(54, 49, 102, 0.04);
}
button,
a {
  border-radius: 0.5rem !important;
  overflow: hidden;
  position: relative;
}

button::after,
a::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(to right, #363166, #030021);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

button:hover::after,
a:hover::after {
  opacity: 1;
}
</style>
