<template>
  <div>
    <NavBar></NavBar>
    <div style="margin-top: 2%; margin-bottom: 2%">
      <LoadingSkeleton v-if="this.toggle == 0" />
      <CamperInfo
        v-if="this.toggle == 1"
        :camp="this.camp"
        :sessionInfo="this.sessionInfo"
        :presetCampers="this.campers"
        :presetPaymentType="this.paymentType"
        :presetEmergencyBool="this.emergencyBool"
        :isMobile="this.isMobile"
        @proceed="toPayment"
        @back="back"
      ></CamperInfo>
      <Payment
        v-if="this.toggle == 2"
        :billingInfo="this.billingInfo"
        :isMobile="this.isMobile"
        :primaryColor="this.primaryColor"
        :secondaryColor="this.secondaryColor"
        :presetBillingInfo="this.billingInfo"
        @proceed="toOrderSummary"
        @back="back"
      ></Payment>
      <OrderSummary
        v-if="this.toggle == 3"
        :camp="this.camp"
        :lineItems="getLineItems"
        :isMobile="this.isMobile"
        :paymentType="this.paymentType"
        :campers="this.campers"
        :sessionInfo="this.sessionInfo"
        @discountCodeApplied="discountCodeApplied"
        @proceed="registerCamper"
        @paypalSuccess="paypalSuccess"
        @back="back"
      ></OrderSummary>
      <RegistrationSuccess
        v-if="this.toggle == 4"
        :camp="this.camp"
      ></RegistrationSuccess>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>

<script>
import axios from 'axios'
import NavBar from '../components/NavBar.vue'
import LoadingSkeleton from '../components/Checkout/LoadingSkeleton.vue'
import CamperInfo from '../components/Checkout/CampSpecific/CamperInfo.vue'
import Payment from '../components/Checkout/Payment.vue'
import OrderSummary from '../components/Checkout/CampSpecific/OrderSummary.vue'

import swal from 'sweetalert'
import moment from 'moment'
import RegistrationSuccess from '../components/Checkout/RegistrationSuccess.vue'
import NavFooter from '../components/NavFooter.vue'
export default {
  components: {
    NavBar,
    RegistrationSuccess,
    LoadingSkeleton,
    CamperInfo,
    Payment,
    OrderSummary,
    NavFooter,
  },
  data() {
    return {
      camp: {},
      campers: null,
      emergencyBool: true,
      billingInfo: {
        firstName: '',
        lastName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
      sessionInfo: {},
      discountCode: '',
      toggle: 0,
      paymentType: 'creditcard',
    }
  },
  methods: {
    discountCodeApplied(discountCode) {
      this.discountCode = discountCode
    },
    paypalSuccess() {
      this.toggle = 4
    },
    back() {
      if (this.toggle == 2) {
        this.toggle = 1
        return
      }
      if (this.toggle == 3) {
        if (this.paymentType != 'creditcard') {
          this.toggle = 1
        } else {
          this.toggle = 2
        }
      }
    },
    async getCampInfo() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getCampCheckoutData?id=' +
        this.$route.params.id
      axios
        .get(url, {})
        .then(response => {
          this.camp = response.data.camp
          this.toggle = 1
        })
        .catch(error => {
          console.log(error)
        })
    },
    paypalCheckout() {
      this.toggle = 999
    },
    toPayment(campers, emergencyBool, paymentType) {
      this.campers = campers
      this.emergencyBool = emergencyBool
      this.paymentType = paymentType

      if (this.getTotal() == 0) {
        this.toggle = 3
      }
      if (this.paymentType != 'creditcard') {
        this.toggle = 3
        return
      }
      this.toggle = 2
    },
    toOrderSummary(x) {
      this.billingInfo = x
      //Format the camper objects to be sent to the server
      this.campers.forEach(camper => {
        camper.email = this.billingInfo.email
        camper.phoneNumber = this.billingInfo.phoneNumber
        camper.address = this.billingInfo.address
        if (this.emergencyBool) {
          camper.emergencyContactInfo = { ...this.billingInfo }
        }
      })
      this.toggle = 3
    },

    registerCamper() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/registerCamper'
      axios
        .post(url, {
          campId: this.camp._id,
          campers: this.campers,
          billingInfo: this.billingInfo,
          discountCode: this.discountCode,
        })
        .then(response => {
          console.log(response.data)
          let message = 'Checkout Successful! '
          for (let camper in this.campers) {
            if (camper == 0) {
              message = message + this.campers[camper].firstName
            } else if (this.campers.length == 2) {
              message = message + ' and ' + this.campers[camper].firstName
            } else if (camper == this.campers.length - 1) {
              message = message + ', and ' + this.campers[camper].firstName
            } else {
              message = message + ', ' + this.campers[camper].firstName
            }
          }
          if (this.campers.length > 1) {
            message = message + ' are registered'
          } else {
            message = message + ' is registered'
          }
          message = message + ' for ' + this.camp.campName + '!'

          swal('Success', message, 'success')
          this.toggle = 4
        })
        .catch(err => {
          console.log(err)
          swal('Error', 'Invalid Card Information', 'error')
          this.toggle = 4
        })
    },
    toInfo(x) {
      console.log('hitting emit')
      console.log(x)
      this.sessionInfo = x
      this.toggle = 1
    },
    getTotal() {
      if (!this.getLineItems || this.getLineItems.length === 0) {
        return 0 // Return 0 if there are no line items
      }

      return this.getLineItems.reduce((total, item) => {
        // Sum the item's price
        let itemTotal = item.price

        // Sum all subitem prices
        const subitemsTotal = item.subitems.reduce((subTotal, subitem) => {
          return subTotal + subitem.price
        }, 0)

        // Add the subitems total to the item's total
        return total + itemTotal + subitemsTotal
      }, 0)
    },
    setChosenDate() {
      let date = new Date()
      this.chosenDate = moment(date)
        .add(parseInt(this.camp.sessionInfo.minAdvanceNotice), 'days')
        .format('YYYY-MM-DD')
    },
    dayToString(date) {
      let temp = new Date(date)
      return temp.toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getLineItems() {
      if (!this.camp || !this.campers || !Array.isArray(this.campers)) {
        return [] // Return empty array if data is missing
      }

      const lineItems = this.campers.map((camper, index) => {
        // Calculate camper's price using multiple camper price logic
        let camperPrice
        if (index === 0) {
          camperPrice = this.camp.campPrice // First camper pays base camp price
        } else if (index - 1 < this.camp.multipleCamperPrices.length) {
          camperPrice = this.camp.multipleCamperPrices[index - 1].camperPrice
        } else {
          camperPrice =
            this.camp.multipleCamperPrices[
              this.camp.multipleCamperPrices.length - 1
            ]?.camperPrice || this.camp.campPrice
        }

        // Sub-items: add-ons purchased by this camper
        const subitems = (camper.customAddOns || [])
          .filter(addOn => addOn.isPurchased)
          .map(addOn => ({
            price: addOn.addOnPrice,
            title: addOn.addOnName,
          }))

        return {
          price: camperPrice,
          title: `${camper.firstName || 'Camper'} ${camper.lastName || ''}`,
          subitems,
        }
      })

      return lineItems
    },
  },
  created() {
    // Fetch search results on page load
    this.getCampInfo()
  },
  mounted() {},
}
</script>

<style scoped></style>
