<template>
  <div
    class="relative bg-white rounded-3xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-[1.02]"
  >
    <!-- Card header -->
    <div
      class="bg-gradient-to-br from-indigo-700 to-indigo-500 px-6 py-8 text-center"
    >
      <div class="mb-4">
        <div
          class="w-16 h-16 mx-auto rounded-full bg-white/20 flex items-center justify-center"
        >
          <i class="fa-solid fa-medal text-2xl text-white"></i>
        </div>
      </div>
      <h3 class="text-2xl font-bold text-white mb-2">
        {{ subscription.subscriptionName }}
      </h3>
      <p class="text-white/80">
        {{ yearly ? 'Billed Annually' : 'Billed Monthly' }}
      </p>
    </div>

    <!-- Pricing section -->
    <div class="px-6 py-8">
      <div class="text-center mb-8">
        <div v-if="yearly" class="text-gray-400 line-through mb-2">
          ${{ getFakeYearly }}
        </div>
        <div class="flex items-center justify-center">
          <span class="text-3xl font-bold text-indigo-900">$</span>
          <span class="text-5xl font-bold text-indigo-900">
            {{ yearly ? getYearly.split('.')[0] : getMonthly.split('.')[0] }}
          </span>
          <span class="text-2xl font-bold text-indigo-900">
            .{{ yearly ? getYearly.split('.')[1] : getMonthly.split('.')[1] }}
          </span>
          <span class="text-gray-500 ml-2 text-lg">
            / {{ yearly ? 'year' : 'month' }}
          </span>
        </div>
        <div v-if="yearly" class="mt-4">
          <span
            class="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 text-sm font-medium"
          >
            <i class="fas fa-tag mr-1"></i>
            Save up to 20%
          </span>
        </div>
      </div>

      <!-- CTA Button -->
      <button
        class="w-full py-4 px-6 rounded-xl font-bold text-white bg-gradient-to-r from-indigo-500 to-indigo-700 hover:from-indigo-600 hover:to-indigo-800 transition-all duration-300 shadow-md hover:shadow-lg"
        @click.stop="buySubscription(subscription)"
        :disabled="isDisabled"
        :class="{ 'opacity-50 cursor-not-allowed': isDisabled }"
      >
        {{ getButtonText }}
        <i class="fa-solid fa-arrow-right ml-2"></i>
      </button>

      <!-- Features -->
      <div class="mt-8">
        <ul class="space-y-4">
          <li class="flex items-start">
            <i class="fa-solid fa-check-circle text-green-500 mt-1 mr-3"></i>
            <span class="text-gray-800"
              >Unlimited Camps and Traning Sessions</span
            >
          </li>
          <li class="flex items-start">
            <i class="fa-solid fa-check-circle text-green-500 mt-1 mr-3"></i>
            <span class="text-gray-800">AI Website Builder</span>
          </li>
          <li class="flex items-start">
            <i class="fa-solid fa-check-circle text-green-500 mt-1 mr-3"></i>
            <span class="text-gray-800">Customer Management Portal</span>
          </li>
          <li class="flex items-start">
            <i class="fa-solid fa-check-circle text-green-500 mt-1 mr-3"></i>
            <span class="text-gray-800">Calendar/Scheduler Suite</span>
          </li>
          <li class="flex items-start">
            <i class="fa-solid fa-check-circle text-green-500 mt-1 mr-3"></i>
            <span class="text-gray-800">
              Platform Checkout Fee
              <span class="font-bold text-indigo-600">
                {{ subscription.customerUpchargePercentage.toFixed(1) }}%
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import numeral from 'numeral'

export default {
  props: {
    promo: {
      type: Boolean,
      default: false,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Keep the subscription object if not passed as prop
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: 'Pro',
        subscriptionTier: 0,
      },
    }
  },
  methods: {
    buySubscription(plan) {
      this.$emit('buySubscription', plan)
    },
    getSubscription() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'subscription/getSubscriptionByName?name=Pro'
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + localStorage.getItem('token'),
          },
        })
        .then(response => {
          this.subscription = response.data.subscription
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  computed: {
    activeSubscription() {
      // Use prop if available, otherwise use local data
      return this.subscription
    },
    getFakeYearly() {
      return numeral(this.activeSubscription.monthlyPrice * 12).format('0,0.00')
    },
    getYearly() {
      return numeral(this.activeSubscription.yearlyPrice).format('0,0.00')
    },
    getMonthly() {
      return numeral(this.activeSubscription.monthlyPrice).format('0,0.00')
    },
    getButtonText() {
      return 'Get Started'
    },
    getSavingsPercentage() {
      const monthlyTotal = this.activeSubscription.monthlyPrice * 12
      const yearlyTotal = this.activeSubscription.yearlyPrice
      const savings = ((monthlyTotal - yearlyTotal) / monthlyTotal) * 100
      return Math.round(savings)
    },
  },
  mounted() {
    this.getSubscription()
  },
}
</script>
