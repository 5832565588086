<template>
  <div>
    <!-- Main Navigation make this rebuild because file name changed -->
    <nav
      class="bg-white border-b relative"
      v-if="!isMobile()"
      :style="{
        backgroundColor:
          '#' + ($route.params.secondaryColor || 'ffffff') + ' !important',
      }"
    >
      <div class="max-w-8xl mx-auto px-4 h-25">
        <div class="flex justify-between h-24">
          <!-- Left Navigation -->
          <div class="md:flex space-x-8"></div>
          <!-- Search Form with Filters in Navbar -->
          <div class="md:flex items-center !space-x-2 relative">
            <!-- Search Input -->
            <input
              type="search"
              placeholder="Search"
              class="w-full px-4 py-2 !border !border-gray-300 rounded-md bg-white"
              v-model="query.search"
              @keyup.enter="handleSearch"
            />
            <button
              class="px-4 py-2 text-white !rounded-md"
              :style="{
                backgroundColor:
                  '#' +
                  ($route.params.primaryColor || '19d412') +
                  ' !important',
              }"
              @click="handleSearch"
            >
              <SearchIcon class="h-5 w-5" />
            </button>

            <!-- Filter Dropdown Button -->
            <button
              @click="toggleFilterDropdown"
              class="px-4 py-2 text-white !rounded-md"
              :style="{
                backgroundColor:
                  '#' +
                  ($route.params.primaryColor || '19d412') +
                  ' !important',
              }"
            >
              <FilterIcon class="h-5 w-5" />
            </button>
          </div>

          <!-- Mobile Menu Button -->
          <button
            @click="mobileMenuOpen = !mobileMenuOpen"
            class="md:hidden p-2 text-gray-800 hover:text-gray-400 focus:outline-none"
          >
            <MenuIcon class="h-6 w-6" />
          </button>
        </div>
      </div>

      <!-- Full-width Dropdowns -->
    </nav>
    <!--New code-->
    <nav
      class="bg-white border-b relative"
      v-else
      :style="{
        backgroundColor:
          '#' + ($route.params.secondaryColor || 'ffffff') + ' !important',
      }"
    >
      <div class="max-w-8xl mx-auto px-4 h-25">
        <div class="flex items-center justify-between h-24">
          <!-- Left Navigation -->
          <div class="hidden md:flex space-x-8"></div>

          <!-- Search Form -->
          <div class="hidden md:flex items-center space-x-4">
            <input
              type="search"
              placeholder="Search"
              class="w-full px-4 py-2 !border !border-gray-300 rounded-md bg-white"
              v-model="query.search"
              @keyup.enter="handleSearch"
            />
            <button
              class="px-4 py-2 text-white rounded-md"
              :style="{
                backgroundColor:
                  '#' +
                  ($route.params.primaryColor || '19d412') +
                  ' !important',
              }"
              @click="handleSearch"
            >
              <SearchIcon class="h-5 w-5" />
            </button>
          </div>

          <!-- Mobile Menu Button -->
          <button
            @click="mobileMenuOpen = !mobileMenuOpen"
            class="md:hidden p-2 text-gray-800 hover:text-gray-400 focus:outline-none"
          >
            <MenuIcon class="h-6 w-6" />
          </button>
        </div>
      </div>

      <!-- Mobile Menu -->
      <div
        v-if="mobileMenuOpen"
        class="md:hidden bg-white border-t shadow-lg space-y-2 py-2"
      >
        <div v-for="(item, index) in navItems" :key="index" class="px-4 py-2">
          <button
            class="w-full text-left text-gray-800 hover:text-gray-600"
            @click="toggleDropdown(index)"
          >
            {{ item.name }}
          </button>
          <div v-if="item.isOpen" class="pl-4 py-2 space-y-1">
            <a
              v-for="category in item.categories"
              :key="category.url"
              :href="category.url"
              class="block text-gray-700 hover:bg-gray-100 px-2 py-1 text-sm"
            >
              {{ category.title }}
            </a>
          </div>
        </div>

        <!-- Search Form for Mobile -->
        <div class="px-4 py-2">
          <input
            type="search"
            placeholder="Search"
            class="w-full px-4 py-2 border border-gray-300 rounded-md bg-white"
            v-model="query.search"
            @keyup.enter="handleSearch"
          />
          <button
            class="w-full mt-2 px-4 py-2 text-white rounded-md primary-background"
            :style="{
              backgroundColor:
                '#' + ($route.params.primaryColor || '19d412') + ' !important',
            }"
            @click="handleSearch"
          >
            Search
          </button>
          <button
            class="w-full mt-2 px-4 py-2 text-white rounded-md primary-background"
            :style="{
              backgroundColor:
                '#' + ($route.params.primaryColor || '19d412') + ' !important',
            }"
            @click="toggleFilterDropdown"
          >
            Filter
          </button>
        </div>
      </div>
    </nav>
    <!-- Filter Dropdown -->
    <div
      v-if="showFilterDropdown"
      class="absolute right-0 mt-2 w-64 bg-white !border rounded-md shadow-lg z-50 p-4"
    >
      <!-- Filter Type: Athletes or Camps -->
      <label class="block font-medium mb-2">Filter By:</label>
      <select v-model="query.type" class="w-full !border rounded-md p-2 mb-2">
        <option value="">Select</option>
        <option value="camp">Camps</option>
        <option value="training">Training Sessions</option>
        <option value="membership">Memberships</option>
      </select>
      <div>
        <label class="block font-medium">Gender:</label>
        <select
          v-model="query.gender"
          class="w-full !border rounded-md p-2 mb-2"
        >
          <option value="">Select</option>
          <option value="Coed">Both</option>
          <option value="Boys">Boys</option>
          <option value="Girls">Girls</option>
        </select>

        <!-- Athlete Filters -->

        <!-- Camp Filters -->
        <div>
          <label class="block font-medium mt-2">Age Range:</label>
          <select v-model="query.age" class="w-full !border rounded-md p-2">
            <option value="" selected>All Ages</option>
            <option value="elementary">Elementary</option>
            <option value="middle">Middle School</option>
            <option value="high">High School</option>
          </select>
        </div>
      </div>

      <!-- Apply Filters Button -->
      <button
        @click="applyFilters"
        class="w-full mt-4 text-white py-2 rounded-md"
        :style="{
          backgroundColor:
            '#' + ($route.params.primaryColor || '19d412') + ' !important',
        }"
      >
        Apply Filters
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { SearchIcon, MenuIcon, FilterIcon } from 'lucide-vue-next'

export default {
  components: { SearchIcon, MenuIcon, FilterIcon },
  data() {
    return {
      showFilterDropdown: false,

      // 'athlete' or 'camp'
      query: {
        gender: '',
        search: '',
        type: '',
        sports: '',
        eventType: '',
        age: '',
        minPrice: '',
        maxPrice: '',
      },
      menSports: [],
      womenSports: [],
      schoolBranding: JSON.parse(localStorage.getItem('schoolBranding')) || {},
      mobileMenuOpen: false,
      navItems: [],
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    toggleFilterDropdown() {
      this.showFilterDropdown = !this.showFilterDropdown
    },
    applyFilters() {
      const query = this.query
      Object.keys(query).forEach(key => {
        if (!query[key]) delete query[key]
      })
      if (!query.type) {
        delete query.gender
        delete query.sports
        delete query.eventType
        delete query.minPrice
        delete query.maxPrice
        delete query.age
      }
      if (query.type === 'athlete') {
        delete query.eventType
        delete query.minPrice
        delete query.maxPrice
        delete query.age
      }
      this.$router.push({
        path: `/coach/${this.$route.params.coachId}/${this.$route.params.primaryColor}/${this.$route.params.secondaryColor}`,
        query,
      })
      this.showFilterDropdown = false // Close the dropdown
    },
    handleSearch() {
      const query = this.query
      Object.keys(query).forEach(key => {
        if (!query[key]) delete query[key]
      })
      if (!query.type) {
        delete query.gender
        delete query.sports
        delete query.eventType
        delete query.minPrice
        delete query.maxPrice
        delete query.age
      }

      if (query.type === 'athlete') {
        delete query.eventType
        delete query.minPrice
        delete query.maxPrice
        delete query.age
      }
      this.$router.push({
        path: `/coach/${this.$route.params.coachId}/${this.$route.params.primaryColor}/${this.$route.params.secondaryColor}`,
        query,
      })
    },
    async getNavBar() {
      let url = import.meta.env.VITE_APP_DB_URL + 'shop/getNavBar'
      await axios
        .get(url)
        .then(response => {
          console.log(response.data)
          this.formatNavItems(response.data)
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
        })
    },
    formatNavItems(data) {
      console.log(data)

      // Group sports into menSports and womenSports
      const menSports = []
      const womenSports = []

      data.sports.forEach(sport => {
        if (sport.gender.toLowerCase() === 'men') {
          menSports.push(sport)
        } else if (sport.gender.toLowerCase() === 'women') {
          womenSports.push(sport)
        } else if (sport.gender.toLowerCase() === 'coed') {
          // Coed sports should be in both
          menSports.push(sport)
          womenSports.push(sport)
        }
      })
      this.menSports = menSports
      this.womenSports = womenSports

      // Function to format sports data into navigation structure
      const formatNavData = (sportsArray, type) => {
        return sportsArray.map(sport => {
          const subItems = [
            {
              title: `View All ${sport.sportName} ${type}`,
              url: `/shop?type=${type}&sports=${sport._id}`,
            },
          ]

          return {
            title: sport.sportName,
            url: `/shop?type=${type}&sports=${sport._id}`,
            subItems: subItems,
          }
        })
      }

      // Format data for ATHLETES
      const athletesData = [
        {
          title: "Men's Sports",
          url: '#',
          subItems: formatNavData(menSports, 'athlete'),
        },
        {
          title: "Women's Sports",
          url: '#',
          subItems: formatNavData(womenSports, 'athlete'),
        },
      ]

      // Format data for CAMPS/TRAINING SESSIONS
      const campsData = [
        {
          title: "Men's Sports",
          url: '#',
          subItems: formatNavData(menSports, 'training'),
        },
        {
          title: "Women's Sports",
          url: '#',
          subItems: formatNavData(womenSports, 'training'),
        },
      ]

      // Assign the formatted data to the respective properties
      this.navItems = [
        {
          name: 'ATHLETES',
          isOpen: false,
          categories: athletesData,
        },
        {
          name: 'CAMPS/TRAINING SESSIONS',
          isOpen: false,
          categories: campsData,
        },
      ]
    },

    toggleDropdown(index) {
      this.navItems[index].isOpen = !this.navItems[index].isOpen
    },
    openItem(index) {
      for (let item in this.navItems) {
        if (item != index) {
          this.navItems[item].isOpen = false
        } else {
          this.navItems[item].isOpen = true
        }
      }
    },
  },
  computed: {
    getHomeLink() {
      return 'https://' + window.location.hostname
    },
    getTeams() {
      return this.teams
    },
    getSportOptions() {
      return this.query.gender === 'Women' ? this.womenSports : this.menSports
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.query = {
          search: newQuery.search || '',
          type: newQuery.type || '',
          gender: newQuery.gender || '',
          sports: newQuery.sports || '',
          eventType: newQuery.eventType || '',
          age: newQuery.age || '',
          minPrice: newQuery.minPrice || '',
          maxPrice: newQuery.maxPrice || '',
        }
      },
    },
  },
  created() {
    this.getNavBar()
  },
}
</script>
<style scoped>
/* Additional styling for filters */
aside {
  min-width: 250px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>