// import Vue from "vue";
// import VueRouter from "vue-router";
import { createRouter, createWebHistory } from 'vue-router'
//Client Pages
import CoachDashboard from '../client/views/CoachDashboard.vue'
import CreateEvent from '../client/views/CreateEvent.vue'
import CreateIndividualCamp from '../client/views/CreateIndividualCamp.vue'
import CreateTeamCamp from '../client/views/CreateTeamCamp.vue'
import ViewIndividualCamp from '../client/views/ViewIndividualCamp.vue'
import ViewTeamCamp from '../client/views/ViewTeamCamp.vue'
import TeamList from '../client/views/TeamList.vue'
import TeamProfile from '../client/views/TeamProfile.vue'
import CamperCheckIn from '../client/views/Campers/CamperCheckIn.vue'
import AccountSettings from '../client/views/AccountSettings.vue'
import CamperProfile from '../client/views/Campers/CamperProfile.vue'
import UploadCamper from '../client/views/Campers/UploadCamper.vue'
import ForgotPassword from '../client/views/ForgotPassword.vue'
import ResetPassword from '../client/views/ResetPassword.vue'
import Payouts from '../client/views/Admin/Payouts.vue'
import login from '../client/views/login.vue'
import TeamCamperProfile from '../client/views/TeamCamperProfile.vue'
import MembershipPayouts from '../client/views/Admin/MembershipPayouts.vue'
import SMSMarketing from '../client/views/Admin/SMSMarketing.vue'
import RecruitReferral from '../client/views/Admin/RecruitReferral.vue'
import Payments from '../client/views/Payments.vue'
import CRM from '../client/views/CRM/CrmHome.vue'
import Analytics from '../client/views/Analytics.vue'
import Transactions from '../client/views/Banking/Transactions.vue'
import BankAccounts from '../client/views/Banking/BankAccounts.vue'
import VerifyEmail from '../client/views/VerifyEmail.vue'
import MySchedule from '../client/views/Scheduling/MySchedule.vue'
import AddSimpleEvent from '../client/views/Scheduling/AddSimpleEvent.vue'
import OneTimeLogin from '../client/views/OneTimeLogin.vue'
import VideoTour from '../client/views/Tours/VideoTour.vue'
import GetStarted from '../client/views/GetStarted.vue'
import CoachInbox from '../client/views/Inbox/CoachInbox.vue'
import SelectAccount from '../client/views/SelectAccount.vue'
import PaymentLinks from '../client/views/PaymentLinks.vue'
//Advertisment Routes
import AdOverview from '../client/views/Advertisments/AdOverview.vue'
import AdStore from '../client/views/Advertisments/AdStore.vue'
import CreditStore from '../client/views/Advertisments/CreditStore.vue'
import AdCreditCheckout from '../client/views/Advertisments/AdCreditCheckout.vue'
import ViewCampaign from '../client/views/Advertisments/ViewCampaign.vue'
import AdLibrary from '../client/views/Advertisments/AdLibrary.vue'
import CreateAdCreative from '../client/views/Advertisments/CreateAdCreative.vue'

//General Camp Routes
import MaxRegistration from '../camps/views/StaticDisplays/MaxRegistration.vue'
import Success from '../camps/views/StaticDisplays/Success.vue'
import Void from '../camps/views/StaticDisplays/Void.vue'

//Individual Camp Pages
import CamperCheckout from '../camps/views/IndividualCamps/CamperCheckout.vue'

//Coach Website Routes
import LandingPage from '../websites/views/LandingPage.vue'
import WebsiteSetup from '../client/views/WebsiteSetup.vue'
import WebsiteOverview from '../client/views/WebsiteOverview.vue'
import EditWebsite from '../client/views/WebsiteEdit.vue'

//Team Camp Pages
import TeamRoster from '../camps/views/TeamCamps/TeamRoster.vue'
import TeamRegistration from '../camps/views/TeamCamps/TeamRegistration.vue'
import CamperRegistration from '../camps/views/TeamCamps/CamperRegistration.vue'

//Membership Routes
import CreateMembership from '../client/views/Memberships/CreateMembership.vue'
import ViewMembership from '../client/views/Memberships/ViewMembership.vue'
import MemberCheckIn from '../client/views/Memberships/MemberCheckIn.vue'
import MemberProfile from '../client/views/Memberships/MemberProfile.vue'
import MemberLogin from '../members/views/MemberLogin.vue'
import MemberForgotPassword from '../members/views/MemberForgotPassword.vue'
import MemberResetPassword from '../client/views/Memberships/MemberResetPassword.vue'
import MemberCheckout from '../members/views/MemberCheckout.vue' //Checkout page
import MemberDashboard from '../members/views/MemberDashboard.vue'
import ViewSubscription from '../members/views/ViewSubscription.vue'
import MemberAccountSettings from '../members/views/MemberAccountSettings.vue'

//Old Routes to be deleted post Summer 2023
import CampRegistrar from '../camps/views/LegacySupport/CampRegistrar.vue'
import DeprecatedSupportRouting from '../camps/views/LegacySupport/DeprecatedSupportRouting.vue'

//Subscription Routes - Internal
import SubscriptionChoices from '../client/views/Subscriptions/SubscriptionChoices.vue'
import SubscriptionCheckout from '../client/views/Subscriptions/SubscriptionCheckout.vue'

//Subscription Routes - External
import ExternalPricingPage from '../client/views/Subscriptions/ExternalPricingPage.vue'
import ExternalSubscriptionCheckout from '../client/views/Subscriptions/ExternalSubscriptionCheckout.vue'
import ExternalSubscriptionCheckoutSecondary from '../client/views/Subscriptions/ExternalSubscriptionCheckoutSecondary.vue'
import AIDemoExternalCheckout from '../client/views/Subscriptions/AIDemoExternalCheckout.vue'

//Recruit Routes
import RecruitDashboard from '../recruits/views/RecruitDashboard.vue'
import RecruitInbox from '../recruits/views/RecruitInbox.vue'
import RecruitProfile from '../recruits/views/RecruitProfile.vue'
import Activities from '../recruits/views/Activities.vue'
import RecruitSettings from '../recruits/views/RecruitSettings.vue'
import RecruitWebsiteSettings from '../recruits/views/RecruitWebsite.vue'
import RecruitWebsiteIframe from '../recruits/views/RecruitWebsiteIframe.vue'
import RecruitExternalCheckout from '../recruits/views/RecruitExternalCheckout.vue'
import RecruitInternalCheckout from '../recruits/views/RecruitInternalCheckout.vue'

//Demo Routes
import AIDemo from '../client/views/AIDemo.vue'
import DigitalAI from '../client/views/DigitalAI.vue'
//Web Template Recruit Routes
import PortfolioBlobTemplate from '../recruits/views/AthleteProfileTemplates/CustomTemplateOne/Container.vue'

//shop and website routes
import SearchResults from '@/shop/views/SearchResults.vue'
import ViewPayment from '@/shop/views/ViewPayment.vue'
import CampCheckout from '@/shop/views/CampCheckout.vue'
import MembershipCheckout from '@/shop/views/MembershipCheckout.vue'

// Vue.use(VueRouter);

//CLIENT ROUTES ****
const clientRoutes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },
  {
    path: '/otl/:id',
    name: 'OneTimeLogin',
    component: OneTimeLogin,
  },
  {
    path: '/Dashboard/ViewCamp/Team/:id',
    name: 'ViewTeamCamp',
    component: ViewTeamCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/ViewCamp/individual/:id',
    name: 'ViewIndividualCamp',
    component: ViewIndividualCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/TeamList/:id',
    name: 'TeamList',
    component: TeamList,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/TeamList/:campId/:teamId',
    name: 'TeamProfile',
    component: TeamProfile,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/TeamList/:campId/:teamId/:camperId',
    name: 'TeamCamperProfile',
    component: TeamCamperProfile,
    props: true,
    meta: {
      reload: true,
    },
  },

  {
    path: '/Dashboard/CheckIn/:campId',
    name: 'CamperCheckIn',
    component: CamperCheckIn,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CheckIn/:id/:profile',
    name: 'CamperProfile',
    component: CamperProfile,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/UploadCamper/:id',
    name: 'UploadCamper',
    component: UploadCamper,
  },
  {
    path: '/Dashboard/CreateCamp/team/:id',
    name: 'CreateCampTeamOnID',
    component: CreateTeamCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateCamp/team/',
    name: 'CreateCampTeam',
    component: CreateTeamCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateCamp/individual/:id',
    name: 'CreateCampOnID',
    component: CreateIndividualCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateCamp/individual/:id/:mode',
    name: 'CreateCampOnIDEdit',
    component: CreateIndividualCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateCamp/individual/',
    name: 'CreateCamp',
    component: CreateIndividualCamp,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateEvent',
    name: 'CreateEvent',
    component: CreateEvent,
  },
  {
    path: '/Dashboard/CoachInbox',
    name: 'CoachInbox',
    component: CoachInbox,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/AccountSettings',
    name: 'AccountSettings',
    component: AccountSettings,
  },
  {
    path: '/Dashboard/VideoTour',
    name: 'VideoTour',
    component: VideoTour,
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: CoachDashboard,
    meta: {
      reload: true,
    },
  },
  {
    path: '/GetStarted',
    name: 'GetStarted',
    component: GetStarted,
  },
  {
    path: '/SelectAccount',
    name: 'SelectAccount',
    component: SelectAccount,
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../client/views/register.vue'),
  },
  {
    path: '/register/:isYearly',
    name: 'registerYearly',
    component: () => import('../client/views/register.vue'),
  },
  {
    path: '/register/:isYearly/:skipSchoolQuestion',
    name: 'registerYearlySkip',
    component: () => import('../client/views/register.vue'),
  },
  {
    path: '/verifyEmail/:userID',
    name: 'verifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/PaymentLinks',
    name: 'PaymentLinks',
    component: PaymentLinks,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Admin/Payouts',
    name: 'Payouts',
    component: Payouts,
    props: true,
    meta: {
      reload: true,
    },
  },

  {
    path: '/Dashboard/Admin/MembershipPayouts',
    name: 'MembershipPayouts',
    component: MembershipPayouts,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Admin/SMSMarketing',
    name: 'SMSMarketing',
    component: SMSMarketing,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Admin/RecruitReferral',
    name: 'RecruitReferral',
    component: RecruitReferral,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    //path to dashboard/payouts
    path: '/Dashboard/payments',
    name: 'Payments',
    component: Payments,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Analytics',
    name: 'Analytics',
    component: Analytics,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Transactions',
    name: 'Transactions',
    component: Transactions,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/BankAccounts',
    name: 'BankAccounts',
    component: BankAccounts,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    //path to dashboard/payouts
    path: '/Dashboard/CRM',
    name: 'CRM',
    component: CRM,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Calendar/MySchedule',
    name: 'MySchedule',
    component: MySchedule,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/Calendar/MySchedule/AddSimpleEvent',
    name: 'AddSimpleEvent',
    component: AddSimpleEvent,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    //path to dashboard/websitesetup
    path: '/Dashboard/WebsiteSetup',
    name: 'WebsiteSetup',
    component: WebsiteSetup,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    //path to dashboard/websitesetup
    path: '/Dashboard/WebsiteOverview',
    name: 'WebsiteOverview',
    component: WebsiteOverview,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    //path to dashboard/websitesetup with the newWebsiteID
    path: '/Dashboard/WebsiteOverview/:websiteID',
    name: 'WebsiteOverviewOnID',
    component: WebsiteOverview,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/EditWebsite/:subdomain',
    name: 'EditWebsite',
    component: EditWebsite,
    props: true,
  },
  {
    path: '/Dashboard/AdOverview/',
    name: 'AdOverview',
    component: AdOverview,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/AdOverview/AdLibrary',
    name: 'AdLibrary',
    component: AdLibrary,
    props: true,
    meta: {
      reload: true,
    },
  },

  {
    path: '/Dashboard/AdOverview/AdLibrary/CreateAdCreative',
    name: 'CreateAdCreative',
    component: CreateAdCreative,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/AdOverview/ViewCampaign/:campaignId',
    name: 'ViewCampaign',
    component: ViewCampaign,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/AdStore/',
    name: 'AdStore',
    component: AdStore,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreditStore/',
    name: 'CreditStore',
    component: CreditStore,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreditStore/PurchaseCredits/:id',
    name: 'CreditStorePurchase',
    component: AdCreditCheckout,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/AIDemo',
    name: 'AIDemo',
    component: AIDemo,
  },
  {
    path: '/DigitalAI',
    name: 'DigitalAI',
    component: DigitalAI,
  },
]

//---------------------------------------------------------------------

//CAMP ROUTES ****
const campRoutes = [
  {
    path: '/Camps/CampCheckout/:campId',
    name: 'CamperCheckout',
    component: CamperCheckout,
    props: true,
  },
  {
    path: '/Camps/CampCheckout/:campId/:primaryColor',
    name: 'CamperCheckoutWithColor',
    component: CamperCheckout,
    props: true,
  },
  {
    path: '/Camps/CampCheckout/:campId/:primaryColor/:secondaryColor',
    name: 'CamperCheckoutWithColors',
    component: CamperCheckout,
    props: true,
  },
  {
    path: '/Camps/Success',
    name: 'Success2',
    component: Success,
    props: true,
  },

  {
    path: '/Camps/MaxRegistration',
    name: 'MaxRegistration2',
    component: MaxRegistration,
    props: true,
  },
  {
    path: '/Camps/:junk',
    name: 'Void',
    component: Void,
    props: true,
  },
  {
    path: '/Camps',
    name: 'Void2',
    component: Void,
    props: true,
  },
]

const teamCampRoutes = [
  {
    path: '/TeamCamps/Register/Team/:campId/',
    name: 'TeamRegistration',
    component: TeamRegistration,
    props: true,
  },
  {
    path: '/TeamCamps/Register/Camper/:campId/:teamId',
    name: 'CamperRegistration',
    component: CamperRegistration,
    props: true,
  },
  {
    path: '/TeamCamps/Roster/:campId/:teamId',
    name: 'TeamRoster',
    component: TeamRoster,
    props: true,
  },
]

//MEMBERSHIP ROUTES
const MembershipRoutes = [
  {
    path: '/Dashboard/CreateMembership/:id',
    name: 'CreateMembershipOnID',
    component: CreateMembership,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateMembership/:id/:mode',
    name: 'CreateMembershipOnIDOnEdit',
    component: CreateMembership,
    props: true,
    meta: {
      reload: true,
    },
  },
  {
    path: '/Dashboard/CreateMembership',
    name: 'CreateMembership',
    component: CreateMembership,
    props: true,
  },
  {
    path: '/Dashboard/ViewMembership/:id',
    name: 'ViewMembership',
    component: ViewMembership,
    props: true,
  },
  {
    path: '/Dashboard/MembershipCheckIn/:id',
    name: 'MembershipCheckIn',
    component: MemberCheckIn,
    props: true,
  },
  {
    path: '/Dashboard/MembershipCheckIn/:membershipID/:memberID/:subscriptionID',
    name: 'MemberProfile',
    component: MemberProfile,
    props: true,
  },

  //Member Facing
  {
    path: '/Memberships/MembershipCheckout/:id',
    name: 'MemberCheckout',
    component: MemberCheckout,
    props: true,
  },
  {
    path: '/Memberships/MembershipCheckout/:id/:primaryColor',
    name: 'MemberCheckoutWithColor',
    component: MemberCheckout,
    props: true,
  },
  {
    path: '/Memberships/MembershipCheckout/:id/:primaryColor/:secondaryColor',
    name: 'MemberCheckoutWithColors',
    component: MemberCheckout,
    props: true,
  },
  {
    path: '/MemberLogin',
    name: 'MemberLogin',
    component: MemberLogin,
    props: true,
  },
  {
    path: '/MemberResetPassword',
    name: 'MemberResetPassword',
    component: MemberResetPassword,
    props: true,
  },
  {
    path: '/MemberForgotPassword',
    name: 'MemberForgotPassword',
    component: MemberForgotPassword,
    props: true,
  },
  {
    path: '/MemberDashboard',
    name: 'MemberDashboard',
    component: MemberDashboard,
    props: true,
  },
  {
    path: '/MemberDashboard/MemberAccountSettings',
    name: 'MemberAccountSettings',
    component: MemberAccountSettings,
    props: true,
  },
  {
    path: '/MemberDashboard/ViewSubscription/:membershipID/:memberID/:subscriptionID',
    name: 'ViewSubscription',
    component: ViewSubscription,
    props: true,
  },
  {
    path: '/Memberships/Success',
    name: 'Success',
    component: Success,
    props: true,
  },

  {
    path: '/Memberships/MaxRegistration',
    name: 'MaxRegistration',
    component: MaxRegistration,
    props: true,
  },
  {
    path: '/Memberships/:junk',
    name: 'Void3',
    component: Void,
    props: true,
  },
  {
    path: '/Memberships',
    name: 'Void4',
    component: Void,
    props: true,
  },
]

const SubscriptionRoutes = [
  {
    path: '/pricing',
    name: 'ExternalPricing',
    component: ExternalPricingPage,
    props: true,
  },
  {
    path: '/SubscriptionChoices',
    name: 'SubscriptionChoices',
    component: SubscriptionChoices,
    props: true,
  },
  {
    path: '/SubscriptionChoices/:id',
    name: 'SubscriptionChoicesOnID',
    component: SubscriptionChoices,
    props: true,
  },
  {
    path: '/SubscriptionChoices/:id/:skipSchoolQuestion',
    name: 'SubscriptionChoicesOnIDSkip',
    component: SubscriptionChoices,
    props: true,
  },
  {
    path: '/SubscriptionChoices/Checkout/:id/:payYearly',
    name: 'SubscriptionCheckoutOnIDYearly',
    component: SubscriptionCheckout,
    props: true,
  },

  {
    path: '/SubscriptionChoices/ExternalCheckout/:id/:payYearly',
    name: 'ExternalSubscriptionCheckout',
    component: ExternalSubscriptionCheckout,
    props: true,
  },
  {
    path: '/ExternalCheckoutSecondary',
    name: 'ExternalSubscriptionCheckoutSecondary',
    component: ExternalSubscriptionCheckoutSecondary,
    props: true,
  },
  {
    path: '/AIDemoExternalCheckout',
    name: 'AIDemoExternalCheckout',
    component: AIDemoExternalCheckout,
    props: true,
  },
]

const LegacySupportRoutes = [
  {
    path: '/camps/CampRegistrar/:id',
    name: 'CampRegistrar',
    component: CampRegistrar,
    props: true,
  },
]

const shopRoutes = [
  {
    path: '/coach/:coachId',
    name: 'SearchResultsBasic',
    component: SearchResults,
    props: route => ({ query: route.query }),
  },
  {
    path: '/coach/:coachId/:primaryColor',
    name: 'SearchResultsOneColor',
    component: SearchResults,
    props: route => ({ query: route.query }),
  },
  {
    path: '/coach/:coachId/:primaryColor/:secondaryColor',
    name: 'SearchResultsTwoColor',
    component: SearchResults,
    props: route => ({ query: route.query }),
  },

  {
    path: '/coach/:coachId/:primaryColor/:secondaryColor/viewpayment/:id',
    name: 'ViewPayment',
    component: ViewPayment,
    props: true,
  },
  {
    path: '/coach/:coachId/:primaryColor/:secondaryColor/campcheckout/:id',
    name: 'CampCheckout',
    component: CampCheckout,
    props: true,
  },
  {
    path: '/coach/:coachId/:primaryColor/:secondaryColor/membershipcheckout/:id/:paymentPeriod',
    name: 'MembershipCheckout',
    component: MembershipCheckout,
    props: true,
  },
]

const recruitRoutes = [
  {
    path: '/recruit/Dashboard',
    name: 'RecruitDashboard',
    component: RecruitDashboard,
    props: true,
  },
  {
    path: '/recruit/Dashboard/Inbox',
    name: 'RecruitInbox',
    component: RecruitInbox,
    props: true,
  },
  {
    path: '/recruit/Dashboard/Settings',
    name: 'RecruitSettings',
    component: RecruitSettings,
    props: true,
  },
  {
    path: '/recruit/Dashboard/Profile',
    name: 'RecruitProfile',
    component: RecruitProfile,
    props: true,
  },
  {
    path: '/recruit/Dashboard/Website',
    name: 'RecruitWebsiteSettings',
    component: RecruitWebsiteSettings,
    props: true,
  },
  {
    path: '/recruit/Dashboard/Activities',
    name: 'Activities',
    component: Activities,
    props: true,
  },
  {
    path: '/recruit/externalprofile/:id',
    name: 'RecruitWebsiteIframe',
    component: RecruitWebsiteIframe,
    props: true,
  },
  {
    path: '/RecruitExternalCheckout',
    name: 'RecruitExternalCheckout',
    component: RecruitExternalCheckout,
    props: true,
  },
  {
    path: '/RecruitInternalCheckout',
    name: 'RecruitInternalCheckout',
    component: RecruitInternalCheckout,
    props: true,
  },
  {
    path: '/CustomTemplateOne',
    name: 'PortfolioBlobTemplate',
    component: PortfolioBlobTemplate,
    props: true,
  },
]

//---------------------------------------------------------------------

//CATCHING ALL OLD CAMP URL PATHS FOR REROUTING TO NEW VERSION

const junkRoutes = [
  {
    path: '/:junk',
    name: 'Void5',
    component: DeprecatedSupportRouting,
    props: true,
  },
  {
    path: '/:junk/:junk2',
    name: 'Void6',
    component: DeprecatedSupportRouting,
    props: true,
  },
  {
    path: '/:junk/:junk2/:junk3',
    name: 'Void7',
    component: DeprecatedSupportRouting,
    props: true,
  },
  {
    path: '/:junk/:junk2/:junk3/:junk4',
    name: 'Void8',
    component: DeprecatedSupportRouting,
    props: true,
  },
  {
    path: '/:junk/:junk2/:junk3/:junk4/:junk5',
    name: 'Void9',
    component: DeprecatedSupportRouting,
    props: true,
  },
]

let mainRoutes = clientRoutes.concat(campRoutes)
mainRoutes = mainRoutes.concat(teamCampRoutes)
mainRoutes = mainRoutes.concat(MembershipRoutes)
mainRoutes = mainRoutes.concat(LegacySupportRoutes)
mainRoutes = mainRoutes.concat(SubscriptionRoutes)
mainRoutes = mainRoutes.concat(recruitRoutes)
mainRoutes = mainRoutes.concat(shopRoutes)
const routes = mainRoutes.concat(junkRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
//
// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') === null) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
