<template>
  <div
    :class="{
      'card-form': true,
      container: true,
      'd-flex': true,
      'justify-content-center': true,
      'mx-4': true,
    }"
    style="margin-top: 4%; min-width: 100%"
  >
    <div
      :class="{ 'card-form__inner': true }"
      v-bind:style="{
        minWidth: isMobile() ? '100%' : '',
        // minWidth: isMobile() ? '23rem' : '75%',
      }"
    >
      <div v-if="this.showMembershipDetails">
        <div class="d-flex justify-content-center" v-if="!isMobile()">
          <div
            class="arrow-steps clearfix"
            :style="{ maxWidth: isMobile() ? '200px' : '' }"
          >
            <div class="step current" @click="toggleMembershipDetails">
              <span>Details</span>
            </div>
            <div class="step" @click="toggleCustomizationForm">
              <span>Customize Form</span>
            </div>
            <div class="step" @click="togglePaymentDetails">
              <span>Pricing</span>
            </div>
          </div>
        </div>
        <center class="m-3">
          <img
            v-if="
              (this.newMembership.membershipLogo == '' ||
                this.newMembership.membershipLogo == null) &&
              !this.gif
            "
            src="../../../../public/SummerAthletes.png"
            class="card-img-top"
            style="max-width: 400px; max-height: 300px"
          />

          <img
            v-else
            :src="this.newMembership.membershipLogo"
            class="card-img-top"
            style="max-width: 500px"
          />
          <img
            id="mygif"
            src="../../../../public/1495.gif"
            class="animated-gif center"
            v-show="this.gif"
          />
        </center>

        <div class="mb-3">
          <label for="formFile" class="form-label">Upload Logo</label>
          <input
            class="form-control"
            type="file"
            id="formFile"
            @change="uploadImage($event)"
          />
        </div>

        <label
          class="form-label mt-3"
          for="form6Example1"
          style="margin-top: 5px"
          >Name</label
        >
        <input
          type="text"
          id="form6Example1"
          class="form-control"
          placeholder="e.g. Junior High Boys Basketball Membership"
          v-model="newMembership.membershipName"
          required
        />

        <label
          class="form-label mt-3"
          for="form6Example4"
          style="margin-top: 5px"
          >Location</label
        >
        <input
          type="text"
          id="form6Example4"
          class="form-control"
          placeholder="e.g. George Washington High School South Gym"
          v-model="newMembership.membershipLocation"
          required
        />

        <label class="form-label mt-3">Description</label>
        <textarea
          class="form-control"
          rows="4"
          placeholder="e.g. The Junior High Basketball Membership will teach your child...."
          v-model="newMembership.membershipDescription"
          required
        ></textarea>

        <button
          class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
          @click="toggleCustomizationForm"
          style="margin-top: 10px"
        >
          <span
            class="relative z-10 flex items-center justify-center gap-2 text-lg"
          >
            Customize Form
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <span
            class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
          ></span>
          <span
            class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
          ></span>
          <span
            class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
          ></span>
        </button>
      </div>

      <!--          Customization Form-->
      <div v-else-if="this.showFormCustomization">
        <div class="d-flex justify-content-center" v-if="!isMobile()">
          <div
            class="arrow-steps clearfix"
            :style="{ maxWidth: isMobile() ? '200px' : '' }"
          >
            <div
              :class="{
                step: true,
                finished: membershipDetailsComplete(),
                incomplete: !membershipDetailsComplete(),
              }"
              @click="toggleMembershipDetails"
            >
              <span>Details</span>
            </div>
            <div class="step current" @click="toggleCustomizationForm">
              <span>Customize Form</span>
            </div>
            <div class="step" @click="togglePaymentDetails">
              <span>Pricing</span>
            </div>
          </div>
        </div>
        <center class="m-3">
          <img
            v-if="
              (this.newMembership.membershipLogo == '' ||
                this.newMembership.membershipLogo == null) &&
              !this.gif
            "
            src="../../../../public/SummerAthletes.png"
            class="card-img-top"
            style="max-width: 400px; max-height: 300px"
          />

          <img
            v-else
            :src="this.newMembership.membershipLogo"
            class="card-img-top"
            style="max-width: 500px; max-height: 400px"
          />
          <img
            id="mygif"
            src="../../../../public/1495.gif"
            class="animated-gif center"
            v-show="this.gif"
          />
        </center>
        <div
          style="
            border: 3px solid #45487a;
            border-radius: 15px;
            max-width: 558px;
          "
        >
          <div class="m-3">
            <h2 class="text-center" style="margin-top: 50px">
              Default Form Preview
              <i
                class="fa-solid fa-circle-info"
                style="color: #45487a; cursor: pointer"
                @click="showDefaultFormExplanation"
              ></i>
            </h2>
            <!-- <h5 class="text-center center mb-3">(What Member Will See)</h5> -->
            <div class="row">
              <div class="card-input col">
                <label for="cardName" class="card-input__label"
                  >First Name</label
                >
                <input
                  type="text"
                  id="cardName"
                  class="card-input__input"
                  data-ref="cardName"
                  autocomplete="given-name"
                  required
                  disabled
                />
              </div>

              <div class="card-input col">
                <label for="cardName" class="card-input__label"
                  >Last Name</label
                >
                <input
                  type="text"
                  id="cardName"
                  class="card-input__input"
                  data-ref="cardName"
                  autocomplete="family-name"
                  disabled
                />
              </div>
            </div>
            <div v-if="newMembership.tShirtsProvided">
              <label>T-shirt size</label>
              <select
                class="card-input__input -select"
                style="margin-bottom: 20px"
                required
              >
                <option disabled selected value="">Size</option>
                <option value="YS" class="option">Youth Small</option>
                <option value="YM" class="option">Youth Medium</option>
                <option value="YL" class="option">Youth Large</option>
                <option value="AS" class="option">Adult Small</option>
                <option value="AM" class="option">Adult Medium</option>
                <option value="AL" class="option">Adult Large</option>
                <option value="AXL" class="option">Adult XLarge</option>
                <option value="AXXL" class="option">Adult XXLarge</option>
              </select>
            </div>

            <h3 v-show="!emergencyContactInfo.emergencyBool">
              Emergency Contact Information
            </h3>
            <div
              v-show="!emergencyContactInfo.emergencyBool"
              style="margin-top: 15px"
            >
              <div class="row">
                <div class="card-input col">
                  <label class="card-input__label">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    class="card-input__input"
                    disabled
                  />
                </div>
                <div class="card-input col">
                  <label class="card-input__label">Last Name</label>
                  <input
                    type="text"
                    id="name"
                    class="card-input__input"
                    disabled
                  />
                </div>
              </div>
              <div class="card-input">
                <label class="card-input__label">Phone Number</label>
                <input
                  type="text"
                  id="phoneNumber"
                  class="card-input__input"
                  disabled
                />
              </div>
              <div class="card-input">
                <label class="card-input__label">Email</label>
                <input
                  type="email"
                  id="email"
                  class="card-input__input"
                  disabled
                />
              </div>
              <div class="card-input">
                <label class="card-input__label">Address</label>
                <input
                  type="text"
                  id="address"
                  class="card-input__input"
                  disabled
                />
              </div>
              <div class="card-input">
                <label class="card-input__label">City</label>
                <input
                  type="text"
                  id="address"
                  class="card-input__input"
                  disabled
                />
              </div>

              <div class="card-form__row">
                <div class="card-form__col">
                  <div class="card-form__group">
                    <label for="cardMonth" class="card-input__label"
                      >State</label
                    >
                    <select class="card-input__input -select" id="cardMonth">
                      <option value="" disabled selected>State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div class="card-form__col">
                  <div class="card-input">
                    <label for="cardCvv">Zip Code</label>
                    <input type="text" class="card-input__input" disabled />
                  </div>
                </div>
              </div>
            </div>

            <input
              v-if="!this.requireEmergency"
              class="form-check-input me-2"
              type="checkbox"
              value=""
              id="form6Example8"
              v-model="emergencyContactInfo.emergencyBool"
            />
            <label
              v-if="!this.requireEmergency"
              class="form-check-label"
              for="form6Example8"
            >
              Emergency Contact will be same as credit card info
            </label>
          </div>
        </div>
        <br />
        <CustomFields
          v-if="this.showCustomFields"
          @back="onBack"
          :fields="this.newMembership.membershipCustomFields"
          :membership="true"
          ref="custom"
          class="mb-5"
          style="
            border: 3px solid #45487a;
            border-radius: 15px;
            max-width: 558px;
          "
        ></CustomFields>
        <div class="form-check d-flex mb-3">
          <input
            class="form-check-input me-2 create-camp-step-7"
            type="checkbox"
            value=""
            v-model="newMembership.requireWaiver"
          />
          <label class="form-check-label"> Require Waiver on Checkout </label>
        </div>
        <div v-if="newMembership.requireWaiver == true" class="mb-4">
          <label class="form-label">Custom Waiver</label>
          <textarea
            class="form-control"
            rows="4"
            placeholder="Leave blank if using Summer Athletes default waiver"
            v-model="newMembership.customWaiver"
            required
          ></textarea>
        </div>

        <div class="form-check d-flex mb-4">
          <input
            class="form-check-input me-2"
            type="checkbox"
            value=""
            v-model="newMembership.tShirtsProvided"
          />
          <label class="form-check-label create-camp-step-8">
            Collect T-Shirt Size
          </label>
        </div>

        <div class="row">
          <div class="col-md-3 col-sm-6">
            <button
              class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
              @click="toggleMembershipDetails"
              style="margin-top: 10px"
            >
              <span
                class="relative z-10 flex items-center justify-center gap-2 text-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                Back
              </span>
              <span
                class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
              ></span>
            </button>
          </div>
          <div class="col-md-9 col-sm-6">
            <button
              class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
              @click="togglePaymentDetails"
              style="margin-top: 10px"
            >
              <span
                class="relative z-10 flex items-center justify-center gap-2 text-lg"
              >
                Set Pricing
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span
                class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
              ></span>
              <span
                class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
              ></span>
              <span
                class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
              ></span>
            </button>
          </div>
        </div>
      </div>

      <div v-else-if="this.showPaymentDetails">
        <div class="d-flex justify-content-center" v-if="!isMobile()">
          <div
            class="arrow-steps clearfix"
            :style="{ maxWidth: isMobile() ? '200px' : '' }"
          >
            <div
              :class="{
                step: true,
                finished: membershipDetailsComplete(),
                incomplete: !membershipDetailsComplete(),
              }"
              @click="toggleMembershipDetails"
            >
              <span>Details</span>
            </div>
            <div class="step finished" @click="toggleCustomizationForm">
              <span>Customize Form</span>
            </div>
            <div class="step current" @click="togglePaymentDetails">
              <span>Pricing</span>
            </div>
          </div>
        </div>
        <center class="m-3">
          <img
            v-if="
              (this.newMembership.membershipLogo == '' ||
                this.newMembership.membershipLogo == null) &&
              !this.gif
            "
            src="../../../../public/SummerAthletes.png"
            class="card-img-top"
            style="max-width: 400px; max-height: 300px"
          />

          <img
            v-else
            :src="this.newMembership.membershipLogo"
            class="card-img-top"
            style="max-width: 500px; max-height: 400px"
          />
          <img
            id="mygif"
            src="../../../../public/1495.gif"
            class="animated-gif center"
            v-show="this.gif"
          />
        </center>
        <li
          class="list-group-item"
          v-for="index in this.newMembership.numPayPeriods"
          :key="index"
        >
          <div class="row mb-3">
            <div class="col">
              <label
                class="form-label create-membership-step-1"
                for="form6Example6"
                style="margin-top: 5px"
                >Price in USD</label
              >
              <input
                type="number"
                id="form6Example6"
                class="form-control"
                placeholder="75"
                min="0"
                v-model="newMembership.tempPay[index - 1].price"
                required
              />
              <i
                class="fa-solid fa-trash removeDay m-1"
                @click="removeDay(index)"
              ></i>
            </div>
            <div class="col">
              <label
                class="form-label create-membership-step-2"
                for="form6Example6"
                style="margin-top: 5px"
                >{{
                  isMobile() ? 'Time Period' : 'Length of Time Period'
                }}</label
              >
              <select
                class="form-select form-control"
                id="periods"
                v-model="newMembership.tempPay[index - 1].period"
                required
              >
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="6month">6 Months</option>
                <option value="year">Year</option>
              </select>
            </div>
          </div>
        </li>
        <div class>
          <center>
            <button
              class="btn btn-primary btn-block mb-4 create-membership-step-3 rounded-pill"
              @click="addPayPeriod"
              style="margin-top: 10px"
              v-if="!newMembership.maxPayPeriods"
            >
              Add Additional Pay Period Rate
            </button>
          </center>
        </div>
        <div class="form-check d-flex mt-5">
          <input
            class="form-check-input me-2 create-camp-step-9"
            type="checkbox"
            value=""
            v-model="newMembership.showPlatformFees"
          />
          <label class="form-check-label">
            Show Platform Fees at Checkout
            <i
              class="fa-solid fa-circle-info"
              style="color: #45487a; cursor: pointer"
              @click="showPlatformFeesExplanation"
            ></i>
          </label>
        </div>
        <div class="row mt-3">
          <div class="col-md-3 col-sm-6">
            <button
              class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
              @click="toggleCustomizationForm"
              style="margin-top: 10px"
            >
              <span
                class="relative z-10 flex items-center justify-center gap-2 text-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                Back
              </span>
              <span
                class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
              ></span>
            </button>
          </div>
          <div class="col-md-9 col-sm-6">
            <button
              class="create-button card-form__button create-camp-step-12 mt-3 rounded-pill group relative w-full py-4 px-6 font-medium text-white bg-gradient-to-r from-[#00c800] to-[#00d800] transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 overflow-hidden flex items-center justify-center"
              @click="createMembership()"
              style="margin-top: 10px"
            >
              <span
                class="relative z-10 flex items-center justify-center gap-2 text-lg"
              >
                {{ editMode ? 'Save Changes' : 'Create Membership' }}
              </span>
              <span
                class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#00d800] to-[#00c800] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
              ></span>
              <span
                class="absolute -inset-px rounded-pill border-2 border-white border-opacity-20"
              ></span>
              <span
                class="absolute inset-0 w-full h-full transition-all duration-300 ease-out scale-0 rounded-full bg-white opacity-30 group-hover:scale-100 group-hover:opacity-0 animate-ripple-on-click"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import CustomFields from '../../components/CustomFields.vue'
export default {
  components: { CustomFields },
  data() {
    return {
      gif: false,
      editMode: false,
      toggle: false,
      dynamicToggle: false,
      showMembershipDetails: true,
      showCustomFields: false,
      showPaymentDetails: false,
      showFormCustomization: false,
      setCustomEmailReceipt: false,
      emergencyContactInfo: { emergencyBool: true },
      requireEmergency: false,
      savedData: {},
      user: {},
      membershipID: null,
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false,
      },
      newMembership: {
        coachID: '',
        numPayPeriods: 1,
        maxPayPeriods: false,
        tempPay: [{ period: '', price: '' }],
        membershipCustomFields: [],
        membershipName: '',
        membershipDescription: '',
        membershipLocation: '',
        membershipLogo: '',
        membershipPayPeriod: {},
        membershipOrganization: '',
        membershipMaxParticipants: '',
        requireWaiver: false,
        tShirtsProvided: false,
        showPlatformFees: false,
        customWaiver: '',
        customEmailReceipt: '',
      },
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    toggleMembershipDetails() {
      this.showMembershipDetails = true
      this.showFormCustomization = false
      this.showCustomFields = false
      this.showPaymentDetails = false
      window.scrollTo(0, 0)
    },
    toggleCustomizationForm() {
      this.showMembershipDetails = false
      this.showPaymentDetails = false
      this.showFormCustomization = true
      this.showCustomFields = true
      window.scrollTo(0, 0)
    },
    togglePaymentDetails() {
      this.showPaymentDetails = true
      this.showMembershipDetails = false
      this.showFormCustomization = false
      this.showCustomFields = false
      window.scrollTo(0, 0)
    },
    showDefaultFormExplanation() {
      swal({
        title: 'Default Form Explanation',
        text: 'This is a preview of what information the default signup form collects. This portion is not to be filled out, only to see what fields we already ask for, and you can specify your additional fields below accordingly, if any.',
        icon: 'info',
      })
    },
    showPlatformFeesExplanation() {
      swal({
        title: 'Show Platform Fees',
        text: 'Select this option if you would like to display the breakdown of all fees at checkout (Platform fee, credit card fee, etc). This option is not recommended.',
        icon: 'info',
      })
    },
    showCustomizeEmailReceiptExplanation() {
      swal({
        title: 'Customize Email Receipt',
        text: 'This text will be added to the default email receipt for this membership. Use this option to provide custom information to your members upon signup.',
        icon: 'info',
      })
    },
    uploadImage(event) {
      if (this.user.subscription.subscriptionTier < 2) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least a Pro user to have personal branding. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      this.gif = true
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadFile'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          this.newMembership.membershipLogo = response.data
          this.gif = false
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error',
          )
        })
    },
    async presetFields() {
      let membershipID = this.$route.params.id
      this.editMode = this.$route.params.mode === 'edit'
      if (membershipID) {
        await this.getMembershipInfo(membershipID)
      } else {
        console.log('normal')
      }
    },
    async getMembershipInfo(membershipID) {
      this.membershipID = membershipID
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMembershipByMembershipID?id=' +
        membershipID
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          let membership = response.data.membership

          this.newMembership.membershipName = membership.membershipName
          this.newMembership.membershipDescription =
            membership.membershipDescription
          this.newMembership.membershipLocation = membership.membershipLocation
          this.newMembership.membershipLogo = membership.membershipLogo
          this.newMembership.membershipOrganization =
            membership.membershipOrganization
          this.newMembership.membershipMaxParticipants =
            membership.membershipMaxParticipants
          this.newMembership.requireWaiver = membership.requireWaiver
          this.newMembership.tShirtsProvided = membership.tShirtsProvided
          this.newMembership.showPlatformFees = !membership.hidePlatformFees
          this.newMembership.customWaiver = membership.customWaiver
          this.newMembership.customEmailReceipt = membership.customEmailReceipt
          if (this.newMembership.customEmailReceipt?.length > 0) {
            this.setCustomEmailReceipt = true
          }
          this.newMembership.membershipCustomFields = []
          this.newMembership.membershipCustomFields =
            response.data.membership.customFields
          if (membership.membershipPayPeriod.weeklyPrice) {
            this.newMembership.tempPay[0] = {
              price: membership.membershipPayPeriod.weeklyPrice.toString(),
              period: 'week',
            }
          }
          if (membership.membershipPayPeriod.monthlyPrice) {
            if (!this.newMembership.tempPay[0].period) {
              this.newMembership.tempPay[0] = {
                price: membership.membershipPayPeriod.monthlyPrice.toString(),
                period: 'month',
              }
            } else {
              this.newMembership.tempPay.push({
                price: membership.membershipPayPeriod.monthlyPrice.toString(),
                period: 'month',
              })
              this.newMembership.numPayPeriods += 1
            }
          }
          if (membership.membershipPayPeriod.semiAnnualPrice) {
            if (!this.newMembership.tempPay[0].period) {
              this.newMembership.tempPay[0] = {
                price:
                  membership.membershipPayPeriod.semiAnnualPrice.toString(),
                period: 'semiannual',
              }
            } else {
              this.newMembership.tempPay.push({
                price:
                  membership.membershipPayPeriod.semiAnnualPrice.toString(),
                period: '6month',
              })
              this.newMembership.numPayPeriods += 1
            }
          }
          if (membership.membershipPayPeriod.annualPrice) {
            if (!this.newMembership.tempPay[0].period) {
              this.newMembership.tempPay[0] = {
                price: membership.membershipPayPeriod.annualPrice.toString(),
                period: 'annual',
              }
            } else {
              this.newMembership.tempPay.push({
                price: membership.membershipPayPeriod.annualPrice.toString(),
                period: 'year',
              })
              this.newMembership.numPayPeriods += 1
            }
          }
          this.newMembership.console.log(membership)
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
        })
    },
    customFields() {
      this.toggle = true
    },

    validateFees() {
      console.log('validating')
      for (let x = 0; x < this.newMembership.tempPay.length; x++) {
        if (
          this.newMembership.tempPay[x].period == 'week' &&
          this.newMembership.tempPay[x].price
        ) {
          if (this.newMembership.membershipPayPeriod.weeklyPrice) {
            swal('Error', 'Multiple rates for same week time period', 'error')

            return 1
          }
          this.newMembership.membershipPayPeriod.weeklyPrice =
            this.newMembership.tempPay[x].price
        }
        if (
          this.newMembership.tempPay[x].period == 'month' &&
          this.newMembership.tempPay[x].price
        ) {
          if (this.newMembership.membershipPayPeriod.monthlyPrice) {
            swal('Error', 'Multiple rates for same month time period', 'error')

            return 1
          }
          this.newMembership.membershipPayPeriod.monthlyPrice =
            this.newMembership.tempPay[x].price
        }
        if (
          this.newMembership.tempPay[x].period == '6month' &&
          this.newMembership.tempPay[x].price
        ) {
          if (this.newMembership.membershipPayPeriod.semiAnnualPrice) {
            swal(
              'Error',
              'Multiple rates for same 6 month time period',
              'error',
            )

            return 1
          }
          this.newMembership.membershipPayPeriod.semiAnnualPrice =
            this.newMembership.tempPay[x].price
        }
        if (
          this.newMembership.tempPay[x].period == 'year' &&
          this.newMembership.tempPay[x].price
        ) {
          if (this.newMembership.membershipPayPeriod.annualPrice) {
            swal('Error', 'Multiple rates for same year time period', 'error')

            return 1
          }
          this.newMembership.membershipPayPeriod.annualPrice =
            this.newMembership.tempPay[x].price
        }
        if (
          !this.newMembership.membershipPayPeriod.annualPrice &&
          !this.newMembership.membershipPayPeriod.semiAnnualPrice &&
          !this.newMembership.membershipPayPeriod.monthlyPrice &&
          !this.newMembership.membershipPayPeriod.weeklyPrice
        ) {
          swal('Error', 'Please fill out at least one pay option', 'error')
          this.newMembership.membershipPayPeriod = {}
          return 1
        }
        if (
          !this.newMembership.tempPay[x].price ||
          !this.newMembership.tempPay[x].period
        ) {
          swal(
            'Error',
            "It looks like you've left some options blank. Please either fill them out or delete the pay period if not needed.",
            'error',
          )
          return 1
        }
      }
      return 0
    },
    addPayPeriod() {
      this.newMembership.numPayPeriods++
      if (this.newMembership.numPayPeriods == 4) {
        this.newMembership.maxPayPeriods = true
      }
      this.newMembership.tempPay.push({ period: '', price: '' })
    },

    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    onBack(x) {
      this.toggle = false
      this.newMembership.membershipCustomFields = [...x]
    },
    logUserOut() {
      localStorage.removeItem('jwt')
      this.$router.push('/')
    },
    validateInfo() {
      if (this.newMembership.membershipName == '') {
        swal('Error', 'Name must be filled out.', 'error')
        return 1
      }
      if (this.newMembership.membershipLocation == '') {
        swal('Error', 'Location must be filled out.', 'error')
        return 1
      }
      if (this.newMembership.membershipPayPeriod == null) {
        swal('Error', 'Price must be filled out.', 'error')
        return 1
      }
      if (this.newMembership.membershipDescription == '') {
        swal('Error', 'Description must be filled out.', 'error')
        return 1
      }
    },

    async createMembership() {
      this.savedData = this.newMembership

      if (!this.membershipDetailsComplete()) {
        swal({
          title: 'Details Incomplete',
          text: 'The details page is incomplete. Please fill out all fields and then return to create your membership.',
          icon: 'error',
          buttons: true,
          dangerMode: true,
        }).then(fillOutMembershipDetails => {
          if (fillOutMembershipDetails) {
            this.toggleMembershipDetails()
          }
        })
        return
      }
      if (this.validateInfo() === 1) {
        return
      }
      if (this.validateFees() == 1) {
        console.log('TEST')
        this.newMembership.membershipPayPeriod = {}
        return
      }
      swal({
        title: 'Confirm Details',
        text: `Once the link has had a purchase, no changes are allowed. Until then, everything that was filled out can be updated.`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          let url = this.editMode
            ? import.meta.env.VITE_APP_DB_URL + 'membership/editMembership'
            : import.meta.env.VITE_APP_DB_URL + 'membership/createMembership'
          axios
            .post(
              url,
              {
                coachID: this.user._id,
                customFields: this.newMembership.membershipCustomFields,
                membershipName: this.newMembership.membershipName,
                membershipDescription: this.newMembership.membershipDescription,
                membershipLocation: this.newMembership.membershipLocation,
                membershipLogo: this.newMembership.membershipLogo,
                membershipPayPeriod: this.newMembership.membershipPayPeriod,
                membershipOrganization:
                  this.newMembership.membershipOrganization,
                membershipMaxParticipants:
                  this.newMembership.membershipMaxParticipants,
                requireWaiver: this.newMembership.requireWaiver,
                tShirtsProvided: this.newMembership.tShirtsProvided,
                hidePlatformFees: !this.newMembership.showPlatformFees,
                customWaiver: this.newMembership.customWaiver,
                customEmailReceipt: this.newMembership.customEmailReceipt,
                membershipID: this.membershipID,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              },
            )
            .then(() => {
              this.$router.push('/Dashboard')
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
              this.newMembership = this.savedData
            })

          if (this.editMode) {
            swal(
              'Membership Updated',
              'Your membership has been updated',
              'success',
            )
          } else {
            swal('Success', 'Membership Created!', 'success')
          }
          this.$emit('createMembership')
        } else {
          this.newMembership.membershipPayPeriod = {}
        }
      })
    },
    removeDay(index) {
      if (this.newMembership.tempPay.length > 1) {
        this.newMembership.numPayPeriods = this.newMembership.numPayPeriods - 1
        this.newMembership.tempPay.splice(index - 1, 1)
        this.newMembership.maxPayPeriods = false
      }
    },
    membershipDetailsComplete() {
      if (this.newMembership.membershipName == '') {
        console.log('MEMBERSHIP NAME IS EMPTY')

        return false
      }
      if (this.newMembership.membershipLocation == '') {
        console.log('LOCATION IS EMPTY')

        return false
      }
      if (this.newMembership.membershipDescription == '') {
        console.log('MEMBERSHIP DESCRIPTION IS EMPTY')

        return false
      }
      return true
    },
  },
  created() {
    this.getUserDetails()
    this.presetFields()
  },
}
</script>
<style>
.editor-datetime {
  position: relative;
}
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.removeDay {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeDay:hover {
  color: darkred;
  cursor: pointer;
}

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #777;
  cursor: pointer;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 1;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: '✔';
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #45487a;
}

.arrow-steps .step.incomplete {
  color: #fff;
  background-color: #ffcc00;
}

.arrow-steps .step.finished {
  color: #fff;
  background-color: #19d412;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #45487a;
}

.arrow-steps .step.incomplete:after {
  border-left: 17px solid #ffcc00;
}

.arrow-steps .step.finished:after {
  border-left: 17px solid #19d412;
}

/* Add a subtle texture to the buttons */
.create-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
}

/* Add ripple animation */
@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.create-button:active .animate-ripple-on-click {
  animation: ripple 0.8s ease-out;
}

/* Add shine effect */
.create-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
  transition: left 0.7s;
}

.create-button:hover::after {
  left: 100%;
}
</style>
