<template>
  <div
    class="modal fade"
    id="PeopleDetailsModal"
    tabindex="-1"
    aria-labelledby="PeopleDetailsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content rounded-xl shadow-lg border border-gray-200">
        <div class="modal-header border-b border-gray-200 p-4">
          <h5
            class="text-xl font-semibold text-gray-900"
            id="PeopleDetailsModalLabel"
          >
            {{ firstName }} {{ lastName }}'s Details
          </h5>

          <button
            type="button"
            class="p-1.5 bg-gray-100 text-gray-500 custom-rounded-button hover:bg-gray-200 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            data-bs-dismiss="modal"
            id="PeopleDetailsCloseButton"
            aria-label="Close"
          >
            <svg
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <button
            type="button"
            id="closeModal"
            class="hidden"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div class="modal-body p-6">
          <PeopleDetails
            :firstName="firstName"
            :lastName="lastName"
            :camps="camps"
            :teamCamps="teamCamps"
            :memberships="memberships"
          ></PeopleDetails>
        </div>
        <div class="modal-footer border-t border-gray-200 p-4 flex justify-end">
          <button
            type="button"
            class="px-6 py-2 bg-gray-100 text-gray-700 custom-rounded-button text-sm font-medium hover:bg-gray-200 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PeopleDetails from '@/client/components/CRM/PeopleDetails.vue'

export default {
  components: { PeopleDetails },
  props: {
    firstName: String,
    lastName: String,
    camps: Array,
    teamCamps: Array,
    memberships: Array,
  },
}
</script>

<style scoped>
.modal-dialog {
  max-width: 700px;
}

.custom-rounded-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
}
</style>
