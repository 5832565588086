<template>
  <div
    class="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-7xl mx-auto">
      <ClientLayout v-if="user && !isMobile" :user="user"></ClientLayout>

      <!-- Alert for suspended account -->
      <div v-if="lock" class="mb-12">
        <div
          class="max-w-3xl mx-auto px-4 py-3 rounded-lg bg-red-50 border border-red-200"
        >
          <div class="flex items-center justify-center text-red-700">
            <i class="fa-solid fa-exclamation-circle mr-2 text-xl"></i>
            <p class="text-center font-medium">
              Your account has been suspended due to failed charges on your
              card. Please re-sign up with correct billing information.
            </p>
          </div>
        </div>
      </div>

      <!-- Pricing Header -->
      <div class="text-center mb-16">
        <h1 class="text-4xl font-bold text-indigo-900 mb-4">
          Choose Your Plan
        </h1>
        <p class="text-xl text-gray-600 max-w-2xl mx-auto">
          Select the perfect plan for your business needs
        </p>
      </div>

      <!-- Billing Toggle -->
      <div class="flex justify-center items-center space-x-8 mb-12">
        <span
          :class="[
            'text-lg font-medium',
            !yearly ? 'text-indigo-900' : 'text-gray-500',
          ]"
        >
          Monthly Billing
        </span>

        <label class="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            @click="setPeriod()"
            :checked="yearly"
            class="sr-only peer"
          />
          <div
            class="w-20 h-10 bg-gray-300 peer-checked:bg-green-500 rounded-full peer peer-checked:after:translate-x-10 after:content-[''] after:absolute after:top-1 after:left-1 after:bg-white after:rounded-full after:h-8 after:w-8 after:transition-all"
          ></div>
        </label>

        <span
          :class="[
            'text-lg font-medium',
            yearly ? 'text-indigo-900' : 'text-gray-500',
          ]"
        >
          Annual Billing
        </span>
      </div>

      <!-- Savings Message -->
      <div class="text-center mb-12">
        <p v-if="yearly" class="text-lg text-green-600 font-medium">
          <i class="fa-solid fa-gift mr-2"></i>
          Save up to 25% with annual billing!
        </p>
        <p v-else class="text-lg text-gray-600">
          <i class="fa-solid fa-clock mr-2"></i>
          Flexible monthly billing - No long-term commitment
        </p>
      </div>

      <!-- Pricing Cards -->
      <div
        class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto px-4"
        v-if="!checkingRookie"
      >
        <ProDetailsCard
          :yearly="yearly"
          :isMobile="isMobile"
          :subscription="pro"
          :isDisabled="getProDisabled"
          :class="{
            'ring-2 ring-indigo-600':
              wantedSubscription.subscriptionName === 'Pro',
          }"
          @buySubscription="buySubscription"
        />

        <!-- Highlighted All Star Card -->
        <div
          class="relative transform hover:scale-105 transition-all duration-300"
        >
          <div
            class="absolute inset-0 bg-gradient-to-b from-indigo-600 to-indigo-800 rounded-3xl transform -rotate-1"
          ></div>
          <AllStarDetailsCard
            :yearly="yearly"
            :isMobile="isMobile"
            :isDisabled="getAllStarDisabled"
            :class="{
              'ring-2 ring-indigo-600':
                wantedSubscription.subscriptionName === 'All Star',
            }"
            @buySubscription="buySubscription"
            class="transform rotate-1"
          />
        </div>

        <HallOfFameDetailsCard
          :yearly="yearly"
          :isMobile="isMobile"
          :class="{
            'ring-2 ring-indigo-600':
              wantedSubscription.subscriptionName === 'Hall of Fame',
          }"
          @buySubscription="buySubscription"
        />
      </div>

      <!-- School Email Check -->
      <div
        v-else
        class="max-w-2xl mx-auto text-center p-8 bg-white rounded-2xl shadow-xl"
      >
        <h3 class="text-2xl font-bold text-indigo-900 mb-8">
          Do you have a school affiliated email?
        </h3>
        <div class="grid grid-cols-2 gap-6">
          <a
            :href="getSchoolRegistrationLink"
            class="px-8 py-4 bg-indigo-600 text-white text-lg font-semibold rounded-xl hover:bg-indigo-700 transition-colors duration-200"
          >
            Yes
          </a>
          <a
            :href="getSubscriptionRookieCheckoutLink"
            class="px-8 py-4 bg-gray-100 text-indigo-900 text-lg font-semibold rounded-xl hover:bg-gray-200 transition-colors duration-200"
          >
            No
          </a>
        </div>
      </div>

      <!-- Current Plan Badge -->
      <div
        class="mt-12 text-center"
        v-if="currentSubscription.subscriptionName"
      >
        <span
          class="inline-flex items-center px-4 py-2 rounded-full bg-indigo-100 text-indigo-800"
        >
          <i class="fa-solid fa-star mr-2"></i>
          Current Plan: {{ currentSubscription.subscriptionName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import FreeDetailsCard from "../../components/Subscriptions/FreeDetailsCard.vue";
import ProDetailsCard from '../../components/Subscriptions/ProDetailsCard.vue'
import AllStarDetailsCard from '../../components/Subscriptions/AllStarDetailsCard.vue'
import HallOfFameDetailsCard from '../../components/Subscriptions/HallOfFameDetailsCard.vue'
import ClientLayout from '../../components/ClientLayout.vue'
import axios from 'axios'
import swal from 'sweetalert'
export default {
  components: {
    ClientLayout,
    ProDetailsCard,
    AllStarDetailsCard,
    HallOfFameDetailsCard,
  },
  data() {
    return {
      yearly: false,
      subscriptions: [],
      currentSubscription: {
        subscriptionName: '',
        subscriptionTier: 0,
        subscriptionPayPeriod: '',
      },
      user: null,
      checkingRookie: false,
      skipSchoolQuestion: false,
      subscription: {},
      wantedSubscription: {
        subscriptionName: '',
        subscriptionTier: 0,
        subscriptionPayPeriod: '',
      },
      lock: false,
    }
  },
  methods: {
    setPeriod() {
      this.yearly = !this.yearly
    },
    buySubscription(subscription) {
      this.subscription = subscription

      if (subscription.subscriptionTier === 1 && !this.skipSchoolQuestion) {
        this.checkingRookie = true
      } else {
        if (
          subscription.subscriptionTier <
          this.currentSubscription.subscriptionTier
        ) {
          swal({
            title: 'Error',
            text: 'This plan is lower than your current plan. You cannot downgrade to this plan.',
            icon: 'error',
          })
        } else {
          if (this.user) {
            this.$router.push(
              '/SubscriptionChoices/Checkout/' +
                subscription._id +
                '/' +
                this.yearly,
            )
          } else {
            this.$router.push(
              '/SubscriptionChoices/CreateAccount/' +
                subscription._id +
                '/' +
                this.yearly,
            )
          }
        }
      }
    },

    setCurrentStatus() {
      if (
        this.$route.params.skipSchoolQuestion &&
        this.$route.params.skipSchoolQuestion != 'false'
      ) {
        this.skipSchoolQuestion = true
      }
      let tempUser = localStorage.getItem('user')
      console.log(tempUser)
      if (tempUser !== '[object Object]') {
        this.user = JSON.parse(tempUser)
      }
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'subscription/getSubscription?id=' +
        this.$route.params.id
      axios.get(url).then(response => {
        if (response.data.subscription) {
          this.currentSubscription = response.data.subscription
        }
      })
      if (this.$route.query.wantedID) {
        url =
          import.meta.env.VITE_APP_DB_URL +
          'subscription/getSubscription?id=' +
          this.$route.query.wantedID
        axios.get(url).then(response => {
          this.wantedSubscription = response.data.subscription
          swal(
            'Upgrade to Earn More!',
            'To start maximizing your revenue, please upgrade to an ' +
              this.wantedSubscription.subscriptionName +
              ' subscription or higher today!',
            'info',
          )
        })
      }
    },
  },
  created() {
    this.setCurrentStatus()
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getProDisabled() {
      if (this.currentSubscription.subscriptionTier >= 2) {
        return true
      } else {
        return false
      }
    },
    getAllStarDisabled() {
      if (this.currentSubscription.subscriptionTier >= 3) {
        return true
      } else {
        return false
      }
    },
    getSubscriptionRookieCheckoutLink() {
      return (
        import.meta.env.VITE_APP_CLIENT_URL +
        'SubscriptionChoices/Checkout/' +
        this.subscription._id +
        '/' +
        this.yearly
      )
    },
    getSchoolRegistrationLink() {
      return (
        import.meta.env.VITE_APP_CLIENT_URL +
        'register/' +
        this.yearly +
        '/true'
      )
    },
  },
  mounted() {
    if (this.$route.query.lock) {
      this.lock = true
      // document.getElementById("advertisementPopUpClose").click();
    }
  },
}
</script>

<style scoped>
/* Remove all existing styles as we're using Tailwind */
</style>
