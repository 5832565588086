<template>
  <div>
    <div
      class="modal fade"
      id="sendToFriends"
      tabindex="-1"
      aria-labelledby="sendToFriendsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center" id="sendToFriendsLabel">
              Transfer Funds
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>

            <button
              type="button"
              id="sendToFriendsClose"
              class="btn-close"
              data-bs-dismiss="modal"
              style="display: none"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="center justify-content-center w-100"
              v-if="
                !sendToFriendConfirmation &&
                !inviteFriend &&
                !invitedFriendSuccessfully &&
                !sendMoneyToFriend
              "
            >
              <h4 class="text-center w-100" style="margin-top: 5px">
                Send Money to Friends - Instantly!
              </h4>

              <h5 class="mt-5">
                {{ addingContact ? 'Friend' : 'Recipient' }} Email
                <i
                  class="fa-solid fa-circle-info"
                  style="color: #45487a; cursor: pointer"
                  @click="showRecipientEmailExplanation"
                ></i>
              </h5>
              <input
                id="sendToFriendEmail"
                type="email"
                class="form-control"
                @input="event => updateSendToFriendEmail(event.target.value)"
                required
              />
            </div>
            <div
              class="center justify-content-center w-100"
              v-else-if="sendToFriendConfirmation"
            >
              <h4 class="text-center w-100" style="margin-top: 5px">
                Does this information look correct?
              </h4>

              <div class="mt-5 information-card">
                <h5 class="">
                  Name: {{ receivingFriend.firstName }}
                  {{ receivingFriend.lastName }}
                </h5>
                <h5>Email: {{ receivingFriend.email }}</h5>
                <h5>
                  Phone: (xxx)-xxx-{{ receivingFriend.phoneNumber.slice(-4) }}
                </h5>
              </div>
            </div>
            <div v-else-if="inviteFriend">
              <h5 class="text-center w-100 text-muted" style="margin-top: 5px">
                This email isn't associated with an account
              </h5>
              <br />
              <h4 class="text-center w-100">
                Sending money to a Summer Athletes account is
                <strong>free and instant</strong>. Would you like to invite them
                to join?
              </h4>
              <div class="center text-center justify-content-center w-100">
                <button
                  type="button"
                  class="center text-center btn btn-primary btn-lg w-50 mt-5"
                  @click="inviteFriendViaEmail()"
                >
                  Invite Friend
                </button>
              </div>
            </div>
            <div v-else-if="invitedFriendSuccessfully">
              <h5 class="text-center w-100 text-muted" style="margin-top: 5px">
                Invitation sent to {{ sendToFriendEmail }}
              </h5>
              <br />
              <h4 class="text-center w-100">
                Here is the link to invite them yourself:

                <a
                  :href="`${inviteFriendLink}${user.firstName}${user.lastName}`"
                  target="_blank"
                  >Invite Link</a
                >
              </h4>
            </div>
            <div v-else-if="sendMoneyToFriend">
              <div class="center justify-content-center w-100">
                <label
                  class="form-label text-center w-100"
                  for="bankDetailsForm"
                  style="margin-top: 5px"
                  >Remaining Balance</label
                >
                <h1
                  v-if="(accountBalance - sendMoneyAmount).toFixed(2) > 0"
                  class="text-center w-100"
                >
                  ${{ (accountBalance - sendMoneyAmount).toFixed(2) }}
                </h1>
                <h1 v-else class="text-center w-100" style="color: red">
                  ${{ (accountBalance - sendMoneyAmount).toFixed(2) }}
                </h1>
              </div>

              <br />
              <br />
              <label
                class="form-label"
                for="bankDetailsForm"
                style="margin-top: 5px"
                >Transfer Amount (USD)</label
              >
              <input
                required
                @input="event => updateSendMoneyAmount(event.target.value)"
                id="transferAmount"
                class="form-control"
                type="number"
              />
              <br />
              <label
                class="form-label"
                for="bankDetailsForm"
                style="margin-top: 5px"
                >Receiving Account</label
              >
              <ul class="list-group">
                <li class="list-group-item">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    :value="receivingFriend.email"
                    v-model="this.receivingFriend.email"
                  />
                  {{ receivingFriend.email }}
                </li>
              </ul>
            </div>
          </div>
          <br />
          <br />
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-secondary btn-lg"
              data-bs-dismiss="modal"
              @click="cancelSendToFriends()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-lg"
              style="background-color: #363166; color: white; float: right"
              v-if="sendToFriendConfirmation || inviteFriend"
              @click="toggleSendToFriendConfirmation()"
            >
              No, Go Back
            </button>
            <button
              type="button"
              v-if="!inviteFriend"
              class="btn btn-primary btn-lg"
              @click="checkSendToFriendsEmail()"
            >
              {{ sendToFriendConfirmation ? 'Yes, Continue' : '' }}
              {{ sendMoneyToFriend ? 'Send Money' : '' }}
              {{ invitedFriendSuccessfully ? 'Close' : '' }}
              {{
                !sendToFriendConfirmation &&
                !inviteFriend &&
                !invitedFriendSuccessfully &&
                !sendMoneyToFriend
                  ? 'Continue'
                  : ''
              }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="viewContactDetails"
      tabindex="-1"
      aria-labelledby="viewContactDetailsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center" id="viewContactDetailsLabel">
              Friend Details
            </h5>
          </div>
          <div class="modal-body">
            <div class="mt-5 information-card">
              <h5 class="">
                Name: {{ viewingContact.firstName }}
                {{ viewingContact.lastName }}
              </h5>
              <h5>Email: {{ viewingContact.email }}</h5>
              <h5>
                Phone: (xxx)-xxx-{{ viewingContact.phoneNumber.slice(-4) }}
              </h5>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              id="viewContactDetailsClose"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn"
              data-bs-toggle="modal"
              data-bs-target="#sendToFriends"
              data-bs-dismiss="modal"
              style="background-color: #363166; color: white"
              @click="setFriendToSendMoney()"
            >
              Transfer Funds
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="removeContact()"
            >
              Remove Friend
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal2 from 'sweetalert2'
import swal from 'sweetalert'

export default {
  data() {
    return {
      inviteFriendLink: 'https://app.summerathletes.com/register?ref=',
      receivingFriend: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },
    }
  },
  props: {
    sendToFriendEmail: String,
    sendToFriendConfirmation: Boolean,
    sendMoneyToFriend: Boolean,
    inviteFriend: Boolean,
    invitedFriendSuccessfully: Boolean,
    addingContact: Boolean,
    user: Object,
    accountBalance: Number,
    sendMoneyAmount: Number,
    viewingContact: Object,
    payoutInfo: Object,
  },
  methods: {
    showRecipientEmailExplanation() {
      swal({
        title: 'Recipient Email',
        text: "This is the email you would like to use for your friend's Summer Athletes account. This email must be the same email they used to create their account.",
        icon: 'info',
      })
    },

    updateSendToFriendEmail(email) {
      this.$emit('updateSendToFriendEmail', email)
    },

    inviteFriendViaEmail() {
      axios
        .get(
          import.meta.env.VITE_APP_DB_URL +
            'banking/inviteFriendViaEmail?email=' +
            this.sendToFriendEmail +
            '&senderName=' +
            this.user.firstName +
            ' ' +
            this.user.lastName,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.$emit('updateInvitedFriendSuccessfully', true)
            this.$emit('updateInviteFriend', false)
            swal2.fire({
              title: 'Invitation Sent',
              icon: 'success',
              html: `An invitation has been sent to <strong>${this.sendToFriendEmail}</strong>. <br /> <br />`,
            })
          }
        })
    },

    cancelSendToFriends() {
      this.$emit('updateSendToFriendConfirmation', false)
      this.$emit('updateSendMoneyToFriend', false)
      this.$emit('updateInviteFriend', false)
      this.$emit('updateInvitedFriendSuccessfully', false)
      this.$emit('updateAddingContact', false)
    },

    toggleSendToFriendConfirmation() {
      if (this.sendToFriendConfirmation) {
        this.$emit('updateSendToFriendConfirmation', false)
      }
      if (this.inviteFriend) {
        this.$emit('updateInviteFriend', false)
      }
    },

    checkSendToFriendsEmail() {
      if (
        !this.sendToFriendConfirmation &&
        !this.inviteFriend &&
        !this.invitedFriendSuccessfully &&
        !this.sendMoneyToFriend
      ) {
        if (
          this.sendToFriendEmail?.toLowerCase() ==
            this.user.email?.toLowerCase() ||
          !this.sendToFriendEmail ||
          !this.sendToFriendEmail.includes('@') ||
          !this.sendToFriendEmail.includes('.')
        ) {
          swal2.fire({
            title: 'Invalid Email',
            icon: 'error',
            html: `Please enter a valid email address.`,
          })
          return
        }

        axios
          .get(
            import.meta.env.VITE_APP_DB_URL +
              'banking/checkSendToFriendsEmail?email=' +
              this.sendToFriendEmail,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('jwt'),
              },
            }
          )
          .then(response => {
            if (response.data.success) {
              this.receivingFriend = response.data.user
              this.$emit('updateSendToFriendConfirmation', true)
              this.$emit('updateInviteFriend', false)
            } else {
              this.$emit('updateSendToFriendConfirmation', false)
              this.$emit('updateInviteFriend', true)
            }
          })
      } else if (
        this.sendToFriendConfirmation &&
        !this.inviteFriend &&
        !this.sendMoneyToFriend &&
        !this.addingContact
      ) {
        this.$emit('updateSendMoneyToFriend', true)
        this.$emit('updateSendToFriendConfirmation', false)
      } else if (this.sendMoneyToFriend) {
        if (this.sendMoneyAmount < 0) {
          swal2.fire({
            title: 'Invalid Amount',
            icon: 'error',
            html: `Amount cannot be negative.`,
          })
          return
        }
        swal2
          .fire({
            title: 'Confirm Transfer',
            icon: 'warning',
            html: `Are you sure you want to send <strong>$${parseFloat(
              this.sendMoneyAmount
            ).toFixed(
              2
            )}</strong> from your Summer Athletes account to <strong>${this.receivingFriend.email?.toLowerCase()}</strong>? <br /> <br />
Once the funds have been transferred, that money is unreachable by Summer Athletes. <br /> <br />Would you still like to initiate the transfer?
`,
            confirmButtonText:
              '<i class="fa-solid fa-money-bill-transfer"></i> Transfer',
            showCancelButton: true,
          })
          .then(willTransfer => {
            if (willTransfer.isConfirmed) {
              swal2.fire({
                title: 'Transferring...',
                icon: 'info',
                html: 'please wait...',
              })

              let url =
                import.meta.env.VITE_APP_DB_URL + 'banking/sendMoneyViaEmail'
              axios
                .post(
                  url,
                  {
                    userID: this.user._id,
                    recipientEmail: this.receivingFriend.email?.toLowerCase(),
                    amount: this.sendMoneyAmount,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                    },
                  }
                )
                .then(response => {
                  if (response.data.success) {
                    // this.payoutInfo = response.data.payoutInfo
                    this.$emit('updateSendMoneyAmount', 0);
                    this.$emit('updatePayoutInfo', {
                      payoutInfo: response.data.payoutInfo,
                      accountBalance: response.data.accountBalance,
                    })
                    swal2.fire({
                      title: 'Transfer Completed',
                      icon: 'success',
                      html: `Successfully completed a transfer of <strong>$${parseFloat(
                        this.sendMoneyAmount
                      ).toFixed(2)}</strong>
from your Summer Athletes account. <br /> <br />`,
                    })
                    
                    document.getElementById('sendToFriendsClose').click()
                    this.$emit('updateSendMoneyToFriend', false)
                    this.$emit(
                      'updateAccountBalance',
                      response.data.accountBalance
                    )
                    
                  } else {
                    swal2.fire({
                      title: 'Transfer Failed',
                      icon: 'error',
                      html: `We're sorry! We failed to complete a transfer of <strong>$${parseFloat(
                        this.sendMoneyAmount
                      ).toFixed(2)}</strong>
from your Summer Athletes account at this time. Please reach out to support@summerathletes.com for questions, or try again at a later time. <br /> <br />`,
                    })
                  }
                })
            }
          })
      } else if (this.invitedFriendSuccessfully) {
        this.$emit('updateInvitedFriendSuccessfully', false)
        document.getElementById('sendToFriendsClose').click()
      } else if (this.addingContact && this.sendToFriendConfirmation) {
        if (
          this.payoutInfo.contactList.filter(
            contact =>
              contact.email?.toLowerCase() ==
                this.receivingFriend.email?.toLowerCase() &&
              contact.isDeleted == false
          ).length > 0
        ) {
          swal2.fire({
            title: 'Contact Already Exists',
            icon: 'error',
            html: `You already have a contact with <strong>${this.receivingFriend.email?.toLowerCase()}</strong>.`,
          })
          return
        }
        swal2.fire({
          title: 'Adding...',
          icon: 'info',
          html: 'please wait...',
        })
        let contact = {
          firstName: this.receivingFriend.firstName,
          lastName: this.receivingFriend.lastName,
          email: this.receivingFriend.email?.toLowerCase(),
          phoneNumber: this.receivingFriend.phoneNumber,
          userID: this.receivingFriend._id,
        }
        axios
          .post(
            import.meta.env.VITE_APP_DB_URL + 'banking/addContact',
            {
              userID: this.user._id,
              contact: contact,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('jwt'),
              },
            }
          )
          .then(response => {
            if (response.data.success) {
              this.$emit('updateSendToFriendConfirmation', false)
              this.$emit('updateAddingContact', false)
              // this.payoutInfo = response.data.payoutInfo
              this.$emit('updatePayoutInfo', {
                payoutInfo: response.data.payoutInfo,
              })

              swal2.fire({
                title: 'Friend Added Successfully',
                icon: 'success',
                html: `Successfully added <strong>${this.receivingFriend.firstName} ${this.receivingFriend.lastName}</strong> to your Summer Athletes account. <br /> <br />`,
              })
              document.getElementById('sendToFriendsClose').click()
            } else {
              swal2.fire({
                title: 'Friend Add Failed',
                icon: 'error',
                html: `We're sorry! We failed to add <strong>${this.receivingFriend.firstName} ${this.receivingFriend.lastName}</strong> to your Summer Athletes account at this time. Please reach out to support@summerathletes.com for questions, or try again at a later time. <br /> <br />`,
              })
            }
          })
      }
    },

    setFriendToSendMoney() {
      axios
        .get(
          import.meta.env.VITE_APP_DB_URL +
            'banking/checkSendToFriendsEmail?email=' +
            this.viewingContact.email?.toLowerCase(),
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.updateSendToFriendEmail(
              this.viewingContact.email?.toLowerCase()
            )
            this.receivingFriend = this.viewingContact
            this.$emit('updateSendMoneyToFriend', true)
          }
        })
        .catch(error => {
          swal2.fire({
            title: 'Account No Longer Exists',
            icon: 'error',
            html: `We're sorry! An account under this email address no longer exists. Please reach out to the account owner, and get their new email address.`,
          })
        })
    },

    removeContact() {
      //axios post to remove the contact from the user's contacts
      swal2.fire({
        title: 'Removing...',
        icon: 'info',
        html: 'please wait...',
      })
      axios
        .post(
          import.meta.env.VITE_APP_DB_URL + 'banking/removeContact',
          {
            userID: this.user._id,
            contact: this.viewingContact,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.$emit('updatePayoutInfo', {
              payoutInfo: response.data.payoutInfo,
            })
            swal2.fire({
              title: 'Friend Removed Successfully',
              icon: 'success',
              html: `Successfully removed <strong>${this.viewingContact.firstName} ${this.viewingContact.lastName}</strong> from your Summer Athletes account. <br /> <br />`,
            })
            document.getElementById('viewContactDetailsClose').click()
          } else {
            swal2.fire({
              title: 'Friend Remove Failed',
              icon: 'error',
              html: `We're sorry! We failed to remove <strong>${this.viewingContact.firstName} ${this.viewingContact.lastName}</strong> from your Summer Athletes account at this time. Please reach out to support@summerathletes.com for questions, or try again at a later time. <br /> <br />`,
            })
          }
        })
    },

    updateSendMoneyAmount(value) {
      if (this.accountBalance - parseFloat(value) < 0 || !parseFloat(value)) {
        this.$emit('updateSendMoneyAmount', this.accountBalance)
      } else if (parseFloat(value) < 0) {
        this.$emit('updateSendMoneyAmount', 0)
      } else {
        this.$emit('updateSendMoneyAmount', parseFloat(value))
      }
    },
  },
}
</script>

<style scoped></style>
