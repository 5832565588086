<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="getImage()" class="card-img-top rounded-corners" />
        <h3 class="text-center" style="margin-top: 20px">Registrant Info</h3>
        <div class="row">
          <div class="card-input col">
            <label for="cardName" class="card-input__label">First Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="member.firstName"
              data-ref="cardName"
              autocomplete="given-name"
              required
            />
          </div>

          <div class="card-input col">
            <label for="cardName" class="card-input__label">Last Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="member.lastName"
              data-ref="cardName"
              autocomplete="family-name"
            />
          </div>
        </div>
        <li
          style="list-style-type: none"
          v-for="(count, index) in member.customFields"
          :key="index"
        >
          <CustomField
            :presetField="member.customFields[index]"
            :number="0"
            :index="index"
            @changed="updateFieldValue(0, index, $event.field)"
          ></CustomField>
        </li>
        <!--End of Custom Forms-->

        <h3 v-show="!this.emergencyBool">Emergency Contact Information</h3>
        <div v-show="!this.emergencyBool" style="margin-top: 15px">
          <div class="row">
            <div class="card-input col">
              <label class="card-input__label">First Name</label>
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                v-model="emergencyContactInfo.firstName"
              />
            </div>
            <div class="card-input col">
              <label class="card-input__label">Last Name</label>
              <input
                type="text"
                id="name"
                class="card-input__input"
                v-model="emergencyContactInfo.lastName"
              />
            </div>
          </div>
          <div class="card-input">
            <label class="card-input__label">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              class="card-input__input"
              v-model="emergencyContactInfo.phoneNumber"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              v-model="emergencyContactInfo.email"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Address</label>
            <input
              type="text"
              id="address"
              class="card-input__input"
              v-model="emergencyContactInfo.address"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">City</label>
            <input
              type="text"
              id="address"
              class="card-input__input"
              v-model="emergencyContactInfo.city"
            />
          </div>

          <div class="card-form__row">
            <div class="card-form__col">
              <div class="card-form__group">
                <label for="cardMonth" class="card-input__label">State</label>
                <select
                  class="card-input__input -select"
                  id="cardMonth"
                  v-model="emergencyContactInfo.state"
                >
                  <option value="" disabled selected>State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
            <div class="card-form__col">
              <div class="card-input">
                <label for="cardCvv">Zip Code</label>
                <input
                  type="text"
                  class="card-input__input"
                  v-model="emergencyContactInfo.zipCode"
                />
              </div>
            </div>
          </div>
        </div>

        <input
          class="form-check-input me-2"
          type="checkbox"
          value=""
          id="form6Example8"
          v-model="this.emergencyBool"
        />
        <label class="form-check-label" for="form6Example8">
          Emergency Contact is Billing Info
        </label>
        <br />
        <br />
        <div class="row">
          <button
            class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
            @click="submitInfo"
          >
            Next
          </button>
        </div>
        <div class="row mt-3">
          <button
            class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button !text-xl"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import CustomField from '../SubComponents/CustomField.vue'
import numeral from 'numeral'

export default {
  components: {
    CustomField,
  },
  props: {
    membership: Object,
    isMobile: Boolean,
    presetEmergencyBool: Boolean,
  },
  data() {
    return {
      member: {},
      emergencyBool: this.presetEmergencyBool,
      emergencyContactInfo: {},
    }
  },
  methods: {
    back() {
      console.log('back')
      const currentRoute = this.$route.path
      const newRoute = currentRoute
        .replace('membershipcheckout', 'viewpayment')
        .replace(/\/(weeklyPrice|monthlyPrice|semiAnnualPrice|annualPrice)/, '')
      this.$router.push(newRoute)
    },
    getImage() {
      if (
        this.membership.membershipLogo == null ||
        this.membership.membershipLogo == ''
      )
        return '/landscape.svg'
      else return this.membership.membershipLogo
    },
    submitInfo() {
      if (!this.emergencyBool) {
        this.member.emergencyContactInfo = this.emergencyContactInfo
      }
      this.$emit('proceed', this.member, this.emergencyBool)
    },

    updateFieldValue(camperIndex, fieldIndex, field) {
      this.campers[camperIndex].customFields[fieldIndex] = field
    },
  },

  created() {
    this.member.customFields = this.membership.customFields.map(field => ({
      ...field,
      fieldValue: null, // Default value for fieldValue
    }))
  },

  computed: {
    primaryColor() {
      return this.$route.params.primaryColor
        ? '#' + this.$route.params.primaryColor
        : '#363166'
    },
  },
  mounted() {},
}
</script>
  <style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  background-color: v-bind('primaryColor') !important;
}
.custom-button:hover {
  filter: brightness(0.95) !important;
}
</style>
  