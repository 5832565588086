<template>
  <div
    class="mt-12 min-h-screen bg-gradient-to-br from-[#030021]/5 to-[#363166]/10 py-8 px-4 sm:px-6 lg:px-8"
  >
    <ClientLayout :user="user" />

    <!-- Loading Overlay -->
    <div
      v-if="loading"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
        <div
          class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#363166] mb-4"
        ></div>
        <p class="text-[#030021] font-medium">Loading memberships...</p>
      </div>
    </div>

    <!-- Header Section -->
    <div class="max-w-6xl mx-auto">
      <div class="bg-white rounded-2xl shadow-xl overflow-hidden mb-8">
        <div class="bg-gradient-to-r from-[#363166] to-[#030021] py-6 px-8">
          <div class="flex flex-col md:flex-row items-start justify-between">
            <div class="flex items-center mb-4 md:mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white mr-3 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <h1 class="text-3xl font-bold text-white">
                {{ membershipName }} Members
              </h1>
            </div>

            <div class="flex flex-col sm:flex-row gap-3">
              <!-- Download Data Button -->
              <button
                v-if="
                  user.subscription && user.subscription.subscriptionTier >= 2
                "
                class="h-10 inline-flex items-center justify-center px-4 whitespace-nowrap bg-white text-[#363166] font-medium rounded-lg shadow-md hover:bg-[#19D412]/10 transition-all duration-300"
              >
                <JsonExcel
                  :data="resultQuery"
                  :name="membershipName + '.xls'"
                  :fields="json_fields"
                  class="flex items-center w-full h-full"
                >
                  <div class="flex items-center whitespace-nowrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    Download Data
                  </div>
                </JsonExcel>
              </button>

              <button
                v-else
                @click="showDownloadAd()"
                class="h-10 inline-flex items-center justify-center px-4 whitespace-nowrap bg-white text-[#363166] font-medium rounded-lg shadow-md hover:bg-[#19D412]/10 transition-all duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                Download Data
              </button>

              <!-- Back Button -->
              <a
                :href="getLinkToMembership"
                class="h-10 inline-flex items-center justify-center px-4 whitespace-nowrap bg-[#19D412] text-white font-medium rounded-lg shadow-md hover:bg-[#19D412]/90 transition-all duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
                Back
              </a>
            </div>
          </div>
        </div>

        <!-- Search and Filter Section -->
        <div class="p-6">
          <div
            class="flex flex-col md:flex-row items-center justify-between gap-4"
          >
            <!-- Search Bar -->
            <div class="relative w-full md:w-2/3">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="search"
                class="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-[#363166] focus:border-[#363166] transition-all duration-300"
                placeholder="Search by name..."
                v-model="searchQuery"
              />
            </div>

            <!-- Filter Dropdown -->
            <div class="w-full md:w-1/3">
              <select
                v-model="statusFilter"
                class="block w-full px-4 py-3 border border-gray-300 rounded-lg transition-all duration-300"
              >
                <option value="all">All Members</option>
                <option value="active">Active Members</option>
                <option value="inactive">Inactive Members</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- Stats Summary -->
      <div
        v-if="resultQuery.length > 0"
        class="bg-white rounded-2xl shadow-xl overflow-hidden mb-8"
      >
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4"
            >
              <div class="flex items-center">
                <div
                  class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <div class="ml-4">
                  <h3 class="text-sm font-medium text-[#363166]">
                    Total Members
                  </h3>
                  <p class="text-2xl font-bold text-[#030021]">
                    {{ members.length }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4"
            >
              <div class="flex items-center">
                <div
                  class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div class="ml-4">
                  <h3 class="text-sm font-medium text-[#363166]">
                    Active Members
                  </h3>
                  <p class="text-2xl font-bold text-[#030021]">
                    {{ activeCount }}
                    <span class="text-sm text-[#363166]"
                      >({{ activePercentage }}%)</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              class="bg-gradient-to-r from-[#363166]/5 to-[#363166]/10 rounded-xl p-4"
            >
              <div class="flex items-center">
                <div
                  class="h-12 w-12 rounded-full bg-[#363166] flex items-center justify-center text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="ml-4">
                  <h3 class="text-sm font-medium text-[#363166]">
                    Monthly Revenue
                  </h3>
                  <p class="text-2xl font-bold text-[#030021]">
                    ${{ totalMonthlyRevenue.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Member List -->
      <div v-if="resultQuery.length > 0" class="space-y-6">
        <div
          v-if="searchQuery || statusFilter !== 'all'"
          class="bg-white rounded-lg p-4 mb-2 shadow-sm"
        >
          <p class="text-[#363166]">
            Found
            <span class="font-bold">{{ resultQuery.length }}</span> members
            <span v-if="searchQuery">matching "{{ searchQuery }}"</span>
            <span v-if="statusFilter !== 'all'">
              with status:
              <span class="font-medium">{{ getStatusFilterLabel }}</span>
            </span>
            <button
              @click="clearFilters"
              class="pl-4 text-[#19D412] hover:underline"
            >
              Clear filters
            </button>
          </p>
        </div>

        <div
          v-for="member in resultQuery"
          :key="member._id"
          class="transform transition-all duration-300"
        >
          <MemberCard
            :firstName="member.memberFirstName"
            :lastName="member.memberLastName"
            :membershipId="member._id"
            :membershipType="member.membershipType"
            :membershipStatus="getMembershipStatus(member)"
            :startDate="member.startDate"
            :endDate="member.endDate"
            :profileImage="member.profileImage"
            :discountCode="member.discountCode"
            :membershipName="membershipName"
            :memberPayPeriod="member.paymentPeriod"
            :membershipPayPeriod="membership.membershipPayPeriod"
          />
        </div>
      </div>

      <!-- No Results State -->
      <div
        v-else
        class="bg-white rounded-2xl shadow-xl overflow-hidden p-8 text-center"
      >
        <div class="flex flex-col items-center justify-center py-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-16 w-16 text-gray-300 mb-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 class="text-2xl font-bold text-[#030021] mb-2">
            {{
              members.length > 0 ? 'No matching results' : 'No members found'
            }}
          </h2>
          <p class="text-[#363166] mb-6">
            {{
              members.length > 0
                ? 'Try adjusting your search criteria or filters.'
                : 'Start by adding members to this membership.'
            }}
          </p>

          <div class="flex flex-col sm:flex-row gap-4">
            <button
              v-if="
                members.length > 0 && (searchQuery || statusFilter !== 'all')
              "
              @click="clearFilters"
              class="inline-flex items-center justify-center px-6 py-3 bg-[#363166] text-white font-medium rounded-lg shadow-md hover:bg-[#363166]/90 transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              Clear Filters
            </button>

            <button
              @click="goToAddMember()"
              class="inline-flex items-center justify-center px-6 py-3 bg-[#19D412] text-white font-medium rounded-lg shadow-md hover:bg-[#19D412]/90 transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              Add Member
            </button>

            <a
              :href="getAdLink"
              class="inline-flex items-center justify-center px-6 py-3 bg-gradient-to-r from-[#363166] to-[#030021] text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
              Advertise My Membership
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Hidden elements for external triggers -->
    <button id="openAdvertisementPopUp" style="display: none"></button>
  </div>
</template>

<script>
import axios from 'axios'
import MemberCard from '../../components/Memberships/MemberCard.vue'
import swal from 'sweetalert'
import ClientLayout from '../../components/ClientLayout.vue'
import JsonExcel from 'vue-json-excel3'
import moment from 'moment'

export default {
  components: { ClientLayout, MemberCard, JsonExcel },
  data() {
    return {
      user: {},
      membership: {},
      membershipID: '',
      membershipName: '',
      members: [],
      searchQuery: '',
      statusFilter: 'all',
      subscriptions: [],
      loading: true,
      json_fields: {
        'Member First Name': 'firstName',
        'Member Last Name': 'lastName',
        'Membership Type': 'membershipType',
        'Start Date': {
          callback: value => {
            return value.startDate
              ? moment(value.startDate).format('MM/DD/YYYY')
              : 'N/A'
          },
        },
        'End Date': {
          callback: value => {
            return value.endDate
              ? moment(value.endDate).format('MM/DD/YYYY')
              : 'N/A'
          },
        },
        Status: {
          callback: value => {
            return this.getMembershipStatus(value)
          },
        },
        'Amount Paid (USD)': {
          callback: value => {
            return value.pricePaid ? value.pricePaid.toFixed(2) : '0.00'
          },
        },
        'Discount Code Used': {
          callback: value => {
            return value.discountCode || 'None'
          },
        },
      },
    }
  },
  methods: {
    getUserDetails() {
      try {
        const userData = localStorage.getItem('user')
        if (userData) {
          this.user = JSON.parse(userData)
        } else {
          console.error('User data not found in localStorage')
          this.redirectToLogin()
        }
      } catch (error) {
        console.error('Error parsing user data:', error)
        this.redirectToLogin()
      }
    },

    redirectToLogin() {
      this.$router.push('/login')
    },

    goToAddMember() {
      this.$router.push(`/Dashboard/AddMember/${this.membershipID}`)
    },

    showDownloadAd() {
      swal({
        icon: 'info',
        title: 'Upgrade Your Plan',
        text: 'You need to be at least a Pro user to download member data locally. Please upgrade your plan to continue.',
        buttons: {
          cancel: 'Not Now',
          upgrade: {
            text: 'Upgrade Plan',
            value: 'upgrade',
          },
        },
      }).then(value => {
        if (value === 'upgrade') {
          document.getElementById('openAdvertisementPopUp').click()
        }
      })
    },

    clearFilters() {
      this.searchQuery = ''
      this.statusFilter = 'all'
    },

    getMembershipStatus(member) {
      const today = new Date()
      const endDate = new Date(member.endDate)

      if (endDate < today) {
        return 'Inactive'
      } else {
        const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24))
        if (daysLeft <= 3) {
          return `Payment Due (${daysLeft} days)`
        } else {
          return 'Active'
        }
      }
    },

    async getMembership() {
      this.loading = true

      try {
        const url = `${import.meta.env.VITE_APP_DB_URL}membership/getMembershipByMembershipId?id=${this.membershipID}`
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })

        this.membership = response.data.membership
        this.members = response.data.membership.members || []
        this.membershipName =
          response.data.membership.membershipName || 'Membership'
      } catch (err) {
        console.error('Error fetching membership data:', err)
        swal(
          'Error',
          err.response?.data?.errorMessage || 'Failed to load membership data',
          'error',
        )
      } finally {
        this.loading = false
      }
    },

    async fetchSubscriptions() {
      try {
        const url = `${import.meta.env.VITE_APP_DB_URL}subscription/getAllSubscriptions`
        const response = await axios.get(url)
        this.subscriptions = response.data.subscriptions || []
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
      }
    },
  },

  created() {
    this.membershipID = this.$route.params.id
    this.getUserDetails()
    this.getMembership()
    this.fetchSubscriptions()
  },

  computed: {
    getLinkToMembership() {
      return '/Dashboard/ViewMembership/' + this.membershipID
    },

    resultQuery() {
      let filteredMembers = [...this.members]

      // Apply status filter
      if (this.statusFilter !== 'all') {
        if (this.statusFilter === 'active') {
          filteredMembers = filteredMembers.filter(member => {
            const status = this.getMembershipStatus(member)
            return (
              status === 'Active' ||
              status === 'Lifetime' ||
              status.includes('Expiring Soon')
            )
          })
        } else if (this.statusFilter === 'inactive') {
          filteredMembers = filteredMembers.filter(member => {
            return this.getMembershipStatus(member) === 'Inactive'
          })
        }
      }

      // Apply search filter
      if (this.searchQuery) {
        const searchTerms = this.searchQuery.toLowerCase().split(' ')
        filteredMembers = filteredMembers.filter(item => {
          return searchTerms.every(
            term =>
              item.firstName?.toLowerCase().includes(term) ||
              item.lastName?.toLowerCase().includes(term),
          )
        })
      }

      return filteredMembers
    },

    getAdLink() {
      if (
        this.user.subscription &&
        this.user.subscription.subscriptionTier >= 4
      ) {
        return '/Dashboard/AdStore'
      } else {
        const adSub =
          this.subscriptions.length > 0
            ? this.subscriptions[this.subscriptions.length - 1]
            : null
        return adSub
          ? `/SubscriptionChoices/Checkout/${adSub._id}/false`
          : '/SubscriptionChoices'
      }
    },

    activeCount() {
      return this.members.filter(member => {
        const status = this.getMembershipStatus(member)
        return (
          status === 'Active' ||
          status === 'Lifetime' ||
          status.includes('Expiring Soon')
        )
      }).length
    },

    activePercentage() {
      if (this.members.length === 0) return 0
      return Math.round((this.activeCount / this.members.length) * 100)
    },

    totalMonthlyRevenue() {
      let total = 0
      for (const member of this.members.filter(member => member.isActive)) {
        if (member.paymentPeriod === 'weeklyPrice') {
          total += this.membership.membershipPayPeriod.weeklyPrice
        } else if (member.paymentPeriod === 'monthlyPrice') {
          total += this.membership.membershipPayPeriod.monthlyPrice
        } else if (member.paymentPeriod === 'semiAnnualPrice') {
          total += this.membership.membershipPayPeriod.semiAnnualPrice
        } else if (member.paymentPeriod === 'annualPrice') {
          total += this.membership.membershipPayPeriod.annualPrice
        }
      }
      return total
    },

    getStatusFilterLabel() {
      switch (this.statusFilter) {
        case 'active':
          return 'Active'
        case 'inactive':
          return 'Inactive'
        default:
          return 'All Members'
      }
    },
  },
}
</script>

<style scoped>
/* Loading animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Smooth transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Card hover effect */
.transform {
  transition: all 0.3s ease-in-out;
}

.transform:hover {
  transform: translateY(-4px);
  box-shadow:
    0 10px 25px -5px rgba(54, 49, 102, 0.1),
    0 10px 10px -5px rgba(54, 49, 102, 0.04);
}

button,
a {
  border-radius: 0.5rem !important;
  overflow: hidden;
  position: relative;
}

button::after,
a::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(to right, #363166, #030021);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

button:hover::after,
a:hover::after {
  opacity: 1;
}
</style>
