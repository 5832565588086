<template>
  <div>
    <div
      class="card-form mb-5"
      v-bind:style="{
        marginBottom: '15px',
      }"
    >
      <div
        class="card-form__inner"
        v-bind:style="{
          textAlign: isMobile() ? 'center' : '',
        }"
      >
        <div class="image-wrapper" style="height: 33vh; overflow: hidden">
          <img
            :src="image || getPlaceholderImage()"
            class="w-full h-full object-cover rounded-corners"
            alt="Card Image"
          />
        </div>
        <div class="card-body">
          <h2 class="title !text-2xl !font-bold !text-gray-800">
            {{ this.title }}
          </h2>
          <div
            v-if="this.ageRange && this.ageRange.length > 0"
            class="space-y-1"
          >
            <div class="flex flex-wrap gap-1">
              <span
                v-for="range in this.ageRange"
                :key="range"
                class="text-xs font-medium text-gray-900 bg-gray-200 px-2 py-0.5 rounded-full"
              >
                {{ range.name }}
              </span>
            </div>
          </div>
          <h2
            class="date !text-xl !font-bold !text-gray-700 whitespace-pre-wrap"
          >
            {{ this.getFormattedDates }}
          </h2>
          <h2 class="!text-xl !font-bold text-gray-800">
            {{ this.getPrice }}
          </h2>
          <button
            class="w-full flex items-center justify-center gap-2 text-white py-3 px-6 font-medium transition-colors duration-200 custom-button"
            :style="{
              backgroundColor: '#' + this.buttonColor + ' !important',
              color: 'white',
              marginTop: '10px !important',
            }"
            @click="viewPayment"
          >
            {{ this.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: String,
    type: String,
    price: Number,
    priceObject: Object,
    dates: Array,
    image: String,
    buttonText: String,
    buttonAction: Function,
    buttonColor: String,
    ageRange: Array,
    gender: Array,
    placeholder: String,
    id: String,
  },
  data() {
    return {
      dateString: this.date,
      displayDate: '',
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getPlaceholderImage() {
      if (this.placeholder === 'headshot') {
        return '/headshot-icon.png'
      } else if (this.placeholder === 'landscape') {
        return '/landscape.svg'
      }
      return ''
    },
    viewPayment() {
      this.$router.push(
        `/coach/${this.$route.params.coachId}/${this.$route.params.primaryColor}/${this.$route.params.secondaryColor}/viewpayment/${this.id}`
      )
    },
  },
  computed: {
    getFormattedDates() {
      if (
        (this.dates == null || this.dates.length == 0) &&
        this.price != null
      ) {
        return ''
      }

      // Check if dates array exists and has at least one element
      if (!this.dates || this.dates.length === 0) {
        return ''
      }

      // Check if the first date object has a start property
      if (!this.dates[0] || !this.dates[0].start) {
        return ''
      }

      // Convert the start date string to a Date object
      const startDate = new Date(this.dates[0].start)

      // Format the date and time
      const dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      //const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true } // 12-hour format with AM/PM
      const formattedDate = startDate.toLocaleDateString(undefined, dateOptions)
      //const formattedTime = startDate.toLocaleTimeString(undefined, timeOptions)

      return `${formattedDate}`
    },
    getPrice() {
      if (this.type == 'membership') {
        if (this.priceObject.monthlyPrice != null) {
          return '$' + this.priceObject.monthlyPrice.toFixed(2) + '/month'
        }
        if (this.priceObject.semiAnnualPrice != null) {
          return (
            '$' + this.priceObject.semiAnnualPrice.toFixed(2) + '/half year'
          )
        }
        if (this.priceObject.annualPrice != null) {
          return '$' + this.priceObject.annualPrice.toFixed(2) + '/year'
        }
      }
      return '$' + this.price.toFixed(2)
    },
  },
  created() {},
}
</script>
<style scoped>
.card-form {
  height: 65vh; /* Ensure all cards are the same height */
}

.card-form__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.image-wrapper {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px; /* Add rounded corners */
}

/* Truncate Title with Ellipsis */
.title {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.date {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.card-body {
  padding: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-button {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  transition: filter 0.2s ease-in-out !important;
}

.custom-button:hover {
  filter: brightness(0.95) !important;
}
</style>