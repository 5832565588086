<template>
  <div>
    <NavBar></NavBar>
    <div style="margin-top: 2%; margin-bottom: 2%">
      <LoadingSkeleton v-if="this.toggle == 0" />
      <MemberInfo
        v-if="this.toggle == 1"
        :membership="this.membership"
        :presetMembers="this.members"
        :presetEmergencyBool="this.emergencyBool"
        :isMobile="this.isMobile"
        @proceed="toPayment"
        @back="back"
      ></MemberInfo>
      <Payment
        v-if="this.toggle == 2"
        :billingInfo="this.billingInfo"
        :isMobile="this.isMobile"
        :primaryColor="this.primaryColor"
        :secondaryColor="this.secondaryColor"
        :presetBillingInfo="this.billingInfo"
        @proceed="toOrderSummary"
        @back="back"
      ></Payment>
      <OrderSummary
        v-if="this.toggle == 3"
        :membership="this.membership"
        :isMobile="this.isMobile"
        :member="this.member"
        @proceed="registerMember"
        @back="back"
      ></OrderSummary>
      <RegistrationSuccess
        v-if="this.toggle == 4"
        :membership="this.membership"
      ></RegistrationSuccess>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import moment from 'moment'
import NavBar from '../components/NavBar.vue'
import NavFooter from '../components/NavFooter.vue'
import LoadingSkeleton from '../components/Checkout/LoadingSkeleton.vue'
import RegistrationSuccess from '../components/Checkout/RegistrationSuccess.vue'
import Payment from '../components/Checkout/Payment.vue'
import MemberInfo from '../components/Checkout/MembershipSpecific/MemberInfo.vue'
import OrderSummary from '../components/Checkout/MembershipSpecific/OrderSummary.vue'

export default {
  components: {
    NavBar,
    NavFooter,
    RegistrationSuccess,
    Payment,
    MemberInfo,
    OrderSummary,
    LoadingSkeleton,
  },
  data() {
    return {
      membership: {},
      members: null,
      emergencyBool: true,
      billingInfo: {
        firstName: '',
        lastName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
      toggle: 0,
      paymentPeriodSelected: '',
    }
  },
  methods: {
    back() {
      if (this.toggle == 2) {
        this.toggle = 1
        return
      }
      if (this.toggle == 3) {
        this.toggle = 2
        return
      }
      if (this.toggle == 4) {
        this.toggle = 3
      }
    },
    async getMembershipInfo() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMembershipByMembershipID?id=' +
        this.$route.params.id
      axios.get(url, {}).then(response => {
        this.membership = response.data.membership
        this.paymentPeriodSelected = this.$route.params.paymentPeriod
        this.toggle = 1
      })
    },
    toPayment(member, emergencyBool) {
      this.member = member
      this.emergencyBool = emergencyBool
      this.toggle = 2
    },
    toOrderSummary(x) {
      this.billingInfo = x
      //Format the camper objects to be sent to the server
      this.member.email = this.billingInfo.email
      this.member.phoneNumber = this.billingInfo.phoneNumber
      this.member.address = this.billingInfo.address
      if (this.emergencyBool) {
        this.member.emergencyContactInfo = { ...this.billingInfo }
      }

      this.toggle = 3
    },
    registerMember() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/registerCamper'
      axios
        .post(url, {
          campId: this.camp._id,
          campers: this.campers,
          billingInfo: this.billingInfo,
          discountCode: this.discountCode,
        })
        .then(response => {
          console.log(response.data)
          let message = 'Checkout Successful! '
          for (let camper in this.campers) {
            if (camper == 0) {
              message = message + this.campers[camper].firstName
            } else if (this.campers.length == 2) {
              message = message + ' and ' + this.campers[camper].firstName
            } else if (camper == this.campers.length - 1) {
              message = message + ', and ' + this.campers[camper].firstName
            } else {
              message = message + ', ' + this.campers[camper].firstName
            }
          }
          if (this.campers.length > 1) {
            message = message + ' are registered'
          } else {
            message = message + ' is registered'
          }
          message = message + ' for ' + this.camp.campName + '!'

          swal('Success', message, 'success')
          this.toggle = 5
        })
        .catch(err => {
          console.log(err)
          swal('Error', 'Invalid Card Information', 'error')
          this.toggle = 3
        })
    },
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
  },
  created() {
    // Fetch search results on page load
    this.getMembershipInfo()
  },
  mounted() {},
}
</script>

<style scoped></style>
