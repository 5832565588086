<template>
  <div class="wrapper container" id="checkIn">
    <ClientLayout :user="user"></ClientLayout>
    <section class="main container">
      <div class="card card-form__inner mt-5">
        <div class="card-title text-center justify-content-center">
          <h1 v-if="this.getProgress === '0%' || this.getProgress === '25%'">
            Let's Get Started!
          </h1>
          <h1 v-else-if="this.getProgress === '50%'">You're Half Way There!</h1>
          <h1 v-else-if="this.getProgress === '75%'">You're Almost Done!</h1>
          <h1 v-else-if="this.getProgress === '100%'">Great Work!</h1>
          <div
            class="progress center text-center mt-3"
            style="
              height: 20px;
              max-width: 400px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <div
              :class="{
                'progress-bar': true,
                'progress-bar-striped': true,
                'progress-bar-animated': this.getProgress !== '100%',
              }"
              role="progressbar"
              :style="{ width: this.getProgress, backgroundColor: '#19d412' }"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="card-body">
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  :class="{
                    'accordion-button': true,
                    collapsed: createdAIWebsite,
                  }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  :aria-expanded="!createdAIWebsite"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Build Your AI Website
                  <span
                    style="margin-left: 15px"
                    @click="markAsComplete('aiWebsites')"
                  >
                    <i
                      class="fa-solid fa-check-circle fa-lg"
                      v-if="tourGuideVideos.aiWebsitesComplete"
                      style="margin-left: 5px; color: #19d412"
                    >
                    </i>
                    <button
                      class="btn btn-primary m-1"
                      v-if="!tourGuideVideos.aiWebsitesComplete"
                    >
                      {{
                        tourGuideVideos.aiWebsitesComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </button>
                    <span v-else>
                      {{
                        tourGuideVideos.aiWebsitesComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </span>
                  </span>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                :class="{
                  'accordion-collapse': true,
                  collapse: true,
                  show: !createdAIWebsite,
                }"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <strong
                    >Generating your AI Website is the first step to
                    success.</strong
                  >
                  Creating a website for your business can significantly enhance
                  your online presence, attract more customers, and boost your
                  overall brand image. A well designed website serves as a
                  virtual storefront for your customers, providing potential
                  clients with a convenient platform to explore your services,
                  learn about your team, and register for your events.
                  <br />
                  <br />
                  <div class="embed-responsive embed-responsive-16by9 controls">
                    <iframe
                      class="embed-responsive-item controls"
                      src="https://tour-guide-media.s3.amazonaws.com/AI+Websites+Demo+HD.mp4"
                      allowfullscreen
                      sandbox
                    ></iframe>
                  </div>
                  <br />
                  <router-link to="/Dashboard/WebsiteSetup">
                    <button class="btn btn-primary">Get Started!</button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  :class="{
                    'accordion-button': true,
                    collapsed: !createdAIWebsite || createdCamp,
                    lock: !createdAIWebsite,
                  }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  :aria-expanded="createdAIWebsite && !createdCamp"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  <i
                    class="fa-solid fa-lock"
                    v-if="!createdAIWebsite"
                    style="margin-right: 5px"
                  >
                  </i>
                  Create Your First Camp/Clinic
                  <span
                    v-if="this.createdAIWebsite"
                    style="margin-left: 15px"
                    @click="markAsComplete('camp')"
                  >
                    <i
                      class="fa-solid fa-check-circle fa-lg"
                      v-if="tourGuideVideos.createCampComplete"
                      style="margin-left: 5px; color: #19d412"
                    >
                    </i>
                    <button
                      class="btn btn-primary m-1"
                      v-if="!tourGuideVideos.createCampComplete"
                    >
                      {{
                        tourGuideVideos.createCampComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </button>
                    <span v-else>
                      {{
                        tourGuideVideos.createCampComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </span>
                  </span>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                :class="{
                  'accordion-collapse': true,
                  collapse: true,
                  show: createdAIWebsite && !createdCamp,
                }"
                aria-labelledby="panelsStayOpen-headingTwo"
                v-if="createdAIWebsite"
              >
                <div class="accordion-body">
                  <strong
                    >Creating camps/clinics is the lifeblood of the
                    platform.</strong
                  >
                  Having events on Summer Athletes can significantly enhance
                  your business and provide numerous benefits to your clients.
                  By using our intuitive and user-friendly platform, you can
                  easily create and manage your clinics, camp training programs,
                  and individual coaching sessions. This will not only save you
                  tremendous time and effort, but also ensure that your events
                  are well-organized and professionally presented.
                  <br />
                  <br />
                  <div class="embed-responsive embed-responsive-16by9 controls">
                    <iframe
                      class="embed-responsive-item controls"
                      src="https://tour-guide-media.s3.amazonaws.com/Easy+Payment+Link+Demo+HD.mp4"
                      allowfullscreen
                      sandbox
                    ></iframe>
                  </div>
                  <br />
                  <router-link to="/Dashboard/CreateCamp/Individual">
                    <button class="btn btn-primary">Get Started!</button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  :class="{
                    'accordion-button': true,
                    collapsed: !createdCamp || createdMembership,
                    lock: !createdCamp,
                  }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  :aria-expanded="createdCamp && !createdMembership"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  <i
                    class="fa-solid fa-lock"
                    v-if="!createdCamp"
                    style="margin-right: 5px"
                  >
                  </i>
                  Setup Your First Membership
                  <span
                    v-if="this.createdCamp"
                    style="margin-left: 15px"
                    @click="markAsComplete('membership')"
                  >
                    <i
                      class="fa-solid fa-check-circle fa-lg"
                      v-if="tourGuideVideos.createMembershipComplete"
                      style="margin-left: 5px; color: #19d412"
                    >
                    </i>
                    <button
                      class="btn btn-primary m-1"
                      v-if="!tourGuideVideos.createMembershipComplete"
                    >
                      {{
                        tourGuideVideos.createMembershipComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </button>
                    <span v-else>
                      {{
                        tourGuideVideos.createMembershipComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </span>
                  </span>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                :class="{
                  'accordion-collapse': true,
                  collapse: true,
                  show: createdCamp && !createdMembership,
                }"
                aria-labelledby="panelsStayOpen-headingThree"
                v-if="createdCamp"
              >
                <div class="accordion-body">
                  <strong
                    >Memberships are what make your business skyrocket.</strong
                  >
                  One of the most significant benefits of having a membership is
                  the <i>recurring revenue</i> it can generate. Recurring
                  revenue is a powerful financial tool that can help you build a
                  stable and predictable income stream, providing you with the
                  resources you need to grow your business and achieve your
                  financial goals. By offering valuable membership programs, you
                  can attract and retain loyal customers, who will continue to
                  support your business over time. This can help you build a
                  strong and sustainable business model, ensuring your long-term
                  financial success. All of this can be achieved directly
                  through the Summer Athletes!
                  <br />
                  <br />
                  <div class="embed-responsive embed-responsive-16by9 controls">
                    <iframe
                      class="embed-responsive-item controls"
                      src="https://tour-guide-media.s3.amazonaws.com/Create+Recurring+Payments+HD.mp4"
                      allowfullscreen
                      sandbox
                    ></iframe>
                  </div>
                  <br />
                  <router-link to="/Dashboard/CreateMembership">
                    <button class="btn btn-primary">Get Started!</button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  :class="{
                    'accordion-button': true,
                    collapsed: !createdMembership || createdScheduledSession,
                    lock: !createdMembership,
                  }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  :aria-expanded="createdMembership && !createdScheduledSession"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  <i
                    class="fa-solid fa-lock"
                    v-if="!createdMembership"
                    style="margin-right: 5px"
                  >
                  </i>
                  Get Started with Scheduled Sessions
                  <span
                    v-if="this.createdMembership"
                    style="margin-left: 15px"
                    @click="markAsComplete('scheduledSession')"
                  >
                    <i
                      class="fa-solid fa-check-circle fa-lg"
                      v-if="tourGuideVideos.createScheduledSessionComplete"
                      style="margin-left: 5px; color: #19d412"
                    >
                    </i>
                    <button
                      class="btn btn-primary m-1"
                      v-if="!tourGuideVideos.createScheduledSessionComplete"
                    >
                      {{
                        tourGuideVideos.createScheduledSessionComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </button>
                    <span v-else>
                      {{
                        tourGuideVideos.createScheduledSessionComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </span>
                  </span>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                :class="{
                  'accordion-collapse': true,
                  collapse: true,
                  show: createdMembership && !createdScheduledSession,
                }"
                aria-labelledby="panelsStayOpen-headingFour"
                v-if="createdMembership"
              >
                <div class="accordion-body">
                  <strong
                    >Calendar scheduling is crucial to your business
                    performance.</strong
                  >
                  Scheduled sessions not only ensure that your clients receive
                  consistent, high-quality training, but also help you manage
                  your time and resources more effectively. Our calendar
                  scheduling feature is designed to have your clients book
                  sessions with you directly online, at a timeslot that is best
                  for them, based on your optimal availability. With this tool,
                  you can easily set up and manage your training sessions,
                  allowing you to focus on what you do best - coaching your
                  athletes.
                  <br />
                  <br />
                  This feature enables you to create custom schedules for each
                  of your training programs, ensuring that your clients receive
                  the right training at the right time. Moreover, everything is
                  fully integrated into our platform, allowing you to easily
                  track your clients' progress and attendance. This means that
                  you can quickly identify any potential issues and take
                  proactive steps to address them, ensuring that your clients
                  receive the best possible training experience.
                  <br />
                  <br />
                  <div class="embed-responsive embed-responsive-16by9 controls">
                    <iframe
                      class="embed-responsive-item controls"
                      src="https://tour-guide-media.s3.amazonaws.com/Scheduled+Sessions+Demo+HD.mp4"
                      allowfullscreen
                      sandbox
                    ></iframe>
                  </div>
                  <br />
                  <router-link
                    to="/Dashboard/CalendarAvailability"
                    v-if="this.user.subscription.subscriptionTier > 2"
                  >
                    <button class="btn btn-primary">Get Started!</button>
                  </router-link>
                  <button class="btn btn-primary" v-else @click="showAdPopUp()">
                    Get Started!
                  </button>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                <button
                  :class="{
                    'accordion-button': true,
                    collapsed: !createdMembership,
                    lock:
                      !createdMembership ||
                      (!createdScheduledSession &&
                        this.user.subscription.subscriptionTier > 2),
                  }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  :aria-expanded="
                    createdScheduledSession ||
                    (createdMembership &&
                      this.user.subscription.subscriptionTier < 3)
                  "
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  <i
                    class="fa-solid fa-lock"
                    v-if="
                      !createdMembership ||
                      (!createdScheduledSession &&
                        this.user.subscription.subscriptionTier > 2)
                    "
                    style="margin-right: 5px"
                  >
                  </i>
                  Increasing Your Business & Customer Base
                  <span
                    v-if="this.createdScheduledSession"
                    style="margin-left: 15px"
                    @click="markAsComplete('increaseBusiness')"
                  >
                    <i
                      class="fa-solid fa-check-circle fa-lg"
                      v-if="tourGuideVideos.increaseBusinessComplete"
                      style="margin-left: 5px; color: #19d412"
                    >
                    </i>
                    <button
                      class="btn btn-primary m-1"
                      v-if="!tourGuideVideos.increaseBusinessComplete"
                    >
                      {{
                        tourGuideVideos.increaseBusinessComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </button>
                    <span v-else>
                      {{
                        tourGuideVideos.increaseBusinessComplete
                          ? 'Completed'
                          : 'Mark as Complete'
                      }}
                    </span>
                  </span>
                </button>
              </h2>
              <div
                v-if="
                  createdScheduledSession ||
                  (createdMembership &&
                    this.user.subscription.subscriptionTier < 3)
                "
                id="panelsStayOpen-collapseFive"
                :class="{
                  'accordion-collapse': true,
                  collapse: true,
                  show:
                    createdScheduledSession ||
                    (createdMembership &&
                      this.user.subscription.subscriptionTier < 3),
                }"
                aria-labelledby="panelsStayOpen-headingFive"
              >
                <div class="accordion-body">
                  <strong
                    >Increasing your business has never been easier now that
                    you've joined the team.</strong
                  >
                  Summer Athletes is designed to help you increase your online
                  presence and reach a wider audience. Our platform integrates
                  seamlessly with popular social media platforms, allowing you
                  to easily promote your coaching services and connect with
                  potential clients. This means that you can easily expand your
                  reach and grow your business, without having to spend a
                  fortune on advertising.
                  <br />
                  <br />
                  We are the premier platform for coaches who want to grow their
                  business, increase their customer base, and establish their
                  brand as a reputable and reliable coach. With our tools, you
                  can easily manage your training sessions, communicate with
                  your clients, and expand your online presence, all while
                  receiving the support and training you need to succeed. So
                  what are you waiting for?
                  <strong
                    >Start posting your links out and get started today!</strong
                  >
                  <br />
                  <br />
                  <div class="embed-responsive embed-responsive-16by9 controls">
                    <iframe
                      class="embed-responsive-item controls"
                      src="https://tour-guide-media.s3.amazonaws.com/Increase+Sales+Demo+HD.mp4"
                      allowfullscreen
                      sandbox
                    ></iframe>
                  </div>
                  <br />
                  <router-link :to="'/Coach/' + this.user._id" target="_blank">
                    <button class="btn btn-primary">Get Started!</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ClientLayout from '../../components/ClientLayout.vue'
import swal from 'sweetalert'
import axios from 'axios'
export default {
  components: {
    ClientLayout,
  },
  data() {
    return {
      user: {},
      websites: [],
      websiteSelected: {
        title: '',
        url: '',
        subdomain: '',
      },
      newWebsiteID: null,
      tourGuideVideos: {
        aiWebsitesComplete: false,
        createCampComplete: false,
        createMembershipComplete: false,
        createScheduledSessionComplete: false,
        increaseBusinessComplete: false,
      },
    }
  },
  methods: {
    showAdPopUp() {
      if (this.user.subscription.subscriptionTier < 3) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least an All Star user to begin using Calendar Scheduling. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
    },
    markAsComplete(video) {
      let url = import.meta.env.VITE_APP_DB_URL + 'user/markAsComplete'
      if (video === 'aiWebsites') {
        if (!this.tourGuideVideos.aiWebsitesComplete) {
          swal({
            icon: 'success',
            title: 'Success',
            text: 'AI Websites Step Completed. Great Work!',
          })
        }
        this.tourGuideVideos.aiWebsitesComplete = true
      } else if (video === 'camp') {
        if (!this.tourGuideVideos.createCampComplete) {
          swal({
            icon: 'success',
            title: 'Success',
            text: 'Create Camp Step Completed. Great Work!',
          })
        }
        this.tourGuideVideos.createCampComplete = true
      } else if (video === 'membership') {
        if (!this.tourGuideVideos.createMembershipComplete) {
          swal({
            icon: 'success',
            title: 'Success',
            text: 'Create Membership Step Completed. Great Work!',
          })
        }
        this.tourGuideVideos.createMembershipComplete = true
      } else if (video === 'scheduledSession') {
        if (!this.tourGuideVideos.createScheduledSessionComplete) {
          swal({
            icon: 'success',
            title: 'Success',
            text: 'Create Scheduled Session Step Completed. Great Work!',
          })
        }
        this.tourGuideVideos.createScheduledSessionComplete = true
      } else if (video === 'increaseBusiness') {
        if (!this.tourGuideVideos.increaseBusinessComplete) {
          swal({
            icon: 'success',
            title: 'Success',
            text: 'Increasing Your Business Step Completed. Great Work!',
          })
        }
        this.tourGuideVideos.increaseBusinessComplete = true
      }
      axios
        .post(
          url,
          { videos: this.tourGuideVideos, userID: this.user._id },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          },
        )
        .then(res => {
          this.tourGuideVideos = res.data.tourGuideVideos
        })
        .catch(err => {
          swal({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message,
          })
        })
    },
    getTourGuideVideos() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'user/getTourGuideVideos?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(res => {
          this.tourGuideVideos = res.data.tourGuideVideos
        })
        .catch(err => {
          swal({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message,
          })
        })
    },
  },

  created() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getTourGuideVideos()
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    createdAIWebsite() {
      if (this.tourGuideVideos.aiWebsitesComplete) {
        return true
      } else {
        return false
      }
    },

    createdCamp() {
      if (
        this.tourGuideVideos.aiWebsitesComplete &&
        this.tourGuideVideos.createCampComplete
      ) {
        return true
      } else {
        return false
      }
    },

    createdMembership() {
      if (
        this.tourGuideVideos.aiWebsitesComplete &&
        this.tourGuideVideos.createCampComplete &&
        this.tourGuideVideos.createMembershipComplete
      ) {
        return true
      } else {
        return false
      }
    },

    createdScheduledSession() {
      if (
        this.tourGuideVideos.aiWebsitesComplete &&
        this.tourGuideVideos.createCampComplete &&
        this.tourGuideVideos.createMembershipComplete &&
        this.tourGuideVideos.createScheduledSessionComplete
      ) {
        return true
      } else {
        return false
      }
    },

    getProgress() {
      let progress = 0
      if (this.createdAIWebsite) {
        progress += 25
      }
      if (this.createdCamp) {
        progress += 25
      }
      if (this.createdMembership) {
        progress += 25
      }
      if (this.createdScheduledSession) {
        progress += 25
      }
      return progress + '%'
    },
  },
  mounted() {},
}
</script>
<style scoped>
.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.07);
}

.accordion-button.lock {
  color: white;
  background: gray;
}
</style>
