<template>
  <div class="wrapper container" id="app">
    <ClientLayout :user="user"></ClientLayout>

    <div class="wrapper container justify-content-center">
      <div class="card-form">
        <div
          class="card-form__inner"
          v-bind:style="{
            width: isMobile() ? '21rem' : '',
            marginLeft: isMobile() ? '1rem' : '',
          }"
        >
          <h3
            class="text-end"
            @click="showSubscriptionChoices()"
            style="cursor: pointer"
          >
            <span class="badge actionFeature"
              >{{ user.subscription.subscriptionName }} <br />
              <span style="font-size: 18px; color: white">{{
                user.subscription.subscriptionPayPeriod
              }}</span
              ><span
                v-if="
                  user.subscription.subscriptionName === 'Free' &&
                  !user.subscription.subscriptionPayPeriod
                "
                style="font-size: 15px; color: white"
                >Click to Upgrade</span
              >
            </span>
            <br />
          </h3>

          <img src="../../../public/SummerAthletes.png" class="card-img-top" />

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Profile
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Billing
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Security
              </button>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div
              class="card-form tab-pane fade show active mt-4"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="row">
                <div class="col">
                  <div class="card-input">
                    <label for="firstName" class="card-input__label"
                      >First Name</label
                    >
                    <input
                      type="text"
                      id="firstName"
                      class="card-input__input"
                      placeholder="First Name"
                      v-model="user.firstName"
                      required
                      readonly
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="card-input">
                    <label for="lastName" class="card-input__label"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      id="lastName"
                      class="card-input__input"
                      placeholder="Last Name"
                      v-model="user.lastName"
                      required
                      readonly
                    />
                  </div>
                </div>
                <div class="card-input">
                  <label for="email" class="card-input__label">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="card-input__input"
                    placeholder="Email"
                    v-model="update.email"
                    required
                  />
                </div>
              </div>
              <div class="card-input">
                <label>Address</label>
                <input
                  type="text"
                  id="address"
                  class="card-input__input"
                  placeholder="Address"
                  v-model="update.address"
                />
              </div>
              <div class="card-input">
                <label for="city" class="card-input__label">City</label>
                <input
                  type="text"
                  id="city"
                  placeholder="City"
                  class="card-input__input"
                  style="margin-bottom: 0"
                  v-model="update.city"
                />
              </div>

              <div class="row">
                <div class="col">
                  <div class="card-input">
                    <label for="cardMonth">State</label>
                    <select
                      required
                      class="card-input__input"
                      id="cardMonth"
                      v-model="update.state"
                    >
                      <option value="" disabled selected>State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="card-input">
                    <label for="cardCvv">Zip Code</label>
                    <input
                      type="text"
                      class="card-input__input"
                      placeholder="Zip Code"
                      v-model="update.zipCode"
                    />
                  </div>
                </div>
              </div>
              <div class="card-input">
                <label>Phone Number</label>
                <input
                  type="number"
                  id="phoneNumber"
                  class="card-input__input"
                  placeholder="Phone Number"
                  v-model="update.phoneNumber"
                />
              </div>
              <center>
                <button
                  class="card-form__button rounded-pill"
                  @click="this.updateUser"
                >
                  Update Personal Info
                </button>
              </center>
            </div>

            <div
              class="tab-pane fade mt-3"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="billing-section">
                <!-- Current Plan Card -->
                <div class="billing-card">
                  <div class="billing-card-header">
                    <i class="fas fa-credit-card"></i>
                    <h4>
                      Current Plan:
                      <span class="plan-name">{{
                        this.user.subscription.subscriptionName
                      }}</span>
                    </h4>
                  </div>
                  <div class="billing-card-content">
                    <div
                      class="upsell-banner"
                      v-if="this.user.subscription.subscriptionTier < 4"
                    >
                      <i class="fas fa-star"></i>
                      <span
                        >Upgrade to unlock more features and grow your
                        business!</span
                      >
                    </div>
                    <div class="plan-details">
                      <div class="detail-row">
                        <span class="detail-label">Amount</span>
                        <span class="detail-value">${{ getAmount }}</span>
                      </div>
                      <div class="detail-row">
                        <span class="detail-label">Billing Period</span>
                        <span class="detail-value">{{
                          this.user.subscription.subscriptionPayPeriod
                        }}</span>
                      </div>
                      <div class="detail-row">
                        <span class="detail-label">Start Date</span>
                        <span class="detail-value">{{ getStartDate }}</span>
                      </div>
                      <div class="detail-row">
                        <span class="detail-label">Next Renewal</span>
                        <span class="detail-value">{{ getRenewalDate }}</span>
                      </div>
                    </div>
                    <div @click="upgradePlan()" style="cursor: pointer">
                      <div
                        class="feature-comparison"
                        v-if="this.user.subscription.subscriptionTier > 2"
                      >
                        <h5>Upgrade Benefits:</h5>
                        <ul>
                          <li>
                            <i class="fas fa-check"></i> Marketing and Strategic
                            Planning
                          </li>
                          <li>
                            <i class="fas fa-check"></i> Liability Insurance
                            Handling
                          </li>
                          <li>
                            <i class="fas fa-check"></i> Quarterly Business
                            Reviews
                          </li>
                          <li>
                            <i class="fas fa-check"></i> Tailored Onboarding and
                            Training Sessions
                          </li>
                        </ul>
                      </div>
                      <div class="feature-comparison" v-else>
                        <h5>Upgrade Benefits:</h5>
                        <ul>
                          <li>
                            <i class="fas fa-check"></i> Recurring Memberships
                          </li>
                          <li>
                            <i class="fas fa-check"></i> Custom Website Domain
                          </li>
                          <li>
                            <i class="fas fa-check"></i> Unlimited Mass Texting
                          </li>
                          <li>
                            <i class="fas fa-check"></i> Free Priority Transfers
                            <i
                              class="fa-solid fa-bolt text-indigo-600 ml-2"
                            ></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Billing Information Card -->
                <div class="billing-card">
                  <div class="billing-card-header">
                    <i class="fas fa-user"></i>
                    <h4>Billing Information</h4>
                  </div>
                  <div class="billing-card-content">
                    <div class="detail-row">
                      <span class="detail-label">Name</span>
                      <span class="detail-value"
                        >{{ billingDetails.firstName }}
                        {{ billingDetails.lastName }}</span
                      >
                    </div>
                    <div class="detail-row">
                      <span class="detail-label">Email</span>
                      <span class="detail-value">{{
                        subscriptionDetails.profile.email
                      }}</span>
                    </div>
                    <div class="detail-row">
                      <span class="detail-label">Phone</span>
                      <span class="detail-value">{{
                        billingDetails.phoneNumber
                      }}</span>
                    </div>
                    <div class="detail-row">
                      <span class="detail-label">Address</span>
                      <span class="detail-value"
                        >{{ billingDetails.address }},
                        {{ billingDetails.city }}, {{ billingDetails.state }}
                        {{ billingDetails.zip }}</span
                      >
                    </div>
                    <div class="detail-row">
                      <span class="detail-label">Card on File</span>
                      <span class="detail-value">{{
                        subscriptionDetails.profile.paymentProfile.payment
                          .creditCard.cardNumber
                      }}</span>
                    </div>
                  </div>
                </div>

                <!-- Action Buttons -->
                <div class="billing-actions">
                  <button
                    class="action-button upgrade-button"
                    @click="upgradePlan()"
                  >
                    <i class="fas fa-arrow-up me-2"></i>Upgrade Plan
                  </button>
                  <button
                    class="action-button cancel-button"
                    @click="cancelPlan()"
                  >
                    <i class="fas fa-ban me-2"></i>Cancel Plan
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div class="card-input mt-4">
                <!-- Password -->
                <label for="password"
                  ><span style="color: red">**</span>Current Password</label
                >
                <input
                  type="password"
                  id="password"
                  class="card-input__input"
                  placeholder="Current Password"
                  v-model="update.password"
                  required
                />
              </div>
              <div class="card-input">
                <!-- Password -->
                <label for="password">New Password</label>
                <input
                  type="password"
                  id="password"
                  class="card-input__input"
                  placeholder="New Password"
                  v-model="update.newPassword"
                />
              </div>

              <center>
                <button
                  class="card-form__button rounded-pill"
                  @click="this.updateUser"
                >
                  Update Security Info
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import swal2 from 'sweetalert2'
import axios from 'axios'
import ClientLayout from '../components/ClientLayout.vue'
import moment from 'moment'
export default {
  components: { ClientLayout },
  data() {
    return {
      user: {},
      tourGuideVideos: {
        aiWebsitesComplete: false,
        createCampComplete: false,
        createMembershipComplete: false,
        createScheduledSessionComplete: false,
        increaseBusinessComplete: false,
      },
      update: {
        email: '',
        password: '',
        newPassword: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
      },
      subscriptionDetails: {
        profile: {
          paymentProfile: {
            payment: {
              creditCard: {
                cardNumber: '',
              },
            },
          },
          shippingProfile: {},
        },
      },
      billingDetails: {},
    }
  },
  methods: {
    //needs try catch validation with
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    showSubscriptionChoices() {
      document.getElementById('openAdvertisementPopUp').click()
    },
    async updateUser() {
      let url = import.meta.env.VITE_APP_DB_URL + 'user/updateAccountSettings'
      axios
        .post(
          url,
          {
            id: this.user._id,
            email: this.update.email,
            password: this.update.password,
            newPassword: this.update.newPassword,
            newEmail: this.update.email,
            newAddress: this.update.address,
            newPhoneNumber: this.update.phoneNumber,
            newCity: this.update.city,
            newState: this.update.state,
            newZipCode: this.update.zipCode,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          },
        )
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
      swal('Success', 'User Info Updated', 'success')
    },
    async getUserDetails() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'user/getDetails?id=' + this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response.data)
          this.user = response.data.user
          this.update.email = this.user.email
          this.update.address = this.user.address
          this.update.state = this.user.state
          this.update.city = this.user.city
          this.update.zipCode = this.user.zipCode
          this.update.phoneNumber = this.user.phoneNumber
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    async getBillingDetails() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'user/getBillingDetails?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response.data)
          this.subscriptionDetails = response.data.billingDetails
          this.billingDetails =
            response.data.billingDetails.profile.shippingProfile
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    getTourGuideVideos() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'user/getTourGuideVideos?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(res => {
          this.tourGuideVideos = res.data.tourGuideVideos
        })
        .catch(err => {
          swal({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message,
          })
        })
    },

    upgradePlan() {
      if (this.user.subscription.subscriptionTier === 4) {
        swal(
          'Thank You!',
          'You are already on the highest tier of Summer Athletes. We sincerely thank you for being a true game changer!',
          'success',
        )
        return
      } else {
        document.getElementById('openAdvertisementPopUp').click()
      }
      return
    },
    async cancelPlan() {
      //will confirm swal alert to delete their account

      if (
        !this.tourGuideVideos.aiWebsitesComplete ||
        !this.tourGuideVideos.createCampComplete ||
        !this.tourGuideVideos.createMembershipComplete ||
        !this.tourGuideVideos.createScheduledSessionComplete ||
        !this.tourGuideVideos.increaseBusinessComplete
      ) {
        swal({
          title: 'Hold On!',
          text: `To avoid incurring cancellation fees, each step of the tour guide must be marked as completed.`,
          icon: 'info',
        })
        this.$router.push('/Dashboard/VideoTour')
        return
      } else {
        //swal will confirm the action
        swal({
          title: 'Permanently Delete Your Account?',
          text: 'Cancelling your plan will delete your account and all customer/event data associated with it. All websites created on the platform will be terminated. Do you wish to continue?',
          icon: 'warning',
          buttons: {
            cancel: "I'm Not Ready",
            confirm: 'Permanently Delete Account',
          },
          dangerMode: true,
        }).then(willDelete => {
          // Delete account
          if (willDelete) {
            swal2
              .fire({
                title: 'One Last Thing!',
                html: `If you are unsatisfied with the price, please reach out to your sales representative or contact support@summerathletes.com to discuss receiving a discounted rate instead of cancelling. <br/><br/> Just tell them code <strong>DISCOUNT5150</strong> to receive up to 50% off!`,
                icon: 'info',
                reverseButtons: true,
                confirmButtonText: 'I Want the Discount!',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
              })
              .then(willTakeDiscount => {
                if (willTakeDiscount.isConfirmed) {
                  swal2.fire({
                    title: 'Thank You!',
                    html: `We are happy to have you stay! <br/><br/> Please contact support@summerathletes.com or your sales representative to begin processing your discount request.`,
                    icon: 'success',
                  })
                  this.$router.push('/Dashboard')
                } else {
                  let url =
                    import.meta.env.VITE_APP_DB_URL + 'user/cancelSubscription'
                  axios
                    .post(
                      url,
                      {
                        subscriptionID: this.user.subscription.subscriptionID,
                        email: this.user.email,
                        firstName: this.user.firstName,
                        manualCancellation: true,
                      },
                      {
                        headers: {
                          Authorization:
                            'Bearer ' + localStorage.getItem('jwt'),
                        },
                      },
                    )
                    .then(response => {
                      console.log(response.data)
                      swal2.fire({
                        title: 'Subscription Cancellation Processed',
                        html: `<strong>We are sad to see you go!</strong></strong> <br/><br/> Its not too late to get your discount! Please contact support@summerathletes.com or your sales representative for next steps!`,
                        icon: 'error',
                      })
                      this.$router.push('/Dashboard')
                    })
                    .catch(function (err) {
                      console.log(err)
                      swal('Error', 'Could Not Cancel at this Time', 'error')
                    })
                }
              })
          }
        })
      }
    },
  },

  created() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getUserDetails()
    this.getBillingDetails()
    this.getTourGuideVideos()
  },
  computed: {
    getStartDate() {
      return new Date(this.user.subscription.subscriptionDate).toDateString()
    },

    getScheduledSessions() {
      let scheduledSessions = []
      for (const camp of this.user.camps) {
        if (camp.sessionSupported) {
          scheduledSessions.push(camp)
        }
      }
      return scheduledSessions
    },

    getRenewalDate() {
      const memberStartDate = moment(
        new Date(this.user.subscription.subscriptionDate),
        'YYYY-MM-DD',
      )
      let workingDate = moment(new Date(), 'YYYY-MM-DD')
      while (memberStartDate.isBefore(workingDate)) {
        if (this.user.subscription.subscriptionPayPeriod === 'monthly') {
          memberStartDate.add(1, 'months')
        } else {
          memberStartDate.add(12, 'months')
        }
      }
      return new Date(memberStartDate).toDateString()
    },

    getAmount() {
      let returnValue = []
      if (this.subscriptionDetails.amount) {
        let amount = this.subscriptionDetails.amount
          .toString()
          .split('')
          .reverse()
        for (let i = 0; i < amount.length; i++) {
          returnValue.push(amount[i])
          if ((i + 1) % 3 === 0 && i + 1 !== amount.length) {
            returnValue.push(',')
          }
        }
        returnValue = returnValue.reverse()
        returnValue.push('.00')
        returnValue = returnValue.join('')
      }
      return returnValue
    },
  },
}
</script>
<style scoped>
.actionFeature {
  background-color: #a39ed0;
}

.billing-section {
  padding: 20px;
}

.billing-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  overflow: hidden;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.billing-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.billing-card-header {
  background: #f8f9fa;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 12px;
}

.billing-card-header i {
  color: #363166;
  font-size: 1.2rem;
}

.billing-card-header h4 {
  margin: 0;
  color: #363166;
  font-size: 1.1rem;
  font-weight: 600;
}

.billing-card-header .plan-name {
  background: #363166;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  margin-left: 8px;
}

.billing-card-content {
  padding: 20px;
}

.upsell-banner {
  background: linear-gradient(135deg, #363166, #4a4380);
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
}

.upsell-banner i {
  color: #ffd700;
}

.plan-details {
  display: grid;
  gap: 12px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.detail-row:last-child {
  border-bottom: none;
}

.detail-label {
  color: #666;
  font-weight: 500;
}

.detail-value {
  color: #363166;
  font-weight: 500;
}

.feature-comparison {
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.feature-comparison h5 {
  color: #363166;
  margin-bottom: 12px;
  font-weight: 600;
}

.feature-comparison ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-comparison li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  color: #666;
}

.feature-comparison li i {
  color: #28a745;
}

.billing-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 24px;
}

.action-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upgrade-button {
  background: linear-gradient(135deg, #363166, #4a4380);
  color: white;
  font-weight: 600;
  padding: 14px 28px;
  box-shadow: 0 4px 6px rgba(54, 49, 102, 0.2);
}

.upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(54, 49, 102, 0.3);
}

.cancel-button {
  background: #dc3545;
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .billing-actions {
    grid-template-columns: 1fr;
  }

  .billing-card {
    margin-bottom: 16px;
  }
}
</style>
