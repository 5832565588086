<template>
  <div class="max-w-md mx-auto">
    <div class="bg-white rounded-3xl shadow-lg p-8">
      <!-- Header -->
      <div class="flex justify-between items-center mb-8">
        <h2 class="text-2xl font-medium text-gray-900">Summer Athletes</h2>
        <span
          class="px-4 py-1.5 bg-indigo-50 text-indigo-600 rounded-full text-sm ml-2"
        >
          {{ subscriptionName }}
        </span>
      </div>

      <!-- Billing Period -->
      <div class="flex justify-between items-center mb-8">
        <span class="text-gray-600">Billing Period - {{ period }}ly</span>
        <button
          @click="changeBillingPeriod()"
          class="text-indigo-600 hover:text-indigo-700"
        >
          Change
        </button>
      </div>

      <!-- Annual Savings Message -->
      <div v-if="period === 'Year'" class="mb-6">
        <div
          class="flex items-center justify-center bg-green-50 text-green-700 px-4 py-2 rounded-lg"
        >
          <i class="fas fa-gift mr-2"></i>
          <span class="text-sm font-medium"
            >Receiving 2 Months Free with Annual Billing!</span
          >
        </div>
      </div>

      <!-- Price -->
      <div class="flex justify-between items-center mb-10">
        <span class="text-gray-600">Today's Total:</span>
        <span class="text-xl font-medium">${{ getPrice }}</span>
      </div>

      <!-- Purchase Button -->
      <div class="space-y-3">
        <button
          @click="submitPayment"
          :disabled="cardInfoIncomplete"
          class="w-full py-3.5 bg-indigo-600 text-white rounded-xl font-medium transition-all duration-200 group relative overflow-hidden"
          :class="{ 'opacity-50 cursor-not-allowed': cardInfoIncomplete }"
        >
          <span class="relative z-10 flex items-center justify-center gap-2">
            Purchase
            <svg
              class="w-5 h-5 transition-transform duration-200 transform group-hover:translate-x-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </span>
          <div
            class="absolute inset-0 bg-indigo-700 transition-transform duration-200 transform translate-y-full group-hover:translate-y-0"
          ></div>
        </button>

        <p class="text-center text-gray-500 text-sm">Cancel at anytime</p>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import swal2 from 'sweetalert2'
export default {
  props: {
    isMobile: Boolean,
    subscriptionName: String,
    period: String,
    price: Number,
    monthlyPrice: Number,
    billingInfo: Object,
    isExternal: Boolean,
    trialApplied: Boolean,
    isDigitalAI: Boolean,
    lock: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    back() {
      this.$emit('back')
    },
    submitPayment() {
      this.$emit('proceed')
    },

    changeBillingPeriod() {
      if (this.period !== 'Month') {
        swal2
          .fire({
            title: 'Are you sure?',
            text: 'You will lose out on up to 30% savings by paying monthly.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!',
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$emit('changeBillingPeriod')
            }
          })
      } else {
        this.$emit('changeBillingPeriod')
      }
    },
  },
  created() {},
  computed: {
    getPrice() {
      return numeral(this.price).format('0,0.00')
    },

    getPotentialPriceFees() {
      return numeral(this.price * 0.07).format('0,0.00')
    },

    getPotentialYearlyPrice() {
      return numeral(this.monthlyPrice * 12).format('0,0.00')
    },

    getSavingPercentage() {
      return Math.floor(100 - (this.price / (this.monthlyPrice * 12)) * 100)
    },

    cardInfoIncomplete() {
      if (this.billingInfo.cardNumber == '') {
        return true
      }
      if (this.billingInfo.cardMonth == '') {
        return true
      }
      if (this.billingInfo.cardYear == '') {
        return true
      }
      if (this.billingInfo.cardCvv == '') {
        return true
      }
      if (this.billingInfo.cardFirstName == '') {
        return true
      }
      if (this.billingInfo.cardLastName == '') {
        return true
      }
      if (this.billingInfo.zipCode == '') {
        return true
      }

      return false
    },
  },
}
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.actionFeature {
  background-color: #a39ed0;
}

.link {
  color: #0d6efd;
  text-decoration: underline;
}

.link:hover {
  opacity: 0.8;
}
</style>
