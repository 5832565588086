<template>
  <div class="min-h-screen flex flex-col">
    <NavBar></NavBar>

    <div class="flex-grow mt-4">
      <div
        v-if="isLoading"
        class="row"
        style="
          max-width: 100%;
          margin-bottom: 2%;
          margin-left: 0%;
          margin-right: 0%;
        "
      >
        <div v-for="n in 8" :key="n" class="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div class="bg-white rounded-lg shadow-md p-4 animate-pulse">
            <div class="w-full h-64 bg-gray-200 rounded-lg mb-4"></div>
            <div class="h-4 bg-gray-200 rounded w-3/4 mb-3"></div>
            <div class="h-4 bg-gray-200 rounded w-1/2 mb-3"></div>
            <div class="h-4 bg-gray-200 rounded w-1/2 mb-3"></div>
            <div class="h-4 bg-gray-200 rounded w-1/4 mb-3"></div>
            <div class="h-4 bg-gray-200 rounded w-1/2"></div>
          </div>
        </div>
      </div>

      <div v-else-if="searchResults.length === 0" class="ml-4 flex">
        <p class="text-lg font-semibold text-gray-500">No results found.</p>
      </div>

      <div
        v-else
        class="row"
        style="
          max-width: 100%;
          margin-bottom: 2%;
          margin-left: 0%;
          margin-right: 0%;
        "
      >
        <div
          class="col-sm-6 col-md-4 col-lg-3"
          v-for="item in searchResults"
          :key="item._id"
        >
          <Card
            :type="item.type"
            :title="item.name"
            :dates="item.dates"
            :price="item.price"
            :ageRange="item.ageRange"
            :gender="item.gender"
            :priceObject="item.priceObject"
            :buttonText="
              item.type === 'membership'
                ? 'View Membership'
                : item.sessionSupported
                ? 'View Session'
                : 'View Camp'
            "
            :image="item.image"
            :buttonColor="$route.params.primaryColor || '#19d412'"
            :placeholder="item.type === 'athlete' ? 'headshot' : 'landscape'"
            :id="item.id"
          />
        </div>
      </div>
      <!-- <div class="pagination">
        <button @click="prevPage" :disabled="!pagination.hasPrevPage">
          Previous
        </button>
        <span
          >Page {{ pagination.currentPage }} of
          {{ pagination.totalPages }}</span
        >
        <button @click="nextPage" :disabled="!pagination.hasNextPage">
          Next
        </button>
      </div> -->
    </div>
    <NavFooter></NavFooter>
  </div>
</template>

<script>
import axios from 'axios'
import NavBar from '../components/NavBar.vue'
import NavFooter from '../components/NavFooter.vue'
import Card from '../components/Card.vue'
export default {
  components: {
    NavBar,
    NavFooter,
    Card,
  },
  data() {
    return {
      searchResults: [],
      schoolBranding: {},
      pagination: {},
      isLoading: true,
    }
  },
  methods: {
    // Method to determine if the user is on a mobile device
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    // Method to handle the search and fetch data from the API
    handleSearch() {
      console.log('handling search')
      this.isLoading = true
      let url = import.meta.env.VITE_APP_DB_URL + 'shop/search'
      const params = {
        ...this.$route.query,
        coachId: this.$route.params.coachId,
      }

      axios
        .get(url, { params })
        .then(response => {
          console.log('Search Results:', response.data)
          this.searchResults = response.data.results || []
          this.pagination = response.data.pagination
        })
        .catch(error => {
          console.error('Error performing search:', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  created() {
    this.handleSearch() // Fetch search results on page load
  },
  mounted() {},
  watch: {
    // Watch for changes in the query parameters and re-fetch results accordingly
    '$route.query': {
      handler(newQuery) {
        console.log('Query parameters updated:', newQuery)
        this.handleSearch()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style>
</style>